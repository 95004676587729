import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './_formStep.scss';
import Image from '../Image/Image';

const FormStep = ({ index, currentStep, form, title, onClickHandler, clickable, narrow }) => {

    return (
        <div className={classNames([
            'form-step__content-wrapper',
            { 'form-step__content-wrapper--collapsed': index !== currentStep },
            { 'form-step__content-wrapper--narrow': narrow === true }
        ])}
        >
            <div onClick={onClickHandler} className={classNames([
                'form-step__step-info',
                { 'form-step__step-info--selected': index === currentStep },
                { 'form-step__clickable': clickable === true }
            ])}
            >
                {title}
                <Image className={classNames([
                    'form-step__progress',
                    { 'form-step__progress--complete': index !== currentStep && currentStep > index }
                ])}
                    alt="logo"
                    image="progress"
                />
            </div>
            <div className={classNames([
                'form-step__content',
                { 'form-step__content--expanded': index === currentStep }
            ])}
            >
                {   /*Redux forms doesn't work with multiple rendered forms with same name*/
                    index === currentStep
                        ? form
                        : <></>
                }
            </div>
        </div>
    );
};

FormStep.propTypes = {
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    currentStep: PropTypes.number.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    clickable: PropTypes.bool.isRequired,
    narrow: PropTypes.bool,
    form: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
export default FormStep;