import * as loadingActions from '../../../Loading/loadingActions';
import * as facilityEnrollmentPageService from './facilityEnrollmentPageService';
import * as types from './facilityEnrollmentPageTypes';

export function enrollIntoAFFacilityWithinProgram(membersAFFacilityWithinProgramEnrollment)
{
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.ENROLL_MEMBER_IN_FACILITTY));
        return facilityEnrollmentPageService.enrollIntoAFFacilityWithinProgram(membersAFFacilityWithinProgramEnrollment)
            .finally(()=>dispatch(loadingActions.unsetLoading(types.ENROLL_MEMBER_IN_FACILITTY)));
    };
}