
const facilityLocationTypes = Object.freeze({
    FitnessFacility: 0,
    FitnessClass: 1,
    FlexInstructor: 2
});

export const facilityLocationTypeNamesMap = Object.freeze({
    [facilityLocationTypes.FitnessFacility]: 'Fitness Facility',
    [facilityLocationTypes.FitnessClass]: 'Fitness Class',
    [facilityLocationTypes.FlexInstructor]: 'Flex Instructor',
});

export const facilityLocationTypeColorsMap = Object.freeze({
    [facilityLocationTypes.FitnessFacility]: 'blue',
    [facilityLocationTypes.FitnessClass]: 'green',
    [facilityLocationTypes.FlexInstructor]: 'beet',
});

export default facilityLocationTypes;
