const PREFIX = 'RECIPIENTS_LIST_';

export const GET_VOUCHERS = `${PREFIX}GET_VOUCHERS`;
export const SET_VOUCHERS = `${PREFIX}SET_VOUCHERS`;
export const UPLOAD_RECIPIENTS_LIST = `${PREFIX}UPLOAD`;
export const SET_RECIPIENTS_FILE_CONTENT_VALIDATION_ERRORS = `${PREFIX}SET_RECIPIENTS_FILE_CONTENT_VALIDATION_ERRORS`;
export const PREVIEW_VOUCHER = `${PREFIX}PREVIEW_VOUCHER`;
export const SET_VOUCHER_EMAIL_PREVIEW = `${PREFIX}SET_VOUCHER_EMAIL_PREVIEW`;
export const SET_IS_PUBLISH_POPUP_VISIBLE = `${PREFIX}SET_IS_PUBLISH_POPUP_VISIBLE`;
export const GET_IS_VOUCHER_PROGRAM_PUBLISHED = `${PREFIX}GET_IS_VOUCHER_PROGRAM_PUBLISHED`;
export const SET_IS_VOUCHER_PROGRAM_PUBLISHED = `${PREFIX}SET_IS_VOUCHER_PROGRAM_PUBLISHED`;
export const PUBLISH_VOUCHER_PROGRAM = `${PREFIX}PUBLISH_VOUCHER_PROGRAM`;
export const SET_IS_PREVIEW_POPUP_VISIBLE = `${PREFIX}SET_IS_PREVIEW_POPUP_VISIBLE`;