import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

function MultiLineCell({ value }) {
    return value.map((element, i) => (
        <Fragment key={i}>
            <span>{element}</span>
            <br />
        </Fragment>
    ));
}

MultiLineCell.propTypes = {
    value: PropTypes.array
};

export default MultiLineCell;
