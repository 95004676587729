import { createSelector } from 'reselect';
import { GET_MANAGERS_LIST, DELETE_MANAGER } from './partnerManagersListTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_MANAGERS_LIST || i === DELETE_MANAGER)
        .size;

    return size > 0;
};

const getManagers = state => state.partnerManagersListReducer.get('managers');

export default createSelector(
    [getIsLoading, getManagers],
    (isLoading, managers) => ({
        isLoading,
        managers
    })
);
