import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_bullet-cell.scss';

function BulletCell({ column, row, value }) {
    const props = column.getProps(row);
    return <span className={'bullet-cell'}>
        <span className={classNames([
            'bullet-cell__bullet',
            `bullet-cell__bullet_${props.theme}`])}
        />
        <span className="bullet-cell__text">
            {value}
        </span>
    </span>;
}

BulletCell.propTypes = {
    value: PropTypes.string.isRequired,
    theme: PropTypes.oneOf(['blue', 'green', 'yellow', 'beet', 'orange']),
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
};

export default BulletCell;