const membersTransferResources = {
    labelFacilityMemberID: 'Key fob/Facility Member ID',
    labelEmployeeName: 'Employee Name',
    labelPlans: 'Plans',
    labelFacilityFromName: 'Facility Name From',
    labelFacilityFromID: 'Facility ID From',
    labelFacilityToName: 'Facility Name To',
    labelFacilityToID: 'Facility ID To',
    labelTransferStatus: 'Status',
    labelTransferReason: 'Reason',
    title: 'Members',
    defaultSortColumn: 'memberFirstName',
    accessorFacilityMemberID: 'facilityMemberID',
    accessorEmployeeName: 'memberFirstName',
    accessorFacilityFromName: 'facilityFromName',
    accessorFacilityFromIDNumber: 'facilityFromIDNumber',
    accessorFacilityToName: 'facilityToName',
    accessorFacilityToIDNumber: 'facilityToIDNumber',
    accessorTransferStatus: 'transferStatus',
    accessorTransferReason: 'transferMessage',
    accessorPlanName: 'planName',
    transferButtonText: 'Transfer',
    backToMemberButtonText: 'Back To Member Page'
};
export default membersTransferResources;
