import { fromJS } from 'immutable';

export default fromJS({
    paymentWithdrawal: {},
    periods: [],
    partners: [],
    popupData: {
        isAchUploadAvailable: false,
        achUploadErrorMessage: null
    },
});