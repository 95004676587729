import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import selectors from './partnerManagerAreaSelectors';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';
import { getIsGroupMembershipAddedToPartner, getGroupMembership } from './partnerManagerAreaActions';
import { getManager } from '../Shared/PartnerManager/managePartnerManagerActions';
import Spinner from '../../components/Spinner/Spinner';

function PartnerManagerArea({ getManager, getIsGroupMembershipAddedToPartner, getGroupMembership, isLoading, currentRoute, filteredRoutes, userId, partnerId }) {
    useEffect(() => {
        getManager(userId);
    }, [userId]);
    useEffect(() => {
        if (partnerId) {
            getGroupMembership(partnerId);
            getIsGroupMembershipAddedToPartner(partnerId);
        }
    }, [partnerId]);
    return <>
        {
            isLoading
                ? <Spinner />
                : <Switch>
                    {filteredRoutes.map((route, i) => (
                        <CustomRoute
                            key={i}
                            path={route.path}
                            currentRoute={route}
                            filteredRoutes={filteredRoutes}
                        />
                    ))}

                    <Redirect
                        from={currentRoute.path}
                        to={filteredRoutes[0].path}
                    />
                </Switch>
        }
    </>;
};

const mapDispatchToProps = {
    getManager,
    getIsGroupMembershipAddedToPartner,
    getGroupMembership
};

PartnerManagerArea.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentRoute: routeType.isRequired,
    filteredRoutes: PropTypes.arrayOf(routeType),
    userId: PropTypes.number.isRequired,
    partnerId: PropTypes.number,
    getManager: PropTypes.func.isRequired,
    getIsGroupMembershipAddedToPartner: PropTypes.func.isRequired,
    getGroupMembership: PropTypes.func.isRequired,
};

export default connect(selectors, mapDispatchToProps)(PartnerManagerArea);
