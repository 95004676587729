import React from 'react';
import PropTypes from 'prop-types';
import './_call-to-action.scss';

function CallToAction(props) {
    return (
        <div className={`call-to-action call-to-action--${props.theme}`}>
            {props.children}
        </div>
    );
}

CallToAction.defaultProps = {
    theme: 'right-image'
};

CallToAction.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    theme: PropTypes.string
};

export default CallToAction;
