import {
    SET_LOADING,
    UNSET_LOADING,
    GET_INSURANCE_INFO_SUCCESS
} from './types';

const setLoading = () => ({
    type: SET_LOADING
});

const unsetLoading = () => ({
    type: UNSET_LOADING
});

const getInsuranceInfoSuccess = insuranceInfo => ({
    type: GET_INSURANCE_INFO_SUCCESS,
    payload: { insuranceInfo }
});

const getInsuranceInfo = membersGuids => (dispatch, getState, { InsuranceInfoService }) => {
    dispatch(setLoading());

    InsuranceInfoService.getInsuranceInfo(membersGuids)
        .then((insuranceInfo) => {
            dispatch(getInsuranceInfoSuccess(insuranceInfo));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

export default { getInsuranceInfo };
