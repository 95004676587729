const reimbursementTypes = Object.freeze({
    none: 0,
    toMember: 4,
    toFacility: 5,
    toBilling: 6,
    check: 7,
    byPaycheck: 8,
    corpWellness: 9,
    noReimbursements: 10,
});

export const reimbursementTypesFullNamesMap = Object.freeze({
    [reimbursementTypes.none]: 'None',
    [reimbursementTypes.toMember]: 'Direct To Member',
    [reimbursementTypes.toFacility]: 'Direct To Bank Account',
    [reimbursementTypes.toBilling]: 'Direct To Billing Co',
    [reimbursementTypes.check]: 'Check To Facility',
    [reimbursementTypes.byPaycheck]: 'By Employee Paycheck',
    [reimbursementTypes.corpWellness]: 'Corporate Wellness',
    [reimbursementTypes.noReimbursements]: 'No Reimbursements'
});
export default reimbursementTypes;
