import * as types from './partnerListTypes';
import initialState from './partnersListInitialState';
import { fromJS } from 'immutable';

export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_PARTNER_SUCCESS:
            return state.updateIn(['partners'], arr =>
                arr.push(fromJS(action.partner))
            );
        case types.GET_PARTNERS_LIST_SUCCESS:
            return state.set('partners', fromJS(action.partners));
        case types.UPDATE_PARTNERS_SUCCESS:
            return state.updateIn(['partners'], partners =>
                partners.map((partner) => {
                    return partner.get('id') === action.partner.id
                        ? fromJS(action.partner)
                        : partner;
                })
            );
        default:
            return state;
    }
}
