import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { MuiInputText } from '../controls/MuiInputText';
import * as validationRules from './confirmPasswordValidation';
import confirmPasswordResources from './confirmPasswordResources';

function ConfirmPassword({ isForChange }) {
    return (
        <>
            {isForChange ?
                (
                    <Field
                        id="new_password"
                        name="newPassword"
                        label={confirmPasswordResources.labelNewPassword}
                        secureTextEntry
                        component={MuiInputText}
                        validate={[
                            validationRules.newPasswordRequired,
                            validationRules.newPasswordMaxLength30,
                            validationRules.passwordRegex,
                        ]}
                    />
                )
                : (
                    <Field
                        id="password"
                        name="password"
                        label={confirmPasswordResources.labelPassword}
                        secureTextEntry
                        component={MuiInputText}
                        validate={[
                            validationRules.passwordRequired,
                            validationRules.passwordMaxLength30,
                            validationRules.passwordRegex,
                        ]}
                    />
                )}
            <Field
                id="confirm_password"
                name="confirmPassword"
                label={confirmPasswordResources.labelConfirmPassword}
                secureTextEntry
                component={MuiInputText}
                validate={[
                    validationRules.confirmPasswordRequired,
                    validationRules.confirmPasswordMaxLength30,
                ]}
            />
        </>
    );
}

ConfirmPassword.propTypes = {
    isForChange: PropTypes.bool,
};

export default ConfirmPassword;
