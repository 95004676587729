import React from 'react';
import PropTypes from 'prop-types';
import './_additional-details.scss';

const AdditionalDetails = ({ title, text, icon }) => {
    return (
        <div className="additional-details">
            <div className="additional-details__icon">
                {icon}
            </div>
            <div className="additional-details__content">
                {title && <h4 className="additional-details__title">{title}</h4>}
                <span className="additional-details__description">
                    {text}
                </span>
            </div>
        </div>
    );
};

AdditionalDetails.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.element
};

export default AdditionalDetails;
