import http from '../../http/base';
import { API_PATHS } from '../../http/configs';
import routes from '../../routing/routes';

const returnUrl = routes.home.path.concat('?isLoginPopupOpen=true');

export function forgotPassword(userName, captchaToken) {
    return http.post(API_PATHS.resetPassword, null, { params: { userName, returnUrl }, headers: { 'g-recaptcha-response': captchaToken } });
}

export function resend(userName, isCaptchaValid) {
    return http.post(API_PATHS.resetPassword, null, { params: { userName, returnUrl, isCaptchaValid } });
}
