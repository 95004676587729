export const OPTUM_ALT_TAG = 'Optum';
export const FITON_HEALTH_ALT_TAG = 'FitOn Health Move';
export const ASH_ALT_TAG = 'American Specialty Health';
export const CORVALLIS_ALT_TAG = 'Corvallis';
export const UCARE_ALT_TAG = 'UCare';
export const AVERA_ALT_TAG = 'Avera';
export const HEALTHPARTNERS_ALT_TAG = 'HealthPartners';
export const MEDICA_ALT_TAG = 'Medica';
export const PREFERREDONE_ALT_TAG = 'PreferredOne';
export const TIVITY_ALT_TAG = 'Tivity';
export const ANYTIME_FITNESS_ALT_TAG = 'Anytime Fitness';
export const GOLDS_ALT_TAG = 'Gold\'s Gym';
export const BAR_METHOD_ALT_TAG = 'The Bar Method';
export const TEN_GYM_ALT_TAG = '10 Gym';
export const KROC_CENTER_ALT_TAG = 'Kroc Center';
export const FRUITA_ALT_TAG = 'Fruita';

export const GYM_PARTNER_IMAGES = [{ imageName: 'anytimeFitness', alt: ANYTIME_FITNESS_ALT_TAG, link: 'https://www.anytimefitness.com/' }, { imageName: 'gold', alt: GOLDS_ALT_TAG, link: 'https://www.goldsgym.com/' }, { imageName: 'barMethod', alt: BAR_METHOD_ALT_TAG, link: 'https://barmethod.com/' }, { imageName: 'ten', alt: TEN_GYM_ALT_TAG, link: 'https://10gym.com/' }, { imageName: 'kroc', alt: KROC_CENTER_ALT_TAG, link: 'https://www.kroccda.org/kroc-cda/' }, { imageName: 'fruita', alt: FRUITA_ALT_TAG, link: 'https://www.fruita.org/parksrec' }];
export const MEDICAL_PARTNER_IMAGES = [{ imageName: 'ucare', alt: UCARE_ALT_TAG, link: 'https://www.ucare.org/' }, { imageName: 'avera', alt: AVERA_ALT_TAG, link: 'https://www.avera.org/' }, { imageName: 'healthpartners', alt: HEALTHPARTNERS_ALT_TAG, link: 'https://www.healthpartners.com/' }, { imageName: 'medica', alt: MEDICA_ALT_TAG, link: 'https://www.medica.com/' }, { imageName: 'preferredone', alt: PREFERREDONE_ALT_TAG, link: 'https://www.preferredone.com/' }, { imageName: 'tivity', alt: TIVITY_ALT_TAG, link: 'https://www.tivityhealth.com/' }];
