import PropTypes from 'prop-types';
import { roleType } from '../enums/roles';

export const authUserType = PropTypes.shape({
    userId: PropTypes.number,
    role: roleType,
    fullName: PropTypes.string,
    memberGroups: PropTypes.arrayOf(
        PropTypes.shape({
            fullName: PropTypes.string
        })
    )
});
