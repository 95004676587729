import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Table from '../../../components/InfoBox/Table';
import partnerGroupMemberhipsPaymentsResources from './partnerGroupMemberhipsPaymentsResources';
import actions from './partnerGroupMemberhipsPaymentsActions';
import selectors from './partnerGroupMemberhipsPaymentsSelectors';
import moment from 'moment';
import { MONTH_YEAR } from '../../../constants/formats';
import { useFlags } from 'launchdarkly-react-client-sdk';
import programPaymentTypes from '../../../enums/programPaymentTypes';
import './_partner-group-memberships-payments.scss';
import PeriodsFilterForm from '../../Shared/Periods/PeriodsFilterForm';
import ExcelImage from '../../../components/Image/ExcelImage/ExcelImage';

function PartnerGroupMembershipsPayments({
    isLoading,
    getClosedUsagePeriods,
    payments,
    getPayments,
    downloadPayments,
    paymentsFilter,
    usagePeriods,
    partnerId,
    programPaymentTypeID,
    getPeriodsUntilCurrent,
    getProgramPaymentTypeID,
    defaultPeriodID,
    selectedPeriodIDFrom,
    selectedPeriodIDTo,
}) {
    const { gmrAddProgram } = useFlags();
    const [programPaymentTypeIDLoaded, setProgramPaymentTypeIDLoaded] = useState(false);
    useEffect(() => {
        getProgramPaymentTypeID(partnerId).then(() => { setProgramPaymentTypeIDLoaded(true); });
    }, [partnerId]);
    useEffect(() => {
        if (!programPaymentTypeIDLoaded) {
            return;
        }
        if (programPaymentTypeID === programPaymentTypes.memberPaid || programPaymentTypeID === programPaymentTypes.partnerMemberPaid) {
            getPeriodsUntilCurrent();
        }
        else if (programPaymentTypeID === programPaymentTypes.partnerPaid) {
            getClosedUsagePeriods();
        }
    }, [programPaymentTypeIDLoaded]);
    useEffect(() => {
        if (!programPaymentTypeIDLoaded) {
            return;
        }
        if (defaultPeriodID) {
            getPayments(programPaymentTypeID, partnerId, defaultPeriodID, defaultPeriodID);
        }
    }, [defaultPeriodID]);

    const gmColumns = useMemo(
        () => [
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelVisitMonth,
                accessor: 'visitPeriodStartDate',
                Cell: cell => moment(cell.value).format(MONTH_YEAR)
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalNumberOfMemberships,
                accessor: 'totalNumberOfMemberships'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelNumberOfActivatedMemberships,
                accessor: 'numberOfActivatedMemberships'
            },
            ...(gmrAddProgram
                ?
                [{
                    Header: partnerGroupMemberhipsPaymentsResources.labelProgramFee,
                    accessor: 'monthlyInstallment',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                }]
                :
                [{
                    Header: partnerGroupMemberhipsPaymentsResources.labelMonthlyInstallment,
                    accessor: 'monthlyInstallment',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                },
                {
                    Header: partnerGroupMemberhipsPaymentsResources.labelStartupFee,
                    accessor: 'startupFee',
                    Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
                }]),
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelRenewalFee,
                accessor: 'renewalFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelFacilityAccessFee,
                accessor: 'facilityAccessFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelOneTimeFee,
                accessor: 'oneTimeFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 }),
                minWidth: 120
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelOneTimeFeeReason,
                accessor: 'oneTimeFeeReason'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalPayment,
                accessor: 'totalPayment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        ],
        [partnerGroupMemberhipsPaymentsResources]
    );

    const gmrColumns = useMemo(
        () => [
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelVisitMonth,
                accessor: 'visitPeriodStartDate',
                Cell: cell => moment(cell.value).format(MONTH_YEAR)
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelRegisteredMembers,
                accessor: 'numberOfActivatedMemberships'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelReimbursedMembers,
                accessor: 'numberOfProcessedMembers'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalMonthlyReimbursementAmount,
                accessor: 'totalPayment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        ],
        [partnerGroupMemberhipsPaymentsResources]
    );

    const gmspColumns = useMemo(
        () => [
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelVisitMonth,
                accessor: 'visitPeriodStartDate',
                Cell: cell => moment(cell.value).format(MONTH_YEAR)
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelAdminFee,
                accessor: 'adminFee',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelRegisteredMembers,
                accessor: 'numberOfActivatedMemberships'
            },
            {
                Header: partnerGroupMemberhipsPaymentsResources.labelTotalMonthlyDuesAmount,
                accessor: 'totalPayment',
                Cell: cell => cell.value.toLocaleString('en-US', { minimumFractionDigits: 2 })
            }
        ],
        [partnerGroupMemberhipsPaymentsResources]
    );

    const columnsForProgramPaymentType = Object.freeze({
        [programPaymentTypes.partnerPaid]: gmColumns,
        [programPaymentTypes.memberPaid]: gmrColumns,
        [programPaymentTypes.partnerMemberPaid]: gmspColumns,
    });

    const defaultSorted = useMemo(() => [{ id: 'visitPeriodStartDate', desc: true }]);
    const handleSubmit = (paymentsFilterSubmitted) => {
        const paymentsFilterSubmittedJS = paymentsFilterSubmitted.toJS();
        getPayments(programPaymentTypeID, partnerId, paymentsFilterSubmittedJS.periodIDFrom, paymentsFilterSubmittedJS.periodIDTo);
    };
    const exportToExcel = () => {
        if (selectedPeriodIDFrom && selectedPeriodIDTo) {
            downloadPayments(partnerId, selectedPeriodIDFrom, selectedPeriodIDTo);
        }
    };
    return <div>
        {(isLoading || !programPaymentTypeIDLoaded) && <Spinner />}
        <InfoBox className="partner-group-membership-payments-container" title={partnerGroupMemberhipsPaymentsResources.titlePartnerGroupMemberhipsPayments}
            bordered
        >
            <div className="partner-group-membership-payments-container__filters">
                <PeriodsFilterForm
                    form={partnerGroupMemberhipsPaymentsResources.periodFormName}
                    periods={usagePeriods}
                    initialValues={paymentsFilter}
                    onSubmit={handleSubmit}
                />
            </div>
            <div className="grid__row">
                <div className="col-xs-12 col-md partner-group-membership-payments-container__excel-button">
                    <ExcelImage
                        onClick={exportToExcel}
                    />
                </div>
            </div>
            {programPaymentTypeIDLoaded && <div className="grid__row">
                <div className="col-lg">
                    <Table
                        columns={columnsForProgramPaymentType[programPaymentTypeID]}
                        data={payments.toJS()}
                        defaultSorted={defaultSorted}
                    />
                    <br />
                </div>
            </div>}
        </InfoBox>
    </div>;
}
PartnerGroupMembershipsPayments.propTypes = {
    partnerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isLoading: PropTypes.bool.isRequired,
    usagePeriods: PropTypes.object.isRequired,
    payments: PropTypes.object.isRequired,
    paymentsFilter: PropTypes.object.isRequired,
    getPayments: PropTypes.func.isRequired,
    downloadPayments: PropTypes.func.isRequired,
    getClosedUsagePeriods: PropTypes.func.isRequired,
    programPaymentTypeID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    getPeriodsUntilCurrent: PropTypes.func.isRequired,
    getProgramPaymentTypeID: PropTypes.func.isRequired,
    defaultPeriodID: PropTypes.number,
    selectedPeriodIDFrom: PropTypes.number,
    selectedPeriodIDTo: PropTypes.number
};
const mapDispatchToProps = {
    ...actions
};
export default connect(selectors, mapDispatchToProps)(PartnerGroupMembershipsPayments);
