import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_icon.scss';

function Icon({ icon, className, onClick, disabled }) {
    const handleClick = (event) => {
        onClick && !disabled && onClick(event);
    };
    return <span className={classNames('icon', { [className]: className }, { disabled })} onClick={handleClick}>
        {icon}
    </span>;
}

Icon.propTypes = {
    style: PropTypes.object,
    icon: PropTypes.element.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
};

export default Icon;
