import validationRules from '../../../utilities/validationRules';

export const passwordRegex = validationRules.passwordRegex;

const oldPasswordLabel = 'Old Password';
const newPasswordLabel = 'New Password';
const confirmPasswordLabel = 'Confirm Password';

export const oldPasswordRequired = validationRules.required(oldPasswordLabel);
export const newPasswordRequired = validationRules.required(newPasswordLabel);
export const confirmPasswordRequired = validationRules.required(confirmPasswordLabel);
export const oldPasswordMaxLength30 = validationRules.length(oldPasswordLabel, undefined, 30);
export const newPasswordMaxLength30 = validationRules.length(newPasswordLabel, undefined, 30);
export const confirmPasswordMaxLength30 = validationRules.length(confirmPasswordLabel, undefined, 30);

export default (values, props) => {
    const errors = {};

    if (values.get('oldPassword') === values.get('newPassword')) {
        errors.newPassword = 'New Password should be different from Old Password.';
    }

    if (values.get('newPassword') !== values.get('confirmPassword')) {
        errors.confirmPassword = 'New Password and Confirm Password must match.';
    }

    return errors;
};