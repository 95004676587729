import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import routes from '../../../routing/routes';
import { connect } from 'react-redux';
import {
    savePartner,
    getPartner
} from '../../Shared/Partners/managePartnerActions';
import ManagePartnerForm from '../../Shared/Partners/ManagePartnerForm';
import Spinner from '../../../components/Spinner/Spinner';
import { getStatesList } from '../../States/stateActions';
import selectors from './addPartnerPageSelectors';
import CardInfo from '../../../components/CardInfo/CardInfo';
import { getProgramTypes } from '../../ProgramTypes/programTypeActions';

function AddPartnerPage({
    savePartner,
    history,
    isLoading,
    getStates,
    states,
    partner,
    getPartner,
    getProgramTypes,
    programTypes
}) {
    useEffect(() => {
        getPartner();
        getStates();
        getProgramTypes();
    }, []);
    function handleSave(partner) {
        savePartner(partner.toJS()).then(() => {
            history.push(routes.partners.path);
        });
    }
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div>
                <h2 className="layout__title">Add Partner</h2>
                <CardInfo>
                    <div className="col-xs">
                        <ManagePartnerForm
                            onSubmit={handleSave}
                            states={states}
                            initialValues={partner}
                            programTypes={programTypes}
                            history={history}
                            isAdd
                        />
                    </div>
                </CardInfo>
            </div>
        );
}

AddPartnerPage.propTypes = {
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    savePartner: PropTypes.func.isRequired,
    getPartner: PropTypes.func.isRequired,
    states: PropTypes.object.isRequired,
    getStates: PropTypes.func.isRequired,
    partner: PropTypes.object.isRequired,
    programTypes: PropTypes.object.isRequired,
    getProgramTypes: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    savePartner,
    getPartner,
    getStates: getStatesList,
    getProgramTypes
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(AddPartnerPage);
