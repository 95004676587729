import logoGreen from '../../assets/images/logo_green.png';
import logoGreenHover from '../../assets/images/logo_green_hover.png';
import logoWhite from '../../assets/images/logo_white.svg';
import accessible from '../../assets/images/IconAccessible.png';
import generalInfoBanner from '../../assets/images/GeneralInfoBanner.jpg';
import excel from '../../assets/images/excel.png';
import ten from '../../assets/images/10gym.svg';
import kroc from '../../assets/images/Kroc-Center.svg';
import gold from '../../assets/images/Golds-Gym.svg';
import zumama from '../../assets/images/New-Zumama.svg';
import pool from '../../assets/images/Pool-Logo.svg';
import fruita from '../../assets/images/Fruitta.svg';
import ucare from '../../assets/images/ucare.svg';
import avera from '../../assets/images/avera.svg';
import healthpartners from '../../assets/images/healthpartners.svg';
import medica from '../../assets/images/medica.svg';
import preferredone from '../../assets/images/preferredone.svg';
import tivity from '../../assets/images/tivity.svg';
import groupYoga from '../../assets/images/Group-Yoga.png';
import grandmaTutuBlue from '../../assets/images/Grandma-Tutu-Blue.png';
import fitnessGroup1 from '../../assets/images/Fitness-Group-1.png';
import fitnessPark from '../../assets/images/Fitness-Park.png';
import fitnessHr from '../../assets/images/Fitness-Hr.png';
import healthScreen from '../../assets/images/Health-Screen.png';
import scrubsWomen from '../../assets/images/Scrubs-Women.png';
import manWprostheticWorkout from '../../assets/images/Man-Wprosthetic-Workout.png';
import womanDaughterSwim from '../../assets/images/Woman-Daughter-Swim.png';
import floatieBlue from '../../assets/images/Floatie-Blue.png';
import fitnessGroup2 from '../../assets/images/Fitness-Group-2.png';
import barMethod from '../../assets/images/bar-method.svg';
import anytimeFitness from '../../assets/images/anytime-fitness.svg';
import progress from '../../assets/images/progress.png';


export default {
    logoGreen,
    logoGreenHover,
    logoWhite,
    accessible,
    generalInfoBanner,
    excel,
    gold,
    ten,
    kroc,
    zumama,
    pool,
    fruita,
    ucare,
    avera,
    healthpartners,
    medica,
    preferredone,
    tivity,
    groupYoga,
    grandmaTutuBlue,
    fitnessGroup1,
    fitnessPark,
    fitnessHr,
    healthScreen,
    scrubsWomen,
    manWprostheticWorkout,
    womanDaughterSwim,
    floatieBlue,
    fitnessGroup2,
    barMethod,
    anytimeFitness,
    progress
};