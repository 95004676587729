import { createSelector } from 'reselect';
import * as types from './changePasswordTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === types.CHANGE_PASSWORD)
        .size;

    return size > 0;
};

export default createSelector([getIsLoading], isLoading => ({
    isLoading
}));
