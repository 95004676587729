import { createSelector } from 'reselect';
import { ENROLL_MEMBER_IN_FACILITTY } from './facilityEnrollmentPageTypes';

const getMemberFacilityEnrollmentIsInProgress = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === ENROLL_MEMBER_IN_FACILITTY)
        .size;
    return size > 0;
};
const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');
const getFacility = state => state.facilityReducer.get('facility');

const membersIds = createSelector(
    [getSelectedMemberGroup],
    (selectedMemberGroup) =>
    {
        return selectedMemberGroup.membersIds;
    });

const getFacilityDescription = createSelector(
    [getFacility],
    (facility) =>
    {
        return facility.get('description');
    });

const getFacilityId = createSelector(
    [getFacility],
    (facility) =>
    {
        return facility.get('id');
    });

const getFacilityAFNumber = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('afNumber');
    }
);

const isLoading = createSelector(
    [   getMemberFacilityEnrollmentIsInProgress,
        getFacilityDescription,
        getFacilityAFNumber
    ],
    (
        memberFacilityEnrollmentIsInProgress,
        facilityDescription,
        facilityAFNumber
    ) => (
        memberFacilityEnrollmentIsInProgress || !facilityDescription || !facilityAFNumber
    ));

export default createSelector(
    [
        isLoading,
        getFacilityDescription,
        getFacilityId,
        membersIds,
        getFacilityAFNumber
    ],
    (
        isLoading,
        facilityDescription,
        facilityId,
        membersIds,
        facilityAFNumber
    ) => (
        {
            isLoading,
            facilityDescription,
            facilityId,
            membersIds,
            facilityAFNumber
        }));

