import {
    GET_BILLING_INFO,
    SET_BILLING_INFO,
    GET_PAYMENT_TYPES_LIST,
    SET_PAYMENT_TYPES_LIST,
    UPDATE_BILLING_INFO,
    GET_BANK_ACCOUNT_DETAILS,
    SET_BANK_ACCOUNT_DETAILS
} from './partnerBillingInfoTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as PartnerBillingInfoService from './partnerBillingInfoService';
import partnerBillingTypes from '../../../enums/partnerBillingTypes';

const setBillingInfo = billingInfo => ({
    type: SET_BILLING_INFO,
    payload: {
        billingInfo
    }
});

const setPaymentTypesList = paymentTypesList => ({
    type: SET_PAYMENT_TYPES_LIST,
    payload: {
        paymentTypesList
    }
});

const setBankAccountDetails = bankAccountDetails => ({
    type: SET_BANK_ACCOUNT_DETAILS,
    payload: {
        bankAccountDetails
    }
});

export const getBillingInfo = partnerId => (dispatch) => {
    dispatch(loadingActions.setLoading(GET_BILLING_INFO));
    return PartnerBillingInfoService.getBillingInfo(partnerId)
        .then((billingInfo) => {
            dispatch(setBillingInfo(billingInfo));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_BILLING_INFO));
        });
};

export const getPaymentTypesList = () => (dispatch) => {
    dispatch(loadingActions.setLoading(GET_PAYMENT_TYPES_LIST));
    return PartnerBillingInfoService.getPaymentTypesList()
        .then((paymentTypesList) => {
            dispatch(setPaymentTypesList(paymentTypesList));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_PAYMENT_TYPES_LIST));
        });
};

export const updateBillingInfo = (partnerId, billingInfo) => (dispatch) => {
    dispatch(loadingActions.setLoading(UPDATE_BILLING_INFO));
    if (billingInfo.partnerBillingTypeID === partnerBillingTypes.ACHPaymentToHC) {
        billingInfo.bankAccount = null;
    }
    return PartnerBillingInfoService.updateBillingInfo(partnerId, billingInfo)
        .then(() => {
            return PartnerBillingInfoService.getBillingInfo(partnerId)
                .then((billingInfo) => {
                    dispatch(setBillingInfo(billingInfo));
                    dispatch(setBankAccountDetails(undefined));
                });
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(UPDATE_BILLING_INFO));
        });
};

export const getBankAccountDetails = payeeToken => (dispatch) => {
    dispatch(loadingActions.setLoading(GET_BANK_ACCOUNT_DETAILS));
    return PartnerBillingInfoService.getBankAccountDetails(payeeToken)
        .then((bankAccountDetails) => {
            dispatch(setBankAccountDetails(bankAccountDetails));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_BANK_ACCOUNT_DETAILS));
        });
};