import initialState from './partnerPaymentWithdrawalSectionInitialState';
import * as types from './partnerPaymentWithdrawalSectionTypes';
import { fromJS } from 'immutable';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PROGRAM_TYPE:
            return state.setIn(['paymentWithdrawal', 'programType'], action.programType);
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.setIn(['paymentWithdrawal', 'programPaymentType'], action.programPaymentType);
        case types.SET_POPUP_DATA:
            return state.set('popupData', action.popupData);
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        case types.SET_PAYMENT_TYPE:
            return state.setIn(['paymentWithdrawal', 'paymentType'], action.paymentType);
        default:
            return state;
    }
}