export const defaultFormProperties = {
    enableReinitialize: true,
    shouldAsyncValidate: ({ pristine, syncValidationPasses }) => !pristine && syncValidationPasses,
    onSubmitFail: (errors) => {
        if (!errors || typeof errors !== 'object') {
            return;
        }
        const invalidInputName = Object.keys(errors)[0];
        if (!invalidInputName) {
            return;
        }
        const invalidInput = document.getElementsByName(invalidInputName)[0];
        if (!invalidInput) {
            return;
        }
        invalidInput.scrollIntoView({
            behavior: 'smooth'
        });
    }
};