import { createSelector } from 'reselect';
import { LOGIN } from './loginTypes';

export const getIsLoginPopupOpen = (state) => {
    return state.loginReducer.get('isLoginPopupOpen');
};

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === LOGIN
    ).size;

    return size > 0;
};

export const getRedirectUrl = (state) => {
    return state.loginReducer.get('redirectUrl');
};

export default createSelector([getIsLoginPopupOpen, getIsLoading, getRedirectUrl], (isLoginPopupOpen, isLoading, redirectUrl) => ({
    isLoginPopupOpen,
    isLoading,
    redirectUrl
}));
