import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Switch, Redirect, generatePath } from 'react-router-dom';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import routes, { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';
import wellnessProgramPaymentResources from './wellnessProgramPaymentsResources';
import { getProgramTypes } from '../ProgramTypes/programTypeActions';
import selectors from './wellnessProgramPaymentsSelectors';

function WellnessProgramPaymentsPage({ match, history, currentRoute, getProgramTypes }) {
    useEffect(() => {
        getProgramTypes();
    }, []);
    const { gmrAddProgram } = useFlags();
    const filteredRoutes = currentRoute.routes.filter(
        (route) => {
            switch (route.path) {
                case routes.memberPayments.path:
                    return gmrAddProgram;
                default:
                    return true;
            }
        }
    );
    const routesForNavigation = filteredRoutes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });

    return (
        <div>
            <h2 className="layout__title">{wellnessProgramPaymentResources.titleWellnessProgramPayments}</h2>
            <NavigationTabControl
                tabs={routesForNavigation}
                onNavigate={history.push}
            />
            <Switch>
                {filteredRoutes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                        filteredRoutes={routesForNavigation}
                    />
                ))}

                <Redirect
                    from={currentRoute.path}
                    to={routesForNavigation[0].path}
                />
            </Switch>
        </div>
    );
};

WellnessProgramPaymentsPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    currentRoute: routeType.isRequired,
    getProgramTypes: PropTypes.func.isRequired,
};

const actions = {
    getProgramTypes,
};

export default connect(selectors, actions)(WellnessProgramPaymentsPage);
