import * as types from './employeeManagementTypes';
import initialState from './employeeManagementInitialState';
import { fromJS } from 'immutable';

export default function employeeManagementReducer(state = initialState, action) {
    switch (action.type) {
        case types.JOIN_KEYFOBS_LIST:
            return state.set('keyfobs', state.get('keyfobs').concat(fromJS(action.keyfobs)));
        case types.SET_KEYFOBS_LIST:
            return state.set('keyfobs', fromJS(action.keyfobs));
        case types.SET_MEMBER_TO_DEACTIVATE_MEMBERSHIP:
            return state.set('memberToDeactivateMembership', action.member);
        case types.SET_KEYFOB_TO_REASSIGN:
            return state.set('keyfobToReassign', action.keyfobToReassign);
        case types.SET_MEMBER_TO_DELETE:
            return state.set('memberToDelete', action.memberToDelete);
        case types.SET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED:
            return state.set('isReassignKeyfobFeatureEnabled', fromJS(action.isReassignKeyfobFeatureEnabled));
        case types.SET_KEYFOBS_TOTAL_COUNT:
            return state.set('keyfobsTotalCount', fromJS(action.totalCount));
        case types.SET_MEMBERS_WAITING_TO_INVITE_COUNT:
            return state.set('membersWaitingToInviteCount', fromJS(action.count));
        case types.SET_PROGRAM_PAYMENT_TYPE_ID:
            return state.set('programPaymentTypeID', fromJS(action.programPaymentTypeID));
        default:
            return state;
    }
}