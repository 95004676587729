import { createSelector } from 'reselect';
import { GET_MEMBERS_LIST, DEACTIVATE_MEMBER } from './memberListTypes';
import { GET_FACILITY } from '../Facilities/facilityTypes';
import { GetDeactivationConfirmationMessage } from './memberResources';
import roles from '../../enums/roles';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_MEMBERS_LIST || i === GET_FACILITY || i === DEACTIVATE_MEMBER)
        .size;

    return size > 0;
};

const getMembers = state => state.memberListReducer.get('members');
const getFacility = state => state.facilityReducer.get('facility');
const getMembersTotalCount = state => state.memberListReducer.get('membersTotalCount');
const getSelectedMembers = state => state.memberListReducer.get('selectedMembers');
const getMe = state => state.app.get('me');
const getMemberToDeactivate = state => state.memberListReducer.get('memberToDeactivate');

const getIsConfirmationPopupVisible = createSelector(
    [getMemberToDeactivate],
    (memberToDeactivate) => {
        return memberToDeactivate !== undefined;
    }
);

const getDeactivationConfirmationPopupMessage = createSelector(
    [getMemberToDeactivate],
    (memberToDeactivate) => {
        if (memberToDeactivate === undefined) {
            return;
        }
        return GetDeactivationConfirmationMessage(
            memberToDeactivate.memberFirstName,
            memberToDeactivate.memberLastName,
            memberToDeactivate.facilityMemberID);
    }
);

const isAdmin = createSelector(
    [getMe], (me) => {
        return me.role === roles.systemAdmin;
    }
);

export default createSelector(
    [
        getIsLoading,
        getMembers,
        getFacility,
        getMembersTotalCount,
        getSelectedMembers,
        getIsConfirmationPopupVisible,
        getMemberToDeactivate,
        getDeactivationConfirmationPopupMessage,
        isAdmin],
    (
        isLoading,
        members,
        facility,
        membersTotalCount,
        selectedMembers,
        isConfirmationPopupVisible,
        memberToDeactivate,
        deactivationConfirmationPopupMessage,
        isAdmin
    ) => ({
        isLoading,
        members,
        facility,
        membersTotalCount,
        selectedMembers,
        isConfirmationPopupVisible,
        memberToDeactivate,
        deactivationConfirmationPopupMessage,
        isAdmin
    })
);
