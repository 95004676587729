import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Icon from '../Icon/Icon';

const SidebarItem = ({ icon, displayName, path }) => (
    <NavLink
        to={path}
        className="sidebar__link"
        activeClassName="sidebar__link_active"
    >
        <span className="sidebar__link-icon">{icon && <Icon icon={icon} />}</span>
        <span className="sidebar__label">{displayName}</span>
    </NavLink>
);

SidebarItem.propTypes = {
    path: PropTypes.string,
    displayName: PropTypes.string,
    icon: PropTypes.element,
};

export default SidebarItem;
