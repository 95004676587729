import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { GOOGLE_SEO_TAG, BING_SEO_TAG } from '../../constants/seo';

function MetaData({ keywords, title, description }) {
    return <Helmet>
        <meta name="keywords" content={keywords} />
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="google-site-verification" content={GOOGLE_SEO_TAG} />
        <meta name="msvalidate.01" content={BING_SEO_TAG} />
    </Helmet>;
}

MetaData.propTypes = {
    keywords: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

export default MetaData;
