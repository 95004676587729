import * as types from './managePartnerManagerTypes';
import * as partnerManagerService from './managePartnerManagerService';
import * as loadingActions from '../../Loading/loadingActions';
import { createManagerSuccess } from '../../PartnerInfo/PartnerManagers/PartnerManagersList/partnerManagersListActions';
import appActions from '../../App/actions';

const setManager = (manager) => {
    return { type: types.SET_MANAGER, manager };
};

export function getManager(managerId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_MANAGER));
        return partnerManagerService
            .getManager(managerId)
            .then((manager) => {
                dispatch(setManager(manager));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_MANAGER));
            });
    };
}

export function createManager(manager) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.CREATE_MANAGER));
        return partnerManagerService
            .createManager(manager)
            .then((result) => {
                if (result.success) {
                    dispatch(createManagerSuccess(result.data));
                }
            })
            .catch((errorResponse) => {
                dispatch(setManager(errorResponse.data.data));
                throw errorResponse;
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.CREATE_MANAGER));
            });
    };
}

export function updateManager(manager, isLogoutRequired) {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.UPDATE_MANAGER));
        return partnerManagerService
            .updateManager(manager)
            .then((result) => {
                if (result.success) {
                    if (isLogoutRequired) {
                        dispatch(appActions.resetMe());
                    }
                    else {
                        dispatch(setManager(result.data));
                    }
                }
            })
            .catch((errorResponse) => {
                dispatch(setManager(errorResponse.data.data));
                throw errorResponse;
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.UPDATE_MANAGER));
            });
    };
}
