/* eslint-disable react/no-multi-comp*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './_input-file.scss';

function InputFile({ label, size, id,
    onChange, error, touched,
    disabled, name, onBlur,
    noMargin, transparentBorder, value, onClick, note }) {
    const isNotEmpty = value && value.length;
    const showError = error && touched;
    return (
        <div
            className={classNames([
                `c-input c-input_${size}`,
                { filled: isNotEmpty },
                { 'c-input--no-margin': noMargin },
                { 'c-input--transparent-border': transparentBorder }])}
        >
            {label &&
                <label className={classNames(['c-input__label', { 'c-input__label--no-margin': note }])} htmlFor={id}>
                    {label}
                </label>}
            {note && <div className="input-file__note">
                <MaterialIcon icon="info" variant={'filled'} className="input-file__note-icon" />
                <span className="input-file__note-text">{note}</span>
            </div>}
            <div className="c-input__container">
                <input
                    className={classNames([
                        'c-input__field',
                        { 'input-validation-error': showError }])}
                    id={id}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    type={'file'}
                    disabled={disabled}
                    name={name}
                />
            </div>
            {
                showError &&
                <div className="field-validation-error">
                    {error}
                </div>
            }
        </div>
    );
}

InputFile.defaultProps = {
    onChange: () => {
    },
    onBlur: () => {
    },
    onClick: () => {
    },
    size: 'medium',
    secureTextEntry: false
};

InputFile.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    size: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    noMargin: PropTypes.bool,
    transparentBorder: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(FileList)
    ]),
    note: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

const ReduxInputFile = ({ input, meta, ...custom }) => {
    return (
        <InputFile
            {...meta}
            {...input}
            {...custom}
        />
    );
};

export default ReduxInputFile;
