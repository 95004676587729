import * as nominateClubService from './nominateClubService';
import nominateClubResources from './nominateClubResources';
import { reset } from 'redux-form';
import * as types from './nominateClubTypes';
import * as loadingActions from '../../../../features/Loading/loadingActions';

export function nominate(nominateClubData, captchaToken) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.NOMINATE));
        return nominateClubService.nominate(nominateClubData, captchaToken)
            .then((result) => {
                if (result.success) {
                    dispatch(reset(nominateClubResources.nominateClubInfo));
                }
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.NOMINATE)));
    };
}
