import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

export const getMemberHasActiveFacilityEnrollments = state =>
    state.memberAreaReducer.get('memberHasActiveFacilityEnrollments');

const getFilteredRoutes = createSelector(
    [getMemberHasActiveFacilityEnrollments, getRoutes],
    (memberHasActiveFacilityEnrollments, authorizedRoutes) => {
        return authorizedRoutes.filter((route) => {
            if (route.name === allRoutes.usageLog.name) {
                return memberHasActiveFacilityEnrollments;
            }
            return true;
        });
    }
);

export default createSelector(
    [
        getFilteredRoutes,
    ],
    filteredRoutes => ({
        filteredRoutes
    })
);