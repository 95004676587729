import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function checkPartnerPaymentsWithdrawal(programTypeId, programPaymentTypeId, partnerId, periodId) {
    return http.get(API_PATHS.checkPartnerPaymentsWithdrawal, { params: { programTypeId, partnerId, periodId } });
};

export function sendPartnerPaymentsWithdrawal(payment) {
    return http.post(API_PATHS.sendPartnerPaymentsWithdrawal, payment);
};

export function dowloadPaymentWithdrawal(payment) {
    const config = {
        responseType: 'blob'
    };
    return http.post(API_PATHS.dowloadPaymentWithdrawal, payment, config);
}