export default Object.freeze({
    memberPaymentsFiltersFormName: 'memberPaymentsFiltersForm',
    fieldProgramTypeID: 'programTypeID',
    labelProgramType: 'Program Type',
    fieldPaymentTypeID: 'paymentTypeID',
    labelPaymentType: 'Payment Type',
    fieldPartnerID: 'partnerID',
    labelPartner: 'Partner',
    fieldPeriodID: 'periodID',
    labelVisitMonth: 'Visit Month',
    buttonTextView: 'View',
    columnNameEmployeeName: 'employee name',
    accessorEmployeeName: 'memberFullName',
    columnNameFacilityId: 'facility id',
    accessorFacilityId: 'facilityIDNumber',
    columnNameAmountToBeWithdrawn: 'amount to be withdrawn',
    columnNameAmountToBeDeposited: 'amount to be deposited',
    accessorAmountToBeWithdrawn: 'amountToBeSent',
    columnNameMemberPaymentType: 'billing type',
    accessorMemberPaymentType: 'memberBillingTypeID',
    columnNameBillingInfo: 'billing info',
    accessorBillingInfo: 'billingInfo',
    columnNameStatus: 'status',
    accessorStatus: 'paymentStatusID',
    columnNameDate: 'date',
    accessorDate: 'paymentStatusDate',
    headerTotalDuesAmount: 'Total Dues Amount',
    buttonSend: 'Send',
});