import guid from '../../utilities/guid';
import { fromJS } from 'immutable';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const POST_NOTIFICATION = 'POST_NOTIFICATION';

export const clearNotificationAction = notificationId => ({
    type: CLEAR_NOTIFICATION,
    payload: {
        notificationId
    }
});

export const postNotificationAction = notification => ({
    type: POST_NOTIFICATION,
    payload: {
        notification
    }
});


const clearNotification = notificationId => (dispatch) => {
    dispatch(clearNotificationAction(notificationId));
};

export const postNotification = notification => (dispatch) => {
    dispatch(postNotificationAction(fromJS({ ...notification, id: guid() })));
};


export default {
    clearNotification,
    postNotification
};
