import * as types from './agreementFormTypes';
import initialState from './agreementFormInitialState';

export default function agreementFormReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_EQUIPMENT_AND_FACILITIES_POLICIES:
            return state.set('equipmentAndFacilitiesPolicies', action.equipmentAndFacilitiesPolicies);
        default:
            return state;
    }
}
