const PartnerResources = {
    labelPartnerIdNumber: 'Partner ID',
    labelName: 'Partner Name',
    labelUSBankPartnerCode: 'US Bank Partner Code',
    labelIndustry: 'Industry',
    labelAddress1: 'Address 1',
    labelAddress2: 'Address 2',
    labelCity: 'City',
    labelState: 'State/Territory',
    labelZipCode: 'Zip Code',
    labelCountry: 'Country',
    labelWebsite: 'Website',
    labelFirstName: 'First Name',
    labelLastName: 'Last Name',
    labelPhoneNumber: 'Contact Number',
    labelEmailAddress: 'Email Address',
    labelNotes: 'Notes',
    labelPrograms: 'Programs',
    addPartnerButtomText: 'Add Partner',
};
export default PartnerResources;
