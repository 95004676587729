import * as types from './partnerPaymentWithdrawalSectionTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnerPaymentWithdrawalSectionService from './partnerPaymentWithdrawalSectionService';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';

const setProgramTypeAction = (programType) => {
    return { type: types.SET_PROGRAM_TYPE, programType };
};

function setProgramType(programType) {
    return function (dispatch) {
        dispatch(setProgramTypeAction(programType));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPopupDataAction = (popupData) => {
    return { type: types.SET_POPUP_DATA, popupData };
};

function checkPartnerPaymentsWithdrawal(programTypeId, programPaymentTypeId, partnerId, periodId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CHECK_PAYMENT_WITHDRAWAL));
        return partnerPaymentWithdrawalSectionService
            .checkPartnerPaymentsWithdrawal(programTypeId, programPaymentTypeId, partnerId, periodId)
            .then((response) => { dispatch(setPopupDataAction(response.data)); })
            .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_PAYMENT_WITHDRAWAL)));
    };
}

function sendPartnerPaymentsWithdrawal(payment) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.SEND_PAYMENT_WITHDRAWAL));
        return partnerPaymentWithdrawalSectionService
            .sendPartnerPaymentsWithdrawal(payment)
            .finally(() => dispatch(loadingActions.unsetLoading(types.SEND_PAYMENT_WITHDRAWAL)));
    };
}

function downloadPaymentWithdrawal(payment) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENT_WITHDRAWAL));
        return partnerPaymentWithdrawalSectionService
            .dowloadPaymentWithdrawal(payment)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENT_WITHDRAWAL)));
    };
}

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

function getPartnersListByProgramPaymentType(programPaymentTypeID, partnerBillingTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, partnerBillingTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

function getPartnersListByProgramType(programTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramType(programTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

const setPaymentTypeAction = (paymentType) => {
    return { type: types.SET_PAYMENT_TYPE, paymentType };
};

function setPaymentType(paymentType) {
    return function (dispatch) {
        dispatch(setPaymentTypeAction(paymentType));
    };
}

export default {
    setProgramType,
    setProgramPaymentType,
    sendPartnerPaymentsWithdrawal,
    downloadPaymentWithdrawal,
    checkPartnerPaymentsWithdrawal,
    getPartnersListByProgramPaymentType,
    getPartnersListByProgramType,
    setPaymentType
};