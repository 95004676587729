import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, generatePath } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';
import selectors from './voucherProgramsPageSelectors';

function VoucherProgramsPage({ match, currentRoute, filteredRoutes }) {
    const routesForNavigation = filteredRoutes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });

    return (
        <Switch>
            {currentRoute.routes.map((route, i) => (
                <CustomRoute
                    key={i}
                    path={route.path}
                    currentRoute={route}
                    filteredRoutes={routesForNavigation}
                />
            ))}
            <Redirect
                from={currentRoute.path}
                to={routesForNavigation[0].path}
            />
        </Switch>
    );
};

VoucherProgramsPage.propTypes = {
    match: PropTypes.object.isRequired,
    currentRoute: routeType.isRequired,
    filteredRoutes: PropTypes.arrayOf(routeType)
};

export default connect(selectors)(VoucherProgramsPage);
