const invitationStatuses = Object.freeze({
    waitingToInvite: 1,
    invited: 2,
    enrolledWaitingForPayment: 3,
    activated: 4,
    suspended: 5,
    waitingToDelete: 6,
    onHold: 7,
    deleted: 8
});

export const invitationStatusesNamesMap = Object.freeze({
    [invitationStatuses.waitingToInvite]: 'Waiting to Invite',
    [invitationStatuses.invited]: 'Invited',
    [invitationStatuses.enrolledWaitingForPayment]: 'Enrolled Waiting For Payment',
    [invitationStatuses.activated]: 'Activated',
    [invitationStatuses.suspended]: 'Suspended',
    [invitationStatuses.waitingToDelete]: 'Waiting to Delete',
    [invitationStatuses.onHold]: 'On Hold',
    [invitationStatuses.deleted]: 'Deleted'
});

export const invitationStatusesColorsMap = Object.freeze({
    [invitationStatuses.waitingToInvite]: 'yellow',
    [invitationStatuses.invited]: 'green',
    [invitationStatuses.enrolledWaitingForPayment]: 'orange',
    [invitationStatuses.activated]: 'green',
    [invitationStatuses.suspended]: 'beet',
    [invitationStatuses.waitingToDelete]: 'beet',
    [invitationStatuses.onHold]: 'orange',
    [invitationStatuses.deleted]: 'beet'
});

export default invitationStatuses;