import React from 'react';
import PropTypes from 'prop-types';
import { ArrowDown, ArrowUp } from '../Icon/Icons';

function SortIcon({ isSortedDesc }) {
    return isSortedDesc ? <ArrowDown/> : <ArrowUp/>;
}

SortIcon.propTypes = {
    isSortedDesc: PropTypes.bool,
};

export default SortIcon;
