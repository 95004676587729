import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routeType } from '../../../routing/routes';
import CustomRoute from '../../../layouts/CustomRoute';

function FacilityInfoTab({ currentRoute }) {
    const { routes } = currentRoute;
    return (
        <Switch>
            {
                routes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                    />
                ))
            }

            <Redirect from={currentRoute.path} to={routes[0].path} />
        </Switch>
    );
}
FacilityInfoTab.propTypes = {
    currentRoute: routeType.isRequired,
};

export default FacilityInfoTab;
