import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactConfirmAlert from 'react-confirm-alert';
import PopupBody from '../PopupBody';

function ConnectedConfirmPopup({ showPopup, title, body, onConfirm, onCancel, labelOk, labelCancel }) {
    return showPopup
        ? <ReactConfirmAlert
            customUI={() =>
                <PopupBody
                    title={title}
                    body={body}
                    onCancel={onCancel}
                    onOk={onConfirm}
                    labelOk={labelOk}
                    labelCancel={labelCancel}
                />}
          />
        : <></>;
}

ConnectedConfirmPopup.propTypes = {
    showPopup: PropTypes.bool.isRequired,
    title: PropTypes.string,
    labelOk: PropTypes.string,
    labelCancel: PropTypes.string,
    body: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default connect()(ConnectedConfirmPopup);
