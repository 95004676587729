import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getBillingInfo(partnerId) {
    return http.get(replaceRouteParams(API_PATHS.partnerBillingInfo, partnerId));
}

export function getPaymentTypesList() {
    return http.get(API_PATHS.partnerBillingTypes);
}

export function updateBillingInfo(partnerId, billingInfo) {
    return http.patch(replaceRouteParams(API_PATHS.updatePartnerBillingInfo, partnerId), billingInfo);
}

export function getBankAccountDetails(payeeToken) {
    return http.get(API_PATHS.partnerBankAccountDetails, { params: { payeeToken } });
}