const contactUsResources = {
    loginButtonText: 'Log in',
    labelFullName: 'Full Name',
    labelEmailAddress: 'Email Address',
    title: 'Contact Us',
    fieldFullName: 'fullName',
    fieldEmailAddress: 'emailAddress',
    labelCompanyName: 'Company Name',
    labelContactNumber: 'Contact Number',
    fieldCompanyName: 'companyName',
    fieldContactNumber: 'contactNumber',
    labelSelfDescription: 'Which best describes you?',
    fieldSelfDescription: 'userDescriptionId',
    labelMessage: 'What should we be prepared to discuss',
    fieldMessage: 'Message',
    contactUsDataForm: 'contactUsDataForm',
    sendButtonText: 'Submit'
};
export default contactUsResources;