import validationRules from '../../../utilities/validationRules';
import partnerManagerResources from './partnerManagerResources';

export const firstNameRequired = validationRules.required(
    partnerManagerResources.labelFirstName
);
export const firstNameMaxLength = validationRules.length(
    partnerManagerResources.labelFirstName,
    null,
    50
);
export const lastNameRequired = validationRules.required(
    partnerManagerResources.labelLastName
);
export const lastNameMaxLength = validationRules.length(
    partnerManagerResources.labelLastName,
    null,
    50
);
export const emailAddressRequired = validationRules.required(
    partnerManagerResources.labelEmailAddress
);
export const emailAddressMaxLength = validationRules.length(
    partnerManagerResources.labelEmailAddress,
    null,
    50
);
export const emailAddressFormat = validationRules.email(
    partnerManagerResources.labelEmailAddress
);

export default (values, props) => {
    const errors = {};

    if (values.get('password') !== values.get('confirmPassword')) {
        errors.confirmPassword = partnerManagerResources.errorMessagePasswordConfirmPasswordMatch;
    }

    return errors;
};