import { SET_BILLING_INFO, SET_PAYMENT_TYPES_LIST, SET_BANK_ACCOUNT_DETAILS } from './partnerBillingInfoTypes';
import { fromJS } from 'immutable';
import { initialState } from './partnerBillingInfoInitials';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_BILLING_INFO:
            return state.set('billingInfo', fromJS(action.payload.billingInfo));
        case SET_PAYMENT_TYPES_LIST:
            return state.set('paymentTypesList', fromJS(action.payload.paymentTypesList));
        case SET_BANK_ACCOUNT_DETAILS:
            return state.set('bankAccountDetails', fromJS(action.payload.bankAccountDetails));
        default:
            return state;
    }
}