import { createSelector } from 'reselect';

const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');
const getFacility = state => state.facilityReducer.get('facility');

const getFirstMembersGuid = createSelector(
    [getSelectedMemberGroup],
    (selectedMemberGroup) =>
    {
        return selectedMemberGroup.membersIds[0];
    });

const getFacilityDescription = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('description');
    }
);

const getFacilityAFNumber = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('afNumber');
    }
);

const getIsLoading = createSelector(
    [   getFacilityDescription,
        getFacilityAFNumber
    ],
    (
        facilityDescription,
        facilityAFNumber
    ) => (
        !facilityDescription || !facilityAFNumber
    ));

export default createSelector(
    [
        getIsLoading,
        getFirstMembersGuid,
        getFacilityDescription,
        getFacilityAFNumber
    ],
    (
        isLoading,
        firstMembersGuid,
        facilityDescription,
        facilityAFNumber
    ) => (
        {
            isLoading,
            firstMembersGuid,
            facilityDescription,
            facilityAFNumber
        }));

