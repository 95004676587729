import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Spinner from '../../../components/Spinner/Spinner';
import CardInfo from '../../../components/CardInfo/CardInfo';
import selectors from './employeeManagementTabSelectors';
import AddKeyfobsForm from './AddKeyfobsForm/AddKeyfobsForm';
import { fromJS } from 'immutable';
import WrappedConfirmPopup from '../../../components/Popups/ConfirmAlert/WrappedConfirmPopup';
import { createKeyfobs } from './employeeManagementActions';
import AddKeyfobConfirmBody from './AddKeyfobConfirmBody';
import KeyfobsAndEmployeesList from './KeyfobsAndEmployeesList/KeyfobsAndEmployeesList';
import parseBigInt from '../../../utilities/parseBigInt';
import * as keyfobConstants from '../../Shared/Keyfobs/keyfobConstants';
import UploadMemberRecipientsForm from './UploadMemberRecipients/UploadMemberRecipientsForm/UploadMemberRecipientsForm';

function EmployeeManagementTab({ match, isLoading, partner, createKeyfobs, launchDate }) {
    const keyfobsInitialRange = fromJS({ firstKeyfob: '', lastKeyfob: '' });
    const [keyfobsInitials, setKeyfobsInitials] = useState(keyfobsInitialRange);
    const partnerId = Number(match.params.partnerId);
    const { gmrAddProgram } = useFlags();

    const handleSubmit = (keyfobsInitials) => {
        const firstKeyfob = keyfobsInitials.get('firstKeyfob');
        const lastKeyfob = keyfobsInitials.get('lastKeyfob');
        const firstKeyfobBigInt = parseBigInt(firstKeyfob, keyfobConstants.KEYFOB_RADIX);
        const lastKeyfobBigInt = parseBigInt(lastKeyfob, keyfobConstants.KEYFOB_RADIX);
        const keyfobs = [];
        const keyfobsLength = firstKeyfob.length;
        for (let i = firstKeyfobBigInt; i <= lastKeyfobBigInt; i++) {
            const keyfobValuePart = i.toString(keyfobConstants.KEYFOB_RADIX);
            const paddedKeyfob = keyfobValuePart.padStart(keyfobsLength, '0');
            keyfobs.push({ partnerId, facilityMemberID: paddedKeyfob });
        }
        WrappedConfirmPopup({
            body: <AddKeyfobConfirmBody keyfobs={keyfobs} partnerName={partner.get('name')} />,
            onConfirm: () => createKeyfobs(keyfobs, partnerId).then(setKeyfobsInitials(keyfobsInitialRange))
        });
    };

    return <CardInfo shadow={false} halfSpace>
        {isLoading ? <Spinner /> : null}
        {gmrAddProgram ?
            <UploadMemberRecipientsForm partnerId={partnerId} launchDate={launchDate} />
            :
            <div className="col-xs">
                <AddKeyfobsForm initialValues={keyfobsInitials} onSubmit={handleSubmit} />
            </div>
        }
        <KeyfobsAndEmployeesList partnerId={partnerId} />
    </CardInfo>;
}

EmployeeManagementTab.propTypes = {
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    partner: PropTypes.object.isRequired,
    createKeyfobs: PropTypes.func.isRequired,
    employeesList: PropTypes.array,
    launchDate: PropTypes.instanceOf(Date).isRequired,
};
const mapDispatchToProps = {
    createKeyfobs
};
const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(EmployeeManagementTab);