export function IsRedirectToAllowedSite(urlString, allowedSites) {
    const url = URL.parse(urlString);
    if (url === null) {
        return isValidPath(urlString);
    }
    return allowedSites.includes(url.origin);
}

const isValidPath = (path) => {
    const validPathRegex = /^\/?([A-Za-z0-9\-._~!$&'()*+,;=:@]+\/?)*$/;
    return validPathRegex.test(path);
};