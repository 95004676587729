import { fromJS } from 'immutable';
import initialState from './memberPaymentsInitialState';
import * as types from './memberPaymentsTypes';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        case types.SET_PERIOD_ID:
            return state.setIn(['filters', 'periodID'], fromJS(action.periodID));
        case types.SET_PROGRAM_PAYMENT_TYPE_ID:
            return state.setIn(['filters', 'programTypeID'], fromJS(action.programTypeID));
        case types.SET_PAYMENTS:
            return state.set('payments', fromJS(action.payments));
        case types.SET_POPUP_DATA:
            return state.set('popupData', fromJS(action.popupData));
        case types.SET_PAYMENT_TYPES:
            return state.set('paymentTypes', fromJS(action.paymentTypes));
        default:
            return state;
    }
}