import * as types from './loginTypes';
import * as loginService from './loginService';
import * as loadingActions from '../../features/Loading/loadingActions';
import { ERROR } from '../../components/Notifications/constants';
import { postNotification } from '../../components/Notifications/actions';

export const setIsLoginPopupOpen = isLoginPopupOpen => ({
    type: types.SET_IS_LOGIN_POPUP_OPEN,
    isLoginPopupOpen
});

export function showErrorAlert(message) {
    return function (dispatch) {
        dispatch(postNotification({ type: ERROR, message }));
    };
}

export const setRedirectUrl = redirectUrl => ({
    type: types.SET_LOGIN_REDIRECT_URL,
    redirectUrl
});

export function login(loginData, redirectUrl) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.LOGIN));
        return loginService.login(loginData)
            .then((result) => {
                if (result.success) {
                    dispatch(setIsLoginPopupOpen(false));
                    window.location = result.redirectUrl || redirectUrl;
                }
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.LOGIN)));
    };
}
