import {
    GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    GET_GROUP_MEMBERSHIP,
    SET_GROUP_MEMBERSHIP,
} from './partnerManagerAreaTypes';
import * as loadingActions from '../Loading/loadingActions';
import partnerManagerAreaService from './partnerManagerAreaService';
import * as groupMembershipsService from '../GroupMemberships/GeneralInfo/groupMembershipsService';

const setIsGroupMembershipAddedToPartner = isGroupMembershipAddedToPartner => ({
    type: SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    payload: {
        isGroupMembershipAddedToPartner
    }
});

export const getIsGroupMembershipAddedToPartner = partnerId => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER));
    partnerManagerAreaService.getIsGroupMembershipAddedToPartner(partnerId)
        .then((response) => {
            dispatch(setIsGroupMembershipAddedToPartner(response.data));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER));
        });
};

export const getGroupMembership = partnerId => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(GET_GROUP_MEMBERSHIP));
    groupMembershipsService.getGroupMembership(partnerId)
        .then((groupMembership) => {
            dispatch(setGroupMembership(groupMembership));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_GROUP_MEMBERSHIP));
        });
};

const setGroupMembership = (groupMembership) => {
    return { type: SET_GROUP_MEMBERSHIP, groupMembership };
};
