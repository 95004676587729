import * as types from './periodsTypes';
import * as periodsService from './periodsService';
import * as loadingActions from '../../Loading/loadingActions';

const setPeriods = (periods) => {
    return { type: types.SET_PERIODS, periods };
};

export function getPeriods() {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_PERIODS));

        return periodsService
            .getPeriods()
            .then((payments) => {
                dispatch(setPeriods(payments));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PERIODS));
            });
    };
}

const setClosedUsagePeriods = (periods) => {
    return { type: types.SET_CLOSED_USAGE_PERIODS, periods };
};

export function getClosedUsagePeriods() {
    return function (dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_CLOSED_USAGE_PERIODS));

        return periodsService
            .getClosedUsagePeriods()
            .then((periods) => {
                dispatch(setClosedUsagePeriods(periods));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_CLOSED_USAGE_PERIODS));
            });
    };
}

const setPeriodsUntilCurrent = (periods) => {
    return { type: types.SET_PERIODS_UNTIL_CURRENT, periods };
};

export function getPeriodsUntilCurrent() {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PERIODS_UNTIL_CURRENT));
        return periodsService
            .getPeriodsUntilCurrent()
            .then((periods) => {
                dispatch(setPeriodsUntilCurrent(periods));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PERIODS_UNTIL_CURRENT));
            });
    };
}