import React from 'react';
import PropTypes from 'prop-types';
import PartnerGroupMembershipsPayments from './PartnerGroupMembershipsPayments';

function PartnerOwnerGroupMembershipsPaymentsTab({ match }) {
    return <PartnerGroupMembershipsPayments partnerId={match.params.partnerId} />;
}

PartnerOwnerGroupMembershipsPaymentsTab.propTypes = {
    match: PropTypes.object.isRequired
};

export default PartnerOwnerGroupMembershipsPaymentsTab;