import { SET_LOADING, UNSET_LOADING, SET_FIELDS } from './types';
import { initialState } from './initials';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOADING:
            return state.set('isLoading', true);
        case UNSET_LOADING:
            return state.set('isLoading', false);
        case SET_FIELDS: {
            const data = action.payload.fieldsData;

            return state.set('fieldsData', [
                { label: 'First Name', content: data.firstName },
                { label: 'Middle Initial', content: data.middleInitial },
                { label: 'Last Name', content: data.lastName },
                { label: 'Date of Birth', content: new Date(data.dateOfBirth).toLocaleDateString() },
                { label: 'Contact Number', content: data.contactNumber },
                { label: 'Email Address', content: data.email },
                { label: 'Address', content: data.address1 },
                { label: 'Address 2', content: data.address2 },
                { label: 'City', content: data.city },
                { label: 'State/Territory', content: data.state },
                { label: 'ZIP Code', content: data.zipCode },
                { label: 'Country', content: data.country }
            ]);
        }
        default:
            return state;
    }
}