import * as types from './employeeManagementTypes';
import * as employeeManagementService from './employeeManagementService';
import * as loadingActions from '../../Loading/loadingActions';
import { postNotification } from '../../../components/Notifications/actions';
import { ERROR } from '../../../components/Notifications/constants';
import * as partnerGroupMemberhipsPaymentsService from '../PartnerGroupMembershipsPayments/partnerGroupMemberhipsPaymentsService';

const setKeyfobsList = (keyfobs) => {
    return { type: types.SET_KEYFOBS_LIST, keyfobs };
};

const setTotalKeyfobsCount = (totalCount) => {
    return { type: types.SET_KEYFOBS_TOTAL_COUNT, totalCount };
};

const joinKeyfobsList = (keyfobs) => {
    return { type: types.JOIN_KEYFOBS_LIST, keyfobs };
};

export function createKeyfobs(keyfobs, partnerID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CREATE_KEYFOBS));
        return employeeManagementService
            .createKeyfobs(keyfobs)
            .then(() => {
                return employeeManagementService
                    .getKeyfobsList(partnerID)
                    .then((keyfobs) => {
                        dispatch(setKeyfobsList(keyfobs.items));
                    });
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.CREATE_KEYFOBS));
            });
    };
}

export function getKeyfobsList(pageNumber, pageSize, sortBy, sortOrder, partnerID, filter) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_KEYFOBS_LIST));
        return employeeManagementService
            .getKeyfobsList(partnerID, pageNumber, pageSize, sortBy, sortOrder, filter)
            .then((keyfobs) => {
                if (pageNumber === 1) {
                    dispatch(setKeyfobsList(keyfobs.items));
                }
                else {
                    dispatch(joinKeyfobsList(keyfobs.items));
                }
                dispatch(setTotalKeyfobsCount(keyfobs.totalCount));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_KEYFOBS_LIST));
            });
    };
}

const setMemberToDeactivateMembershipAction = (member) => {
    return { type: types.SET_MEMBER_TO_DEACTIVATE_MEMBERSHIP, member };
};

const setKeyfobToReassignAction = (keyfobToReassign) => {
    return { type: types.SET_KEYFOB_TO_REASSIGN, keyfobToReassign };
};

const setMemberToDeleteAction = (memberToDelete) => {
    return { type: types.SET_MEMBER_TO_DELETE, memberToDelete };
};

export function setMemberToDeactivateMembership(member) {
    return function (dispatch) {
        dispatch(setMemberToDeactivateMembershipAction(member));
    };
}

export function setKeyfobToReassign(keyfob) {
    return function (dispatch) {
        const keyfobToReassign = { oldFacilityMemberID: keyfob };
        dispatch(setKeyfobToReassignAction(keyfobToReassign));
    };
}

export function setMemberToDelete(member) {
    return function (dispatch) {
        dispatch(setMemberToDeleteAction(member));
    };
}

export function resetMemberToDeactivateMembership() {
    return function (dispatch) {
        dispatch(setMemberToDeactivateMembershipAction(undefined));
    };
}

export function resetKeyfobToReassign() {
    return function (dispatch) {
        dispatch(setKeyfobToReassignAction(undefined));
    };
}

export function resetKeyfobIdToDelete() {
    return function (dispatch) {
        dispatch(setMemberToDeleteAction(undefined));
    };
}

export function deleteKeyfob(keyfobID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DELETE_KEYFOB));
        return employeeManagementService
            .deleteKeyfob(keyfobID)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.DELETE_KEYFOB));
            });
    };
}

export function uploadMemberRecipients(partnerId, uploadRequest) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.UPLOAD_MEMBER_RECIPIENTS));
        return employeeManagementService
            .uploadMemberRecipients(partnerId, uploadRequest)
            .then((response) => {
                dispatch(getMembersWaitingToInviteCount(partnerId));
                return employeeManagementService
                    .getKeyfobsList(partnerId)
                    .then((keyfobs) => {
                        dispatch(setKeyfobsList(keyfobs.items));
                        dispatch(setTotalKeyfobsCount(keyfobs.totalCount));
                        return response;
                    });
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.UPLOAD_MEMBER_RECIPIENTS));
            });
    };
}

export function deactivateMembership(facilityMemberID, partnerID, pageNumber, pageSize, sortBy, sortOrder) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DEACTIVATE_MEMBERSHIP));
        return employeeManagementService
            .deactivateMembership(facilityMemberID)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.DEACTIVATE_MEMBERSHIP));
            });
    };
}

export function reassignKeyfob(keyfobToReassign) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.REASSIGN_KEYFOB));
        return employeeManagementService
            .reassignKeyfob(keyfobToReassign)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.REASSIGN_KEYFOB));
            });
    };
}

const setIsReassignKeyfobFeatureEnabled = isReassignKeyfobFeatureEnabled => ({
    type: types.SET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED,
    isReassignKeyfobFeatureEnabled
});

export function getIsReassignKeyfobFeatureEnabled() {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED));
        return employeeManagementService
            .getIsReassignKeyfobFeatureEnabled()
            .then((result) => {
                dispatch(setIsReassignKeyfobFeatureEnabled(result.data));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED));
            });
    };
}

export function downloadKeyfobsAndEmployees(partnerId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_KEYFOBS_EMPLOYEES_REPORT));
        return employeeManagementService
            .downloadKeyfobsAndEmployees(partnerId)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_KEYFOBS_EMPLOYEES_REPORT));
            });
    };
}

export function getMembersWaitingToInviteCount(partnerID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_MEMBERS_WAITING_TO_INVITE_COUNT));
        return employeeManagementService
            .getMembersWaitingToInviteCount(partnerID)
            .then((result) => {
                dispatch(setMembersWaitingToInviteCount(result));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_MEMBERS_WAITING_TO_INVITE_COUNT));
            });
    };
}

const setMembersWaitingToInviteCount = count => ({
    type: types.SET_MEMBERS_WAITING_TO_INVITE_COUNT,
    count
});

export const showFileUploadErrorAlert = message => (dispatch) => {
    dispatch(postNotification({ type: ERROR, message }));
};

export function getProgramPaymentTypeID(partnerID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PROGRAM_PAYMENT_TYPE_ID));
        return partnerGroupMemberhipsPaymentsService
            .getProgramPaymentTypeID(partnerID)
            .then((programPaymentTypeID) => {
                dispatch(setProgramPaymentTypeID(programPaymentTypeID));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PROGRAM_PAYMENT_TYPE_ID));
            });
    };
}

const setProgramPaymentTypeID = (programPaymentTypeID) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE_ID, programPaymentTypeID };
};

export function deleteMemberGroupMembership(memberToDelete, programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DELETE_MEMBERSHIP));
        return employeeManagementService
            .deleteMemberGroupMembershipEnrollment(memberToDelete.groupMembershipEnrollmentID, programPaymentTypeID)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.DELETE_MEMBERSHIP));
            });
    };
}

export function updateMember(member) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.UPDATE_EMPLOYEE_DATA));
        return employeeManagementService
            .updateMember(member)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.UPDATE_EMPLOYEE_DATA));
            });
    };
}