import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../../components/Spinner/Spinner';
import routes from '../../../../routing/routes';
import FacilityLocatorMap from './FacilityLocatorMap/FacilityLocatorMap';
import { getFacilitiesForMap } from './facilityLocatorActions';
import selectors from './facilityLocatorSelectors';
import IconButton from '../../../../components/IconButton/IconButton';
import facilityLocatorResources from './facilityLocatorResources';
import './_facility-locator.scss';
import FacilityLocatorMapLegend from './FacilityLocatorMap/FacilityLocatorMapLegend/FacilityLocatorMapLegend';

function FacilityLocator({ isLoading, getFacilitiesForMap, facilities, history }) {
    useEffect(() => {
        getFacilitiesForMap();
    }, []);
    return isLoading
        ? <Spinner />
        : (<div className="facility-locator grid__row">
            <div className="col-xs-12">
                <IconButton clickHandler={() => { history.push(routes.memberDashboardHome.path); }} text={facilityLocatorResources.labelBack}
                    icon={'arrow_back'}
                />
                <h2>{facilityLocatorResources.headerLocator}</h2>
                <FacilityLocatorMapLegend />
                <div className="facility-locator__map-wrapper">
                    <FacilityLocatorMap facilitiesInitial={facilities} history={history}
                        isEnrollButtonVisible maxClusterZoom={6}
                        clusterSize={15} clusterRadius={130}
                    />
                </div>
            </div>
        </div>);
}

FacilityLocator.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    getFacilitiesForMap: PropTypes.func.isRequired,
    facilities: PropTypes.object,
    history: PropTypes.object.isRequired
};

const actions = {
    getFacilitiesForMap
};

export default connect(selectors, actions)(FacilityLocator);