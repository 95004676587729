import { MULTIPART_FORM_DATA } from '../../../constants/contentTypes';
import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function createKeyfobs(keyfobs) {
    return http.post(API_PATHS.createKeyfobs, keyfobs);
}

export function getKeyfobsList(partnerID, pageNumber, pageSize, sortBy, sortOrder, filter) {
    return http.get(API_PATHS.listKeyfobs, { params: { page: pageNumber, pageSize, sortBy, sortOrder, partnerID, filter } });
}

export function downloadKeyfobsAndEmployees(partnerID) {
    return http.get(API_PATHS.downloadKeyfobsAndEmployees, { responseType: 'blob', params: { partnerID } });
};

export function deleteKeyfob(keyfobID) {
    return http.delete(replaceRouteParams(API_PATHS.deleteKeyfob, keyfobID));
}

export function deactivateMembership(facilityMemberID) {
    return http.post(API_PATHS.deactivateMembership, null, { params: { facilityMemberID } });
}

export function getKeyfobsCount(partnerId) {
    return http.get(API_PATHS.keyfobsCount, { params: { partnerId } });
}

export function reassignKeyfob(keyfobToReassign) {
    return http.post(API_PATHS.reassignKeyfob, keyfobToReassign);
}

export function getIsReassignKeyfobFeatureEnabled() {
    return http.get(API_PATHS.isReassignKeyfobFeatureEnabled);
}

export function uploadMemberRecipients(partnerId, recipientsFile) {
    const formData = new FormData();
    formData.append('file', recipientsFile);
    const config = {
        headers: {
            'content-type': MULTIPART_FORM_DATA
        },
        params: { partnerId }
    };
    return http.post(API_PATHS.uploadMemberRecipients, formData, config);
}

export function getMembersWaitingToInviteCount(partnerID) {
    return http.get(replaceRouteParams(API_PATHS.getMembersWaitingToInviteCount, partnerID));
}

export function deleteMemberGroupMembershipEnrollment(groupMembershipEnrollmentID, programPaymentTypeID) {
    return http.delete(replaceRouteParams(API_PATHS.deleteMemberGroupMembership, groupMembershipEnrollmentID, programPaymentTypeID));
}

export function updateMember(member) {
    return http.patch(replaceRouteParams(API_PATHS.updateMember, member.memberId), { 'id': member.memberId, 'email': member.memberEmail });
}