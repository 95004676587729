import { fromJS } from 'immutable';
import * as types from './voucherProgramsListTypes';
import initialState from './voucherProgramsListInitialState';

export default function reducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case types.SET_VOUCHER_PROGRAMS:
        {
            return state.set(
                'voucherPrograms',
                fromJS(action.voucherPrograms)
            );
        }
        default:
            return state;
    }
}
