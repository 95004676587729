import React from 'react';
import image1 from './images/img1.jpg';
import image2 from './images/img2.jpg';
import image3 from './images/img3.jpg';

function DashboardImagesSection() {
    return (
        <div className="dashboard__images-row grid__row">
            <div className="col-xs-12 col-md-4">
                <img className="img-fluid" src={image1}
                    alt={''}
                />
            </div>
            <div className="col-xs-12 col-md-4">
                <img className="img-fluid" src={image2}
                    alt={''}
                />
            </div>
            <div className="col-xs-12 col-md-4">
                <img className="img-fluid" src={image3}
                    alt={''}
                />
            </div>
        </div>
    );
}

export default DashboardImagesSection;
