import React from 'react';
import PropTypes from 'prop-types';
import './_solutions-section.scss';
import TextSection from '../TextSection/TextSection';

function SolutionsSection({ headerTheme, header, description, children }) {
    return <div className="solutions-section">
        <div className="solutions-section__header-container">
            <TextSection theme={headerTheme} title={header}
                text={description}
            />
        </div>
        <ul className="solutions-section__items ul-no-style">
            {children}
        </ul>
    </div>;
}

SolutionsSection.propTypes = {
    headerTheme: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
};

export default SolutionsSection;