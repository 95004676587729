import * as types from './facilitySearchBoxTypes';
import initialState from './facilitySearchBoxState';
import { fromJS } from 'immutable';

export default function facilitySearchBoxReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FACILITIES_FOR_DROPDOWN:
            return state.set('facilities', fromJS(action.facilities));
        default:
            return state;
    }
}
