import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './_forgot-password.scss';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../controls/FormGroup';
import { defaultFormProperties } from '../../utilities/form';
import * as validationRules from './forgotPasswordValidations';
import { MuiInputText } from '../controls/MuiInputText';
import forgotPasswordResources from './forgotPasswordResources';
import Button from '../controls/Button';
import selectors from './forgotPasswordSelectors';
import { connect } from 'react-redux';
import Captcha from '../Captcha/Сaptcha';
import { forgotPassword } from './forgotPasswordActions';
import { useFlags } from 'launchdarkly-react-client-sdk';

function ForgotPasswordForm({ pristine, submitting, handleSubmit, forgotPassword, sendButtonRef, userNameTextBoxRef }) {
    const { useCaptcha } = useFlags();
    const captchaRef = useRef(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const changeCaptchaHandler = (captchaToken) => {
        setCaptchaToken(captchaToken);
    };

    const forgotPasswordHandler = (data) => {
        const username = data.get('username');
        forgotPassword(username, captchaToken).finally(() => {
            if (captchaRef.current != null) {
                captchaRef.current.reset();
            }
        });
    };

    return <div className="forgot-popup">
        <FormGroup onSubmit={handleSubmit(forgotPasswordHandler)}>
            <div className="forgot-popup__form-group">
                <div className="forgot-popup__text-fields">
                    <Field
                        id={forgotPasswordResources.userNameTextBoxId}
                        name={forgotPasswordResources.fieldNameUsername}
                        component={MuiInputText}
                        label={forgotPasswordResources.labelUsername}
                        validate={[
                            validationRules.emailRequired,
                        ]}
                        addAsteriskToLabel
                        autoFocus
                        required
                        autocompleteType="username"
                        textBoxRef={userNameTextBoxRef}
                    />
                </div>
                {
                    useCaptcha && <div className="forgot-popup__captcha">
                        <Captcha changeCaptchaHandler={changeCaptchaHandler} captchaRef={captchaRef} />
                    </div>
                }
                <div className="forgot-popup__button-wrapper">
                    <Button className="btn_new btn_new--blue"
                        id={forgotPasswordResources.sendButtonId}
                        buttonRef={sendButtonRef}
                    >
                        {forgotPasswordResources.forgotButtonText}
                    </Button>
                </div>
            </div>
        </FormGroup>
    </div>;
};

ForgotPasswordForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    sendButtonRef: PropTypes.object,
    userNameTextBoxRef: PropTypes.object
};

const connectStateToProps = connect(selectors, { forgotPassword });

export default reduxForm({
    form: forgotPasswordResources.forgotPasswordDataForm,
    ...defaultFormProperties
})(connectStateToProps(ForgotPasswordForm));
