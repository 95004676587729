import * as types from './partnerManagerAreaTypes';
import { initialState } from './partnerManagerAreaInitials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case types.SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER:
        {
            return state.set(
                'isGroupMembershipAddedToPartner', payload.isGroupMembershipAddedToPartner
            );
        }
        default:
            return state;
    }
}
