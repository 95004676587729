import React, { useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputRadio from './InputRadio';
import './_radio-group.scss';

const RadioGroup = ({ value, meta = {}, input = {}, options, label, inline, ...props }) => {
    const computedValue = useMemo(() => {
        if (input.value) return input.value.toString();
        if (value) return value.toString();
        return value;
    }, [value, input.value]);
    return (
        <div className={classNames(['radio-group'])}>
            {label && <label className="radio-group__label">{label}</label>}
            <div className={classNames(['radio-group__content', { 'radio-group__content_inline': inline }])}>
                {
                    options.map(option =>
                        <InputRadio
                            key={option.id}
                            {...input}
                            {...option}
                            {...props}
                            checked={computedValue === option.value}
                        />
                    )
                }
            </div>
            {
                meta.submitFailed && meta.error &&
                <div className="field-validation-error">
                    {meta.error}
                </div>
            }
        </div>
    );
};

RadioGroup.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        value: PropTypes.string,
        label: PropTypes.string.isRequired
    })),
    inline: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object
};

export default RadioGroup;
