import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import selectors from './memberProfileSelectors';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

const MemberProfile = (props) => {
    const {
        filteredRoutes
    } = props;

    return (
        <div className="layout__new-container">
            <h2 className="layout__new-title">My Member Profile</h2>
            <NavigationTabControl tabs={filteredRoutes} onNavigate={props.history.push} />
            <Switch>
                {filteredRoutes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                        filteredRoutes={filteredRoutes}
                    />
                ))}

                <Redirect from={props.currentRoute.path} to={filteredRoutes[0].path} />
            </Switch>
        </div>
    );
};

MemberProfile.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }),
    currentRoute: routeType,
    filteredRoutes: PropTypes.arrayOf(routeType),
};

export default connect(selectors, null)(MemberProfile);
