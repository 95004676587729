import validationRules from '../../../../utilities/validationRules';
import nominateClubResources from './nominateClubResources';
import * as nominateClubConstants from '../../../Shared/NominateClub/nominateClubConstants';

export const fullNameRequired = validationRules.required(
    nominateClubResources.labelName
);

export const fullNameMaxLength = validationRules.length(
    nominateClubResources.labelName,
    nominateClubConstants.MIN_FULL_NAME_LENGTH,
    nominateClubConstants.MAX_FULL_NAME_LENGTH
);

export const emailRegex = validationRules.email(
    nominateClubResources.labelEmail
);

export const emailRequired = validationRules.required(
    nominateClubResources.labelEmail
);

export const emailMaxLength = validationRules.length(
    nominateClubResources.labelEmail,
    nominateClubConstants.MIN_EMAIL_LENGTH,
    nominateClubConstants.MAX_EMAIL_LENGTH
);

export const providerNameMaxLength = validationRules.length(
    nominateClubResources.labelProvider,
    nominateClubConstants.MIN_PROVIDER_NAME_LENGTH,
    nominateClubConstants.MAX_PROVIDER_NAME_LENGTH
);

export const clubNameRequired = validationRules.required(
    nominateClubResources.labelClubName
);

export const clubNameMaxLength = validationRules.length(
    nominateClubResources.labelClubName,
    nominateClubConstants.MIN_CLUB_NAME_LENGTH,
    nominateClubConstants.MAX_CLUB_NAME_LENGTH
);

export const cityRequired = validationRules.required(
    nominateClubResources.labelCity
);

export const cityMaxLength = validationRules.length(
    nominateClubResources.labelCity,
    nominateClubConstants.MIN_CITY_LENGTH,
    nominateClubConstants.MAX_CITY_LENGTH
);

export const clubStateRequired = validationRules.required(
    nominateClubResources.labelState
);

export const contactNameMaxLength = validationRules.length(
    nominateClubResources.labelContactName,
    nominateClubConstants.MIN_CONTACT_NAME_LENGTH,
    nominateClubConstants.MAX_CONTACT_NAME_LENGTH
);

export const phoneRegex = validationRules.phoneNumber(
    nominateClubResources.labelPhone
);

export const clubEmailRegex = validationRules.email(
    nominateClubResources.labelClubEmail
);

export const clubEmailMaxLength = validationRules.length(
    nominateClubResources.labelClubEmail,
    nominateClubConstants.MIN_CONTACT_EMAIL_LENGTH,
    nominateClubConstants.MAX_CONTACT_EMAIL_LENGTH
);