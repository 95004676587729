const MEMBER_GROUP_NAME = 'member_group_name';

export const preserveMemberGroup = ({ fullName }) => {
    localStorage.setItem(MEMBER_GROUP_NAME, fullName);
};

export const extractMemberGroup = (memberGroups) => {
    const preservedMemberGroupName = localStorage.getItem(MEMBER_GROUP_NAME);
    if (preservedMemberGroupName) {
        const targetMemberGroup = memberGroups.find(({ fullName }) => fullName === preservedMemberGroupName);
        return targetMemberGroup || memberGroups[0];
    } else {
        return memberGroups[0];
    }
};