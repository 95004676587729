import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../IconButton/IconButton';

function SelectedPlan({ plan, onRemove }) {
    const removePlan = () => {
        onRemove(plan);
    };

    return (
        <li className={'icon-caption'}>
            <IconButton icon={'cancel'}
                role="button"
                tabIndex="0"
                iconClassName="filter-box__close-icon"
                clickHandler={removePlan}
            />
            <span className="icon-caption__text">{
                plan.programNames && plan.programNames.length > 0
                    && !plan.programNames.filter(pn => pn.name && pn.name.length > 0).every(pn => pn.checked)
                    ? `${plan.name} - ${plan.programNames.filter(pn => pn.checked && pn.name && pn.name.length > 0).map(pn => pn.name)
                        .join(', ')}`
                    : plan.name}</span>
        </li>
    );
}


SelectedPlan.propTypes = {
    plan: PropTypes.object.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default SelectedPlan;