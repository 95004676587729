import * as types from './partnerManagersListTypes';
import initialState from './partnerManagersListInitialState';
import { fromJS } from 'immutable';

export default function partnerManagerReducer(state = initialState, action) {
    switch (action.type) {
        case types.CREATE_MANAGER_SUCCESS:
            return state.updateIn(['managers'], arr =>
                arr.push(fromJS(action.manager))
            );
        case types.GET_MANAGERS_LIST_SUCCESS:
            return state.set('managers', fromJS(action.managers));
        case types.DELETE_MANAGER_SUCCESS: {
            return state.updateIn(['managers'], arr =>
                arr.filter(manager => manager.get('id') !== action.managerId)
            );
        }
        default:
            return state;
    }
}
