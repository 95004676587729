import { createSelector } from 'reselect';
import { GET_FACILITIES_FOR_DROPDOWN } from './facilitySearchBoxTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_FACILITIES_FOR_DROPDOWN)
        .size;

    return size > 0;
};

export const getFacilities = (state) => {
    return state.facilitySearchBoxReducer.get('facilities').toJS();
};

export default createSelector(
    [
        getFacilities,
        getIsLoading
    ],
    (facilities, isLoading) => ({
        facilities,
        isLoading
    })
);
