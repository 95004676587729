import React from 'react';
import PropTypes from 'prop-types';
import routes from '../../../../routing/routes';
import CallToAction from '../../../../components/CallToAction/CallToAction';
import CallToActionBody from '../../../../components/CallToAction/CallToActionBody';
import guestAreaSharedResources from '../guestAreaSharedResources';

function ContactUsSection(props) {
    function contactUsHandler() {
        props.history.push(routes.contactUs.path);
    };
    return <div>
        <CallToAction>
            <CallToActionBody buttonText={guestAreaSharedResources.contactUsButtonText}
                description={guestAreaSharedResources.contactUsDescriptionText}
                title={<>{props.title || guestAreaSharedResources.contactUsTitle}</>}
                buttonClickHandler={contactUsHandler}
            />
            {props.children}
        </CallToAction>
    </div>;
};

ContactUsSection.propTypes = {
    children: PropTypes.object,
    history: PropTypes.object.isRequired,
    title: PropTypes.string
};

export default ContactUsSection;
