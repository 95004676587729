import validationRules from '../../../utilities/validationRules';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';

export const forProgramTypeRequired = validationRules.required(
    wellnessProgramPaymentResources.labelForProgramType
);
export const forPeriodRequired = validationRules.required(
    wellnessProgramPaymentResources.labelForUsagePeriod
);
export const forPartnerRequired = validationRules.required(
    wellnessProgramPaymentResources.labelForPartner
);

export const programTypeRequired = validationRules.required(
    wellnessProgramPaymentResources.labelProgramType
);
export const periodRequired = validationRules.required(
    wellnessProgramPaymentResources.labelVisitMonth
);
export const partnerRequired = validationRules.required(
    wellnessProgramPaymentResources.labelPartner
);