import * as loadingActions from '../../Loading/loadingActions';
import * as service from './storageService';

export const getFile = (partitionKey, rowKey) => (dispatch, getState) => {
    dispatch(loadingActions.setLoading());
    return service.getFile(partitionKey, rowKey)
        .finally(() => { dispatch(loadingActions.unsetLoading()); });
};


