import * as types from './legalContractFormTypes';
import initialState from './legalContractFormInitialState';

export default function legalContractFormReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_EQUIPMENT_AND_FACILITIES_POLICIES:
            return state.set('equipmentAndFacilitiesPolicies', action.equipmentAndFacilitiesPolicies);
        default:
            return state;
    }
}
