import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Spinner from '../../../components/Spinner/Spinner';
import FacilityPatnerPaymentsGenerationForm from '../FacilityPaymentsGenerationForm/FacilityPatnerPaymentsGenerationForm';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './facilityPaymentGenerationSectionActions';
import selectors from './facilityPaymentsGenerationSectionSelectors';
import ConfirmPopup from '../../../components/Popups/ConfirmAlert/ConfirmPopup';
import { useFlags } from 'launchdarkly-react-client-sdk';

const FacilityPaymentsGenerationSection = ({
    isLoading,
    programTypes,
    programPaymentTypes,
    periods,
    paymentsGenerationSettings,
    defaultProgramType,
    defaultProgramPaymentType,
    setProgramType,
    setProgramPaymentType,
    setPeriod,
    setPartner,
    doPaymentsExist,
    generate,
    message,
    selectedPeriodID,
    selectedProgramTypeID,
    selectedProgramPaymentTypeID,
    selectedPartnerID,
    getPartnersListByProgramType,
    getPartnersListByProgramPaymentType,
    partners,
    checkPaymentsExistence,
    resetPaymentsExistenceCheck
}) => {
    const { gmrAddProgram } = useFlags();
    useEffect(() => {
        if (!gmrAddProgram) {
            setProgramType(defaultProgramType);
            if (defaultProgramType) {
                getPartnersListByProgramType(defaultProgramType.get('id'));
            }
        }
        else {
            setProgramPaymentType(defaultProgramPaymentType);
            if (defaultProgramPaymentType) {
                getPartnersListByProgramPaymentType(defaultProgramPaymentType.get('id'));
            }
        }
    }, []);
    useEffect(() => {
        if (!gmrAddProgram && selectedProgramTypeID) {
            getPartnersListByProgramType(selectedProgramTypeID);
        }
    }, [selectedProgramTypeID]);
    useEffect(() => {
        if(gmrAddProgram && selectedProgramPaymentTypeID && selectedPeriodID && selectedPartnerID) {
            checkPaymentsExistence(0, selectedProgramPaymentTypeID, selectedPartnerID, selectedPeriodID);
        }
        else if (!gmrAddProgram && selectedProgramTypeID && selectedPeriodID && selectedPartnerID) {
            checkPaymentsExistence(selectedProgramTypeID, 0, selectedPartnerID, selectedPeriodID);
        }
    }, [selectedProgramPaymentTypeID, selectedProgramTypeID, selectedPeriodID, selectedPartnerID]);
    useEffect(() => {
        if (doPaymentsExist === false) {
            confirmGenerate();
        }
    }, [doPaymentsExist]);
    const handleGenerate = (settings) => {
        const programPaymentType = settings.get('programPaymentType');
        const programType = settings.get('programType');
        const partner = settings.get('partner');
        const period = settings.get('period');
        setProgramPaymentType(programPaymentType);
        setProgramType(programType);
        setPartner(partner);
        setPeriod(period);
    };
    const closePopup = () => {
        resetPaymentsExistenceCheck();
        setPartner();
        setPeriod();
    };
    const confirmGenerate = () => {
        closePopup();
        const generateParams = createGenerateParams();
        generate(generateParams);
    };
    const createGenerateParams = () => {
        return {
            programTypeID: selectedProgramTypeID,
            programPaymentTypeID: selectedProgramPaymentTypeID,
            partnerID: selectedPartnerID,
            periodID: selectedPeriodID
        };
    };
    return <InfoBox title={wellnessProgramPaymentResources.titleFacilityPayments} bordered>
        {
            isLoading
                ? <Spinner />
                : <></>
        }
        <ConfirmPopup showPopup={doPaymentsExist || false} body={<div className="message">{message}</div>}
            onConfirm={confirmGenerate}
            onCancel={closePopup}
        />
        <FacilityPatnerPaymentsGenerationForm
            gmrAddProgram={gmrAddProgram}
            programTypes={programTypes}
            programPaymentTypes={programPaymentTypes}
            periods={periods}
            partners={partners}
            initialValues={paymentsGenerationSettings}
            onSubmit={handleGenerate}
            onProgramPaymentTypeChange={getPartnersListByProgramPaymentType}
        />
    </InfoBox>;
};

FacilityPaymentsGenerationSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    paymentsGenerationSettings: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    setProgramType: PropTypes.func.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    setPeriod: PropTypes.func.isRequired,
    setPartner: PropTypes.func.isRequired,
    generate: PropTypes.func.isRequired,
    doPaymentsExist: PropTypes.bool,
    defaultProgramType: PropTypes.object,
    defaultProgramPaymentType: PropTypes.object,
    selectedProgramTypeID: PropTypes.number,
    selectedProgramPaymentTypeID: PropTypes.number,
    selectedPeriodID: PropTypes.number,
    selectedPartnerID: PropTypes.number,
    checkPaymentsExistence: PropTypes.func.isRequired,
    resetPaymentsExistenceCheck: PropTypes.func.isRequired,
    getPartnersListByProgramType: PropTypes.func.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    ...actions
};

export default connect(selectors, mapDispatchToProps)(FacilityPaymentsGenerationSection);