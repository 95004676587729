import PropTypes from 'prop-types';

const roles = Object.freeze({
    guest: 0,
    member: 1,
    facilityManager: 2,
    systemAdmin: 4,
    mpsAdmin: -1,
    partnerManager: 1026
});

export const roleType = PropTypes.oneOf([
    roles.guest,
    roles.member,
    roles.facilityManager,
    roles.systemAdmin,
    roles.mpsAdmin,
    roles.partnerManager
]);

export default roles;
