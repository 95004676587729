import initialState from './wellnessProgramPaymentsInitialState';
import * as types from './wellnessProgramPaymentsTypes';
import { fromJS } from 'immutable';

export default function(state = initialState, action) {
    switch (action.type) {
        case types.SET_ACH_UPLOAD_AVAILABILITY:
            return state.set('achUploadAvailability', fromJS(action.achUploadAvailability));
        default:
            return state;
    }
}