import { createSelector } from 'reselect';

const getGoodRecords = state => state.uploadRecipientsResultsReducer.getIn(['uploadResults', 'goodRecords']);
const getBadRecords = state => state.uploadRecipientsResultsReducer.getIn(['uploadResults', 'badRecords']);
const getFileName = state => state.uploadRecipientsResultsReducer.get('fileName');

export default createSelector(
    [getGoodRecords, getBadRecords, getFileName],
    (goodRecords, badRecords, fileName) => ({
        goodRecords,
        badRecords,
        fileName
    })
);