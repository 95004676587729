const facilityLocatorResources = {
    labelBack: 'Back',
    labelFitnessFacility: 'Fitness Facility',
    labelFitnessClass: 'Fitness Class',
    labelFlexInstructor: 'Flex Instructor',
    placeholderSearch: 'Search by City & State or Zip Code',
    headerLocator: 'Use this map to find facility',
    backButtonText: 'Back to results',
    noClubsInThisArea: 'There are no clubs in your search area. Please try a different search area.',
    filterTooltip: 'Filter by program',
};
export default facilityLocatorResources;