import parseBigInt from '../../../../utilities/parseBigInt';
import validationRules from '../../../../utilities/validationRules';
import addKeyfobsResources from './addKeyfobsResources';
import * as keyfobConstants from '../../../Shared/Keyfobs/keyfobConstants';

export const firstKeyfobRequired = validationRules.required(
    addKeyfobsResources.labelFirstKeyfob
);
export const firstKeyfobValidRadixNumber = validationRules.bigInt(
    keyfobConstants.KEYFOB_RADIX,
    addKeyfobsResources.labelFirstKeyfob
);
export const keyfobMinMaxLength = validationRules.length(
    addKeyfobsResources.labelKeyfob,
    keyfobConstants.MIN_KEYFOB_LENGTH,
    keyfobConstants.MAX_KEYFOB_LENGTH
);
export const keyfobsSameLength = (value, allValues, formProps) => {
    const firstKeyfob = allValues.get('firstKeyfob');
    const lastKeyfob = allValues.get('lastKeyfob');
    return firstKeyfob.length === lastKeyfob.length ? undefined : `${addKeyfobsResources.labelFirstKeyfob} and ${addKeyfobsResources.labelLastKeyfob} must be the same length.`;
};
export const firstKeyfobLessThanOrEqual = (value, allValues, formProps) => {
    const firstKeyfobDecimal = parseBigInt(value, keyfobConstants.KEYFOB_RADIX);
    const lastKeyfobDecimal = parseBigInt(allValues.get('lastKeyfob'), keyfobConstants.KEYFOB_RADIX);
    return firstKeyfobDecimal <= lastKeyfobDecimal ? undefined : `${addKeyfobsResources.labelFirstKeyfob} can't be greater than ${addKeyfobsResources.labelLastKeyfob}.`;
};

export const lastKeyfobRequired = validationRules.required(
    addKeyfobsResources.labelLastKeyfob
);
export const lastKeyfobValidRadixNumber = validationRules.bigInt(
    keyfobConstants.KEYFOB_RADIX,
    addKeyfobsResources.labelLastKeyfob
);
export const lastKeyfobGreaterThanOrEqual = (value, allValues, formProps) => {
    const lastKeyfobDecimal = parseBigInt(value, keyfobConstants.KEYFOB_RADIX);
    const firstKeyfobDecimal = parseBigInt(allValues.get('firstKeyfob'), keyfobConstants.KEYFOB_RADIX);
    return lastKeyfobDecimal >= firstKeyfobDecimal ? undefined : `${addKeyfobsResources.labelLastKeyfob} can't be less than ${addKeyfobsResources.labelFirstKeyfob}.`;
};
export const lastKeyfobMaxNumberOfKeyfobs = (value, allValues, formProps) => {
    const lastKeyfobDecimal = parseBigInt(value, keyfobConstants.KEYFOB_RADIX);
    const firstKeyfobDecimal = parseBigInt(allValues.get('firstKeyfob'), keyfobConstants.KEYFOB_RADIX);
    return !firstKeyfobDecimal || !lastKeyfobDecimal || lastKeyfobDecimal - firstKeyfobDecimal < BigInt(keyfobConstants.MAX_KEYFOBS_COUNT_TO_GENERATE) ? undefined : `More than ${keyfobConstants.MAX_KEYFOBS_COUNT_TO_GENERATE} key fobs cannot be created at a time.`;
};