import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Image from '../../../components/Image/Image';
import selectors from './selectors';
import actions from './actions';
import Spinner from '../../../components/Spinner/Spinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../components/InfoBox/InfoBoxRow';
import withMemberGroup from '../../../hoc/withMemberGroup';
import './_general-info.scss';

class GeneralInfo extends Component {
    render() {
        return (
            <div id="general_info" className="general-info grid__row">
                {this.props.isLoading ?
                    (
                        <Spinner />
                    )
                    : (
                        <Fragment>
                            <div className="col-md-5">
                                <InfoBox>
                                    {this.props.fieldsData.map((item, i) => (
                                        <InfoBoxRow
                                            key={i}
                                            label={item.label}
                                            content={item.content}
                                        />
                                    ))}
                                </InfoBox>
                            </div>
                            <div className="col-md-7 general-info__banner-container">
                                <Image
                                    alt="General Info Banner"
                                    image="generalInfoBanner"
                                    className="general-info__banner"
                                />
                            </div>
                        </Fragment>
                    )}
            </div>
        );
    }
}

GeneralInfo.propTypes = {
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    loadFieldsData: PropTypes.func,
    isLoading: PropTypes.bool,
    fieldsData: PropTypes.array,
    allMemberGroups: PropTypes.array,
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'loadFieldsData',
})(GeneralInfo);

export default connect(
    selectors,
    actions
)(withMemberGroupObserved);
