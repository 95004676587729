import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../components/Spinner/Spinner';
import { connect } from 'react-redux';
import selectors from './facilityPaymentsSelectors';
import CardInfo from '../../../components/CardInfo/CardInfo';
import FacilityPaymentsSection from '../FacilityPaymentsSection/FacilityPaymentsSection';
import FacilityPaymentDepositSection from '../FacilityPaymentDepositSection/FacilityPaymentDepositSection';
import FacilityPaymentsGenerationSection from '../FacilityPaymentsGenerationSection/FacilityPaymentsGenerationSection';
import { getClosedUsagePeriods } from '../../Shared/Periods/periodsActions';

function FacilityPaymentsTab({
    isLoading,
    programPaymentTypes,
    programTypes,
    periods,
    defaultPeriod,
    defaultProgramType,
    defaultProgramPaymentType,
    getClosedUsagePeriods
}) {
    useEffect(() => {
        getClosedUsagePeriods();
    }, []);
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div>
                <CardInfo>
                    <div className="grid__row">
                        <div className="col-lg-6">
                            <FacilityPaymentsGenerationSection
                                periods={periods}
                                programTypes={programTypes}
                                programPaymentTypes={programPaymentTypes}
                                defaultProgramType={defaultProgramType}
                                defaultProgramPaymentType={defaultProgramPaymentType}
                            />
                        </div>
                        <div className="col-lg-6" >
                            <FacilityPaymentDepositSection
                                programTypes={programTypes}
                                programPaymentTypes={programPaymentTypes}
                                periods={periods}
                                defaultProgramType={defaultProgramType}
                                defaultProgramPaymentType={defaultProgramPaymentType}
                            />
                        </div>
                    </div>
                    <FacilityPaymentsSection
                        programPaymentTypes={programPaymentTypes}
                        programTypes={programTypes}
                        periods={periods}
                        defaultPeriod={defaultPeriod}
                        defaultProgramType={defaultProgramType}
                    />
                </CardInfo>
            </div>
        );
}

FacilityPaymentsTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    programTypes: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    partners: PropTypes.object,
    defaultProgramType: PropTypes.object,
    defaultProgramPaymentType: PropTypes.object,
    defaultPeriod: PropTypes.object,
    getClosedUsagePeriods: PropTypes.func.isRequired
};

const actions = {
    getClosedUsagePeriods
};

export default connect(selectors, actions)(FacilityPaymentsTab);
