import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../../components/controls/FormGroup';
import { defaultFormProperties } from '../../../../utilities/form';
import * as validationRules from './nominateClubValidations';
import { MuiInputText } from '../../../../components/controls/MuiInputText';
import nominateClubResources from './nominateClubResources';
import Button from '../../../../components/controls/Button';
import './_nominateClub.scss';

function GeneralInfoDataForm({ pristine, submitting, onNextStep, handleSubmit }) {
    return (
        <div>
            <div className="nominate__step-info-text">{nominateClubResources.generalInfoStepText}</div>
            <FormGroup onSubmit={handleSubmit(onNextStep)}>
                <div className="nominate__text-fields">
                    <Field
                        id="name"
                        name={nominateClubResources.fieldName}
                        component={MuiInputText}
                        label={nominateClubResources.labelName}
                        validate={[
                            validationRules.fullNameRequired,
                            validationRules.fullNameMaxLength
                        ]}
                        addAsteriskToLabel
                    />
                    <Field
                        id="email"
                        name={nominateClubResources.fieldEmail}
                        component={MuiInputText}
                        label={nominateClubResources.labelEmail}
                        validate={[
                            validationRules.emailRequired,
                            validationRules.emailRegex,
                            validationRules.emailMaxLength
                        ]}
                        addAsteriskToLabel
                    />
                    <Field
                        id="provider"
                        name={nominateClubResources.fieldProvider}
                        component={MuiInputText}
                        label={nominateClubResources.labelProvider}
                        validate={[
                            validationRules.providerNameMaxLength
                        ]}
                    />
                </div>
                <div className="nominate__section--buttons">
                    <Button className="btn_new btn_new--blue"
                        disabled={pristine || submitting}
                        type="submit"
                    >
                        {nominateClubResources.linkButtonNextText}
                    </Button>
                </div>
            </FormGroup>
        </div>
    );
};

GeneralInfoDataForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    onNextStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
};

export default reduxForm({
    ...defaultFormProperties,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true
})(GeneralInfoDataForm);
