import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './_error-boundary.scss';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            info: null
        };
    }

    componentDidCatch(error, info) {
        this.setState({ error, info });
    }

    render() {
        if (this.state.error) {
            return (
                <div className="error-boundary">
                    <h3>{this.state.error.message}</h3>
                </div>
            );
        }
        return this.props.children;
    }
}


ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

export default ErrorBoundary;