import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import InputSelect from '../../../components/controls/InputSelect';
import MuiButton from '../../../components/controls/MuiButton';
import { defaultFormProperties } from '../../../utilities/form';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import * as validationRules from './partnerPaymentsGenerationFormValidation';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';

const PatnerPaymentsGenerationForm = ({
    gmrAddProgram,
    pristine,
    submitting,
    handleSubmit,
    programTypes,
    programPaymentTypes,
    partners,
    onSubmitGenerate,
    onSubmitGenerateWithFees,
    periods,
    onProgramPaymentTypeChange,
    onPaymentTypeChange,
    paymentTypes }) => {
    const [isGenerateWithFeesDisabled, setIsGenerateWithFeesDisabled] = useState(false);
    const [selectedProgramPaymentTypeID, setSelectedProgramPaymentTypeID] = useState(null);
    return <FormGroup>
        {
            gmrAddProgram ?
                <>
                    <Field
                        label={wellnessProgramPaymentResources.labelProgramType}
                        name={wellnessProgramPaymentResources.fieldProgramPaymentType}
                        options={programPaymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        component={InputSelect}
                        validate={[
                            validationRules.programTypeRequired
                        ]}
                        onChange={(option) => {
                            const programPaymentTypeID = option.get('id');
                            setSelectedProgramPaymentTypeID(programPaymentTypeID);
                            onProgramPaymentTypeChange(programPaymentTypeID);
                            if (programPaymentTypeID === programPaymentTypesEnum.partnerPaid) {
                                setIsGenerateWithFeesDisabled(false);
                            }
                            else {
                                setIsGenerateWithFeesDisabled(true);
                            }
                        }}
                    />
                    <Field
                        label={wellnessProgramPaymentResources.labelPaymentType}
                        name={wellnessProgramPaymentResources.fieldPaymentType}
                        options={paymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        component={InputSelect}
                        validate={[
                            validationRules.paymentTypeRequired
                        ]}
                        onChange={option => onPaymentTypeChange(selectedProgramPaymentTypeID, option.get('id'))}
                    />
                </>
                :
                <Field
                    label={wellnessProgramPaymentResources.labelForProgramType}
                    name="programType"
                    options={programTypes}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    component={InputSelect}
                    isDisabled
                />
        }
        <Field
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelPartner : wellnessProgramPaymentResources.labelForPartner}
            name="partner"
            options={partners}
            getOptionLabel={option => option.get('name')}
            getOptionValue={option => option.get('id')}
            component={InputSelect}
            validate={gmrAddProgram ? [validationRules.partnerRequired] : [validationRules.forPartnerRequired]}
        />
        <Field
            component={InputSelect}
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelVisitMonth : wellnessProgramPaymentResources.labelForUsagePeriod}
            name="period"
            options={periods}
            getOptionLabel={option => option.get('yearMonth')}
            getOptionValue={option => option.get('id')}
            validate={gmrAddProgram ? [validationRules.visitMonthRequired] : [validationRules.periodRequired]}
        />
        <div className="grid__row">
            <div className="col-lg-4">
                <MuiButton
                    className="btn_new btn_new--blue"
                    name="buttonGenerate"
                    disabled={pristine || submitting}
                    onClick={handleSubmit(values => onSubmitGenerate(values))}
                >
                    {wellnessProgramPaymentResources.buttonGenerate}
                </MuiButton>
            </div>
            <div className="col-lg-4">
                <MuiButton
                    className="btn_new btn_new--blue"
                    name="buttonGenerateWithFees"
                    disabled={pristine || submitting || isGenerateWithFeesDisabled}
                    size="big"
                    onClick={handleSubmit(values => onSubmitGenerateWithFees(values))}
                >
                    {wellnessProgramPaymentResources.buttonGenerateWithFees}
                </MuiButton>
            </div>
        </div>
    </FormGroup>;
};

PatnerPaymentsGenerationForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    periods: PropTypes.object,
    onSubmitGenerateWithFees: PropTypes.func.isRequired,
    onSubmitGenerate: PropTypes.func.isRequired,
    onProgramPaymentTypeChange: PropTypes.func.isRequired,
    gmrAddProgram: PropTypes.bool,
    paymentTypes: PropTypes.object,
    onPaymentTypeChange: PropTypes.func.isRequired
};

export default reduxForm({
    form: wellnessProgramPaymentResources.formPartnerPaymentsGeneration,
    ...defaultFormProperties
})(PatnerPaymentsGenerationForm);
