import React from 'react';
import PropTypes from 'prop-types';
import { IndeterminateCheckbox } from '../../components/controls/IndeterminateCheckbox';

function IndeterminateCheckboxCell({ row }) {
    return <IndeterminateCheckbox {...row.getToggleRowSelectedProps()}/>;
}

IndeterminateCheckboxCell.propTypes = {
    row: PropTypes.object.isRequired
};

export default IndeterminateCheckboxCell;
