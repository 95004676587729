import * as types from './contactUsTypes';
import * as contactUsService from './contactUsService';
import * as loadingActions from '../../../../features/Loading/loadingActions';

const getUserDescriptionListSuccess = (descriptions) => {
    return { type: types.GET_USER_DESCRIPTION_LIST_SUCCESS, descriptions };
};

export function getUserDescriptionList() {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_USER_DESCRIPTION_LIST));
        return contactUsService.getUserDescriptionList()
            .then((descriptions) => {
                dispatch(getUserDescriptionListSuccess(descriptions));
            })
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_USER_DESCRIPTION_LIST))
            );
    };
}

export function sendContactUsNotification(emailModel, captchaToken) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CONTACT_US_NOTIFICATION));
        return contactUsService.sendContactUsNotification(emailModel, captchaToken)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.CONTACT_US_NOTIFICATION));
            });

    };
}
