import * as React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './_c-splitbutton.scss';

export function MuiSplitButton(props) {
    const {
        options,
        label,
        classNames,
        size,
        variant,
        disabled
    } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef();
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleMenuItemClick = (event, index) => {
        const selectedOption = options[index];
        setSelectedIndex(index);
        handleClose(event);

        if (selectedOption.action) {
            selectedOption.action(selectedOption);
        }
    };

    return (
        <div className="split-button">
            <ButtonGroup variant={variant} ref={anchorRef}
                aria-label="split button" disabled={disabled}
            >
                <Button
                    type="button"
                    className={classNames}
                    size={size}
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label={label.description}
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    {label.value}
                    <MaterialIcon className="btn_new-icon btn_new-icon--right" icon={open ? 'arrow_drop_up' : 'arrow_drop_down'} />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 5,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal className="split-popper"
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }} className="grow-dropdown"
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option.value}
                                            disabled={option.disabled || false}
                                            selected={index === selectedIndex}
                                            onClick={event => handleMenuItemClick(event, index)}
                                        >
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

MuiSplitButton.defaultProps = {
    size: 'large',
    variant: 'contained'
};

MuiSplitButton.propTypes = {
    id: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            disabled: PropTypes.bool,
            action: PropTypes.func,
        })
    ).isRequired,
    label: PropTypes.shape({
        value: PropTypes.string.isRequired,
        description: PropTypes.string,
    }).isRequired,
    classNames: PropTypes.array,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string
};