import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../../../../../components/Icon/Icon';
import FacilityTypes from '../../../../../../enums/facilityLocationTypes';
import { MapPin } from '../../../../../../components/Icon/Icons';

function FacilityPin({ facility, isActive }) {
    return <>
        <Icon icon={<MapPin />}
            className={classNames([
                'facility-locator__pin-container',
                { 'facility-locator__pin-container_blue': facility.facilityLocationType === FacilityTypes.FitnessFacility },
                { 'facility-locator__pin-container_green': facility.facilityLocationType === FacilityTypes.FitnessClass },
                { 'facility-locator__pin-container_beet': facility.facilityLocationType === FacilityTypes.FlexInstructor },
                { 'facility-locator__pin-container_blue-active': isActive && facility.facilityLocationType === FacilityTypes.FitnessFacility },
                { 'facility-locator__pin-container_green-active': isActive && facility.facilityLocationType === FacilityTypes.FitnessClass },
                { 'facility-locator__pin-container_beet-active': isActive && facility.facilityLocationType === FacilityTypes.FlexInstructor },
            ])}
        />
    </>;
}

FacilityPin.propTypes = {
    facility: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired
};

export default FacilityPin;