import * as types from './partnerManagersListTypes';
import * as loadingActions from '../../../Loading/loadingActions';
import * as managersListService from './managersListService';

const getManagersListSuccess = (managers) => {
    return { type: types.GET_MANAGERS_LIST_SUCCESS, managers };
};

const deleteManagerSuccess = (managerId) => {
    return { type: types.DELETE_MANAGER_SUCCESS, managerId };
};

export const createManagerSuccess = (manager) => {
    return { type: types.CREATE_MANAGER_SUCCESS, manager };
};

export function getManagersList(partnerId) {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_MANAGERS_LIST));
        return managersListService
            .getManagersList(partnerId)
            .then((managers) => {
                dispatch(getManagersListSuccess(managers));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_MANAGERS_LIST));
            });
    };
}

export function deleteManager(managerId) {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.DELETE_MANAGER));
        return managersListService
            .deleteManager(managerId)
            .then(() => {
                dispatch(deleteManagerSuccess(managerId));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.DELETE_MANAGER));
            });
    };
}
