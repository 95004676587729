import { createSelector } from 'reselect';
import legalContractResources from '../legalContractResources';
import { GET_EQUIPMENT_AND_FACILITIES_POLICIES } from './agreementFormTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_EQUIPMENT_AND_FACILITIES_POLICIES)
        .size;
    return size > 0;
};

const getMembersAFFacilityWithinProgramEnrollment = state => state.legalContractReducer.get('membersAFFacilityWithinProgramEnrollment');
const getFormValues = state => state.form.getIn([legalContractResources.formNameLegalContract, 'values']);
const getEquipmentAndFacilitiesPolicies = state => state.agreementFormReducer.get('equipmentAndFacilitiesPolicies');
const getDocumentAgreementVersion = createSelector(
    [getMembersAFFacilityWithinProgramEnrollment],
    (membersAFFacilityWithinProgramEnrollment) => {
        return membersAFFacilityWithinProgramEnrollment.get('documentAgreementVersion');
    }
);

export default createSelector([getIsLoading, getMembersAFFacilityWithinProgramEnrollment, getDocumentAgreementVersion, getFormValues, getEquipmentAndFacilitiesPolicies], (isLoading, initialValues, documentAgreementVersion, formValues, equipmentAndFacilitiesPolicies) => ({ isLoading, initialValues, documentAgreementVersion, formValues, equipmentAndFacilitiesPolicies }));