import { GET_PROGRAM_TYPES_SUCCESS } from './programTypeTypes';
import initialState from './programTypeInitialState';
import { fromJS } from 'immutable';

export default function programTypeReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROGRAM_TYPES_SUCCESS:
            return state.set('programTypes', fromJS(action.programTypes));
        default:
            return state;
    }
}
