import * as types from './uploadRecipientsResultsTypes';
import initialState from './uploadRecipientsResultsInitialState';
import { fromJS } from 'immutable';

export default function uploadRecipientsResultsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_UPLOAD_RESULTS:
            return state.set('uploadResults', fromJS(action.uploadResults));
        case types.SET_FILE_NAME:
            return state.set('fileName', action.fileName);
        default:
            return state;
    }
}