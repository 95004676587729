import * as loadingActions from '../../Loading/loadingActions';
import * as types from './changePasswordTypes';
import appActions from '../../App/actions';

export default {
    changePassword: passwords => (
        dispatch,
        getState,
        { ChangePasswordService }
    ) => {
        dispatch(loadingActions.setLoading(types.CHANGE_PASSWORD));

        return ChangePasswordService.changePassword(passwords.toJS())
            .then(() => {
                dispatch(appActions.resetMe());
            })
            .catch((errorResponse) => {
                throw errorResponse;
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.CHANGE_PASSWORD));
            });
    }
};
