import { fromJS } from 'immutable';

export default fromJS({
    paymentsGenerationSettings: {},
    paymentsFees: {
        startupFeeAmount: '0.00',
        renewalFeeAmount: '0.00',
        facilityAccessFeeAmount: '0.00',
        oneTimeFeeAmount: '0.00'
    },
    partners: [],
    isAdditionalFeesPopupVisible: false
});