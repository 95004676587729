import { fromJS } from 'immutable';
import * as types from './voucherProgramTypes';
import initialState from './voucherProgramInitialState';

export default function reducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case types.SET_VOUCHER_PROGRAM:
            return state.set(
                'voucherProgram',
                fromJS(action.voucherProgram)
            );
        default:
            return state;
    }
}
