import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router';
import { facilityLocationTypeNamesMap, facilityLocationTypeColorsMap } from '../../../../../../enums/facilityLocationTypes';
import BulletCaption from '../../../../../../components/BulletCaption/BulletCaption';
import IconCaption from '../../../../../../components/IconCaption/IconCaption';
import Button from '../../../../../../components/controls/Button';
import routes from '../../../../../../routing/routes';
import classNames from 'classnames';
import resources from './FacilityDetailedInfoPanelResources';
import CustomLink from '../../../../../../components/controls/CustomLink';
import { HTTPS_PREFIX, GOOGLE_MAPS_URL } from '../../../../../../constants/websites';
import { TEL_PREFIX } from '../../../../../../constants/phones';

function FacilityDetailedInfoPanel({ facility, history, isEnrollButtonVisible, isListView }) {
    function handleEnroll() {
        history.push(generatePath(routes.facilityEnrollmentPage.path, {
            facilityId: facility.id
        }));
    };

    const formatFacilityUrl = function(url) {
        if(!url.startsWith(HTTPS_PREFIX)) {
            return HTTPS_PREFIX.concat(url);
        }
        return url;
    };

    const formatFacilityAddress = function() {
        return `${facility.address1} ${facility.city}, ${facility.state} ${facility.zipCode}`;
    };

    const formatGoogleMapsUrl = function(facilityAddress) {
        return GOOGLE_MAPS_URL.concat(facilityAddress);
    };

    const formatPhoneUrl = function() {
        return TEL_PREFIX.concat(facility.contactNumber);
    };

    return <div className={classNames(['facility-locator__facility-detailed-info-wrapper', { 'facility-locator--hidden-on-mobile': !isListView }])}>
        <div className="facility-locator__facility-detailed-info">
            <hr className="facility-locator__facility-info-divider" />
            <div className="facility-locator__facility-detailed-title-wrapper">
                <h4 className="facility-locator__facility-detailed-title">{facility.displayName}</h4>
                <div>
                    <BulletCaption color={facilityLocationTypeColorsMap[facility.facilityLocationType]} text={facilityLocationTypeNamesMap[facility.facilityLocationType]} />
                </div>
                {isEnrollButtonVisible &&
                    <Button isMaxWidth
                        className="btn_new btn_new--blue"
                        keepLabelCase
                        onClick={handleEnroll}
                    >
                        {resources.enrollInFacilityButtonText}
                    </Button>
                }
            </div>
            <hr className="facility-locator__facility-info-divider" />
            <div className="facility-locator__facility-info-line">
                <IconCaption icon="place" className="facility-locator__box-icon"><CustomLink className="solutions-section__link" linkPath={formatGoogleMapsUrl(formatFacilityAddress())} isExternalLink isNewTab>{formatFacilityAddress()}</CustomLink></IconCaption>
            </div>
            <div className="facility-locator__facility-info-line">
                <IconCaption icon="local_phone" className="facility-locator__box-icon"><CustomLink className="solutions-section__link" linkPath={formatPhoneUrl(facility.contactNumber)} isExternalLink>{facility.contactNumber}</CustomLink></IconCaption>
            </div>
            <div className="facility-locator__facility-info-line">
                {facility.website && <IconCaption icon="public" className="facility-locator__box-icon"><CustomLink className="solutions-section__link" linkPath={formatFacilityUrl(facility.website)} isExternalLink isNewTab>{facility.website}</CustomLink></IconCaption>}
            </div>
            <hr className="facility-locator__facility-info-divider" />
            <h5 className="facility-locator__facility-detailed-program">Programs</h5>
            <div className="facility-locator__facility-detailed-programs">
                {facility.activePlanAndProgramNames.map((planName, i) => {
                    return <Fragment key={i}>
                        {`${i !== 0
                            ? '\u00A0\u00A0\u0387\u00A0\u00A0'// dot separator with double nbsp around
                            : ''}
                    ${planName}`}
                    </Fragment>;
                })}
            </div>
            <hr className="facility-locator__facility-info-divider" />
        </div>
    </div>;
}

FacilityDetailedInfoPanel.propTypes = {
    history: PropTypes.object.isRequired,
    facility: PropTypes.object.isRequired,
    isEnrollButtonVisible: PropTypes.bool.isRequired,
    isListView: PropTypes.bool.isRequired
};

export default FacilityDetailedInfoPanel;
