import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../../MaterialIcon/MaterialIcon';
import classNames from 'classnames';
import '../_slider.scss';

function CustomArrow({ className, icon, onClick }) {
    return (
        <div onClick={onClick}>
            <MaterialIcon className={classNames(['arrow', className])} icon={icon} />
        </div>
    );
}

CustomArrow.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string.isRequired
};

export default CustomArrow;