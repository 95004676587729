const PREFIX = 'PARTNER_GROUP_MEMBERSHIPS_PAYMENTS_';

export const GET_PAYMENTS = `${PREFIX}GET`;
export const DOWNLOAD_PAYMENTS = `${PREFIX}DOWNLOAD`;
export const SET_PAYMENTS = `${PREFIX}SET`;
export const SET_INITIAL_PERIODS_RANGE = `${PREFIX}SET_INITIAL_PERIODS_RANGE`;
export const GET_USAGE_PERIODS = `${PREFIX}GET_USAGE_PERIODS`;
export const SET_USAGE_PERIODS = `${PREFIX}SET_USAGE_PERIODS`;
export const GET_PROGRAM_PAYMENT_TYPE_ID = `${PREFIX}GET_PROGRAM_PAYMENT_TYPE_ID`;
export const SET_PROGRAM_PAYMENT_TYPE_ID = `${PREFIX}SET_PROGRAM_PAYMENT_TYPE_ID`;


