import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { List } from 'immutable';
import { connect } from 'react-redux';
import RadioGroup from '../../../../components/controls/RadioGroup';
import CardInfo from '../../../../components/CardInfo/CardInfo';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../../components/InfoBox/InfoBoxRow';
import Spinner from '../../../../components/Spinner/Spinner';
import Button from '../../../../components/controls/Button';
import withMemberGroup from '../../../../hoc/withMemberGroup';
import selectors from './selectors';
import actions from './actions';
import routes from '../../../../routing/routes';
import facilitiesSectionResources from './FacilitiesSectionResources';
import preferredAccessTypes from '../../../../enums/preferredAccessTypes';

class FacilitiesSection extends Component {
    render() {
        const { isLoading, facilities, history, memberHasFacilityEnrollments } = this.props;
        return isLoading
            ? <Spinner />
            : <div className="facility-info grid__row" id="facility_info">
                <div className="col-lg-10">
                    {
                        facilities.size > 0
                            ? facilities.map((facility, i) => (
                                <div className="grid__row" key={i}>
                                    <CardInfo title={facility.get('name')}>
                                        <div className="col-lg-6">
                                            <InfoBox title="Location Information" bordered>
                                                {
                                                    <div>
                                                        <InfoBoxRow
                                                            label="Address"
                                                            content={facility.get('address')}
                                                        />
                                                        <InfoBoxRow label="City" content={facility.get('city')} />
                                                        <InfoBoxRow label="State/Territory" content={facility.get('state')} />
                                                        <InfoBoxRow
                                                            label="ZIP Code"
                                                            content={facility.get('zipCode')}
                                                        />
                                                    </div>
                                                }
                                            </InfoBox>
                                        </div>

                                        <div className="col-lg-6">
                                            <InfoBox title="Membership" bordered>
                                                {
                                                    <div>
                                                        {
                                                        facility.get('preferredAccessType') === preferredAccessTypes.physicalKey &&
                                                        <InfoBoxRow
                                                            label="Unique Facility Member ID"
                                                            content={facility.get('uniqueFacilityMemberId')}
                                                        />
                                                        }
                                                        <InfoBoxRow
                                                            label="Contract Number"
                                                            content={facility.get('contractNumber')}
                                                        />
                                                        <InfoBoxRow
                                                            label="Facility Enrollment Date"
                                                            content={new Date(
                                                                facility.get('facilityEnrollmentDate')
                                                            ).toLocaleDateString()}
                                                        />
                                                        <InfoBoxRow
                                                            label="Membership Type"
                                                            content={
                                                                <RadioGroup
                                                                    value={facility.get('membershipType')}
                                                                    name={`membership_type${i}`}
                                                                    options={[
                                                                        {
                                                                            id: `membership_type_single${i}`,
                                                                            label: 'Single',
                                                                            value: 'Single',
                                                                            disabled: true,
                                                                        },
                                                                        {
                                                                            id: `membership_type_couple$${i}`,
                                                                            label: 'Couple',
                                                                            value: 'Couple',
                                                                            disabled: true,
                                                                        },
                                                                        {
                                                                            id: `membership_type_family$${i}`,
                                                                            label: 'Family',
                                                                            value: 'Family',
                                                                            disabled: true,
                                                                        },
                                                                    ]}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                }
                                            </InfoBox>
                                        </div>

                                        <div className="col-lg-6">
                                            <InfoBox title="Contact Information" bordered>
                                                {
                                                    <div>
                                                        <InfoBoxRow
                                                            label="Email Address"
                                                            content={facility.get('email')}
                                                        />
                                                        <InfoBoxRow
                                                            label="Contact Number"
                                                            content={facility.get('contactNumber')}
                                                        />
                                                    </div>
                                                }
                                            </InfoBox>
                                        </div>

                                        <div className="col-lg-6">
                                            <InfoBox title="Related Plans" bordered>
                                                {facility.get('memberRelatedPlanAndProgramNames').map((name, index) => (
                                                    <NavLink
                                                        key={index}
                                                        className="link link_color_blue"
                                                        to={routes.insurance.path}
                                                    >
                                                        {name}
                                                    </NavLink>
                                                ))}
                                            </InfoBox>
                                        </div>
                                    </CardInfo>
                                </div>
                            ))
                            : 'Here you will see an information about the facilities you were registered in.'
                    }
                </div>
                {
                    memberHasFacilityEnrollments
                        ? <></>
                        : <div className="col-lg-2">
                            <Button className="btn_new btn_new--blue" onClick={() => {
                                history.push(routes.facilityLocator.path);
                            }}
                            >
                                {facilitiesSectionResources.findFacilityButtonText}
                            </Button>
                        </div>
                }
            </div>;
    }
}

FacilitiesSection.propTypes = {
    history: PropTypes.object.isRequired,
    selectedMemberGroup: PropTypes.shape({
        fullName: PropTypes.string,
    }),
    loadFacilities: PropTypes.func,
    isLoading: PropTypes.bool,
    facilities: PropTypes.instanceOf(List),
    allMemberGroups: PropTypes.array,
    memberHasFacilityEnrollments: PropTypes.bool.isRequired
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'loadFacilities',
})(FacilitiesSection);

export default connect(
    selectors,
    actions
)(withMemberGroupObserved);
