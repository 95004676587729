import http from '../../http/base';
import { API_PATHS } from '../../http/configs';
import { replaceRouteParams } from '../../utilities/routeHelper';

export function getPartnerPaymentTypesByProgramPaymentType(programPaymentTypeID) {
    return http.get(replaceRouteParams(API_PATHS.getPartnerPaymentTypesByProgramPaymentType, programPaymentTypeID));
}

export function getMemberPaymentTypesByProgramPaymentType(programPaymentTypeID) {
    return http.get(replaceRouteParams(API_PATHS.getMemberPaymentTypesByProgramPaymentType, programPaymentTypeID));
}
