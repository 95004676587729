import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, FormHelperText, Checkbox, FormGroup } from '@material-ui/core';
import styles from './_muiinputcheckbox.module.scss';

const MuiInputCheckbox = ({ label, id, input, meta, ...props }) => {
    const { value, name, onChange, checked, disabled, onBlur } = input || props;
    const { error, touched } = meta;
    const showError = error && touched;
    return (
        <FormControl>
            <FormGroup row>
                <FormControlLabel
                    classes={{ label: styles.label }}
                    control={
                        <Checkbox
                            id={id}
                            value={value === '' ? false : value}
                            checked={checked || value === true}
                            onChange={onChange}
                            name={name}
                            disabled={disabled}
                            onBlur={onBlur}
                            {...props}
                        />
                    }
                    label={label}
                />
            </FormGroup>
            <FormHelperText variant="outlined">{showError ? error : ''}</FormHelperText>
        </FormControl>
    );
};

MuiInputCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    input: PropTypes.object,
    meta: PropTypes.object.isRequired,
};

export default MuiInputCheckbox;