import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, generatePath } from 'react-router-dom';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import allRoutes, { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';
import programPaymentTypes from '../../enums/programPaymentTypes';
import { useSelector } from 'react-redux';


function GroupMembershipsPage({ match, history, currentRoute }) {
    const { routes } = currentRoute;
    const partnerOwnerAreaReducer = useSelector(state => state.partnerOwnerAreaReducer);
    const programPaymentTypeID = partnerOwnerAreaReducer.get('programPaymentTypeID');
    const filteredRoutes = routes.filter(
        (route) => {
            switch (route.path) {
                case allRoutes.groupMembershipsVisitLog.path:
                    return programPaymentTypeID === programPaymentTypes.partnerPaid;
                case allRoutes.partnerOwnerGroupMembershipsPayments.path:
                case allRoutes.groupMembershipsEmployeeManagement.path:
                    return programPaymentTypeID !== undefined;
                default:
                    return true;
            }
        }
    );
    const routesForNavigation = filteredRoutes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });

    return (
        <div className="layout__new-container">
            <h2 className="layout__new-title">Group Memberships</h2>
            <NavigationTabControl
                tabs={routesForNavigation}
                onNavigate={history.push}
            />
            <Switch>
                {filteredRoutes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                        filteredRoutes={routesForNavigation}
                    />
                ))}

                <Redirect
                    from={currentRoute.path}
                    to={routesForNavigation[0].path}
                />
            </Switch>
        </div>
    );
};

GroupMembershipsPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    currentRoute: routeType.isRequired
};

export default GroupMembershipsPage;
