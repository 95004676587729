import React from 'react';
import PropTypes from 'prop-types';
import './_icon-caption.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';

function IconCaption({ children, icon, className }) {
    return <span className={'icon-caption'}>
        <span className={'icon-caption__icon'}>
            <MaterialIcon icon={icon} className={className} ariaHidden/>
        </span>
        <span className="icon-caption__text">{children}</span>
    </span>;
}

IconCaption.propTypes = {
    icon: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export default IconCaption;