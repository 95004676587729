import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LegalContract from '../LegalContract/LegalContract';
import selectors from './memberLegalContractPageSelectors';
import { connect } from 'react-redux';
import { getMemberFacility } from '../../../Facilities/facilityActions';
import Spinner from '../../../../components/Spinner/Spinner';

function MemberLegalContractPage({
    isLoading,
    firstMembersGuid,
    getMemberFacility,
    facilityDescription,
    facilityAFNumber }) {
    useEffect(() => {
        getMemberFacility(firstMembersGuid);
    }, [firstMembersGuid]);
    return isLoading
        ? <Spinner />
        : <div>
            <LegalContract memberIdGuid={firstMembersGuid} facilityDescription={facilityDescription} facilityAFNumber={facilityAFNumber}
                readOnly
            />
        </div>;
}

MemberLegalContractPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    firstMembersGuid: PropTypes.string.isRequired,
    getMemberFacility: PropTypes.func.isRequired,
    facilityDescription: PropTypes.string,
    facilityAFNumber: PropTypes.string
};

const mapDispatchToProps = {
    getMemberFacility
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(MemberLegalContractPage);