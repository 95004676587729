import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function getPeriods() {
    return http.get(API_PATHS.getPeriods);
};

export function getClosedUsagePeriods() {
    return http.get(API_PATHS.getClosedUsagePeriods);
};

export function getPeriodsUntilCurrent() {
    return http.get(API_PATHS.getPeriodsUntilCurrent);
};
