import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_bullet-caption.scss';

function BulletCaption({ text, color, size = 'small', textColor = 'grey' }) {
    return <span className={'bullet-caption'}>
        <span className={classNames([
            'bullet-caption__bullet',
            `bullet-caption__bullet_${color}`])}
        />
        <span className={classNames([
            'bullet-caption__text',
            `bullet-caption__text_${size}`,
            `bullet-caption__text_${textColor}`])}
        >
            {text}
        </span>
    </span>;
}

BulletCaption.propTypes = {
    text: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['blue', 'green', 'beet', 'yellow']),
    size: PropTypes.oneOf(['big', 'small', 'medium']),
    textColor: PropTypes.oneOf(['blue', 'grey']),
};

export default BulletCaption;