import { createSelector } from 'reselect';
import {
    GET_MANAGER,
    CREATE_MANAGER
} from '../../../Shared/PartnerManager/managePartnerManagerTypes';

const getManager = state => state.partnerManagerReducer.get('manager');
const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === CREATE_MANAGER || i === GET_MANAGER)
        .size;

    return size > 0;
};

export default createSelector(
    [getIsLoading, getManager],
    (isLoading, manager) => ({
        isLoading,
        manager
    })
);
