import * as types from './facilityPaymentsSectionTypes';
import * as facilityPaymentSectionService from './facilityPaymentsSectionService';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';

const setPayments = (payments) => {
    return { type: types.SET_PAYMENTS, payments };
};

function getPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return function(dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));

        return facilityPaymentSectionService
            .getPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly)
            .then((payments) => {
                dispatch(setPayments(payments));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS));
            });
    };
}

function downloadPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return function(dispatch, getState) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENTS));
        return facilityPaymentSectionService
            .downloadPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENTS)));
    };
}

const setProgramTypeAction = (programType) => {
    return { type: types.SET_PROGRAM_TYPE, programType };
};

function setProgramType(programType) {
    return function(dispatch)
    {
        dispatch(setProgramTypeAction(programType));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPeriodAction = (period) => {
    return { type: types.SET_PERIOD, period };
};

function setPeriod(period) {
    return function(dispatch)
    {
        dispatch(setPeriodAction(period));
    };
}

const setPartnerAction = (partner) => {
    return { type: types.SET_PARTNER, partner };
};

function setPartner(partner) {
    return function(dispatch)
    {
        dispatch(setPartnerAction(partner));
    };
}

const setShowCorrectedOnlyAction = (value) => {
    return { type: types.SET_SHOW_CORRECTED_ONLY, value };
};

function setShowCorrectedOnly(value) {
    return function(dispatch)
    {
        dispatch(setShowCorrectedOnlyAction(value));
    };
}

const updateAmountToBeSentAction = (paymentId, amountToBeSent) => {
    return { type: types.SET_AMOUNT_TO_BE_SENT, paymentId, amountToBeSent };
};

function updateFacilityPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent) {
    return function(dispatch)
    {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return facilityPaymentSectionService
            .updateFacilityPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent)
            .then(() => {
                dispatch(updateAmountToBeSentAction(paymentId, amountToBeSent));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS)));
    };
}

const undoAmountCorrectionAction = (paymentId) => {
    return { type: types.UNDO_AMOUNT_CORRECTION, paymentId };
};

function undoFacilityPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId) {
    return function(dispatch)
    {
        dispatch(loadingActions.setLoading(types.GET_PAYMENTS));
        return facilityPaymentSectionService
            .undoFacilityPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId)
            .then(() => {
                dispatch(undoAmountCorrectionAction(paymentId));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_PAYMENTS)));
    };
}

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

export function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, null)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

export default {
    setProgramType,
    setProgramPaymentType,
    getPayments,
    setPeriod,
    setPartner,
    setShowCorrectedOnly,
    downloadPayments,
    updateFacilityPaymentAmountToBeSent,
    undoFacilityPaymentAmountCorrection,
    getPartnersListByProgramPaymentType
};
