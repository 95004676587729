import React from 'react';
import './_error-summary.scss';

const ErrorSummary = function ({ errors }) {
    return <>
        {
            errors && errors.toJS().length > 0
                ? <div className="error-summary__container">
                    <h4 className="error-summary__title">The following data is invalid:</h4>
                    <ul>
                        {errors.toJS().map((error, i) => <li className="error-summary__error-message" key={i}>{error}</li>)}
                    </ul>
                </div>
                : null
        }
    </>;
};

export default ErrorSummary;