import { createSelector } from 'reselect';

const getPlansFilters = state => state.plansFilterReducer.get('plansFilters');
const getPlans = state => state.clubLocatorSectionReducer.get('plans');

export default createSelector(
    [
        getPlans,
        getPlansFilters
    ],
    (plansInitial, checkedPlans) => ({
        plansInitial, checkedPlans
    })
);
