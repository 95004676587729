import validationRules from '../../../../utilities/validationRules';
import contactUsResources from './contactUsResources';
import * as generalInfoConstants from '../../../Shared/GeneralInfo/generalInfoConstants';

export const fullNameRequired = validationRules.required(
    contactUsResources.labelFullName
);

export const fullNameMaxLength = validationRules.length(
    contactUsResources.labelFullName,
    0,
    generalInfoConstants.MAX_FULLNAME_LENGTH
);

export const companyNameMaxLength = validationRules.length(
    contactUsResources.labelCompanyName,
    0,
    generalInfoConstants.MAX_COMPANY_NAME_LENGTH
);

export const emailAddressRequired = validationRules.required(
    contactUsResources.labelEmailAddress
);

export const emailAddressMaxLength = validationRules.length(
    contactUsResources.labelEmailAddress,
    0,
    generalInfoConstants.MAX_EMAIL320_LENGTH
);
export const emailAddressRegex = validationRules.email(
    contactUsResources.labelEmailAddress
);

export const contactNumberRequired = validationRules.required(
    contactUsResources.labelContactNumber
);

export const contactNumberMaxLength = validationRules.length(
    contactUsResources.labelContactNumber,
    0,
    generalInfoConstants.MAX_PHONE_NUMBER_LENGTH
);

export const contactNumberRegex = validationRules.phoneNumber(
    contactUsResources.labelContactNumber,
);

export const descriptionRequired = validationRules.required(
    contactUsResources.labelSelfDescription
);

export const messageRequired = validationRules.required(
    contactUsResources.labelMessage
);

export const messageMaxLength = validationRules.length(
    contactUsResources.labelMessage,
    0,
    generalInfoConstants.MAX_MESSAGE_LENGTH
);