import React from 'react';
import PropTypes from 'prop-types';
import { WarningRed, WarningYellow } from '../Icon/Icons';
import ToolTip from '../controls/ToolTip';

function WarningCell({ column, row }) {
    const props = column.getProps(row);
    if (props.isInvalid || props.isPending) {
        return <ToolTip title={props.invalidMessage} arrow
            placement="top"
               >
            <div>
                {props.isInvalid ? <WarningRed /> : <WarningYellow />}
            </div>
        </ToolTip>;
    }
    return null;
}

WarningCell.propTypes = {
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    isInvalid: PropTypes.bool,
    isPending: PropTypes.bool,
    invalidMessage: PropTypes.string
};

export default WarningCell;
