import validationRules from '../../../../../utilities/validationRules';
import legalContractFormResources from './legalContractFormResources';
import * as keyfobConstants from '../../../../Shared/Keyfobs/keyfobConstants';
import * as generalInfoConstants from '../../../../Shared/GeneralInfo/generalInfoConstants';

export const keyfobRequired = validationRules.required(
    legalContractFormResources.labelKeyfob
);
export const keyfobMinMaxLength = validationRules.length(
    legalContractFormResources.labelKeyfob,
    keyfobConstants.MIN_KEYFOB_LENGTH,
    keyfobConstants.MAX_KEYFOB_LENGTH
);
export const keyfobValidRadixNumber = validationRules.bigInt(
    keyfobConstants.KEYFOB_RADIX,
    legalContractFormResources.labelKeyfob
);
export const isMemberAgreedRequired = validationRules.required(
    legalContractFormResources.labelIsMemberAgreed, legalContractFormResources.customValidationMessagelabelIsMemberAgreed
);
export const firstNameRequired = validationRules.required(
    legalContractFormResources.labelFirstName
);
export const firstNameMaxLength = validationRules.length(
    legalContractFormResources.labelFirstName,
    0,
    generalInfoConstants.MAX_FIRSTNAME_LENGTH
);
export const lastNameRequired = validationRules.required(
    legalContractFormResources.labelLastName
);
export const lastNameMaxLength = validationRules.length(
    legalContractFormResources.labelLastName,
    0,
    generalInfoConstants.MAX_LASTNAME_LENGTH
);
export const phoneNumberRequired = validationRules.required(
    legalContractFormResources.labelContactNumber
);
export const phoneNumberMaxLength = validationRules.length(
    legalContractFormResources.labelContactNumber,
    0,
    generalInfoConstants.MAX_PHONE_NUMBER_LENGTH
);
export const phoneNumberRegex = validationRules.phoneNumber(
    legalContractFormResources.labelContactNumber
);
export const emailAddressRequired = validationRules.required(
    legalContractFormResources.labelEmailAddress
);
export const emailAddressMaxLength = validationRules.length(
    legalContractFormResources.labelEmailAddress,
    0,
    generalInfoConstants.MAX_EMAIL50_LENGTH
);
export const emailAddressRegex = validationRules.email(
    legalContractFormResources.labelEmailAddress
);