function parseBigInt(value, radix) {
    let result = BigInt(0);
    let isBroke = false;
    for (const symbol of [...value.toString()]) {
        const currentSymbolAsDeciaml = parseInt(symbol, radix);
        if (isNaN(currentSymbolAsDeciaml)) {
            isBroke = true;
            break;
        }
        result = result * BigInt(radix) + BigInt(currentSymbolAsDeciaml);
    }
    return isBroke ? null : result;
}

export default parseBigInt;