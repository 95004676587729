import * as types from './facilityPaymentsGenerationSectionTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as facilityPaymentsGenerationSectionService from './facilityPaymentsGenerationSectionService';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';

const setProgramTypeAction = (programType) => {
    return { type: types.SET_PROGRAM_TYPE, programType };
};

function setProgramType(programType) {
    return function (dispatch) {
        dispatch(setProgramTypeAction(programType));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPeriodAction = (period) => {
    return { type: types.SET_PERIOD, period };
};

function setPeriod(period) {
    return function (dispatch) {
        dispatch(setPeriodAction(period));
    };
};

const setPartnerAction = (partner) => {
    return { type: types.SET_PARTNER, partner };
};

function setPartner(partner) {
    return function (dispatch) {
        dispatch(setPartnerAction(partner));
    };
};

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

const setCheckPaymentsExistAction = (doPaymentsExist) => {
    return { type: types.SET_PAYMENTS_EXIST, doPaymentsExist };
};

function resetPaymentsExistenceCheck() {
    return function (dispatch) {
        dispatch(setCheckPaymentsExistAction(null));
    };
};

function checkPaymentsExistence(programTypeID, programPaymentTypeID, partnerID, periodID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CHECK_PAYMENTS_EXISTENCE));
        return facilityPaymentsGenerationSectionService
            .checkPaymentsExist(programTypeID, programPaymentTypeID, partnerID, periodID)
            .then((response) => {
                dispatch(setCheckPaymentsExistAction(response.data));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_PAYMENTS_EXISTENCE)));
    };
}

export function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID, null)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

export function getPartnersListByProgramType(programTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramType(programTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

function generate(generateParams) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GENERATE_PAYMENTS));
        return facilityPaymentsGenerationSectionService
            .generate(generateParams)
            .finally(() => dispatch(loadingActions.unsetLoading(types.GENERATE_PAYMENTS)));
    };
}

export default {
    setProgramType,
    setProgramPaymentType,
    setPeriod,
    generate,
    getPartnersListByProgramType,
    getPartnersListByProgramPaymentType,
    setPartner,
    checkPaymentsExistence,
    resetPaymentsExistenceCheck
};