import { createSelector } from 'reselect';
import { DASHBOARD_LOADING } from './dashboardTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === DASHBOARD_LOADING)
        .size;
    return size > 0;
};

export default createSelector(
    [
        getIsLoading
    ],
    isLoading => ({ isLoading })
);