import React from 'react';
import PropTypes from 'prop-types';
import guid from '../utilities/guid';
import { roleType } from '../enums/roles';
import { Dashboard, Member, Account } from '../components/Icon/Icons';

export default Object.freeze({
    root: {
        id: guid(),
        name: 'root',
        displayName: 'Root',
        path: '/'
    },
    healthCheck: {
        id: guid(),
        name: 'healthcheck',
        displayName: '',
        path: '/healthcheck'
    },
    privacynotice: {
        id: guid(),
        name: 'privacynotice',
        displayName: '',
        path: '/privacy'
    },
    privacyarchivearea: {
        id: guid(),
        name: 'privacyarchives',
        displayName: '',
        path: '/privacyarchives'
    },
    privacyarchives: {
        id: guid(),
        name: 'privacyarchives',
        displayName: '',
        path: '/privacyarchives/index'
    },
    privacypolicy20230110: {
        id: guid(),
        name: 'privacypolicy20230110',
        displayName: '',
        path: '/privacyarchives/privacypolicy20230110'
    },
    californiaprivacypolicy20230110: {
        id: guid(),
        name: 'californiaprivacypolicy20230110',
        displayName: '',
        path: '/privacyarchives/californiaprivacypolicy20230110'
    },
    privacynotice20230824: {
        id: guid(),
        name: 'privacynotice20230824',
        displayName: '',
        path: '/privacyarchives/privacynotice20230824'
    },
    consumerhealthprivacynotice: {
        id: guid(),
        name: 'consumerhealthprivacynotice',
        displayName: '',
        path: '/privacy#additional-information-for-washington-residents'
    },
    dataprivacyrequest: {
        id: guid(),
        name: 'dataPrivacyRequest',
        displayName: '',
        path: '/dataprivacyrequest'
    },
    authorizedagentform: {
        id: guid(),
        name: 'authorizedagentdesignationform',
        displayName: '',
        path: '/resources/authorizedagentdesignationform'
    },
    termsofuse: {
        id: guid(),
        name: 'termsofuse',
        displayName: '',
        path: '/termsofuse'
    },
    // guest
    guestArea: {
        id: guid(),
        name: 'guestArea',
        displayName: '',
        path: '/guest'
    },
    home: {
        id: guid(),
        name: 'home',
        displayName: '',
        path: '/guest/home'
    },
    forFacilities: {
        id: guid(),
        name: 'forFacilities',
        displayName: '',
        path: '/guest/forfacilities'
    },
    forEmployers: {
        id: guid(),
        name: 'forEmployers',
        displayName: '',
        path: '/guest/foremployers'
    },
    forInsurers: {
        id: guid(),
        name: 'forInsurers',
        displayName: '',
        path: '/guest/forinsurers'
    },
    forMembers: {
        id: guid(),
        name: 'forMembers',
        displayName: '',
        path: '/guest/formembers'
    },
    aboutUs: {
        id: guid(),
        name: 'aboutUs',
        displayName: '',
        path: '/guest/aboutus'
    },
    accessibilityAndMaintenance: {
        id: guid(),
        name: 'accessibilityAndMaintenance',
        displayName: '',
        path: '/accessibilityAndMaintenance'
    },
    contactUs: {
        id: guid(),
        name: 'contactUs',
        displayName: '',
        path: '/guest/contactus'
    },
    nominateClub: {
        id: guid(),
        name: 'nominateClub',
        displayName: '',
        path: '/guest/nominateclub'
    },
    employeeWellness: {
        id: guid(),
        name: 'employeeWellness',
        displayName: '',
        path: '/guest/employeewellness'
    },
    // admin
    adminArea: {
        id: guid(),
        name: 'adminArea',
        displayName: '',
        path: '/admin'
    },
    partners: {
        id: guid(),
        name: 'partners',
        displayName: 'Partners',
        path: '/admin/partners'
    },
    partnersList: {
        id: guid(),
        name: 'partnersList',
        displayName: 'Partners list',
        path: '/admin/partners/list'
    },
    partnersAdd: {
        id: guid(),
        name: 'partnersAdd',
        displayName: 'Add new partner',
        path: '/admin/partners/add'
    },
    wellnessProgramPayments: {
        id: guid(),
        name: 'wellnessProgramPayments',
        displayName: 'Wellness Program Payments',
        path: '/admin/wellnessprogrampayments'
    },
    members: {
        id: guid(),
        name: 'members',
        displayName: 'Members',
        path: '/admin/members'
    },
    membersTransferResult: {
        id: guid(),
        name: 'membersTransferResults',
        path: '/admin/member/transfers',
        displayName: 'Members Transfer Results'
    },
    partnerPayments: {
        id: guid(),
        name: 'partnerPayments',
        displayName: 'Partner Payments',
        path: '/admin/wellnessprogrampayments/partnerpayments'
    },
    facilityPayments: {
        id: guid(),
        name: 'facilityPayments',
        displayName: 'Facility Payments',
        path: '/admin/wellnessprogrampayments/facilitypayments'
    },
    memberPayments: {
        id: guid(),
        name: 'memberPayments',
        displayName: 'Member Payments',
        path: '/admin/wellnessprogrampayments/memberpayments'
    },
    adminLegalContractPage: {
        id: guid(),
        path: '/admin/members/:memberIdGuid/legalcontract',
        name: 'adminLegalContractPage',
        displayName: 'Legal Contract',
        isHiddenOnUI: true
    },
    // partnerOwner
    partnerOwnerArea: {
        id: guid(),
        name: 'partnerOwnerArea',
        displayName: '',
        path: '/partnerowner/partners/:partnerId(\\d+)'
    },
    partnerDashboard: {
        id: guid(),
        name: 'dashboard',
        displayName: 'Dashboard',
        icon: <Dashboard />,
        path: '/partnerowner/partners/:partnerId(\\d+)/dashboard'
    },
    groupMemberships: {
        id: guid(),
        name: 'groupMemberships',
        displayName: 'Group Memberships',
        path: '/partnerowner/partners/:partnerId(\\d+)/groupmemberships'
    },
    groupMembershipsGeneral: {
        id: guid(),
        name: 'groupMembershipsGeneral',
        displayName: 'General',
        path: '/partnerowner/partners/:partnerId(\\d+)/groupmemberships/general'
    },
    groupMembershipsEmployeeManagement: {
        id: guid(),
        name: 'groupMembershipsEmployeeManagement',
        displayName: 'Employee Management',
        path: '/partnerowner/partners/:partnerId(\\d+)/groupmemberships/employeemanagement'
    },
    groupMembershipsVisitLog: {
        id: guid(),
        name: 'groupMembershipsVisitLog',
        displayName: 'Visits',
        path: '/partnerowner/partners/:partnerId(\\d+)/groupmemberships/visitlog'
    },
    partnerOwnerGroupMembershipsPayments: {
        id: guid(),
        name: 'partnerOwnerGroupMembershipsPayments',
        displayName: 'Group Memberships Payments',
        path: '/partnerowner/partners/:partnerId(\\d+)/groupmemberships/payments'
    },
    partner: {
        id: guid(),
        name: 'partner',
        displayName: 'Partner Info',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo'
    },
    partnerGeneral: {
        id: guid(),
        name: 'partnerGeneral',
        displayName: 'General Info',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/general'
    },
    partnerManagers: {
        id: guid(),
        name: 'partnerManagers',
        displayName: 'Partner Managers',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/managers'
    },
    partnerManagersList: {
        id: guid(),
        name: 'partnerManagersList',
        displayName: 'Partner managers list',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/managers/list'
    },
    partnerManagersAdd: {
        id: guid(),
        name: 'partnerManagersAdd',
        displayName: 'Add partner manager',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/managers/add'
    },
    partnerBankingInfo: {
        id: guid(),
        name: 'partnerBankingInfo',
        displayName: 'Banking Info',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/banking',
    },
    partnerBillingInfo: {
        id: guid(),
        name: 'partnerBillingInfo',
        displayName: 'Billing Info',
        path: '/partnerowner/partners/:partnerId(\\d+)/partnerInfo/billing',
    },
    voucherPrograms: {
        id: guid(),
        name: 'voucherPrograms',
        displayName: 'Voucher Programs',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms'
    },
    voucherProgramsList: {
        id: guid(),
        name: 'voucherProgramsList',
        displayName: 'Voucher Programs List',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms/list'
    },
    voucherProgramsCreate: {
        id: guid(),
        name: 'voucherProgramsCreate',
        displayName: 'Voucher Program Create',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms/create'
    },
    voucherProgramsEdit: {
        id: guid(),
        name: 'voucherProgramsEdit',
        displayName: 'Voucher Program Edit',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms/edit/:voucherProgramId(\\d+)'
    },
    voucherProgramsView: {
        id: guid(),
        name: 'voucherProgramsView',
        displayName: 'Voucher Program View',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms/view/:voucherProgramId(\\d+)'
    },
    voucherProgramRecipientsList: {
        id: guid(),
        name: 'voucherProgramRecipientsList',
        displayName: 'Voucher Program Recipients List',
        path: '/partnerowner/partners/:partnerId(\\d+)/voucherprograms/recipients/:voucherProgramId(\\d+)'
    },
    // partnerManager
    partnerManagerArea: {
        id: guid(),
        name: 'partnerManager',
        displayName: '',
        path: '/partnermanager'
    },
    partnerManagerDashboard: {
        id: guid(),
        name: 'partnerManagerDashboard',
        displayName: 'Dashboard',
        icon: <Dashboard />,
        path: '/partnermanager/dashboard'
    },
    partnerManagerProfile: {
        id: guid(),
        name: 'partnerManagerProfile',
        displayName: 'User Profile',
        icon: <Member />,
        path: '/partnermanager/profile'
    },
    partnerManagerProfileGeneral: {
        id: guid(),
        name: 'partnerManagerProfileGeneral',
        displayName: 'General Info',
        path: '/partnermanager/profile/general'
    },
    partnerManagerProfileChangePassword: {
        id: guid(),
        name: 'partnerManagerProfileChangePassword',
        displayName: 'Change Password',
        path: '/partnermanager/profile/changepassword'
    },
    partnerManagerGroupMemberships: {
        id: guid(),
        name: 'groupMemberships',
        displayName: 'Group Memberships',
        path: '/partnermanager/groupmemberships/'
    },
    partnerManagerGroupMembershipsEmployeeManagement: {
        id: guid(),
        name: 'groupMembershipsEmployeeManagement',
        displayName: 'Employee Management',
        path: '/partnermanager/groupmemberships/employeemanagement'
    },
    partnerManagerGroupMembershipsVisitLog: {
        id: guid(),
        name: 'groupMembershipsVisitLog',
        displayName: 'Visits',
        path: '/partnermanager/groupmemberships/visitlog'
    },
    partnerManagerGroupMembershipsPayments: {
        id: guid(),
        name: 'partnerManagerGroupMembershipsPayments',
        displayName: 'Group Memberships Payments',
        path: '/partnermanager/groupmemberships/payments'
    },
    // member
    memberArea: {
        id: guid(),
        name: 'memberArea',
        displayName: '',
        path: '/member'
    },
    memberDashboard: {
        id: guid(),
        name: 'memberDashboard',
        displayName: 'Dashboard',
        icon: <Dashboard />,
        path: '/member/dashboard'
    },
    memberDashboardHome: {
        id: guid(),
        name: 'memberDashboardHome',
        path: '/member/dashboard/home'
    },
    facilityLocator: {
        id: guid(),
        name: 'facilityLocator',
        path: '/member/dashboard/facilitylocator'
    },
    profile: {
        id: guid(),
        name: 'profile',
        displayName: 'My Member Profile',
        path: '/member/profile',
        icon: <Member />
    },
    general: {
        id: guid(),
        path: '/member/profile/general',
        name: 'general',
        displayName: 'General Info'
    },
    facilityInformation: {
        id: guid(),
        path: '/member/profile/facilityinformation',
        name: 'facilityinformation',
        displayName: 'Facility Info'
    },
    facilities: {
        id: guid(),
        path: '/member/profile/facilityinformation/facilities',
        name: 'facilities',
        displayName: 'Facilities'
    },
    facilityEnrollmentPage: {
        id: guid(),
        path: '/member/profile/facilityinformation/:facilityId(\\d+)/facilityenrollment',
        name: 'facilityenrollment',
        displayName: 'Facility Enrollment'
    },
    memberLegalContractPage: {
        id: guid(),
        path: '/member/profile/facilityinformation/legalcontract',
        name: 'memberLegalContractPage',
        displayName: 'Legal Contract'
    },
    usageLog: {
        id: guid(),
        path: '/member/profile/usagelog',
        name: 'usageLog',
        displayName: 'Visits'
    },
    changePassword: {
        id: guid(),
        path: '/member/profile/changepassword',
        name: 'changePassword',
        displayName: 'Change Password'
    },
    account: {
        id: guid(),
        name: 'account',
        displayName: 'Memberships',
        path: '/member/account',
        icon: <Account />
    },
    insurance: {
        id: guid(),
        path: '/member/account/insurance',
        name: 'insurance',
        displayName: 'General'
    },
    billing: {
        id: guid(),
        path: '/member/account/billing',
        name: 'billing',
        displayName: 'Billing'
    },
    reimbursement: {
        id: guid(),
        path: '/member/account/reimbursement',
        name: 'reimbursement',
        displayName: 'Reimbursements'
    },
    // facilityManager
    facilityManagerArea: {
        id: guid(),
        name: 'facilityManagerArea',
        displayName: '',
        path: '/facilitymanager'
    },
    facilityManagerLegalContractPage: {
        id: guid(),
        path: '/facilitymanager/members/:memberIdGuid/legalcontract',
        name: 'facilityManagerLegalContractPage',
        displayName: 'Legal Contract',
        isHiddenOnUI: true
    },
    facilityManagerDashboard: {
        id: guid(),
        path: '/facilitymanager/dashboard',
        name: 'facilityManagerDashboard',
        isHiddenOnUI: true
    },
    // general uploads
    uploadResults: {
        id: guid(),
        name: 'uploadResults',
        displayName: '',
        path: '/uploadresults'
    },
    storage: {
        path: '/storage/:filePartitionKey/:fileRowKey',
        name: 'storage',
        isHiddenOnUI: true
    },
});

const routeTypeShape = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    path: PropTypes.string.isRequired,
    icon: PropTypes.element,
    component: PropTypes.elementType,
    authorize: PropTypes.arrayOf(roleType)
};

routeTypeShape.routes = PropTypes.arrayOf(PropTypes.shape(routeTypeShape));

export const routeType = PropTypes.shape(routeTypeShape);
