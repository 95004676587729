import { createSelector } from 'reselect';

export const getIsLoading = state => state.memberProfileUsageLog.get('isLoading');
export const getUsageLogData = state => state.memberProfileUsageLog.get('usageLog');
export const getFacilityUsagesGroups = state => state.memberProfileUsageLog.getIn(['usageLog', 'facilityUsagesGroups']);
export const getAvailableUsagePeriods = state => state.memberProfileUsageLog.getIn(['usageLog', 'availableUsagePeriods']);
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

export default createSelector(
    [
        getIsLoading,
        getUsageLogData,
        getFacilityUsagesGroups,
        getAvailableUsagePeriods,
        getSelectedMemberGroup
    ],
    (
        isLoading,
        usageLog,
        facilityUsagesGroups,
        availableUsagePeriods,
        selectedMemberGroup
    ) => ({
        isLoading,
        usageLog,
        facilityUsagesGroups,
        availableUsagePeriods,
        selectedMemberGroup
    })
);