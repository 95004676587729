import React, { useEffect, useState } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import InputSelect from '../controls/InputSelect';
import InputText from '../controls/InputText';
import SortIcon from './SortIcon';
import tableResources from './tableResources';

function  Table({ columns, data, defaultSorted = [], updateCellHandler, validateCellRules = [] }) {
    const pageSizes = [10, 20, 30, 40].map((pageSize) => {
        return { value: pageSize, label: pageSize };
    });
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex }
    } = useTable(
        {
            columns,
            data,
            disableMultiSort: true,
            initialState: {
                sortBy: defaultSorted,
                pageSize: pageSizes[0].value,
            },
            updateCellHandler,
            validateCellRules
        },
        useSortBy,
        usePagination
    );
    const [pageIndexInputValue, setPageIndexInputValue] = useState(pageIndex + 1);
    useEffect(() => {
        setPageIndexInputValue(pageIndex + 1);
    }, [pageIndex]);
    return (
        <div className="prtl-table">
            <div className="info-box__table-container">
                <table {...getTableProps()} className="info-box__table">
                    <thead>
                        {headerGroups.map((headerGroup, i) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`head-row_${i}`}>
                                {headerGroup.headers.map((column, i) => (
                                    <th
                                        key={`head-cell_${i}`}
                                        {...column.getHeaderProps()}
                                        className="info-box__table-cell info-box__table-cell_head"
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        <span {...column.getSortByToggleProps()}>
                                            {column.render('Header')}
                                            {/* Add a sort direction indicator */}
                                            {column.isSorted ?
                                                (
                                                    <SortIcon isSortedDesc={column.isSortedDesc} />
                                                )
                                                : (
                                                    ''
                                                )}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {data.length > 0
                            ? page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr
                                        {...row.getRowProps()}
                                        className="info-box__table-row"
                                        key={`body-row_${i}`}
                                    >
                                        {row.cells.map((cell, i) => {
                                            return (
                                                <td
                                                    key={`body-cell_${i}`}
                                                    {...cell.getCellProps()}
                                                    className="info-box__table-cell"
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                            : <tr className="info-box__table-row">
                                <td className="info-box__table-cell info-box__table-cell_center-text" colSpan={columns.length}>
                                    {tableResources.textNoDataFound}
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div className="info-box__table-controls-container">
                <div className="info-box__table-size-selector-container">
                    <label className="info-box__table-size-selector-label h5">{tableResources.labelShow}</label>
                    <InputSelect
                        defaultValue={pageSizes[0]}
                        options={pageSizes}
                        onChange={(e) => {
                            setPageSize(Number(e.value));
                        }}
                    />
                </div>
                <div className="info-box__table-pager-container">
                    <button
                        className="table-pager__icon table-pager__seek-first"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                    />
                    <button
                        className="table-pager__icon table-pager__seek-prev"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    />
                    <label className="table-pager__label h5">{tableResources.labelPage}</label>
                    <div className="info-box__table-page-selector-container">
                        <div className="c-input c-input_medium">
                            <InputText
                                value={pageIndexInputValue}
                                className="c-input__field"
                                onBlur={(e) => {
                                    gotoPage(pageIndexInputValue - 1);
                                }}
                                onChange={(e) => {
                                    setPageIndexInputValue(e.target.value && !isNaN(e.target.value) ? Number(e.target.value) : 1);
                                }}
                            />
                        </div>
                    </div>
                    <div className="table-pager__label table-pager__label_total-pages h5">
                        {tableResources.labelOf} <span>{pageOptions.length !== 0 ? pageOptions.length : 1}</span>
                    </div>
                    <button
                        className="table-pager__icon table-pager__seek-next"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                    />
                    <button
                        className="table-pager__icon table-pager__seek-last"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Table;
