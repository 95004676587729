import React from 'react';
import PropTypes from 'prop-types';

function AddKeyfobConfirmBody({ keyfobs, partnerName }) {
    return <>
        <div className="message" style={{ fontWeight: 'bold' }}> {keyfobs.length} keyfobs will be added to {partnerName}</div>
        <ol style={{ height: '200px', overflow: 'auto' }}>
            {keyfobs.map((keyfob, i) => <li key={i}>{keyfob.facilityMemberID}</li>)}
        </ol>
    </>;
}

AddKeyfobConfirmBody.propTypes = {
    keyfobs: PropTypes.array.isRequired,
    partnerName: PropTypes.string.isRequired
};

export default AddKeyfobConfirmBody;