import { createSelector } from 'reselect';
import {
    UPLOAD_MEMBER_RECIPIENTS,
    GET_KEYFOBS_LIST
} from './employeeManagementTypes';
const getPartnerId = state => state.partnerManagerReducer.getIn(['manager', 'partnerID']);

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === UPLOAD_MEMBER_RECIPIENTS || i === GET_KEYFOBS_LIST
    ).size;

    return size > 0;
};

const getLaunchDate = (state) => {
    const launchDate = state.groupMembershipsReducer.getIn(['groupMembership', 'launchDate']);
    return launchDate ? new Date(launchDate) : new Date();
};

export default createSelector(
    [getPartnerId, getIsLoading, getLaunchDate],
    (partnerId, isLoading, launchDate) => ({
        partnerId,
        isLoading,
        launchDate
    })
);
