import { createSelector } from 'reselect';
import { NOMINATE } from './nominateClubTypes';

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === NOMINATE
    ).size;

    return size > 0;
};

const getStates = state => state.stateReducer.get('states');

export default createSelector(
    [getStates, getIsLoading],
    (states, isLoading) => ({
        states, isLoading
    })
);