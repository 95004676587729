import * as types from './loadingTypes';

export const setLoading = key => ({
    type: types.SET_LOADING,
    key,
});

export const unsetLoading = key => ({
    type: types.UNSET_LOADING,
    key,
});
