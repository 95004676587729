import React from 'react';
import PropTypes from 'prop-types';

function FacilityCluster({ pointCount, googleMap, clusterId, longitude, latitude, supercluster, facilitiesCount, clusterSize }) {
    const onClickCallback = () => {
        const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterId), 20);
        if (googleMap) {
            googleMap.setZoom(expansionZoom);
            googleMap.panTo({ lat: latitude, lng: longitude });
        }
    };
    clusterSize = clusterSize + (pointCount / facilitiesCount) * 100;

    return <>
        <div
            className="cluster-marker"
            style={{
                width: `${clusterSize}px`,
                height: `${clusterSize}px`
            }}
            onClick={onClickCallback}
        >
            {pointCount}
        </div>
    </>;
}

FacilityCluster.propTypes = {
    pointCount: PropTypes.number.isRequired,
    googleMap: PropTypes.object,
    clusterId: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    supercluster: PropTypes.object.isRequired,
    facilitiesCount: PropTypes.number.isRequired,
    clusterSize: PropTypes.number.isRequired
};

export default FacilityCluster;