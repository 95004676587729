import React, { useState } from 'react';
import FormStep from './FormStep';
import PropTypes from 'prop-types';
import './_formStep.scss';

export default function MultiStepForms({ forms, onSubmit, formName, narrow }) {
    const [filledStage, setFilledStage] = useState([0]);
    const [step, setStep] = useState(0);
    const [open, setOpen] = useState(false);
    const onPrevStep = () => {
        setStep(step - 1);
        scrollToTop();
    };
    const onNextStep = () => {
        setFilledStage([...filledStage, step + 1]);
        setStep(step + 1);
    };
    const scrollToTop = () => window.scrollTo(0, 0);
    return (
        <div className="form-step__forms">
            {forms.map((form, index, forms) =>
                <FormStep key={index} index={index}
                    currentStep={step}
                    title={`${index + 1}. ${form.title}`}
                    onClickHandler={() => {
                        if (!open || index !== step) {
                            if (filledStage.includes(index)) {
                                setStep(index);
                                setOpen(true);
                            }
                        }
                        else {
                            if (filledStage.includes(index + 1)) {
                                setOpen(false);
                                setStep(index + 1);
                            }
                        }

                    }}
                    clickable={filledStage.includes(index)}
                    form={React.createElement(form.formComponent, {
                        onPrevStep,
                        onNextStep,
                        scrollToTop,
                        onFormSubmit: index + 1 === forms.length ? onSubmit : undefined,
                        form: formName,
                        formParams: form.formParams
                    })}
                    narrow={narrow}
                />
            )}
        </div>
    );
}

MultiStepForms.propTypes = {
    forms: PropTypes.arrayOf(PropTypes.object).isRequired,
    onSubmit: PropTypes.func,
    formName: PropTypes.string.isRequired,
    narrow: PropTypes.bool,
};