import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import MuiButton from '../../../components/controls/MuiButton';
import { defaultFormProperties } from '../../../utilities/form';
import MuiInputSelect from '../../../components/controls/MuiInputSelect';
import resources from './memberPaymentsResources';

const MemberPaymentsFiltersForm = ({
    pristine,
    submitting,
    handleSubmit,
    programPaymentTypes,
    onProgramPaymentTypeChange,
    paymentTypes,
    partners,
    periods,
    viewButtonDisabled
}) => {

    function handleChange(event) {
        onProgramPaymentTypeChange(event.target.value);
    }

    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className="grid__row">
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <Field
                        id="program_type"
                        labelId="program_type_label"
                        name={resources.fieldProgramTypeID}
                        component={MuiInputSelect}
                        label={resources.labelProgramType}
                        options={programPaymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <Field
                        id="payment_type"
                        labelId="payment_type_label"
                        name={resources.fieldPaymentTypeID}
                        component={MuiInputSelect}
                        label={resources.labelPaymentType}
                        options={paymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <Field
                        id="partner"
                        labelId="partner_label"
                        name={resources.fieldPartnerID}
                        component={MuiInputSelect}
                        label={resources.labelPartner}
                        options={partners}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                    />
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <Field
                        id="period"
                        labelId="period_label"
                        name={resources.fieldPeriodID}
                        component={MuiInputSelect}
                        label={resources.labelVisitMonth}
                        options={periods}
                        getOptionLabel={option => option.get('yearMonth')}
                        getOptionValue={option => option.get('id')}
                    />
                </div>
            </div>
            <div>
                <MuiButton
                    type="submit"
                    disabled={pristine || submitting || viewButtonDisabled}
                    className="btn_new btn_new--white"
                >
                    {resources.buttonTextView}
                </MuiButton>
            </div>
        </FormGroup>
    );
};

MemberPaymentsFiltersForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    onProgramPaymentTypeChange: PropTypes.func.isRequired,
    paymentTypes: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    viewButtonDisabled: PropTypes.bool.isRequired
};

export default reduxForm({
    form: resources.memberPaymentsFiltersFormName,
    ...defaultFormProperties
})(MemberPaymentsFiltersForm);
