import React from 'react';
import AdditionalDetails from '../../../components/AdditionalDetails/AdditionalDetails';
import Icon from '../../../components/Icon/Icon';
import { Attention } from '../../../components/Icon/Icons';

function BankingInfoSecurityNote() {
    return (
        <AdditionalDetails
            icon={<Icon icon={<Attention/>} />}
            title="Please, know"
            text="Your privacy, and compliance to HIPAA & PCI requirements are of the utmost importance. It is with industry leading technology safeguards we protect your information from the moment its typed in and on through our Web and processing systems. We feel these extensive privacy features gives everyone peace of mind."
        />
    );
}

export default BankingInfoSecurityNote;
