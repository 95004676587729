import { GET_USER_DESCRIPTION_LIST_SUCCESS } from './contactUsTypes';
import initialState from './contactUsInitialState';
import { fromJS } from 'immutable';

export default function contactUsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_USER_DESCRIPTION_LIST_SUCCESS:
            return state.set('userDescriptions', fromJS(action.descriptions));
        default:
            return state;
    }
}
