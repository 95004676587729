import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export default {
    getBankingInfo: partnerId =>
        http.get(API_PATHS.partnerBankingInfo, { params: { partnerId } }),
    updateBankingInfo: bankingInfo =>
        http.post(API_PATHS.partnerBankingInfo, bankingInfo.toJS()),
    getBankAccountDetails: payeeToken =>
        http.get(API_PATHS.partnerBankAccountDetails, { params: { payeeToken } }),
};
