import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import routes from '../../../../routing/routes';
import { HEALTHY_CONTRIBUTIONS_LEGAL_NAME } from '../../../../constants/companyNames';
import { HEALTHY_CONTRIBUTIONS_PRIVACY } from '../../../../constants/emails';
import { HEALTHY_CONTRIBUTIONS } from '../../../../constants/websites';
import '../../../../styles/_info-page.scss';
import '../../../../styles/_utils.scss';
import privacyPolicyArchiveTitleFormatter from '../../../../utilities/formatters/privacyPolicyArchiveTitleFormatter';
import privacyPolicyEffectiveDateFormatter from '../../../../utilities/formatters/privacyPolicyEffectiveDateFormatter';

function PrivacyPolicy20230110() {
    const effectiveDate = new Date(2023, 0, 10);
    const formattedEffectiveDate = privacyPolicyEffectiveDateFormatter.formatPrivacyPolicyEffectiveDate(effectiveDate);
    useEffect(() => {
        document.title = privacyPolicyArchiveTitleFormatter.formatPrivacyPolicyTitle(effectiveDate);
    }, []);
    return <div className="info-page">
        <div className="info-page__title">Privacy Policy</div>
        <div className="info-page__subtitle">Effective Date: {formattedEffectiveDate}</div>
        <div className="info-page__content">
            <div className="info-page__primary-content">
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-paragraph">
                        This Privacy Policy outlines {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}’s (“we” or “us”) current policy on personal information concerning program partners, participating facilities, their owners, employees and members, and covers the operations of our fitness incentive data and/or payment processing and/or reimbursement programs, business development, and use of our website: {HEALTHY_CONTRIBUTIONS} (the “Sites”).
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        By using Healthy Contributions® services, products, tools or resources, you agree to the terms of this Privacy Policy and our Terms of Use. You may refuse or withdraw your consent at any time; however, we may not then be able to provide you with our full range of products and services. You may not use the Sites or our products or services if you do not agree to the Privacy Policy.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        This policy may change from time to time. Your continued use after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">
                        We encourage you to read this whenever you visit our fitness clubs or Sites, since we may change this policy from time to time. A copy of the current policy is always posted at&nbsp;
                        <NavLink
                            className="link link--blue link--overflow-wrap"
                            to={routes.privacynotice.path}
                        >
                            https://portal.healthycontributions.com/privacy
                        </NavLink>
                    </div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">Territories</div>
                    <div className="info-page__primary-content-section-paragraph">This Privacy Policy covers our activities throughout the world. We may host the Sites or information within the Sites on servers located within these countries or in any other country we, our service providers, or our service providers’ vendors maintain facilities, including the United States. This means that your personal information may be stored on servers located within any country. The locations of our servers may change from time-to-time. Further, our participating facilities may maintain individual websites in the country or territory they service.</div>
                    <div className="info-page__primary-content-section-paragraph">Privacy requirements and treatment of personal information varies from state to state, and country to country. To meet these requirements, we have included certain “Location-Specific Notices” at the end of this Privacy Policy. These “Location-Specific Notices” are intended to supplement this Privacy Policy. For a more complete review of how we handle your personal information, please review this Privacy Policy in its entirety.</div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">Consent</div>
                    <div className="info-page__primary-content-section-paragraph">BY SUBMITTING YOUR PERSONAL INFORMATION TO US, YOU ARE CONSENTING TO THE PROCESSING OF YOUR PERSONAL INFORMATION IN THE UNITED STATES OR IN ANY OTHER COUNTRY IN THE WORLD AND TO OUR USE OF YOUR PERSONAL INFORMATION AS SET FORTH IN THIS PRIVACY POLICY. IF YOU ARE SUBMITTING PERSONAL INFORMATION OF THIRD PARTIES TO US, YOU WARRANT AND REPRESENT YOU HAVE THEIR CONSENT TO PROVIDE THEIR PERSONAL INFORMATION TO US AND WILL HOLD US HARMLESS FROM AND AGAINST CLAIMS BY SUCH INDIVIDUALS RELATING TO OUR PROCESSING AND USE OF SUCH PERSONAL INFORMATION WITHIN THE TERMS OF THIS PRIVACY POLICY.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="personal-info-collect">
                    <div className="info-page__primary-content-section-title">1. Personal Information We Collect through the Site</div>
                    <div className="info-page__primary-content-section-paragraph">In general, you can browse the non-password protected portions of our Sites without telling us who you are or revealing any personal information about yourself, except for our collection of “cookies” as described in Section 3.</div>
                    <div className="info-page__primary-content-section-paragraph">We may collect personal information from you on our Site or through any other digital or personal communication with you. Information collected by us is voluntarily provided by you and may be integrated with other information you have provided to us or our participating facilities upon signing up for a membership or fitness incentive and/or reimbursement program.</div>
                    <div className="info-page__primary-content-section-paragraph">Our Site may also collect general information about your technology interface or preferences (such as the type of browser used or the files requested). Some of the information described below is mandatory for the performance of certain services, and if not entered, you may not be able to utilize or participate in some of our online services or features.</div>
                    <div className="info-page__primary-content-section-paragraph">The information we may collect through your use of the Site may include, but is not limited to:</div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">Where the account registration feature is available, your contact information to create an Healthy Contributions account, such as your first name, last name, phone number, and email address;</li>
                        <li className="info-page__primary-content-section-list-item">Where the account registration feature is available, your profile username and password to enter the password-protected portion of the Site;</li>
                        <li className="info-page__primary-content-section-list-item">Your transaction history relating to the use of participating facilities, purchase of certain products or other information you wish to share with us and integrate with the Site;</li>
                        <li className="info-page__primary-content-section-list-item">If integration with your membership is available to you, membership-related information, such as your home address, participating facility location, key FOB number, and membership number;</li>
                        <li className="info-page__primary-content-section-list-item">Fitness-related information provided in the membership process, such as your height, weight, general fitness-related goals or other information helpful to achieving your fitness goal;</li>
                        <li className="info-page__primary-content-section-list-item">Information regarding training sessions, classes, or other information regarding your utilization of services at the club-level;</li>
                        <li className="info-page__primary-content-section-list-item">Information provided by you when you participate in a survey or communication to you regarding your use of the Site, or utilizing Healthy Contributions services;</li>
                        <li className="info-page__primary-content-section-list-item">Information provided by you to participate in advertising contests or sweepstakes;</li>
                        <li className="info-page__primary-content-section-list-item">Various account information related to your Healthy Contributions account to allow you to update credit card, bank account, other payment information, or any other information regarding your profile;</li>
                        <li className="info-page__primary-content-section-list-item">Computer data or files, often called “cookies,” which may be sent by your web browser and stored on an individual computer to allow our server to “recognize” you through the navigation of the Site and for us to credit your account for various online transactions;</li>
                        <li className="info-page__primary-content-section-list-item">Computer data files, often called “web beacons” that are collected from emails or links on third-party sites that allow our server to evaluate the popularity of those links or the information contained in emails or on third-party sites; and</li>
                        <li className="info-page__primary-content-section-list-item">Your IP address to measure our website traffic and to help provide a more personalized experience.</li>
                    </ul>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">Children Under the Age of 13</div>
                    <div className="info-page__primary-content-section-paragraph">Our Site is not designed for children and we do not knowingly collect information from any person under the age of 13. If you are under the age of 13, please do not submit any personal information to us through the Site. If we learn we have collected or received personal information from a child under 13, we will delete that information.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="personal-info-use">
                    <div className="info-page__primary-content-section-title">2. How We Use and Share Your Personal Information</div>
                    <div className="info-page__primary-content-section-paragraph">We will use your personal information to provide the services we offer on the Site, including:</div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">Processing and updating your account and/or membership information, including personal information you provide, credit card information or other electronic payment processing;</li>
                        <li className="info-page__primary-content-section-list-item">Processing your incentive or reimbursement information;</li>
                        <li className="info-page__primary-content-section-list-item">Contacting you regarding services you have engaged in or with;</li>
                        <li className="info-page__primary-content-section-list-item">Providing you with information regarding participating facility locations nearest to you or certain information regarding your facility;</li>
                        <li className="info-page__primary-content-section-list-item">Conducting marketing and research, through direct surveys to you, but also through our or participating facilities’ communication with you;</li>
                        <li className="info-page__primary-content-section-list-item">Providing you with information you have requested from us, our facilities or their employees;</li>
                        <li className="info-page__primary-content-section-list-item">Developing and displaying content and advertising tailored to your interests and preferences;</li>
                        <li className="info-page__primary-content-section-list-item">Sending you electronic communications you have requested, such as text messages, push notifications, notifications through our Site; or</li>
                        <li className="info-page__primary-content-section-list-item">Contact you regarding updates to the Site or provide administrative notices to you.</li>
                    </ul>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-paragraph">We strive to offer you integrated, comprehensive fitness incentive and reimbursement programs. In order to operate the Site as one seamless experience, however, we must share your personal information with our parent company, subsidiaries, and affiliates to provide you with the integrated services offered through the Site. We may also share such information with our participating facilities and program partners, or other business partners with whom we collaborate or work with to provide specific services to you or if we think the products or services they offer would be of interest to you. We may provide user information to third-party service providers or vendors for purposes of, including without limitation, initiating direct marketing programs on our behalf, data tracking, maintenance or development of our Site, development of online products and services, customer service or new product development, or other contracted promotional opportunities provided to our users. We, as well as our franchisees, vendors, or other business partners may use this information for marketing and solicitation purposes.</div>
                    <div className="info-page__primary-content-section-paragraph">Your fitness club visit data and personal information may be made available to participating facilities and program partners for the administration of fitness incentive and reimbursement programs and therefore may be available to participating facilities, including their management and employees, or other contracted service providers whom you have engaged to provide services to you. Each of those parties is obligated to maintain the confidentiality of your personal information, and is permitted to access and use your personal information solely for the purpose of providing or enhancing services you use through our programs.</div>
                    <div className="info-page__primary-content-section-paragraph">While we try to be selective in working with third parties, we are not responsible for their use of your personal information. Where payments are involved, we must release your credit card information to credit card authorization service providers and associated banks to process your fitness incentive reimbursements, if applicable. We may transfer to our online service providers personal information you provide to us so that these parties may store and process your personal information.‎</div>
                    <div className="info-page__primary-content-section-paragraph">We may share aggregate information with vendors, potential advertisers, business partners, or other third parties. Aggregate information does not include personally identifiable information.</div>
                    <div className="info-page__primary-content-section-paragraph">Except as described in this section, we will not give or sell your personal information to any other party for its use in marketing or solicitation.</div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">Additional Disclosures</div>
                    <div className="info-page__primary-content-section-paragraph">We may also disclose personal information in any of the following circumstances: (1) in response to a subpoena, search warrant, court order, or other legal process or request, or as may otherwise be required by applicable law or regulation; (2) to protect and defend our rights or property or those of its franchisees, suppliers, related entities, affiliates, business partners, or others; or (3) as we, in our sole discretion, believe is appropriate under exigent circumstances in response to a perceived threat to the personal safety, property, or rights of any other person or organization.</div>
                    <div className="info-page__primary-content-section-paragraph">In addition, since member information on our Site is a business asset, in the event we are reorganized or sold to or merged with another company, we may sell, assign, or transfer your personal information to a purchaser of all or substantially all of our business assets, or to an affiliate, as applicable.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="cookie-use">
                    <div className="info-page__primary-content-section-title">3. Our Use of Your “Cookie” Information on the Site</div>
                    <div className="info-page__primary-content-section-paragraph">A “cookie” is a small data file stored on your web browser or on your mobile device that allows us to recognize your computer or mobile device when you visit the Site by associating identification numbers with other user information you have provided us. Some cookies will remain on the hard drive of your computer or mobile device for the duration of your browser or user session, while others will remain until deleted by you. You may also be able to configure your computer or mobile device to limit the collection of these “cookies,” but that limitation may also limit our ability to provide all the services or functionality of the Site. Some third party service providers may use “flash cookies,” which are saved on your computer, but cannot be rejected, disabled, turned off, opted out of, or deleted in the same way as regular cookies. To learn how to manage your flash cookie settings, visit the Flash player settings page on Adobe’s website.</div>
                    <div className="info-page__primary-content-section-paragraph">We may also use third-party vendors, such as Google Analytics, to advertise to our users online. These third-party vendors may display Healthy Contributions product advertisements on other websites based on your internet usage. More specifically, these vendors use first-party cookies (such as the Google Analytic cookie) and third-party cookies (such as the DoubleClick cookie) together to inform, optimize, and serve ads based on your past visits to the Site or another site affiliated with Healthy Contributions products or services. Any information that these third parties collect via cookies is not linked to any personally identifiable information (name, address, email address, or phone number) we collect or you provide on the website. You can opt-out of Google Analytics for Display Advertising by using Google’s Ads Settings.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="website-relationships">
                    <div className="info-page__primary-content-section-title">4. Our Relationship with the Websites</div>
                    <div className="info-page__primary-content-section-paragraph">Other websites may contain links to the Sites. We do not review or monitor the websites linked to the Sites and are not responsible for the contents or privacy policies related to any other websites. If you visit one of these other websites, you may want to review the privacy policy on that website. Where the Sites contains a link to another website owned and/or operated by us, such website use may be subject to different and additional terms of service and privacy policy terms and conditions.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="personal-info-access">
                    <div className="info-page__primary-content-section-title">5. Access and Correction of your Personal Information</div>
                    <div className="info-page__primary-content-section-paragraph">We strive to maintain the accuracy and integrity of the personal information in our databases and to keep that information up-to-date. We encourage you to promptly update your personal information if it changes. You may edit or deactivate your account information or account at any time by signing into your account on the Site and navigating through your settings. You may request a copy of the personal information we have about you. You may send a request by letter to: {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}, Attention: Data Protection Officer, 111 Weir Drive, Woodbury, Minnesota 55125 U.S.A.</div>
                    <div className="info-page__primary-content-section-paragraph">Unless otherwise stated in an addendum, all communications must be in the English language. We will respond to reasonable requests within the time limits established by applicable law or within a reasonable time if no time limit is established by applicable law. For your protection, we may ask you for additional information to verify your identity. In most cases, we will provide the information you request and correct or delete any inaccurate personal information you discover. We reserve the right, however, to limit or deny your request to the extent permitted by applicable law if: (1) the disclosure may be threat to the personal safety, property, or rights of any other person or organization; (2) the disclosure may lead to a violation of applicable laws or regulations; or (3) where you have failed to provide sufficient evidence to verify your identity.</div>
                </section>
                <section className="info-page__primary-content-section">
                    <div className="info-page__primary-content-section-title">Opting Out or Unsubscribing</div>
                    <div className="info-page__primary-content-section-paragraph">You may opt out of certain collections of cookies or other web- or mobile device based enhancements, including geolocation data collection, by changing your browser settings or going into your preferences on your mobile device and restricting the transmission of certain information. Your limitation of the collection of this information, however, may disable our ability to provide you with the most relevant products or information regarding the Site. Currently, our servers do not respond to browser “do not track” signals. If you would like more information regarding online marketing practices and know your choices about not having this information used by our third party service providers, you may visit&nbsp;
                        <a className="link link--blue" href="https://www.networkadvertising.org/choices/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            www.networkadvertising.org/choices
                        </a>.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">To unsubscribe from our emails or other communication from us or a third party, you may follow the “unsubscribe” link at the bottom of the email or sign onto your account and change your email settings. To unsubscribe from text messages or any other mobile messages, you may follow the instructions provided at the time of opt-in or contact us at <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a>.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="personal-info-security">
                    <div className="info-page__primary-content-section-title">6. Security of Your Personal Information</div>
                    <div className="info-page__primary-content-section-paragraph">We take reasonable precautions and have implemented managerial and technical procedures to try to protect the security of data and information, including personal information. However, we cannot guarantee against any loss, misuse, unauthorized disclosure, or alteration or destruction of data or personal information. You provide your personal information to us at your own risk. You acknowledge that: (1) there are security and privacy limitations in computer systems and on the Internet which are beyond our control; (2) the security, integrity, and privacy of any and all information and data exchanged between you and us through the Sites, including personal information, cannot be guaranteed; and (3) any such information and data, including personal information, may be viewed or tampered with by a third party while such information or data is being used, transmitted, processed, or stored. We collect, maintain, utilize and disclose your information only as required by law and as described in this Privacy Policy.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="personal-info-retention">
                    <div className="info-page__primary-content-section-title">7. Retention of Your Personal Information</div>
                    <div className="info-page__primary-content-section-paragraph">We keep your personal information only as long as we need it for the purposes for which it was originally collected (or to which you have subsequently consented) or for other legitimate purposes (such as regulatory compliance), or as permitted by applicable law.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="privacy-policy-changes">
                    <div className="info-page__primary-content-section-title">8. Changes to our Privacy Policy</div>
                    <div className="info-page__primary-content-section-paragraph">We reserve the right to modify this Privacy Policy, completely or in part, from time to time through the posting of notices on the Sites, at any time and without notice to you, that this Privacy Policy has changed (“Notices”). You expressly waive any right to receive Notices. You should check the Sites frequently for Notices to identify modifications of the Privacy Policy that may be of interest to you. Any use of the Sites following the date on which we implement changes to this Privacy Policy or post a Notice constitutes your acceptance of all such changes.</div>
                    <div className="info-page__primary-content-section-paragraph">For questions or comments related to this Privacy Policy, please email us at <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a>.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="contact-us">
                    <div className="info-page__primary-content-section-title">9. How to Contact Us</div>
                    <div className="info-page__primary-content-section-paragraph">For further information or enquiries regarding your personal information (including to request access to or correction of your personal information or to make a privacy complaint), please contact our Data Protection Officer at:</div>
                    <address className="info-page__primary-content-section-address">
                        {HEALTHY_CONTRIBUTIONS_LEGAL_NAME}<br />
                        Attention: Data Protection Officer<br />
                        111 Weir Drive, Woodbury<br />
                        Minnesota 55125 U.S.A.<br />
                        <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a>
                    </address>
                    <div className="info-page__primary-content-section-paragraph">If you have a complaint about how we have collected or handled your personal information, please contact our Data Protection Officer at the mail address set forth above.</div>
                </section>
                <section className="info-page__primary-content-section scroll-to-section" id="location-notices">
                    <div className="info-page__primary-content-section-title">10. Location-Specific Notices</div>
                    <div className="info-page__primary-content-section-subtitle">Your California Privacy Rights</div>
                    <div className="info-page__primary-content-section-paragraph">If you are a California resident, please also visit our Privacy Notice for California Residents, which supplements this Privacy Policy at&nbsp;
                        <NavLink
                            className="link link--blue link--overflow-wrap"
                            to={routes.californiaprivacypolicy20230110.path}
                        >
                            https://portal.healthycontributions.com/ccpa/
                        </NavLink>.
                    </div>
                    <div className="info-page__primary-content-section-paragraph">In addition, California Civil Code Section § 1798.83 permits users of our Site or Apps that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please contact our Data Protection Officer.</div>
                    <div className="info-page__primary-content-section-subtitle">Your Nevada Privacy Rights</div>
                    <div className="info-page__primary-content-section-paragraph">Nevada law allows consumers to direct certain businesses not to license or sell their personally identifiable information to third parties. If you are a Nevada resident, you may submit such opt-out requests to <a className="link link--blue" href={`mailto:${HEALTHY_CONTRIBUTIONS_PRIVACY}`}>{HEALTHY_CONTRIBUTIONS_PRIVACY}</a>. To be effective, your request must include your full name, address, phone number, and email address. Healthy Contributions will endeavor to respond to your verified request within 60 days of receiving the request. However, due to unforeseen circumstances, Healthy Contributions may need to extend this period by up to 30 days. If an extension is reasonably necessary, Healthy Contributions will notify you of this during the initial 60-day period.</div>
                    <div className="info-page__primary-content-section-subtitle">Your Virginia Privacy Rights</div>
                    <div className="info-page__primary-content-section-paragraph">If you are a Virginia resident, please refer to the information below. This section describes how we, as the “controller”, process your personal data under Virginia’s Consumer Data Protection Act (“VCDPA”), effective as of January 1, 2023. For purposes of this section, “controller”, “personal data” and other terms used herein have the meanings provided for in the VCDPA.</div>
                    <div className="info-page__primary-content-section-paragraph">The law offers Virginia residents various rights to access and control their personal data, and sets certain notice obligations, outlined below.</div>
                    <div className="info-page__primary-content-section-subtitle">Consumer Privacy Rights. <span className="info-page__primary-content-section-subtitle--regular">The Virginia law grants consumers the right to:</span></div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item">Know whether we are processing your personal data;</li>
                        <li className="info-page__primary-content-section-list-item">Access personal data we process;</li>
                        <li className="info-page__primary-content-section-list-item">Correct inaccuracies in your personal data;</li>
                        <li className="info-page__primary-content-section-list-item">Delete your personal data;</li>
                        <li className="info-page__primary-content-section-list-item">Obtain a copy of your personal data;</li>
                        <li className="info-page__primary-content-section-list-item">Opt-out of processing your personal data for: targeted advertising, personal data sales, or automated decision-making, including profiling, which has legal or other significant effects on you</li>
                    </ul>
                    <div className="info-page__primary-content-section-paragraph">If we refuse to honor any one of your privacy requests outlined above, you may appeal our refusal. In this case, please contact us within a reasonable time as outlined in Section 9, “How to Contact Us”, of this Privacy Policy.</div>
                    <div className="info-page__primary-content-section-paragraph">To submit a verifiable request to us, please complete the online Data Privacy Request form located here:&nbsp;
                        <NavLink
                            className="link link--blue link--overflow-wrap"
                            to={routes.dataprivacyrequest.path}
                        >
                            https://portal.healthycontributions.com/dataprivacyrequest/
                        </NavLink>
                        . Please also refer to Section 5, “Access and Correction of your Personal Information”, of this Privacy Policy for more information.</div>
                    <div className="info-page__primary-content-section-subtitle">How We Use and Share Your Personal Information. <span className="info-page__primary-content-section-subtitle--regular">This section is intended to supplement the information contained in other applicable sections of this Privacy Policy. It outlines the broad categories of personal data we process, examples of personal data we collect, the purposes for processing this data, and the categories of third parties that we “sell” and/or share your data with.</span></div>
                    <ul className="info-page__primary-content-section-list">
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Categories of personal data.</span> The following categories of personal data we collect includes: сontact information, transaction history relating to the use of clubs, information regarding utilization of services, identifiers, geolocation data, information related to becoming an employee;</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Personal data we collect.</span> The personal data we collect includes but is not limited to (see also personal data we collect under Section 1 and other applicable sections of this Privacy Policy): personal data we may collect includes but is not limited to: Contact information to create an account (e.g., full name, phone number, email address, physical address, birth date); computer data or files which may be sent by your web browser to allow our server to “recognize” you through the navigation of the site; your IP address to measure our website traffic and to help provide a more personalized experience; contact information for marketing purposes where you have consented to receive such communications where applicable; information collected where you use our application;</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Categories of sources of data.</span> We receive your personal data from the following sources: yourself by providing the data to us, as a result of certain interactions with third parties, and public sources;</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Purposes for processing your data.</span> We process your data for the following purposes: creating and managing your account, improving our services, sending marketing communications with your consent, processing your payments (using a third-party payment provider) and related purposes;</li>
                        <li className="info-page__primary-content-section-list-item"><span className="info-page__primary-content-section-list-item-title">Categories of entities with whom we “sell” or share your data (if applicable).</span> Wellness partners, marketing providers, cloud-hosting services, our affiliates</li>
                    </ul>
                </section>
            </div>
            <div className="info-page__secondary-content">
                <div className="info-page__secondary-content-title">In the following Privacy Policy, we provide you with information regarding:</div>
                <div className="info-page__secondary-content-description">
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#personal-info-collect">1. Personal Information We Collect through the Site</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#personal-info-use">2. How We Use and Share Your Personal Information</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#cookie-use">3. Our Use of Your “Cookie” Information on the Site</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#website-relationships">4. Our Relationship with other Websites</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#personal-info-access">5. Access and Correction of your Personal Information</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#personal-info-security">6. Security of Your Personal Information</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#personal-info-retention">7. Retention of your Personal Information</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#privacy-policy-changes">8. Changes to this Privacy Policy</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#contact-us">9. How to Contact Us</a></div>
                    <div className="info-page__secondary-content-paragraph"><a className="link link--blue" href="#location-notices">10. Location-Specific Notices</a></div>
                </div>
            </div>
        </div>
    </div>;
}

export default PrivacyPolicy20230110;