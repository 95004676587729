import React from 'react';
import PropTypes from 'prop-types';
import styles from './_table-filters-panel.module.scss';

const TableFiltersPanel = function ({ children }) {
    return <div className={styles.table_filters_panel}>{children}</div>;
};

TableFiltersPanel.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default TableFiltersPanel;