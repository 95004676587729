import { createSelector } from 'reselect';
import {
    GetDeactivationConfirmationMessage,
    GetReassignConfirmationMessage,
    GetKeyfobDeleteConfirmationMessage,
    GetMemberDeleteConfirmationMessage,
    GetMemberDeleteConfirmationTitle,
    GetMemberDeleteAtNextMonthConfirmationMessage
} from './keyfobsAndEmployeesListResources';
import roles from '../../../../enums/roles';
import programPaymentTypes from '../../../../enums/programPaymentTypes';
import invitationStatuses from '../../../../enums/invitationStatuses';

const getKeyfobs = state => state.employeeManagementReducer.get('keyfobs');
const getMemberToDeactivate = state => state.employeeManagementReducer.get('memberToDeactivateMembership');
const getKeyfobToReassign = state => state.employeeManagementReducer.get('keyfobToReassign');
const getMemberToDelete = state => state.employeeManagementReducer.get('memberToDelete');
const getMe = state => state.app.get('me');
const getIsReassignKeyfobFeatureEnabled = state => state.employeeManagementReducer.get('isReassignKeyfobFeatureEnabled');
const getKeyfobsTotalCount = state => state.employeeManagementReducer.get('keyfobsTotalCount');
const getMembersWaitingToInviteCount = state => state.employeeManagementReducer.get('membersWaitingToInviteCount');
const getProgramPaymentTypeID = state => state.employeeManagementReducer.get('programPaymentTypeID');
const getIsConfirmationPopupVisible = createSelector(
    [getMemberToDeactivate],
    (memberToDeactivateMembership) => {
        return memberToDeactivateMembership !== undefined;
    }
);

const getDeactivationConfirmationPopupMessage = createSelector(
    [getMemberToDeactivate],
    (memberToDeactivateMembership) => {
        if (memberToDeactivateMembership === undefined) {
            return;
        }
        return GetDeactivationConfirmationMessage(memberToDeactivateMembership.memberFirstName, memberToDeactivateMembership.memberLastName, memberToDeactivateMembership.facilityMemberID);
    }
);

const getEmployeeDeletionConfirmationPopupMessage = createSelector(
    [getProgramPaymentTypeID, getMemberToDelete],
    (programPaymentTypeID, memberToDelete) => {
        if (memberToDelete === undefined) {
            return;
        }
        if (programPaymentTypeID === programPaymentTypes.partnerMemberPaid && memberToDelete.invitationStatus === invitationStatuses.activated) {
            return GetMemberDeleteAtNextMonthConfirmationMessage();
        }
        return GetMemberDeleteConfirmationMessage();
    }
);

const getEmployeeDeletionConfirmationPopupTitle = createSelector(
    [getMemberToDelete],
    (memberToDelete) => {
        if (memberToDelete === undefined) {
            return;
        }
        return GetMemberDeleteConfirmationTitle(memberToDelete.memberFirstName, memberToDelete.memberLastName);
    }
);

const getKeyfobIdToDelete = createSelector(
    [getMemberToDelete], (memberToDelete) => {
        if (memberToDelete === undefined) {
            return;
        }
        return memberToDelete.keyfobID;
    }
);

const getIsDeleteKeyfobConfirmationPopupVisible = createSelector(
    [getMemberToDelete],
    (memberToDelete) => {
        return memberToDelete !== undefined && memberToDelete.facilityMemberID !== undefined;
    }
);

const getDeleteKeyfobConfirmationPopupMessage = createSelector(
    [getMemberToDelete],
    (memberToDelete) => {
        if (memberToDelete === undefined) {
            return;
        }
        return GetKeyfobDeleteConfirmationMessage(memberToDelete.facilityMemberID);
    }
);

const getIsKeyfobReassignConfirmationPopupVisible = createSelector(
    [getKeyfobToReassign],
    (keyfobToReassign) => {
        return keyfobToReassign !== undefined;
    }
);

const getKeyfobReassignConfirmationPopupMessage = createSelector(
    [getKeyfobToReassign],
    (keyfobToReassign) => {
        if (keyfobToReassign === undefined) {
            return;
        }
        return GetReassignConfirmationMessage(keyfobToReassign.oldFacilityMemberID);
    }
);

const isAdmin = createSelector(
    [getMe], (me) => {
        return me.role === roles.systemAdmin;
    }
);

const isPartnerManager = createSelector(
    [getMe], (me) => {
        return me.role === roles.partnerManager;
    }
);

const getIsActionVisible = createSelector([getProgramPaymentTypeID], (programPaymentTypeID) => {
    return programPaymentTypeID === programPaymentTypes.partnerPaid || programPaymentTypeID === programPaymentTypes.partnerMemberPaid;
});

export default createSelector(
    [
        getKeyfobs,
        getMemberToDeactivate,
        getKeyfobToReassign,
        getKeyfobIdToDelete,
        getMemberToDelete,
        getIsReassignKeyfobFeatureEnabled,
        getDeactivationConfirmationPopupMessage,
        getDeleteKeyfobConfirmationPopupMessage,
        getEmployeeDeletionConfirmationPopupMessage,
        getEmployeeDeletionConfirmationPopupTitle,
        getIsConfirmationPopupVisible,
        getKeyfobReassignConfirmationPopupMessage,
        getIsKeyfobReassignConfirmationPopupVisible,
        isAdmin,
        getIsDeleteKeyfobConfirmationPopupVisible,
        getKeyfobsTotalCount,
        getMembersWaitingToInviteCount,
        getIsActionVisible,
        getProgramPaymentTypeID,
        isPartnerManager
    ],
    (
        keyfobs,
        memberToDeactivateMembership,
        keyfobToReassign,
        keyfobIdToDelete,
        memberToDelete,
        isReassignKeyfobFeatureEnabled,
        deactivationConfirmationPopupMessage,
        deleteKeyfobConfirmationMessage,
        deleteMemberConfirmationMessage,
        deleteMemberConfirmationTitle,
        isConfirmationPopupVisible,
        reassignConfirmationPopupMessage,
        isReassignConfirmationPopupVisible,
        isAdmin,
        isDeleteKeyfobConfirmationPopupVisible,
        totalCount,
        membersWaitingToInviteCount,
        isActionVisible,
        programPaymentTypeID,
        isPartnerManager
    ) => ({
        keyfobs,
        memberToDeactivateMembership,
        keyfobToReassign,
        keyfobIdToDelete,
        memberToDelete,
        isReassignKeyfobFeatureEnabled,
        deactivationConfirmationPopupMessage,
        deleteKeyfobConfirmationMessage,
        deleteMemberConfirmationMessage,
        deleteMemberConfirmationTitle,
        isConfirmationPopupVisible,
        reassignConfirmationPopupMessage,
        isReassignConfirmationPopupVisible,
        isAdmin,
        isDeleteKeyfobConfirmationPopupVisible,
        totalCount,
        membersWaitingToInviteCount,
        isActionVisible,
        programPaymentTypeID,
        isPartnerManager
    })
);