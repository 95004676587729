const PREFIX = 'WELLNESS_PROGRAM_PAYMENTS_';

export const GET_PAYMENTS = `${PREFIX}GET`;
export const SET_PAYMENTS = `${PREFIX}SET`;
export const SET_PROGRAM_PAYMENT_TYPE = `${PREFIX}SET_PROGRAM_PAYMENT_TYPE`;
export const SET_PERIOD = `${PREFIX}SET_PERIOD`;
export const SET_PARTNER = `${PREFIX}SET_PARTNER`;
export const SET_PAYMENT_TYPE = `${PREFIX}SET_PAYMENT_TYPE`;
export const DOWNLOAD_PAYMENTS = `${PREFIX}DOWNLOAD`;
export const SET_SHOW_CORRECTED_ONLY = `${PREFIX}SET_SHOW_CORRECTED_ONLY`;
export const SET_AMOUNT_TO_BE_SENT = `${PREFIX}SET_AMOUNT_TO_BE_SENT`;
export const UNDO_AMOUNT_CORRECTION = `${PREFIX}UNDO_AMOUNT_CORRECTION`;
export const GET_PARTNERS = `${PREFIX}_GET_PARTNERS`;
export const SET_PARTNERS = `${PREFIX}_SET_PARTNERS`;
export const GET_PAYMENT_TYPES = `${PREFIX}_GET_PAYMENT_TYPES`;
export const SET_PAYMENT_TYPES = `${PREFIX}_SET_PAYMENT_TYPES`;