import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { PopupBody } from '../PopupBody';

function RenderConfirmBody(onClose, body, onConfirm, store) {
    return React.createElement(PopupBody, {
        body,
        onClose,
        onOk: onConfirm,
    });
}

function WrappedConfirmPopup({ body, onConfirm }) {
    confirmAlert({
        customUI: ({ onClose }) => RenderConfirmBody(onClose, body, onConfirm)
    });
}

export default WrappedConfirmPopup;
