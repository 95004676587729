import * as types from './facilityTypes';
import initialState from './facilityInitialState';
import { fromJS } from 'immutable';

export default function facilityReducer(state = initialState, action) {
    const extendFacilityObject = (facility) => {
        const fullAddress = `${facility.address} ${facility.city}, ${facility.state.name} ${facility.zipCode}`;
        return Object.assign(facility,
            {
                fullAddress,
                description: `${facility.displayName} - ${fullAddress}`
            }
        );
    };
    switch (action.type) {
        case types.SET_FACILITIES:
            return state.set('facilities', fromJS(
                action.facilities.map(facility => extendFacilityObject(facility)))
            );
        case types.SET_FACILITY:
            return state.set('facility', fromJS(extendFacilityObject(action.facility)));
        default:
            return state;
    }
}