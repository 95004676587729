function enclose(number, lowerBound, upperBound) {
    number = Number(number);
    lowerBound = Number(lowerBound);
    upperBound = Number(upperBound);
    lowerBound = isNaN(lowerBound) ? 0 : lowerBound;
    upperBound = isNaN(upperBound) ? 0 : upperBound;
    if (!isNaN(number)) {
        if (number <= upperBound && number >= lowerBound) {
            return number;
        } else if (number > upperBound) {
            return upperBound;
        } else {
            return lowerBound;
        }
    }
    return number;
}

export default enclose;