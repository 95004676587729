const earthRadiusInMiles = 3958.8;

// using Haversine formula for distance https://en.wikipedia.org/wiki/Haversine_formula
export function getDistanceFromLatLngInMiles(lat1, lng1, lat2, lng2) {
    const dLat = deg2rad(lat2 - lat1);
    const dLng = deg2rad(lng2 - lng1);
    const sinHalfDLat = Math.sin(dLat / 2);
    const sinHalfDLng = Math.sin(dLng / 2);
    const intermediateResult =
        sinHalfDLat * sinHalfDLat +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        sinHalfDLng * sinHalfDLng;
    const res = 2 * earthRadiusInMiles * Math.asin(Math.sqrt(intermediateResult));
    return res;
}

export function deg2rad(deg) {
    return deg * (Math.PI / 180);
}
