import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LegalContract from '../LegalContract/LegalContract';
import selectors from './staffLegalContractPageSelectors';
import { connect } from 'react-redux';
import { getMemberFacility } from '../../../Facilities/facilityActions';
import Spinner from '../../../../components/Spinner/Spinner';

function StaffLegalContractPage({
    isLoading,
    match,
    getMemberFacility,
    facilityDescription,
    facilityAFNumber }) {
    useEffect(() => {
        getMemberFacility(match.params.memberIdGuid);
    }, [match.params.memberIdGuid]);
    return isLoading
        ? <Spinner />
        : <div>
            <LegalContract memberIdGuid={match.params.memberIdGuid} facilityDescription={facilityDescription} facilityAFNumber={facilityAFNumber}
                readOnly
            />
        </div>;
}

StaffLegalContractPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    getMemberFacility: PropTypes.func.isRequired,
    facilityDescription: PropTypes.string,
    facilityAFNumber: PropTypes.string
};

const mapDispatchToProps = {
    getMemberFacility
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(StaffLegalContractPage);