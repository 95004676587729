import { fromJS } from 'immutable';

export default fromJS({
    paymentDeposit: {
        programPaymentType: null,
        programType: null,
        partner: undefined,
        period: null
    },
    partners: [],
    popupData: {
        isAchUploadAvailable: false,
        achUploadErrorMessage: null
    },
});