import {
    RENDER_FACILITIES
} from './types';
import { fromJS } from 'immutable';
import { initialState } from './initials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case RENDER_FACILITIES:
            return state.set('facilities', fromJS(payload.facilities));
        default:
            return state;
    }
}