import { createSelector } from 'reselect';
import { FORGOT_PASSWORD, RESEND } from './forgotPasswordTypes';

export const getIsForgotPasswordPopupOpen = (state) => {
    return state.forgotPasswordReducer.get('isForgotPasswordPopupOpen');
};

export const getIsForgotPasswordResultPopupOpen = (state) => {
    return state.forgotPasswordReducer.get('isForgotPasswordResultPopupOpen');
};

export const getUsername = (state) => {
    return state.forgotPasswordReducer.get('username');
};

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === FORGOT_PASSWORD || i === RESEND)
        .size;
    return size > 0;
};

export default createSelector(
    [
        getIsForgotPasswordPopupOpen,
        getIsForgotPasswordResultPopupOpen,
        getUsername,
        getIsLoading,
    ],
    (isForgotPasswordPopupOpen, isForgotPasswordResultPopupOpen, username, isLoading) => ({
        isForgotPasswordPopupOpen,
        isForgotPasswordResultPopupOpen,
        username,
        isLoading,
    }));
