import React, { Component } from 'react';
import PropTypes from 'prop-types';
import routes from '../routing/routes';

function withMemberGroup({ actionName }) {
    return function (WrappedComponent) {
        class WithMemberGroup extends Component {
            componentDidMount() {
                if (!this.props.shouldActionWait) {
                    this.performAction(this.props.selectedMemberGroup);
                }
            }

            componentDidUpdate(prevProps) {
                if ((!this.props.shouldActionWait
                    && this.props.shouldActionWait !== prevProps.shouldActionWait)
                    || this.props.selectedMemberGroup !== prevProps.selectedMemberGroup) {
                    this.performAction(this.props.selectedMemberGroup);
                }
            }

            performAction(selectedMemberGroup) {
                const { history } = this.props;
                if (selectedMemberGroup.membersIds) {
                    if (!this.props[actionName]) {
                        throw new ReferenceError(
                            `There is no action "${actionName}" on instance of ${WrappedComponent.name
                            }`
                        );
                    } else if (typeof this.props[actionName] !== 'function') {
                        throw new TypeError(
                            `"${actionName}" is not a function. Make sure ${WrappedComponent.name
                            } gets ${actionName} as a function`
                        );
                    }

                    this.props[actionName](selectedMemberGroup.membersIds);
                } else {
                    history.push(routes.memberDashboard.path);
                }
            }

            render() {
                return <WrappedComponent {...this.props} />;
            }
        }

        return WithMemberGroup;
    };
}

withMemberGroup.propTypes = {
    actionName: PropTypes.string.isRequired,
};

export default withMemberGroup;
