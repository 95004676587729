import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

function Captcha({ changeCaptchaHandler, captchaRef }) {
    return (
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_PUBLIC_KEY}
            size="normal"
            onChange={changeCaptchaHandler}
            ref={captchaRef}
        />
    );
}

Captcha.propTypes = {
    changeCaptchaHandler: PropTypes.func.isRequired,
    captchaRef: PropTypes.object.isRequired
};

export default Captcha;