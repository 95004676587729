import http from '../../../../http/base';
import { API_PATHS } from '../../../../http/configs';

export function getUserDescriptionList() {
    return http.get(API_PATHS.listUserDescription);
}

export function sendContactUsNotification(emailModel, captchaToken) {
    return http.post(API_PATHS.contactUs, emailModel, { headers: { 'g-recaptcha-response': captchaToken } });
}
