export const launchDarklySettings = {
    clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID,
    context: {
        kind: 'hc-admin',
        key: 'hc-admin'
    },
    reactOptions: {
        // this is to convert flag keys to camelCase (without special chars) and use them as variable names
        useCamelCaseFlagKeys: true
    },
    flags: { // flags are the feature toggles to consider
        'gmr-enable-features': false,
        'gmr-add-program': false,
        'gm-member-import': false,
        'use-captcha': false,
        'gm-access-pass-feature': false,
    }
};
export default launchDarklySettings;