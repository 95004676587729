import React from 'react';
import PropTypes from 'prop-types';
import './tooltip.scss';
import Tooltip from '@material-ui/core/Tooltip';

function ToolTip({ title, arrow, placement, children, isOpen, id }) {
    return (
        <Tooltip
            title={title}
            arrow={arrow}
            placement={placement}
            open={isOpen}
            id={id}
        >
            {children}
        </Tooltip>
    );
};

ToolTip.propTypes = {
    title: PropTypes.string.isRequired,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    id: PropTypes.string
};

export default ToolTip;