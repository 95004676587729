import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, generatePath } from 'react-router-dom';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

const PartnerManagerProfilePage = ({ match, history, currentRoute }) => {
    const { routes } = currentRoute;

    const routesForNavigation = routes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });

    return (
        <div>
            <h2 className="layout__title">User Profile</h2>
            <NavigationTabControl
                tabs={routesForNavigation}
                onNavigate={history.push}
            />
            <Switch>
                {routes.map((route, i) => (
                    <CustomRoute
                        key={i}
                        path={route.path}
                        currentRoute={route}
                        filteredRoutes={routesForNavigation}
                    />
                ))}

                <Redirect
                    from={currentRoute.path}
                    to={routesForNavigation[0].path}
                />
            </Switch>
        </div>
    );
};

PartnerManagerProfilePage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    currentRoute: routeType.isRequired
};

export default PartnerManagerProfilePage;
