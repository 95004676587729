import * as types from './forgotPasswordTypes';
import initialState from './forgotPasswordState';

export default function forgotPasswordReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_IS_FORGOT_PASSWORD_POPUP_OPEN:
            return state.set('isForgotPasswordPopupOpen', action.isForgotPasswordPopupOpen);
        case types.SET_IS_FORGOT_PASSWORD_RESULT_POPUP_OPEN:
            return state.set('isForgotPasswordResultPopupOpen', action.isForgotPasswordResultPopupOpen);
        case types.SET_USERNAME:
            return state.set('username', action.username);
        default:
            return state;
    }
}
