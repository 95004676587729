const PREFIX = 'PARTNER_BILLING_INFO_';

export const GET_BILLING_INFO = `${PREFIX}GET_BILLING_INFO`;
export const SET_BILLING_INFO = `${PREFIX}SET_BILLING_INFO`;
export const GET_PAYMENT_TYPES_LIST = `${PREFIX}GET_PAYMENT_TYPES_LIST`;
export const SET_PAYMENT_TYPES_LIST = `${PREFIX}SET_PAYMENT_TYPES_LIST`;
export const UPDATE_BILLING_INFO = `${PREFIX}UPDATE_BILLING_INFO`;
export const GET_BANK_ACCOUNT_DETAILS = `${PREFIX}GET_BANK_ACCOUNT_DETAILS`;
export const SET_BANK_ACCOUNT_DETAILS = `${PREFIX}SET_BANK_ACCOUNT_DETAILS`;

