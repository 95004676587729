import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import { defaultFormProperties } from '../../../utilities/form';
import partnerPaymentsFeesConfirmationFormResources from './partnerPaymentsFeesConfirmationFormResources';
import validate, * as validationRules from './partnerPaymentsFeesConfirmationFormValidation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import './_fees-confirmation.scss';

const PartnerPaymentsFeesConfirmationForm = ({
    handleSubmit,
    error,
    submitFailed
}) => {
    const oneTimeFeeAmountChanged = function (e, value) {
        setIsOneTimeFeeAmountGreaterThanZero(parseFloat(value) > 0);
    };
    const [isOneTimeFeeAmountGreaterThanZero, setIsOneTimeFeeAmountGreaterThanZero] = useState(false);
    const { gmrAddProgram } = useFlags();
    return <FormGroup onSubmit={handleSubmit}>
        <div className="fees-confirmation">
            { !gmrAddProgram && <div className="grid__row">
                <div className="col-md-5">
                    <label>{partnerPaymentsFeesConfirmationFormResources.labelForStartupFeeAmount}</label>
                </div>
                <div className="col-md-7">
                    <Field
                        name="startupFeeAmount"
                        component={MuiInputText}
                        highlightInputValidationError={submitFailed && error}
                        validate={[
                            validationRules.startupFeeAmountNumeric,
                            validationRules.startupFeeAmountRange,
                            validationRules.startupFeeAmountDecimalWithTwoPlaces
                        ]}
                    />
                </div>
            </div>}
            <div className="grid__row">
                <div className="col-md-5">
                    <label>{partnerPaymentsFeesConfirmationFormResources.labelForRenewalFeeAmount}</label>
                </div>
                <div className="col-md-7">
                    <Field
                        name="renewalFeeAmount"
                        component={MuiInputText}
                        highlightInputValidationError={submitFailed && error}
                        validate={[
                            validationRules.renewalFeeAmountNumeric,
                            validationRules.renewalFeeAmountRange,
                            validationRules.renewalFeeAmountDecimalWithTwoPlaces
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-5">
                    <label>{partnerPaymentsFeesConfirmationFormResources.labelForFacilityAccessFeeAmount}</label>
                </div>
                <div className="col-md-7">
                    <Field
                        name="facilityAccessFeeAmount"
                        component={MuiInputText}
                        highlightInputValidationError={submitFailed && error}
                        validate={[
                            validationRules.facilityAccessFeeAmountNumeric,
                            validationRules.facilityAccessFeeAmountRange,
                            validationRules.facilityAccessFeeAmountDecimalWithTwoPlaces,
                        ]}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-5">
                    <label>{partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeAmount}</label>
                </div>
                <div className="col-md-7">
                    <Field
                        name="oneTimeFeeAmount"
                        component={MuiInputText}
                        highlightInputValidationError={submitFailed && error}
                        validate={[
                            validationRules.oneTimeFeeAmountNumeric,
                            validationRules.oneTimeFeeAmountRange,
                            validationRules.oneTimeFeeAmountDecimalWithTwoPlaces,
                        ]}
                        onChange={oneTimeFeeAmountChanged}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-md-5">
                    <label>{partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeReason}</label>
                </div>
                <div className="col-md-7">
                    <Field
                        name="oneTimeFeeReason"
                        component={MuiInputText}
                        validate={[
                            validationRules.oneTimeFeeReasonRequiredIfGreaterThan,
                            validationRules.oneTimeFeeReasonMaxLength
                        ]}
                        disabled={!isOneTimeFeeAmountGreaterThanZero}
                    />
                </div>
            </div>
            {error && submitFailed && <div className="validation-summary">{error}</div>}
        </div>
    </FormGroup>;
};

PartnerPaymentsFeesConfirmationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string,
    submitFailed: PropTypes.bool
};

export default reduxForm({
    form: 'partnerPaymentsFeesConfirmationForm',
    validate,
    ...defaultFormProperties
})(PartnerPaymentsFeesConfirmationForm);
