import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function getPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return http.get(API_PATHS.getPartnerPayments, { params: { programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly } });
};

export function downloadPayments(programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return http.get(API_PATHS.downloadPartnerPayments, { responseType: 'blob', params: { programPaymentTypeId, paymentTypeId, periodId, partnerId, showCorrectedOnly } });
};

export function updatePartnerPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent) {
    return http.put(API_PATHS.updatePartnerPaymentAmountToBeSent, null, { params: { programTypeId, programPaymentTypeId, paymentId, amountToBeSent } });
};

export function undoPartnerPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId) {
    return http.put(API_PATHS.undoPartnerPaymentAmountCorrection, null, { params: { programTypeId, programPaymentTypeId, paymentId } });
};