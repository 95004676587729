import validationRules from '../../../utilities/validationRules';
import BankingInfoResources from './bankingInfoResources';

export const routingNumberRequired = validationRules.required(
    BankingInfoResources.routingNumberLabel
);
export const routingNumberNumeric = validationRules.numericOrEmpty(
    BankingInfoResources.routingNumberLabel
);
export const routingNumberLength = validationRules.length(
    BankingInfoResources.routingNumberLabel,
    9,
    9
);
export const routingNumberSpecialCombination = (
    value,
    allValues,
    formProps
) => {
    const errorMessage = 'The Routing Number field is invalid.';

    if (value.length !== 9) return errorMessage;

    const numbers = value.split('').map(Number);

    const firstSum = 3 * (numbers[0] + numbers[3] + numbers[6]);
    const secondSum = 7 * (numbers[1] + numbers[4] + numbers[7]);
    const thirdSum = numbers[2] + numbers[5] + numbers[8];

    return (firstSum + secondSum + thirdSum) % 10 === 0
        ? undefined
        : errorMessage;
};

export const accountNumberRequired = validationRules.required(
    BankingInfoResources.accountNumberLabel
);
export const accountNumberNumericWithWildcard = validationRules.numericOrEmptyWithWildcard(
    BankingInfoResources.accountNumberLabel,
    'x'
);
export const accountNumberNumeric = validationRules.numericOrEmpty(
    BankingInfoResources.accountNumberLabel
);
export const accountNumberLength = validationRules.length(
    BankingInfoResources.accountNumberLabel,
    4,
    17
);

export const accountTypeRequired = validationRules.required('Account Type');
