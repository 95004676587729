import React, { useMemo, useState, useEffect } from 'react';
import { getFacilitiesForDropDown } from './facilitySearchBoxAction';
import { connect } from 'react-redux';
import { MuiAutocomplete } from '../../../components/controls/MuiAutocomplete';
import selectors from './facilitySearchBoxSelectors';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import facilitySearchBoxResources from './facilitySearchBoxResources';
import './_facility-search-box.scss';

function FacilitySearchBox({ facilities, getFacilitiesForDropDown, onChange, placeholder, isLoading, facility, inputLabel, className  }) {
    const [value, setValue] = useState(null);
    useEffect(() => {
        if (facility && facility.size > 0) {
            setValue(facility.toJS());
        }
    }, [facility]);
    const inputChangeHandler = (event, newInputValue) => {
        getFacilitiesForDropDown(newInputValue);
    };
    const debouncedChangeHandler = useMemo(
        () => debounce(inputChangeHandler, 400)
        , [inputChangeHandler]);
    const changeHandler = (event, value) => {
        setValue(value);
        onChange(value);
    };
    return <div className={`facility-search-box ${className}`}>
        <MuiAutocomplete
            id="facilitySearchBox"
            options={facilities}
            onInputChange={debouncedChangeHandler}
            getOptionLabel={option => `${option.facilityIDNumber} ${option.name}`}
            noOptionsText={facilitySearchBoxResources.noOptionsText}
            onChange={changeHandler}
            placeholder={placeholder}
            value={value}
            loading={isLoading}
            inputLabel={inputLabel}
        />
    </div>;
}

FacilitySearchBox.propTypes = {
    facilities: PropTypes.array.isRequired,
    getFacilitiesForDropDown: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    changeHandler: PropTypes.func,
    placeholder: PropTypes.string,
    currentFacilityIDNumber: PropTypes.number,
    onChange: PropTypes.func,
    isLoading: PropTypes.bool,
    getFacility: PropTypes.func,
    facility: PropTypes.object,
    inputLabel: PropTypes.string,
    className: PropTypes.string
};

const mapDispatchToProps = {
    getFacilitiesForDropDown
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(FacilitySearchBox);