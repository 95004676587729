import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import selectors from './memberAreaSelectors';
import actions from './memberAreaActions';
import { routeType } from '../../routing/routes';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import CustomRoute from '../../layouts/CustomRoute';
import Spinner from '../../components/Spinner/Spinner';
import withMemberGroup from '../../hoc/withMemberGroup';

function MemberArea({ isLoading, currentRoute, filteredRoutes, shouldRedirect }) {
    return isLoading
        ? <Spinner />
        : (
            <div>
                <Switch>
                    {filteredRoutes.map((route, i) => (
                        <CustomRoute
                            key={i}
                            path={route.path}
                            currentRoute={route}
                            filteredRoutes={filteredRoutes}
                        />
                    ))}

                    <ConditionalRedirect
                        condition={shouldRedirect}
                        fromRoute={currentRoute}
                        toRoute={filteredRoutes[0]}
                    />
                </Switch>
            </div>);
};

MemberArea.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    currentRoute: routeType.isRequired,
    shouldRedirect: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    hasActiveFacilityEnrollments: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    ...actions
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'hasActiveFacilityEnrollments'
})(MemberArea);

export default connect(selectors, mapDispatchToProps)(withMemberGroupObserved);
