import { fromJS } from 'immutable';
import * as types from './recipientsListTypes';
import initialState from './recipientsListInitialState';

export default function reducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case types.SET_VOUCHERS:
            if (!action.vouchers) {
                action.vouchers = [];
            }
            for (let i = 0; i < action.vouchers.length; i++) {
                const voucher = action.vouchers[i];
                for (const key in voucher.recipientMetaDataDictionary) {
                    voucher[key] = voucher.recipientMetaDataDictionary[key];
                }
            }
            return state.set(
                'vouchers',
                fromJS(action.vouchers)
            );
        case types.SET_RECIPIENTS_FILE_CONTENT_VALIDATION_ERRORS:
            return state.set(
                'recipientsFileContentValidationErrors',
                fromJS(action.recipientsFileContentValidationErrors)
            );
        case types.SET_VOUCHER_EMAIL_PREVIEW:
            return state.set(
                'voucherEmailPeview',
                fromJS(action.voucherEmailPeview)
            );
        case types.SET_IS_PUBLISH_POPUP_VISIBLE:
            return state.set(
                'isPublishPopupVisible',
                fromJS(action.isPublishPopupVisible)
            );
        case types.SET_IS_VOUCHER_PROGRAM_PUBLISHED:
            return state.set(
                'isVoucherProgramPublished',
                fromJS(action.isVoucherProgramPublished)
            );
        case types.SET_IS_PREVIEW_POPUP_VISIBLE:
            return state.set(
                'isPreviewPopupVisible',
                fromJS(action.isPreviewPopupVisible)
            );
        default:
            return state;
    }
}
