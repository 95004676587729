import validationRules from '../../../utilities/validationRules';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';

export const programTypeRequired = validationRules.required(
    wellnessProgramPaymentResources.labelProgramType
);

export const visitMonthRequired = validationRules.required(
    wellnessProgramPaymentResources.labelVisitMonth
);

export const partnerRequired = validationRules.required(
    wellnessProgramPaymentResources.labelPartner
);

export const periodRequired = validationRules.required(
    wellnessProgramPaymentResources.labelForUsagePeriod
);

export const forPartnerRequired = validationRules.required(
    wellnessProgramPaymentResources.labelForPartner
);

export const paymentTypeRequired = validationRules.required(
    wellnessProgramPaymentResources.labelPaymentType
);