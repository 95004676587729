import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    savePartner
} from '../../Shared/Partners/managePartnerActions';
import ManagePartnerForm from '../../Shared/Partners/ManagePartnerForm';
import Spinner from '../../../components/Spinner/Spinner';
import { getStatesList } from '../../States/stateActions';
import selectors from './generalInfoSelectors';
import CardInfo from '../../../components/CardInfo/CardInfo';
import { getProgramTypes } from '../../ProgramTypes/programTypeActions';

function GeneralInfoTab({
    savePartner,
    isLoading,
    getStates,
    states,
    partner,
    getProgramTypes,
    programTypes,
    history
}) {
    useEffect(() => {
        getStates();
        getProgramTypes();
    }, []);

    function handleSave(partner) {
        savePartner(partner.toJS());
    }
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <CardInfo>
                <div className="col-xs">
                    <ManagePartnerForm
                        onSubmit={handleSave}
                        states={states}
                        initialValues={partner}
                        programTypes={programTypes}
                        history={history}
                    />
                </div>
            </CardInfo>
        );
}

GeneralInfoTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    savePartner: PropTypes.func.isRequired,
    states: PropTypes.object.isRequired,
    getStates: PropTypes.func.isRequired,
    partner: PropTypes.object.isRequired,
    getProgramTypes: PropTypes.func.isRequired,
    programTypes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
    savePartner,
    getStates: getStatesList,
    getProgramTypes
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(GeneralInfoTab);
