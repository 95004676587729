import * as types from './memberListTypes';
import initialState from './membersListInitialState';
import { fromJS } from 'immutable';

export default function memberReducer(state = initialState, action) {
    switch (action.type) {
        case types.JOIN_MEMBERS_LIST:
            return state.set('members', state.get('members').concat(fromJS(action.members)));
        case types.SET_MEMBERS_LIST:
            return state.set('members', fromJS(action.members));
        case types.SET_MEMBERS_TOTAL_COUNT:
            return state.set('membersTotalCount', fromJS(action.totalCount));
        case types.SET_SELECTED_MEMBERS: {
            return state.set('selectedMembers', fromJS(action.selectedMembers));
        }
        case types.SET_MEMBER_TO_DEACTIVATE:
            return state.set('memberToDeactivate', action.member);
        default:
            return state;
    }
}