import React, { useEffect } from 'react';
import './_data-privacy-request.scss';
import pageTitleFormatter from '../../../../utilities/formatters/pageTitleFormatter';

export default function DataPrivacyRequest() {
    useEffect(() => {
        document.title = pageTitleFormatter.formatPageTitle('Data Privacy Request');
    }, []);
    return <div className="data-privacy-request">
        <div className="data-privacy-request__title">Data Privacy Request</div>
        <div className="data-privacy-request__frame">
            <iframe title="data privacy request" src="https://seb-privacy.my.onetrust.com/webform/2c8f1e47-9f01-4278-a1e1-5bedeb3cf227/768ced49-0f06-456d-8230-6fb0e65dbaa4" />
        </div>
    </div>;
}