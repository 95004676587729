import * as types from './clubLocatorSectionTypes';
import { initialState } from './clubLocatorSectionInitials';
import { fromJS } from 'immutable';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FACILITIES_FOR_MAP:
            return state.set(
                'facilities',
                fromJS(action.facilities)
            );
        case types.SET_PLANS_FOR_MAP:
            return state.set(
                'plans',
                fromJS(action.plans)
            );
        default:
            return state;
    }
}
