import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { API_PATHS } from '../../http/configs';
import routes from '../../routing/routes';
import selectors from './headerSelectors';
import { connect } from 'react-redux';
import { authUserType } from '../../propTypes/customTypes';
import './header.scss';

function HeaderNavList({ isMobile, me, portalDashboardLink }) {
    return (
        <ul className="header__nav-list">
            {me && me.role && <li className="header__nav-item">
                {portalDashboardLink
                    ?
                        <NavLink
                            className="new-link"
                            to={portalDashboardLink}
                            activeClassName="header__nav-item--active"
                        >
                            dashboard
                        </NavLink>
                    :
                        <a href={API_PATHS.HCDashboard}
                            className="new-link"
                        >
                            dashboard
                        </a>
                }
            </li>
            }
            {isMobile && <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.home.path}
                    activeClassName="header__nav-item--active"
                >
                    home page
                </NavLink>
            </li>}
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forFacilities.path}
                    activeClassName="header__nav-item--active"
                >
                    for facilities
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forEmployers.path}
                    activeClassName="header__nav-item--active"
                >
                    for employers
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forInsurers.path}
                    activeClassName="header__nav-item--active"
                >
                    for insurers
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.forMembers.path}
                    activeClassName="header__nav-item--active"
                >
                    for members
                </NavLink>
            </li>
            <li className="header__nav-item">
                <NavLink
                    className="new-link"
                    to={routes.aboutUs.path}
                    activeClassName="header__nav-item--active"
                >
                    about us
                </NavLink>
            </li>
        </ul>
    );
}

HeaderNavList.propTypes = {
    me: authUserType,
    isMobile: PropTypes.bool.isRequired,
    portalDashboardLink: PropTypes.string
};
export default connect(selectors, null)(HeaderNavList);
