import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import MuiInputSelect from '../../../components/controls/MuiInputSelect';
import partnerBillingInfoResources from './partnerBillingInfoResources';
import Button from '../../../components/controls/Button';
import sharedResources from '../../Shared/resources';
import { defaultFormProperties } from '../../../utilities/form';
import selectors from './partnerBillingInfoSelectors';
import * as partnerBillingValidationRules from './partnerBillingInfoValidations';
import { getBankAccountDetails } from './partnerBillingInfoActions';
import MuiInfoBox from '../../../components/InfoBox/MuiInfoBox';
import BankAccountInfoRetriver from '../../Shared/BankingInfo/BankAccountInfoRetriver';
import BankAccountDetails from '../../Shared/BankingInfo/BankAccountDetails';
import BankAccountSecurityNote from '../../Shared/BankingInfo/BankAccountSecurityNote';

function PartnerBillingInfoForm({ pristine, submitting, handleSubmit, paymentTypesList, isWithdrawalInfoVisible, payeeToken, getBankAccountDetails, accountNumber, routingNumber }) {
    const retrievePartnerBankAccountDetails = () => {
        getBankAccountDetails(payeeToken);
    };
    return <FormGroup onSubmit={handleSubmit}>
        <div className="grid__row align-items-center">
            <div className="col-md-6">
                <Field
                    id="payment_type"
                    labelId="payment_type_label"
                    name={partnerBillingInfoResources.fieldPartnerBillingTypeID}
                    component={MuiInputSelect}
                    label={partnerBillingInfoResources.labelBillingType}
                    options={paymentTypesList}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    validate={[
                        partnerBillingValidationRules.partnerBillingTypeRequired
                    ]}
                />
            </div>
            <div className="col-md-2">
                <Button
                    type="submit"
                    disabled={pristine || submitting}
                    theme="new btn_new--blue"
                    className="no-margin"
                >
                    {sharedResources.saveButtonText}
                </Button>
            </div>
        </div>
        {
            isWithdrawalInfoVisible ?
                <MuiInfoBox
                    title={partnerBillingInfoResources.titleWithdrawalFromBankingInfo}
                    className="height-auto"
                    bordered
                >
                    <BankAccountSecurityNote/>
                    <BankAccountInfoRetriver
                        payeeToken={payeeToken}
                        accountNumber={accountNumber}
                        routingNumber={routingNumber}
                        retrievePartnerBankAccountDetails={retrievePartnerBankAccountDetails}
                    />
                    <BankAccountDetails bankAccountModelName={partnerBillingInfoResources.bankAccountModelName} />
                </MuiInfoBox>
                : undefined
        }
    </FormGroup>;
}

PartnerBillingInfoForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    paymentTypesList: PropTypes.object,
    isWithdrawalInfoVisible: PropTypes.bool.isRequired,
    payeeToken: PropTypes.string,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    getBankAccountDetails: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getBankAccountDetails
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    form: partnerBillingInfoResources.formPartnerBillingInfo,
    ...defaultFormProperties
})(connectStateToProps(PartnerBillingInfoForm));
