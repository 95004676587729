const keyfobStatuses = Object.freeze({
    availableForActivation: 0,
    activated: 1,
    onHold: 2,
    banned: 3,
});

export const keyfobStatusesColorsMap = Object.freeze({
    [keyfobStatuses.availableForActivation]: 'yellow',
    [keyfobStatuses.activated]: 'green',
    [keyfobStatuses.onHold]: 'blue',
});

export default keyfobStatuses;