import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import InfoBox from '../../../components/InfoBox/InfoBox';
import Table from '../../../components/InfoBox/Table';
import InputSelect from '../../../components/controls/InputSelect';
import InputCheckbox from '../../../components/controls/InputCheckbox';
import ExcelImage from '../../../components/Image/ExcelImage/ExcelImage';
import facilityResources from '../../Shared/Facilities/facilityResources';
import partnerResources from '../../Shared/Partners/partnerResources';
import groupMembershipResources from '../../Shared/Programs/groupMembershipResources';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './facilityPaymentsSectionActions';
import selectors from './facilityPaymentsSectionSelectors';
import { getPartnersListByProgramType } from '../../Partners/PartnersList/partnersListActions';
import UndoAmountCorrectionActionCell from '../UndoAmountCorrectionActionCell';
import facilityPaymentsSectionResources from './facilityPaymentsSectionResources';
import EditAmountToBeSentEditableCell from '../EditAmountToBeSentEditableCell';
import { useFlags } from 'launchdarkly-react-client-sdk';

function FacilityPaymentsSection({
    isLoading,
    programTypes,
    programPaymentTypes,
    partners,
    partnersByProgramPaymentType,
    periods,
    payments,
    getPayments,
    paymentsFilter,
    setProgramPaymentType,
    setPeriod,
    setPartner,
    setProgramType,
    setShowCorrectedOnly,
    defaultPeriod,
    defaultProgramType,
    defaultProgramPaymentType,
    downloadPayments,
    programTypeId,
    programPaymentTypeId,
    periodId,
    partnerId,
    showCorrectedOnly,
    getPartnersListByProgramType,
    getPartnersListByProgramPaymentType,
    updateFacilityPaymentAmountToBeSent,
    undoFacilityPaymentAmountCorrection
}) {
    useEffect(() => {
        setProgramType(defaultProgramType);
    }, []);
    useEffect(() => {
        setPeriod(defaultPeriod);
    }, []);
    useEffect(() => {
        if (programTypeId) {
            getPartnersListByProgramType(programTypeId);
        }
    }, [programTypeId]);
    useEffect(() => {
        if (programPaymentTypeId) {
            getPartnersListByProgramPaymentType(programPaymentTypeId);
        }
    }, [programPaymentTypeId]);
    useEffect(() => {
        if (programPaymentTypeId && periodId) {
            getPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly);
        }
    }, [programPaymentTypeId, partnerId, periodId, showCorrectedOnly]);
    const exportToExcel = () => {
        if (programPaymentTypeId && periodId) {
            downloadPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly);
        }
    };
    const updateAmountToBeSentHandler = (rowIndex, columnId, value, rowData) => {
        updateFacilityPaymentAmountToBeSent(programTypeId, programPaymentTypeId, rowData[wellnessProgramPaymentResources.accessorId], value);
    };
    const undoHandler = (paymentId) => {
        undoFacilityPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId)
            .then(() => {
                getPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly);
            });
    };
    const onChangeShowCorrectedOnly = (event) => {
        setShowCorrectedOnly(event.target.checked);
    };
    const { gmrAddProgram } = useFlags();
    const columns = useMemo(
        () => [
            {
                Header: partnerResources.labelPartnerIdNumber,
                accessor: facilityPaymentsSectionResources.accessorPartnerIdNumber
            },
            {
                Header: partnerResources.labelName,
                accessor: 'partnerName'
            },
            {
                Header: facilityResources.labelFacilityIdNumber,
                accessor: facilityPaymentsSectionResources.accessorFacilityIdNumber
            },
            {
                Header: facilityResources.labelName,
                accessor: 'facilityName'
            },
            {
                Header: gmrAddProgram ? groupMembershipResources.labelVisitMonth : groupMembershipResources.labelUsagePeriod,
                accessor: facilityPaymentsSectionResources.accessorUsagePeriod
            },
            {
                Header: groupMembershipResources.labelNumberOfMembershipsPaid,
                accessor: 'numberOfMembersWhoWillBePaid'
            },
            {
                Header: groupMembershipResources.labelDuesPaidForOneMember,
                accessor: 'duesPaidForOneMember'
            },
            {
                Header: groupMembershipResources.labelTotalPayment,
                accessor: facilityPaymentsSectionResources.accessorTotalFacilityPayments
            },
            {
                Header: groupMembershipResources.labelFacilityAmountToBeSent,
                accessor: wellnessProgramPaymentResources.accessorAmountToBeSent,
                Cell: EditAmountToBeSentEditableCell
            },
            ...gmrAddProgram ?
                [{
                    Header: groupMembershipResources.labelBillingInfo,
                    accessor: facilityPaymentsSectionResources.billingInfo,
                }]
                : [{
                    Header: groupMembershipResources.labelPayeeToken,
                    accessor: facilityPaymentsSectionResources.accessorPayeeToken,
                    minWidth: 340
                }],
            {
                Header: groupMembershipResources.labelStatus,
                accessor: facilityPaymentsSectionResources.accessorStatus
            },
            {
                Header: groupMembershipResources.labelStatusDate,
                accessor: facilityPaymentsSectionResources.accessorStatusDate
            },
            {
                Header: 'Actions',
                disableSortBy: true,
                getProps: () => ({
                    handleUndo: undoHandler
                }),
                Cell: UndoAmountCorrectionActionCell,
                accessor: row => row,
                minWidth: 200
            }
        ],
        [partnerResources, facilityResources, groupMembershipResources, undoHandler, gmrAddProgram]
    );
    const defaultSorted = useMemo(() => [{ id: 'partnerIDNumber' }]);
    return <InfoBox title={wellnessProgramPaymentResources.titleFacilityPayments} bordered>
        {isLoading ?
            <div>
                <Spinner />
            </div>
            : <></>
        }
        <div className="grid__row">
            <div className="col-lg-3">
                {
                    gmrAddProgram ?
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelProgramType}
                            value={paymentsFilter.get('programPaymentType')}
                            options={programPaymentTypes}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            onChange={setProgramPaymentType}
                        />
                        :
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelForProgramType}
                            value={paymentsFilter.get('programType')}
                            options={programTypes}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            isDisabled
                            onChange={() => { }}
                        />
                }
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg-3">
                {
                    gmrAddProgram ?
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelPartner}
                            value={paymentsFilter.get('partner')}
                            options={partnersByProgramPaymentType}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            onChange={setPartner}
                        />
                        :
                        <InputSelect
                            label={wellnessProgramPaymentResources.labelForPartner}
                            value={paymentsFilter.get('partner')}
                            options={partners}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('id')}
                            onChange={setPartner}
                        />
                }
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg-3">
                <InputSelect
                    label={gmrAddProgram ? wellnessProgramPaymentResources.labelVisitMonth : wellnessProgramPaymentResources.labelForUsagePeriod}
                    value={paymentsFilter.get('period')}
                    options={periods}
                    getOptionLabel={option => option.get('yearMonth')}
                    getOptionValue={option => option.get('id')}
                    onChange={setPeriod}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg-3">
                <InputCheckbox
                    id="show-corrected-payments-only"
                    label={wellnessProgramPaymentResources.labelShowAllCorrectedPayments}
                    checked={paymentsFilter.get('showCorrectedOnly')}
                    onChange={onChangeShowCorrectedOnly}
                />
            </div>
            <div className="col-lg-9">
                <ExcelImage
                    onClick={exportToExcel}
                />
            </div>
        </div>
        <div className="grid__row">
            <div className="col-lg">
                <Table
                    columns={columns}
                    data={payments.toJS()}
                    defaultSorted={defaultSorted}
                    updateCellHandler={updateAmountToBeSentHandler}
                />
                <br />
            </div>
        </div>
    </InfoBox>;
}
FacilityPaymentsSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    payments: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    partnersByProgramPaymentType: PropTypes.object.isRequired,
    paymentsFilter: PropTypes.object.isRequired,
    setProgramType: PropTypes.func.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    setPeriod: PropTypes.func.isRequired,
    setPartner: PropTypes.func.isRequired,
    setShowCorrectedOnly: PropTypes.func.isRequired,
    getPayments: PropTypes.func.isRequired,
    defaultPeriod: PropTypes.object,
    defaultProgramType: PropTypes.object,
    defaultProgramPaymentType: PropTypes.object,
    downloadPayments: PropTypes.func.isRequired,
    programTypeId: PropTypes.number,
    periodId: PropTypes.number,
    partnerId: PropTypes.number,
    showCorrectedOnly: PropTypes.bool.isRequired,
    getPartnersListByProgramType: PropTypes.func.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    updateFacilityPaymentAmountToBeSent: PropTypes.func.isRequired,
    undoFacilityPaymentAmountCorrection: PropTypes.func.isRequired,
    programPaymentTypeId: PropTypes.number
};
const mapDispatchToProps = {
    getPartnersListByProgramType,
    ...actions,
};
export default connect(selectors, mapDispatchToProps)(FacilityPaymentsSection);
