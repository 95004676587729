import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import solutionsSectionResources from './solutionsSectionResources';
import CustomLink from '../controls/CustomLink';

function SolutionsSectionItem({ materialIconName, header, description, linkPath, isExternalLink }) {
    return <li className="solutions-section__item" role="group" aria-label={header}>
        <MaterialIcon className="solutions-section__item-icon" icon={materialIconName} ariaHidden />
        <h3 className={'solutions-section__item-header'}>{header}</h3>
        <div className="solutions-section__item-description">{description}</div>
        {linkPath
            ? <div className="solutions-section__link-container">
                <div className="solutions-section__link-spacer" />
                <CustomLink className="solutions-section__link" linkPath={linkPath}
                    isExternalLink={isExternalLink}
                >
                    {solutionsSectionResources.linkLearnMore}
                    <MaterialIcon className={'solutions-section__link-icon'} icon="arrow_forward" ariaHidden />
                </CustomLink>
            </div>
            :
            <></>
        }
    </li>;
}

SolutionsSectionItem.propTypes = {
    materialIconName: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    description: PropTypes.string,
    linkPath: PropTypes.string,
    isExternalLink: PropTypes.bool
};

export default SolutionsSectionItem;