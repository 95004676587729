import { CLEAR_NOTIFICATION, POST_NOTIFICATION } from './actions';
import { initialState } from './initials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case POST_NOTIFICATION: {
            const { notification } = payload;
            return state.push(notification);
        }

        case CLEAR_NOTIFICATION: {
            const { notificationId } = payload;
            const targetNotificationIndex = state
                .findIndex(notification => notification.get('id') === notificationId)
                .toString();
            return state.delete(targetNotificationIndex);
        }

        default: {
            return state;
        }
    }
};


