const facilityFeesBillingTypes = Object.freeze({
    none: 0,
    directFromFacility: 1,
    optumHealth: 2, //Removed
    invoiceFacility: 3,
    feesWaived: 4, //Removed
    humanaInvoice: 5, //Removed
    atYourBest: 6,
    renewActive: 7,
    uprLifetimeFitness: 8,
    renewActiveInvoiceAndCheck: 9,
    facilityInvoiceCheck: 10,
    aarpMedSupInvoiceCheck: 11,
    facilityInvoiceAarpMedSupCheck: 12,
    onePassCommercial: 13,
    onePassCommercialInvoiceAndCheck: 14
});

export const facilityFeesBillingTypesFullNamesMap = Object.freeze({
    [facilityFeesBillingTypes.none]: 'None',
    [facilityFeesBillingTypes.directFromFacility]: 'Direct From Bank Account',
    [facilityFeesBillingTypes.optumHealth]: 'Optum FRP invoice', //Removed
    [facilityFeesBillingTypes.invoiceFacility]: 'Invoice Facility',
    [facilityFeesBillingTypes.feesWaived]: 'Fees Waived', //Removed
    [facilityFeesBillingTypes.humanaInvoice]: 'Humana invoice', //Removed
    [facilityFeesBillingTypes.atYourBest]: 'AARP/At Your Best invoice',
    [facilityFeesBillingTypes.renewActive]: 'Renew Active/One Pass Medicare & Medicaid Invoice',
    [facilityFeesBillingTypes.uprLifetimeFitness]: 'UPR Lifetime Fitness invoice',
    [facilityFeesBillingTypes.renewActiveInvoiceAndCheck]: 'Renew Active/One Pass Medicare & Medicaid Invoice & Check',
    [facilityFeesBillingTypes.facilityInvoiceCheck]: 'Facility Invoice & Check',
    [facilityFeesBillingTypes.aarpMedSupInvoiceCheck]: 'AARP Med Sup Invoice & Check',
    [facilityFeesBillingTypes.facilityInvoiceAarpMedSupCheck]: 'Facility Invoice & AARP Med Sup Check',
    [facilityFeesBillingTypes.onePassCommercial]: 'One Pass Select (Commercial)/Aaptiv Access Invoice',
    [facilityFeesBillingTypes.onePassCommercialInvoiceAndCheck]: 'One Pass Select (Commercial)/Aaptiv Access Invoice & Check'
});

export default facilityFeesBillingTypes;
