import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { SEND_PAYMENT_DEPOSIT, DOWNLOAD_PAYMENT_DEPOSIT, CHECK_FACILITY_DEPOSIT, GET_PARTNERS } from './facilityPaymentDepositSectionTypes';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import programPaymentTypes from '../../../enums/programPaymentTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === SEND_PAYMENT_DEPOSIT
            || i === GET_PARTNERS
            || i === DOWNLOAD_PAYMENT_DEPOSIT
            || i === CHECK_FACILITY_DEPOSIT
    ).size;

    return size > 0;
};

const getPaymentDeposit = state => state.facilityPaymentDepositSectionReducer.get('paymentDeposit');
const getPartners = state => state.facilityPaymentDepositSectionReducer.get('partners');
const getPopupMessage = state => state.facilityPaymentDepositSectionReducer.getIn(['popupData', 'message']);
const getPopupSubmitText = state => state.facilityPaymentDepositSectionReducer.getIn(['popupData', 'submitText']);
const getIsAchUploadAvailable = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'isAchUploadAvailable']);
const getAchUploadErrorMessage = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'errorMessage']);
const getForm = formValueSelector(wellnessProgramPaymentResources.formFacilityPaymentDepositViaACH, state => state.form.toJS());
const getProgramPaymentTypeID = (state) => {
    const programPaymentType = getForm(state, wellnessProgramPaymentResources.fieldProgramPaymentType);
    if (programPaymentType) {
        return programPaymentType.id;
    } else {
        return programPaymentTypes.partnerPaid;
    }
};
const getProgramTypeID = (state) => {
    const programType = getForm(state, wellnessProgramPaymentResources.fieldProgramType);
    if (programType) {
        return programType.id;
    }
};
const getPartnerID = (state) => {
    const partner = getForm(state, wellnessProgramPaymentResources.fieldPartner);
    if (partner) {
        return partner.id;
    }
};
const getPeriodID = (state) => {
    const period = getForm(state, wellnessProgramPaymentResources.fieldPeriod);
    if (period) {
        return period.id;
    }
};

export default createSelector(
    [getIsLoading, getPaymentDeposit, getPartners, getPopupMessage, getPopupSubmitText, getIsAchUploadAvailable, getAchUploadErrorMessage, getProgramPaymentTypeID, getProgramTypeID, getPartnerID, getPeriodID],
    (isLoading, paymentDeposit, partners, popupMessage, popupSubmitText, isAchUploadAvailable, achUploadErrorMessage, programPaymentTypeId, programTypeId, partnerId, periodId) => ({
        isLoading,
        paymentDeposit,
        partners,
        popupMessage,
        popupSubmitText,
        isAchUploadAvailable,
        achUploadErrorMessage,
        programPaymentTypeId,
        programTypeId,
        partnerId,
        periodId
    })
);