import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'immutable';
import selectors from './selectors';
import actions from './actions';
import Spinner from '../../../components/Spinner/Spinner';
import withMemberGroup from '../../../hoc/withMemberGroup';
import CardInfo from '../../../components/CardInfo/CardInfo';
import InfoBox from '../../../components/InfoBox/InfoBox';
import InfoBoxTable from '../../../components/InfoBox/InfoBoxTable';
import InfoBoxRow from '../../../components/InfoBox/InfoBoxRow';
import { NavLink } from 'react-router-dom';
import routes from '../../../routing/routes';
import insuranceInfoResources from './insuranceInfoResources';
import { getFile } from '../../Shared/Storage/storageActions';
import documentAgreementVersions from '../../../enums/documentAgreementVersions';

function InsuranceInfo({ isLoading, insuranceInfo, getFile }) {
    return (
        <div className="insurance-info" id="insurance_info">
            {
                isLoading
                    ? <Spinner />
                    : (
                        <div>
                            {insuranceInfo.map((insuranceInfo, index) => (
                                <CardInfo key={index} title={insuranceInfo.get('name')}>
                                    <div className="col-md-6">
                                        <InfoBox title="Insurance Information" bordered>
                                            {
                                                insuranceInfo.get('insuranceInfoItems').toJS()
                                                    .map((item, index) => (
                                                        <InfoBoxRow
                                                            key={index}
                                                            label={item.key}
                                                            content={item.value}
                                                        />)
                                                    )
                                            }
                                            {
                                                insuranceInfo.get('showMemberLegalContractLink') && insuranceInfo.get('documentAgreementVersion') >= documentAgreementVersions.V4 && <button type="button" className="link link_color_blue link_bold" onClick={() => getFile(insuranceInfo.get('agreementPartitionKey'), insuranceInfo.get('agreementRowKey'))}>
                                                    {insuranceInfoResources.linkLegalContract}
                                                </button>
                                            }
                                            {
                                                insuranceInfo.get('showMemberLegalContractLink') && insuranceInfo.get('documentAgreementVersion') < documentAgreementVersions.V4 && <NavLink className="link link_color_blue link_bold" to={routes.memberLegalContractPage.path} >
                                                    {insuranceInfoResources.linkLegalContract}
                                                </NavLink>
                                            }
                                        </InfoBox>
                                    </div>
                                    <div className="col-md-6">
                                        <InfoBox title="Related Clubs" bordered>
                                            <InfoBoxTable header={['facility name', 'address']} data={insuranceInfo.get('relatedFacilities').toJS()} />
                                        </InfoBox>
                                    </div>
                                </CardInfo>
                            ))}
                        </div>
                    )
            }
        </div >
    );
}

InsuranceInfo.propTypes = {
    getInsuranceInfo: PropTypes.func,
    insuranceInfo: PropTypes.instanceOf(List),
    selectedMemberGroup: PropTypes.object,
    isLoading: PropTypes.bool,
    getFile: PropTypes.func,
};

const withMemberGroupObserved = withMemberGroup({
    actionName: 'getInsuranceInfo'
})(InsuranceInfo);

export default connect(selectors, { getFile, ...actions })(withMemberGroupObserved);
