import validationRules from '../../../utilities/validationRules';
import voucherProgramFormResources from './voucherProgramFormResources';
import { ALLOWED_TYPES, MAX_SIZE_BYTES, ALLOWED_FILE_EXTENSIONS, MAX_SIZE_MB } from '../../../constants/partnerLogo';

export const programNameRequired = validationRules.required(
    voucherProgramFormResources.labelProgramName
);
export const programNameMaxLength = validationRules.length(
    voucherProgramFormResources.labelProgramName,
    null,
    100
);
export const redemptionAmountRequired = validationRules.required(
    voucherProgramFormResources.labelRedemptionAmount
);
export const redemptionAmountNumeric = validationRules.numericOrEmpty(
    voucherProgramFormResources.labelRedemptionAmount
);
export const redemptionAmountIsPositive = validationRules.greaterThanOrEqualSpecificValue(0, voucherProgramFormResources.labelRedemptionAmount);
export const redemptionAmountIsDecimalWithTwoPlaces = validationRules.decimalWithTwoPlaces(voucherProgramFormResources.labelRedemptionAmount);
export const redemptionAmountRange = validationRules.range(
    voucherProgramFormResources.labelRedemptionAmount,
    0.01,
    99999999.99
);
export const startDateRequired = validationRules.required(
    voucherProgramFormResources.labelStartDate
);
export const startDateLessThanOrEqualEndDate = validationRules.lessThanOrEqual('endDate',
    voucherProgramFormResources.labelStartDate, voucherProgramFormResources.labelEndDate, 'dateString'
);
export const endDateRequired = validationRules.required(
    voucherProgramFormResources.labelEndDate
);
export const endDateGreaterThanOrEqualStartDate = validationRules.greaterThanOrEqual('startDate',
    voucherProgramFormResources.labelEndDate, voucherProgramFormResources.labelStartDate, 'dateString'
);

export const fileMaxSize = (fileList) => {
    return !fileList || fileList.length === 0 || fileList[0].size <= MAX_SIZE_BYTES
        ? undefined
        : `File with maximum size of ${MAX_SIZE_MB} MB is allowed`;
};

export const fileType = (fileList) => {
    return !fileList || fileList.length === 0 || ALLOWED_TYPES.includes(fileList[0].type)
        ? undefined
        : `Invalid file format. Please make sure that you are using ${ALLOWED_FILE_EXTENSIONS} file formats.`;
};