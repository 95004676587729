import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmPopup from '../../../components/Popups/ConfirmAlert/ConfirmPopup';
import Spinner from '../../../components/Spinner/Spinner';
import selectors from './groupMembershipsGeneralInfoTabSelectors';
import GroupMembershipsInfoForm from './GroupMembershipsInfoForm/GroupMembershipsInfoForm';
import {
    getGroupMembership,
    updateGroupMembership,
    addGroupMembership,
    resetGroupMembershipToAdd,
    setGroupMembershipToAdd,
    resetGroupMembership,
    getHasEnrolledOrInvitedMembers
} from './groupMembershipsActions';
import resources from '../../Shared/resources';
import { useFlags } from 'launchdarkly-react-client-sdk';

function GroupMembershipsGeneralInfoTab({
    match,
    isLoading,
    groupMembership,
    getGroupMembership,
    updateGroupMembership,
    addGroupMembership,
    resetGroupMembershipToAdd,
    setGroupMembershipToAdd,
    resetGroupMembership,
    isConfirmationPopupVisible,
    savingConfirmationPopupMessage,
    groupMembershipToAdd,
    isGroupMembershipAddedToPartner,
    isExisting,
    hasEnrolledOrInvitedMembers,
    getHasEnrolledOrInvitedMembers }) {
    const { gmrAddProgram } = useFlags();
    useEffect(() => {
        if (isGroupMembershipAddedToPartner) {
            getGroupMembership(match.params.partnerId);
            getHasEnrolledOrInvitedMembers(match.params.partnerId);
        } else {
            resetGroupMembership(match.params.partnerId);
        }
    }, [match.params.partnerId, isExisting, isGroupMembershipAddedToPartner]);
    function handleConfirmation() {
        if (!gmrAddProgram) {
            return false;
        }
        return addGroupMembership(groupMembershipToAdd.toJS())
            .then((result) => {
                if (result.success) {
                    window.location.reload();
                }
            });
    }
    function handleGroupMembershipSave(groupMembershipItem) {
        if (!isExisting && gmrAddProgram) {
            return setGroupMembershipToAdd(groupMembershipItem);
        }
        groupMembershipItem = groupMembershipItem.toJS();
        return updateGroupMembership(groupMembershipItem);
    }
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div>
                <ConfirmPopup
                    showPopup={isConfirmationPopupVisible}
                    body={<div className="message">{savingConfirmationPopupMessage}</div>}
                    onConfirm={handleConfirmation}
                    onCancel={resetGroupMembershipToAdd}
                    labelOk={resources.confirmLabel}
                />
                <GroupMembershipsInfoForm initialValues={groupMembership}
                    onSubmit={handleGroupMembershipSave}
                    isExisting={isExisting}
                    intialProgramPaymentType={groupMembership.get('programPaymentTypeID')}
                    isGmrFeatureEnabled={gmrAddProgram}
                    hasEnrolledOrInvitedMembers={hasEnrolledOrInvitedMembers}
                />
            </div>);
}

GroupMembershipsGeneralInfoTab.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isExisting: PropTypes.bool.isRequired,
    groupMembership: PropTypes.object.isRequired,
    getGroupMembership: PropTypes.func.isRequired,
    updateGroupMembership: PropTypes.func.isRequired,
    addGroupMembership: PropTypes.func.isRequired,
    isGroupMembershipAddedToPartner: PropTypes.bool,
    resetGroupMembershipToAdd: PropTypes.func.isRequired,
    setGroupMembershipToAdd: PropTypes.func.isRequired,
    resetGroupMembership: PropTypes.func.isRequired,
    groupMembershipToAdd: PropTypes.object,
    isConfirmationPopupVisible: PropTypes.bool.isRequired,
    savingConfirmationPopupMessage: PropTypes.string,
    isGmrFeatureEnabled: PropTypes.bool,
    hasEnrolledOrInvitedMembers: PropTypes.bool,
    getHasEnrolledOrInvitedMembers: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getGroupMembership,
    updateGroupMembership,
    addGroupMembership,
    resetGroupMembershipToAdd,
    setGroupMembershipToAdd,
    resetGroupMembership,
    getHasEnrolledOrInvitedMembers
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(GroupMembershipsGeneralInfoTab);
