import * as types from './partnerOwnerAreaTypes';
import programTypes from '../../enums/programTypes';

const setIsGroupMembershipAddedToPartner = isGroupMembershipAddedToPartner => ({
    type: types.SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER,
    payload: { isGroupMembershipAddedToPartner }
});

const setPartnerName = partnerName => ({
    type: types.SET_PARTNER_NAME,
    payload: { partnerName }
});

const setProgramPaymentType = programPaymentType => ({
    type: types.SET_PROGRAM_PAYMENT_TYPE,
    payload: { programPaymentType }
});

export const setPartnerForPartnerOwnerArea = partner => (dispatch) => {
    const isGroupMembershipAddedToPartner = partner.programTypes
        && partner.programTypes[programTypes.groupMemberships] !== undefined
        && partner.programTypes[programTypes.groupMemberships];

    if (partner.partnerPrograms) {
        const groupMemberships = partner.partnerPrograms.find(x => x.programType.id === programTypes.groupMemberships);
        const programPaymentType = groupMemberships
            ? groupMemberships.programPaymentTypeID
            : undefined;
        dispatch(setProgramPaymentType(programPaymentType));
    }
    dispatch(setPartnerName(partner.name));
    dispatch(setIsGroupMembershipAddedToPartner(isGroupMembershipAddedToPartner));
};