import { createSelector } from 'reselect';
import {
    GET_BANKING_INFO,
    UPDATE_BANKING_INFO
} from './partnerBankingInfoTypes';
import roles from '../../../enums/roles';

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_BANKING_INFO || i === UPDATE_BANKING_INFO
    ).size;

    return size > 0;
};

const getMe = state => state.app.get('me');

export const getBankingInfoSelector = state =>
    state.partnerBankingInfoReducer.get('bankingInfo');

const getPayeeToken = (state) => {
    return state.partnerBankingInfoReducer.getIn(['bankingInfo', 'bankAccount', 'payeeToken']);
};
const getAccountNumber = (state) => {
    return state.partnerBankingInfoReducer.getIn(['bankAccount', 'accountNumber']);
};

const getRoutingNumber = (state) => {
    return state.partnerBankingInfoReducer.getIn(['bankAccount', 'routingNumber']);
};

const getIsAdmin = createSelector(
    [getMe], (me) => {
        return me.role === roles.systemAdmin;
    }
);

export default createSelector(
    [getIsLoading, getBankingInfoSelector, getPayeeToken, getAccountNumber, getRoutingNumber, getIsAdmin],
    (isLoading, bankingInfo, payeeToken, accountNumber, routingNumber, isAdmin) => ({
        isLoading,
        bankingInfo,
        payeeToken,
        accountNumber,
        routingNumber,
        isAdmin
    })
);
