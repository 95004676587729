import { createSelector } from 'reselect';

export const getIsLoading = state => state.memberProfileGeneralInfo.get('isLoading');
export const getFieldsData = state => state.memberProfileGeneralInfo.get('fieldsData');
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

export default createSelector(
    [
        getIsLoading,
        getFieldsData,
        getSelectedMemberGroup
    ],
    (
        isLoading,
        fieldsData,
        selectedMemberGroup
    ) => ({
        isLoading,
        fieldsData,
        selectedMemberGroup
    })
);