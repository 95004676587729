import { createSelector } from 'reselect';
import { GET_FACILITIES_FOR_MAP } from './clubLocatorSectionTypes';


const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_FACILITIES_FOR_MAP
    ).size;
    return size > 0;
};

const getFacilities = state => state.clubLocatorSectionReducer.get('facilities');
const getPlansFilters = state => state.plansFilterReducer.get('plansFilters');

export default createSelector(
    [
        getIsLoading,
        getFacilities,
        getPlansFilters
    ],
    (isLoading, facilities, checkedPlans) => ({
        isLoading,
        facilities,
        checkedPlans
    })
);
