import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './_muiinput.scss';
import { TextField } from '@material-ui/core';

export function MuiTextArea(props) {
    const {
        meta = {},
        input = {},
        label,
        addAsteriskToLabel,
        variant,
        defaultValue,
        id,
        disabled,
        onClick,
        fullWidth,
        minRows,
        maxRows
    } = props;
    const muiLabel = useMemo(() => {
        return addAsteriskToLabel ? `${label} *` : label;
    }, [addAsteriskToLabel]);
    const { value, name, onChange, onBlur } = input;
    const { error, touched } = meta;
    const showError = error && touched;
    return <div className="mui-input-container">
        {
            (
                <TextField
                    id={id}
                    name={name}
                    label={muiLabel}
                    error={showError}
                    helperText={showError ? error : ''}
                    type={'text'}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    onClick={onClick}
                    disabled={disabled}
                    value={value}
                    variant={variant}
                    multiline
                    minRows={minRows}
                    maxRows={maxRows}
                    fullWidth={fullWidth}
                />
            )
        }
    </div>;
}

MuiTextArea.defaultProps = {
    addAsteriskToLabel: false,
    variant: 'outlined',
    fullWidth: true,
    minRows: 4,
    maxRows: 4,
};

MuiTextArea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    addAsteriskToLabel: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    minRows: PropTypes.number,
    maxRows: PropTypes.number
};
