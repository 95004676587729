import * as types from './memberAreaTypes';
import MemberAreaService from './memberAreaService';
import * as loadingActions from '../Loading/loadingActions';


const setMemberHasActiveFacilityEnrollments = memberHasActiveFacilityEnrollments => ({
    type: types.SET_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS,
    payload: { memberHasActiveFacilityEnrollments }
});

export const hasActiveFacilityEnrollments = membersGuids => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(types.CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS));

    return MemberAreaService.getMemberHasActiveFacilityEnrollments(membersGuids)
        .then((memberHasActiveFacilityEnrollments) => {
            dispatch(setMemberHasActiveFacilityEnrollments(memberHasActiveFacilityEnrollments));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS)));
};

export default {
    hasActiveFacilityEnrollments
};
