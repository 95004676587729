import * as types from './facilitySearchBoxTypes';
import * as facilitySearchBoxService from './facilitySearchBoxService';
import * as loadingActions from '../../Loading/loadingActions';

export const setFacilities = facilities => ({
    type: types.SET_FACILITIES_FOR_DROPDOWN,
    facilities
});

export function getFacilitiesForDropDown(filter) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_FACILITIES_FOR_DROPDOWN));
        return facilitySearchBoxService.getFacilities(filter)
            .then((facilities) => {
                dispatch(setFacilities(facilities));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_FACILITIES_FOR_DROPDOWN));
            });
    };
}
