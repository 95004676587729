import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import InputText from './InputText';
import { format } from 'date-fns';
import { JSON_DATE_TIME, SLASHED_UNICODE_DATE_FORMAT } from '../../constants/formats';
import 'react-datepicker/dist/react-datepicker.css';
import './_react-datepicker.scss';

function InputDatePicker({
    input, formatString, placeholder,
    meta, label, disabled,
}) {
    const handleChange = function (date) {
        input.onChange(format(date, JSON_DATE_TIME));
    };
    const InputTextRef = forwardRef(({ value, onClick }, ref) => {
        return (
            <InputText onClick={onClick}
                meta={meta}
                value={value}
                label={label}
                disabled={disabled}
                placeholder={placeholder}
            />
        );
    });
    return (
        <DatePicker
            dateFormat={formatString}
            selected={input.value ? new Date(input.value) : null}
            onChange={handleChange}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            customInput={<InputTextRef />}
        />
    );
}

InputDatePicker.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func.isRequired,
        value: PropTypes.string.isRequired,
    }).isRequired,
    formatString: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    placeholder: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

InputDatePicker.defaultProps = {
    formatString: SLASHED_UNICODE_DATE_FORMAT,
    placeholder: ''
};

export default InputDatePicker;
