import initialState from './legalContractInitialState';
import * as types from './legalContractTypes';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_SIGNATURE_MEMBER_INFO_SUCCESS:
            if (!action.signatureMemberInfo.documentAgreementVersion) {
                action.signatureMemberInfo.documentAgreementVersion = state.getIn(['membersAFFacilityWithinProgramEnrollment', 'documentAgreementVersion']);
            }
            if (!action.signatureMemberInfo.date) {
                action.signatureMemberInfo.date = action.signatureMemberInfo.date.toLocaleDateString();
            }
            return state.mergeDeep({ membersAFFacilityWithinProgramEnrollment: action.signatureMemberInfo });
        default:
            return state;
    }
}