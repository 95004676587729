import { createSelector } from 'reselect';

const getFacility = state => state.facilityReducer.get('facility');

const getFacilityDescription = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('description');
    }
);

const getFacilityAFNumber = createSelector(
    [getFacility],
    (facility) => {
        return facility.get('afNumber');
    }
);

const getIsLoading = createSelector(
    [   getFacilityDescription,
        getFacilityAFNumber
    ],
    (
        facilityDescription,
        facilityAFNumber
    ) => (
        !facilityDescription || !facilityAFNumber
    ));

export default createSelector(
    [
        getIsLoading,
        getFacilityDescription,
        getFacilityAFNumber
    ],
    (
        isLoading,
        facilityDescription,
        facilityAFNumber
    ) => (
        {
            isLoading,
            facilityDescription,
            facilityAFNumber
        }));

