import React from 'react';
import facilityLocatorResources from '../../facilityLocatorResources';
import BulletCaption from '../../../../../../components/BulletCaption/BulletCaption';
import { connect } from 'react-redux';

function FacilityLocatorMapLegend() {
    return <div className="facility-locator__map-legend">
        <div className="facility-locator__map-legend-item" >
            <BulletCaption color="blue"
                text={facilityLocatorResources.labelFitnessFacility}
            />
        </div>
        <div className="facility-locator__map-legend-item">
            <BulletCaption color="green"
                text={facilityLocatorResources.labelFitnessClass}
            />
        </div>
        <div className="facility-locator__map-legend-item" >
            <BulletCaption color="beet"
                text={facilityLocatorResources.labelFlexInstructor}
            />
        </div>
    </div>;
}

export default connect(null, null)(FacilityLocatorMapLegend);