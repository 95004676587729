import * as types from './plansFilterTypes';

const setPlansFiltersAction = plansFilters => ({
    type: types.SET_PLANS_FILTERS,
    plansFilters
});

export const setPlansFilters = plansFilters => (dispatch) => {
    dispatch(setPlansFiltersAction(plansFilters));
};
