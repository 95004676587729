import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import CustomRoute from '../../../../layouts/CustomRoute';
import { routeType } from '../../../../routing/routes';

function PrivacyArchiveArea({ currentRoute }) {
    return <Switch>
        {currentRoute.routes.map((route, i) => (
            <CustomRoute
                key={i}
                path={route.path}
                currentRoute={route}
                filteredRoutes={currentRoute.routes}
            />
        ))}
        <Redirect
            from={currentRoute.path}
            to={currentRoute.routes[0].path}
        />
    </Switch>;
}

PrivacyArchiveArea.propTypes = {
    currentRoute: routeType,
};

export default PrivacyArchiveArea;