import * as types from './uploadRecipientsResultsTypes';

const setUploadResultsAction = (uploadResults) => {
    return {
        type: types.SET_UPLOAD_RESULTS,
        uploadResults
    };
};
const setFileNameAction = (fileName) => {
    return {
        type: types.SET_FILE_NAME,
        fileName
    };
};
export function setUploadResults(uploadResults) {
    return function(dispatch) {
        dispatch(setUploadResultsAction(uploadResults));
    };
}

export function setUploadResultsFileName(fileName) {
    return function(dispatch) {
        dispatch(setFileNameAction(fileName));
    };
}