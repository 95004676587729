import * as types from './memberListTypes';
import * as membersService from './membersService';
import * as loadingActions from '../Loading/loadingActions';

export const setMembersList = (members) => {
    return { type: types.SET_MEMBERS_LIST, members };
};

export const joinMembersList = (members) => {
    return { type: types.JOIN_MEMBERS_LIST, members };
};

const setTotalMembersCount = (totalCount) => {
    return { type: types.SET_MEMBERS_TOTAL_COUNT, totalCount };
};

export const updateSelectedMembers = (selectedMembers) => {
    return { type: types.SET_SELECTED_MEMBERS, selectedMembers };
};

export function getMembersList(pageNumber, pageSize, facilityID, sortBy, sortOrder, filter) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_MEMBERS_LIST));
        return membersService
            .getMembersList(pageNumber, pageSize, facilityID, sortBy, sortOrder, filter)
            .then((members) => {
                if (pageNumber === 1) {
                    dispatch(setMembersList(members.items));
                }
                else {
                    dispatch(joinMembersList(members.items));
                }
                dispatch(setTotalMembersCount(members.totalCount));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_MEMBERS_LIST));
            });
    };
}

const setMemberToDeactivateAction = (member) => {
    return { type: types.SET_MEMBER_TO_DEACTIVATE, member };
};

export function resetMemberToDeactivate() {
    return function(dispatch) {
        dispatch(setMemberToDeactivateAction(undefined));
    };
}

export function setMemberToDeactivate(member) {
    return function(dispatch) {
        dispatch(setMemberToDeactivateAction(member));
    };
}
export function deactivateMember(memberID) {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.DEACTIVATE_MEMBER));
        return membersService
            .deactivateMember(memberID)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.DEACTIVATE_MEMBER));
            });
    };
}