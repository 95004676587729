import React, { useMemo } from 'react';
import './uploadRecipientsResults.scss';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewTable from '../../../components/NewInfoBox/NewTable';
import TooltipCell from '../../../components/NewInfoBox/TooltipCell';
import MaterialIcon from '../../../components/MaterialIcon/MaterialIcon';
import selectors from './uploadRecipientsResultsSelector';
import uploadRecipientsResources from './uploadRecipientsResultsResources';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { setUploadResultsFileName, setUploadResults } from './uploadRecipientsResultsActions';
import Button from '../../../components/controls/Button';
import resources from '../resources';

function UploadRecipientsResults({
    fileName,
    badRecords
}) {
    const history = useHistory();
    const updateHandler = () => {
    };
    const goBack = () => {
        history.goBack();
    };
    const totalRecords = () => {
        return badRecords ? (badRecords.size || 0) : 0;
    };
    const columns = useMemo(() => [
        {
            Header: uploadRecipientsResources.labelRowNumber,
            accessor: 'row',
            width: 80,
            minWidth: 80
        },
        {
            id: 'fullName',
            Header: uploadRecipientsResources.labelFullName,
            accessor: (x) => {
                return `${x.firstName ? x.firstName : ''} ${x.lastName ? x.lastName : ''}`;
            },
            width: 200,
            minWidth: 150
        },
        {
            Header: uploadRecipientsResources.labelEmail,
            accessor: 'email',
            Cell: TooltipCell,
            width: 184,
            minWidth: 184
        },
        {
            Header: uploadRecipientsResources.labelReason,
            accessor: 'reason',
            Cell: TooltipCell,
            width: 300,
            minWidth: 200
        }
    ], [uploadRecipientsResources]);

    return <div className="layout__new-container upload-results-container">
        <div>
            <h1 className="upload-recipients-results__header">
                <MaterialIcon icon="arrow_back" onClick={goBack} />
                {uploadRecipientsResources.uploadResultsTitle}</h1>
            <h3 className="upload-recipients-results__title">{fileName}</h3>
            <p className="upload-recipients-results__title-note">{uploadRecipientsResources.uploadResultsLabel}</p>
        </div>
        <NewTable columns={columns}
            update={updateHandler}
            initialData={fromJS(badRecords)}
            initialSortBy={{
                id: 'row',
                desc: false
            }}
            totalCount={totalRecords()}
            getItemId={item => item.row}
        />
        <div className="upload-recipients-results__footer">
            <Button className="btn_new btn_new--white"
                size="small"
                variant="contained"
                onClick={goBack}
            >
                <MaterialIcon className="btn_new-icon btn_new-icon--left" icon="arrow_back" />
                {resources.backButtonText}
            </Button>

        </div>
    </div>;
}
UploadRecipientsResults.defaultProps = {
    fileName: '',
    badRecords: []
};

UploadRecipientsResults.propTypes = {
    fileName: PropTypes.string,
    badRecords: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

const mapDispatchToProps = {
    setUploadResultsFileName,
    setUploadResults
};
export default connect(selectors, mapDispatchToProps)(UploadRecipientsResults);