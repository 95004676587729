import * as types from './voucherProgramsListTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as voucherProgramsListService from './voucherProgramsListService';

const setVoucherPrograms = (voucherPrograms) => {
    return { type: types.SET_VOUCHER_PROGRAMS, voucherPrograms };
};

export function getVoucherPrograms(partnerId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_VOUCHER_PROGRAMS));
        return voucherProgramsListService
            .getVoucherPrograms(partnerId)
            .then((voucherPrograms) => {
                dispatch(setVoucherPrograms(voucherPrograms));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_VOUCHER_PROGRAMS));
            });
    };
}
