import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setMemberToDeactivate } from './membersListActions';
import MaterialIcon from '../../components/MaterialIcon/MaterialIcon';
import ToolTip from '../../components/controls/ToolTip';
import MemberResources from './memberResources';

function MembersActionsCell({ value, setMemberToDeactivate }) {

    const deactivateHandler = () => {
        setMemberToDeactivate(value.row);
    };
    return (
        <div className="member-list__button-action-container">
            {value.isAdmin && value.row.isPpv && value.row.isAF &&
            <ToolTip title={MemberResources.tooltipDeactivate} arrow
                placement="top"
            >
                <div>
                    <button className="member-list__button-action" onClick={deactivateHandler}
                        disabled={!value.row.memberFirstName}
                    >
                        <MaterialIcon className="member-list__button-action-icon" icon="person_off"
                            variant={'filled'}
                        />
                    </button>
                </div>
            </ToolTip>}
        </div>
    );
}

MembersActionsCell.propTypes = {
    value: PropTypes.object.isRequired,
    setMemberToDeactivate: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    setMemberToDeactivate
};

export default connect(null, mapDispatchToProps)(MembersActionsCell);