/* eslint-disable react/no-multi-comp*/
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import './_c-select.scss';
import Immutable from 'immutable';

export function InputSelect(props) {
    const {
        meta = {},
        label,
        inline,
        size = 'medium',
        mode,
        value,
        defaultValue,
        hideValidationMessage,
        onChange,
        onBlur = () => undefined,
        isSearchable = false,
        ...restProps
    } = props;
    const { error, touched, initial } = meta;
    const showError = error && touched;
    /* https://github.com/JedWatson/react-select/issues/1129#issuecomment-248240544 */
    const handleBlur = () =>
        onBlur(
            value
        );
    const setValue = (value) => {
        /* issue with selected value for react-select https://github.com/JedWatson/react-select/issues/3648 */
        if (Immutable.Iterable.isIterable(props.options) && value) {
            const objectKey = value.keySeq().first();
            const foundValue = props.options.filter(option => option.get(objectKey) === value.get(objectKey)).first();
            return foundValue
                ? foundValue
                : '';
        }
        return value;
    };
    return (
        <div
            className={classNames([
                `c-select c-select_${size}`,
                { 'c-select_inline': inline },
                { [`c-select_style_${mode}`]: mode }
            ])}
        >
            {label && <label className="c-select__label">{label}</label>}
            <Select
                isSearchable={isSearchable}
                onChange={onChange}
                className={classNames([
                    { 'c-select-container': mode !== 'dropdown' },
                    { 'input-validation-error': showError }
                ])}
                classNamePrefix="c-select"
                value={setValue(value)}
                defaultValue={initial || defaultValue}
                onBlur={handleBlur}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                maxMenuHeight={360}
                {...restProps}
            />
            {!hideValidationMessage && showError && (
                <div className="field-validation-error">{error}</div>
            )}
        </div>
    );
}

InputSelect.propTypes = {
    label: PropTypes.string,
    inline: PropTypes.bool,
    size: PropTypes.string,
    mode: PropTypes.string,
    onChange: PropTypes.func,
    hideValidationMessage: PropTypes.bool
};

const ReduxSelectInput = ({ input, meta, ...custom }) => {
    return (
        <InputSelect meta={meta} {...input}
            {...custom}
        />);
};

export default ReduxSelectInput;
