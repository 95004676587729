import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import { defaultFormProperties } from '../../../utilities/form';
import paymentConfirmationFormResources from './paymentConfirmationFormResources';
import * as validationRules from './paymentConfrimationFormValidation';

const PaymentConfirmationForm = ({
    handleSubmit,
    message
}) => {
    return <FormGroup onSubmit={handleSubmit}>
        <Field
            id="pin"
            label={paymentConfirmationFormResources.labelForPIN}
            name="pin"
            component={MuiInputText}
            secureTextEntry
            validate={[
                validationRules.pinRequired,
                validationRules.pinNumeric
            ]}
        />
        <div className="message">
            {message}
        </div>
    </FormGroup>;
};

PaymentConfirmationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
};

export default reduxForm({
    ...defaultFormProperties
})(PaymentConfirmationForm);

