const programPaymentTypes = Object.freeze({
    partnerPaid: 1,
    memberPaid: 2,
    partnerMemberPaid: 3
});
export const programPaymentTypesFullNamesMap = Object.freeze({
    [programPaymentTypes.partnerPaid]: 'GM - Partner Paid',
    [programPaymentTypes.memberPaid]: 'GMR - Member Paid',
    [programPaymentTypes.partnerMemberPaid]: 'GMSP - Partner&Member Paid'
});
export const programPaymentTypesShortNamesMap = Object.freeze({
    [programPaymentTypes.partnerPaid]: 'GM',
    [programPaymentTypes.memberPaid]: 'GMR',
    [programPaymentTypes.partnerMemberPaid]: 'GMSP',
});
export default programPaymentTypes;
