import { createSelector } from 'reselect';

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

export default createSelector(
    [
        getRoutes,
    ],
    filteredRoutes => ({
        filteredRoutes,
    })
);
