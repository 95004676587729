import { createSelector } from 'reselect';

const getFacilities = state => state.facilityLocatorMapReducer.get('facilities');
const getSelectedFacility = state => state.facilityLocatorMapReducer.get('selectedFacility');
const getFacilitiesInMapBounds = state => state.facilityLocatorMapReducer.get('facilitiesInMapBounds');
const getIsListView = state => state.facilityLocatorMapReducer.get('isListView');

export default createSelector(
    [
        getFacilities,
        getSelectedFacility,
        getFacilitiesInMapBounds,
        getIsListView
    ],
    (facilities, selectedFacility, facilitiesInMapBounds, isListView) => ({
        facilities, selectedFacility, facilitiesInMapBounds, isListView
    })
);
