import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_spinner.scss';

function Spinner({ messageText, className }) {
    return (
        <div
            className={classNames([
                'spinner',
                { [className]: className }
            ])}
        >
            <div className="spinner__inner-wrapper">
                <div className="spinner__animation" />
                <div className="spinner__message-text">{messageText}</div>
            </div>
        </div>
    );
}

Spinner.propTypes = {
    className: PropTypes.string,
    messageText: PropTypes.string
};

export default Spinner;