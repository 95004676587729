import { GET_STATES_LIST_SUCCESS } from './stateTypes';
import initialState from './stateInitialState';
import { fromJS } from 'immutable';

export default function stateReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATES_LIST_SUCCESS:
            return state.set('states', fromJS(action.states));
        default:
            return state;
    }
}
