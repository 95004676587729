import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SPACE } from '../../constants/keyCodes';
import './_tabs.scss';

class NavigationTabControl extends Component {
    constructor(props) {
        super(props);

        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(event, tab) {
        if (event.which === SPACE) {
            event.preventDefault();
            this.props.onNavigate(tab.path);
        }
    }

    render() {
        const tabs = this.props.tabs.filter(
            tab => !tab.isHiddenOnUI
        );

        return (
            <div className="tabs">
                <ul className="tabs__list flex-column flex-md-row" role="tablist">
                    {tabs.map(tab => (
                        <NavLink
                            key={tab.id}
                            to={tab.path}
                            className={classNames(['tabs__item js-tab-link'])}
                            onKeyPress={event => this.handleKeyPress(event, tab)}
                            activeClassName="tabs__item_active"
                        >
                            {tab.displayName}
                        </NavLink>
                    ))}
                </ul>
            </div>
        );
    }
}

NavigationTabControl.defaultProps = {
    onNavigate: () => { },
};

NavigationTabControl.propTypes = {
    children: PropTypes.node,
    onNavigate: PropTypes.func,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            displayName: PropTypes.string,
            path: PropTypes.string,
        })
    ),
};

export default NavigationTabControl;
