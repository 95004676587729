import * as types from './managePartnerTypes';
import initialState from './managePartnerInitialState';
import { fromJS } from 'immutable';

export default function partnerReducer(state = initialState, action) {
    switch (action.type) {
        case types.UPDATE_PARTNER:
            return state.set('partner', fromJS(action.partner));
        default:
            return state;
    }
}
