import { createSelector } from 'reselect';
import * as types from './facilityPaymentsSectionTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === types.GET_PAYMENTS || i === types.DOWNLOAD_PAYMENTS || i === types.GET_PARTNERS
    ).size;

    return size > 0;
};
const getFacilityPaymentsFilter = state => state.facilityPaymentsSectionReducer.get('paymentsFilter');
const getFacilityPayments = state => state.facilityPaymentsSectionReducer.get('payments');
const getProgramTypeId = createSelector([getFacilityPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['programType', 'id']);
});
const getPeriodId = createSelector([getFacilityPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['period', 'id']);
});
const getPartnerId = createSelector([getFacilityPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['partner', 'id']);
});
const getShowCorrectedOnly = createSelector([getFacilityPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.get('showCorrectedOnly');
});
const getProgramPaymentTypeId = createSelector([getFacilityPaymentsFilter], (paymentsFilter) => {
    return paymentsFilter.getIn(['programPaymentType', 'id']);
});
const getPartnersByProgramPaymentType = state => state.facilityPaymentsSectionReducer.get('partners');
const getPartners = state => state.partnersListReducer.get('partners');
export default createSelector([getIsLoading, getFacilityPaymentsFilter, getFacilityPayments, getPartners, getPartnersByProgramPaymentType, getProgramPaymentTypeId, getProgramTypeId, getPeriodId, getPartnerId, getShowCorrectedOnly],
    (isLoading, paymentsFilter, payments, partners, partnersByProgramPaymentType, programPaymentTypeId, programTypeId, periodId, partnerId, showCorrectedOnly) => ({
        isLoading, paymentsFilter, payments, partners, partnersByProgramPaymentType, programPaymentTypeId, programTypeId, periodId, partnerId, showCorrectedOnly
    }));