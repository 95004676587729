import React from 'react';
import nominateClubResources from './nominateClubResources';
import './_nominateClub.scss';
import ClubInfo from './ClubInfoDataForm';
import GeneralInfo from './GeneralInfoDataForm';
import MultiStepForms from '../../../../components/FormStep/MultiStepForms';

function NominateClub() {
    const forms = [{ title: nominateClubResources.generalInfoText, formComponent: GeneralInfo }, { title: nominateClubResources.clubInfoText, formComponent: ClubInfo }];
    return <div className="nominate">
        <div className="nominate__title-text">
            Nominate a club
        </div>
        <div className="nominate__description">
            Not seeing the Fitness Center you were looking for?
        </div>
        <div className="nominate__info">
            We would love to work with you to get them involved in the Fitness Reimbursement Programs. Simply fill in the information below as completely as possible and this will get to one of our friendly staff who will determine the best method for that facility to work with us. Thank you for your interest in Healthy Contributions, we look forward to serving you!
        </div>
        <div className="nominate__main-section">
            <MultiStepForms forms={forms} formName={nominateClubResources.nominateClubInfo} narrow />
        </div>
    </div>;
}

export default NominateClub;