import {
    GET_BANKING_INFO,
    GET_BANKING_INFO_SUCCESS,
    UPDATE_BANKING_INFO,
    SET_BANK_ACCOUNT
} from './partnerBankingInfoTypes';
import * as loadingActions from '../../Loading/loadingActions';
import PartnerBankingInfoService from './partnerBankingInfoService';

const getBankingInfoSuccess = bankingInfo => ({
    type: GET_BANKING_INFO_SUCCESS,
    payload: {
        bankingInfo
    }
});

const setBankAccount = bankAccount => ({
    type: SET_BANK_ACCOUNT,
    payload: {
        bankAccount
    }
});

const getBankingInfo = partnerId => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(GET_BANKING_INFO));

    PartnerBankingInfoService.getBankingInfo(partnerId)
        .then((bankingInfo) => {
            dispatch(getBankingInfoSuccess(bankingInfo));
            dispatch(loadingActions.unsetLoading(GET_BANKING_INFO));
        })
        .catch(() => {
            dispatch(loadingActions.unsetLoading(GET_BANKING_INFO));
        });
};

const getBankAccountDetails = payeeToken => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(SET_BANK_ACCOUNT));

    PartnerBankingInfoService.getBankAccountDetails(payeeToken)
        .then((bankingAccount) => {
            dispatch(setBankAccount(bankingAccount));
            dispatch(loadingActions.unsetLoading(SET_BANK_ACCOUNT));
        })
        .catch(() => {
            dispatch(loadingActions.unsetLoading(SET_BANK_ACCOUNT));
        });
};

const submit = bankingInfo => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(UPDATE_BANKING_INFO));

    PartnerBankingInfoService.updateBankingInfo(bankingInfo)
        .then((result) => {
            dispatch(getBankingInfoSuccess(result.data));
            dispatch(setBankAccount(undefined));
            dispatch(loadingActions.unsetLoading(UPDATE_BANKING_INFO));
        })
        .catch(() => {
            dispatch(loadingActions.unsetLoading(UPDATE_BANKING_INFO));
        });
};

export const resetBankAccount = () => (dispatch) => {
    dispatch(setBankAccount(undefined));
};

export default {
    getBankingInfo,
    submit,
    getBankAccountDetails
};
