import * as types from './facilityLocatorTypes';
import { initialState } from './facilityLocatorInitials';
import { fromJS } from 'immutable';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FACILITIES_FOR_MAP:
            return state.set(
                'facilities',
                fromJS(action.facilities)
            );
        default:
            return state;
    }
}
