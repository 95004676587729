/* eslint-disable react/no-multi-comp*/
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import './_muiinput.scss';
import styles from './_muiinput.module.scss';
import { NumericFormat } from 'react-number-format';
import { TextField, InputAdornment } from '@material-ui/core';

export function MuiInputNumeric(props) {
    const {
        meta = {},
        input = {},
        label,
        addAsteriskToLabel,
        variant,
        defaultValue,
        id,
        disabled,
        onClick,
        fullWidth,
        showErrorMessage,
        readOnly,
        prefix,
        decimalScale,
        fixedDecimalScale,
        thousandSeparator,
        thousandsGroupStyle,
        decimalSeparator,
        adornmentVariant,
        labelVariant
    } = props;
    const muiLabel = useMemo(() => {
        return addAsteriskToLabel ? `${label} *` : label;
    }, [addAsteriskToLabel]);
    const { value, name, onChange, onBlur, onFocus } = input;
    const { error, touched } = meta;
    const showError = (error && touched) || false;
    const showHelperText = showError && showErrorMessage;
    const materialUITextFieldProps = {
        name,
        label: muiLabel,
        error: showError,
        helperText: showHelperText ? error : '',
        defaultValue,
        onChange,
        onBlur,
        onClick,
        disabled,
        variant: readOnly ? 'filled' : variant,
        fullWidth,
        onFocus,
        id,
        InputProps: {
            startAdornment: prefix ? (<InputAdornment position="start" variant={adornmentVariant} className={styles.inputAdornment}>{prefix}</InputAdornment>) : null,
            readOnly,
            className: readOnly ? styles.input_readOnly : ''
        },
        InputLabelProps: {
            variant: labelVariant,
            className: readOnly ? styles.label_readOnly : ''
        }
    };
    return <div className="mui-input-container">
        {
            <NumericFormat
                value={value}
                decimalScale={decimalScale}
                thousandSeparator={thousandSeparator}
                fixedDecimalScale={fixedDecimalScale}
                thousandsGroupStyle={thousandsGroupStyle}
                decimalSeparator={decimalSeparator}
                displayType="input"
                customInput={TextField}
                {...materialUITextFieldProps}
            />
        }
    </div>;
}

MuiInputNumeric.defaultProps = {
    fixedDecimalScale: true,
    decimalScale: 0,
    decimalSeparator: '.',
    thousandSeparator: '',
    thousandsGroupStyle: 'thousand',
    addAsteriskToLabel: false,
    variant: 'outlined',
    fullWidth: true,
    showErrorMessage: true,
    readOnly: false,
    adornmentVariant: 'outlined',
    labelVariant: 'outlined',
};

MuiInputNumeric.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    prefix: PropTypes.string,
    decimalScale: PropTypes.number,
    decimalSeparator: PropTypes.string,
    fixedDecimalScale: PropTypes.bool,
    thousandSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    addAsteriskToLabel: PropTypes.bool,
    meta: PropTypes.object,
    input: PropTypes.object,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    onFocus: PropTypes.func,
    showErrorMessage: PropTypes.bool,
    readOnly: PropTypes.bool,
    adornmentVariant: PropTypes.string,
    labelVariant: PropTypes.string
};
