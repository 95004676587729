const FacilityResources = {
    labelFacilityIdNumber: 'Facility ID',
    labelName: 'Facility Name',
    labelUSBankFacilityCode: 'US Bank Facility Code',
    labelIndustry: 'Industry',
    labelAddress1: 'Address 1',
    labelAddress2: 'Address 2',
    labelCity: 'City',
    labelState: 'State/Territory',
    labelZipCode: 'Zip Code',
    labelCountry: 'Country',
    labelWebsite: 'Website',
    labelFirstName: 'First Name',
    labelLastName: 'Last Name',
    labelPhoneNumber: 'Contact Number',
    labelEmailAddress: 'Email Address',
    labelNotes: 'Notes',
    labelPrograms: 'Programs',
};
export default FacilityResources;
