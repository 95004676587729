import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/controls/Button';
import wellnessProgramPaymentsResources from './wellnessProgramPaymentsResources';
import paymentStatuses, { paymentStatusesNamesMap } from '../../enums/paymentStatuses';

function UndoAmountCorrectionActionCell({ value, column }) {
    const rowData = value;
    const columnProps = column.getProps();
    const clickHandler = () => {
        columnProps.handleUndo(rowData[wellnessProgramPaymentsResources.accessorId]);
    };
    const paymentStatus = rowData[wellnessProgramPaymentsResources.accessorStatus];
    const disabled = rowData && (rowData[wellnessProgramPaymentsResources.accessorAmountToBeSent] >= rowData[wellnessProgramPaymentsResources.accessorTotalPayments]
        || (paymentStatus !== paymentStatusesNamesMap[paymentStatuses.pending]
            && paymentStatus !== paymentStatusesNamesMap[paymentStatuses.returnedByUSBank] && paymentStatus !== paymentStatusesNamesMap[paymentStatuses.sentExternally]));
    return (
        <Button
            className="btn_new btn_new--blue btn_new--small"
            size="small"
            onClick={clickHandler}
            disabled={disabled}
        >
            {wellnessProgramPaymentsResources.buttonUndo}
        </Button>
    );
}

UndoAmountCorrectionActionCell.propTypes = {
    value: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired
};

export default UndoAmountCorrectionActionCell;
