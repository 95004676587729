import { createSelector } from 'reselect';

export const getIsLoading = state => state.accountInsuranceInfo.get('isLoading');
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');
export const getInsuranceInfo = state => state.accountInsuranceInfo.get('insuranceInfo');

export default createSelector(
    [
        getIsLoading,
        getSelectedMemberGroup,
        getInsuranceInfo
    ],
    (
        isLoading,
        selectedMemberGroup,
        insuranceInfo
    ) => ({
        isLoading,
        selectedMemberGroup,
        insuranceInfo
    })
);