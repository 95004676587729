import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

const PartnersPage = ({ currentRoute }) => {
    const { routes } = currentRoute;

    return (
        <Switch>
            {routes.map((route, i) => (
                <CustomRoute
                    key={i}
                    path={route.path}
                    currentRoute={route}
                />
            ))}

            <Redirect from={currentRoute.path} to={routes[0].path} />
        </Switch>
    );
};

PartnersPage.propTypes = {
    currentRoute: routeType.isRequired,
};

export default PartnersPage;
