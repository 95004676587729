import { createSelector } from 'reselect';
import { SEND_PAYMENT_WITHDRAWAL, DOWNLOAD_PAYMENT_WITHDRAWAL, CHECK_PAYMENT_WITHDRAWAL, GET_PARTNERS } from './partnerPaymentWithdrawalSectionTypes';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import { GET_CLOSED_USAGE_PERIODS } from '../../Shared/Periods/periodsTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i =>
            i === SEND_PAYMENT_WITHDRAWAL
            || i === GET_PARTNERS
            || i === DOWNLOAD_PAYMENT_WITHDRAWAL
            || i === CHECK_PAYMENT_WITHDRAWAL
            || i === GET_CLOSED_USAGE_PERIODS
    ).size;

    return size > 0;
};
const getPaymentWithdrawal = state => state.partnerPaymentWithdrawalSectionReducer.get('paymentWithdrawal');
const getPartners = state => state.partnerPaymentWithdrawalSectionReducer.get('partners');
const getPopupMessage = state => state.partnerPaymentWithdrawalSectionReducer.get('popupData').message;
const getPopupSubmitText = state => state.partnerPaymentWithdrawalSectionReducer.get('popupData').submitText;
const getIsAchUploadAvailable = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'isAchUploadAvailable']);
const getAchUploadErrorMessage = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'errorMessage']);
const getProgramPaymentTypeId = createSelector([getPaymentWithdrawal], paymentWithdrawal => paymentWithdrawal.getIn(['programPaymentType', 'id']));
const getProgramTypeId = createSelector([getPaymentWithdrawal], paymentWithdrawal => paymentWithdrawal.getIn(['programType', 'id']));
const getPaymentTypeId = createSelector([getPaymentWithdrawal], paymentWithdrawal => paymentWithdrawal.getIn(['paymentType', 'id']));

const getSelf = state => state;
const getPeriods = createSelector([getProgramPaymentTypeId, getProgramTypeId, getSelf], (selectedProgramPaymentTypeID, selectedProgramTypeID, state) => {
    if (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerPaid || selectedProgramTypeID) {
        return state.periodsReducer.get('closedUsagePeriods');
    }
});

export default createSelector(
    [getIsLoading, getPaymentWithdrawal, getPartners, getPopupMessage, getPopupSubmitText, getIsAchUploadAvailable, getAchUploadErrorMessage, getProgramPaymentTypeId, getPeriods, getProgramTypeId, getPaymentTypeId],
    (isLoading, paymentWithdrawal, partners, popupMessage, popupSubmitText, isAchUploadAvailable, achUploadErrorMessage, programPaymentTypeId, periods, programTypeId, paymentTypeId) => ({
        isLoading,
        paymentWithdrawal,
        partners,
        popupMessage,
        popupSubmitText,
        isAchUploadAvailable,
        achUploadErrorMessage,
        programPaymentTypeId,
        periods,
        programTypeId,
        paymentTypeId
    })
);