import { fromJS } from 'immutable';

export default fromJS({
    paymentsFilter: {
        periodFrom: {
            id: 0,
            yearMonth: ''
        },
        periodTo: {
            id: 0,
            yearMonth: ''
        }
    },
    payments: [],
    usagePeriods: [],
    programPaymentTypeID: undefined,
});
