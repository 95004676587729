import * as types from './partnerListTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as partnersListService from './partnersListService';

const getPartnersListSuccess = (partners) => {
    return { type: types.GET_PARTNERS_LIST_SUCCESS, partners };
};

export const updatePartnersSuccess = (partner) => {
    return { type: types.UPDATE_PARTNERS_SUCCESS, partner };
};

export const createPartnerSuccess = (partner) => {
    return { type: types.CREATE_PARTNER_SUCCESS, partner };
};

export function getPartnersList() {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS_LIST));
        return partnersListService
            .getPartnersList()
            .then((partners) => {
                dispatch(getPartnersListSuccess(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS_LIST));
            });
    };
}

export function getPartnersListByProgramType(programTypeID) {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS_LIST));
        return partnersListService
            .getPartnersListByProgramType(programTypeID)
            .then((partners) => {
                dispatch(getPartnersListSuccess(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS_LIST));
            });
    };
}
