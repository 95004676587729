import { createSelector } from 'reselect';
import { GET_VOUCHER_PROGRAMS } from './voucherProgramsListTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_VOUCHER_PROGRAMS)
        .size;

    return size > 0;
};

const getVoucherPrograms = state => state.voucherProgramsListReducer.get('voucherPrograms');

export default createSelector(
    [getIsLoading, getVoucherPrograms],
    (isLoading, voucherPrograms) => ({
        isLoading,
        voucherPrograms,
    })
);
