import React from 'react';
import DashboardImagesSection from '../../../Dashboard/DashboardImagesSection';
import DashboardDescriptionSection from '../../../Dashboard/DashboardDescriptionSection';

function Home() {
    return (
        <div className="dashboard">
            <h2 className="layout__title">Healthy Contributions Portal</h2>
            <div className="dashboard__content">
                <h4 className="dashboard__heading">Hello!</h4>
                <div className="dashboard__description">
                    <p>
                        Welcome to your own personal Healthy Contributions portal and
                        dashboard. We are excited and pleased to have you here!
                    </p>
                    <p>
                        Take a look around to see your facility, your personal information,
                        and updates we may have for you regarding your monthly fitness
                        reimbursement. We care about our individual members and want you to
                        have the best possible experience while navigating your HC portal.
                    </p>
                    <p>
                        We hope you enjoy checking on your own information, updating your
                        own banking when needed and taking your fitness benefit to the next
                        level of satisfaction. Your Healthy Contributions team is here to
                        help you as well. Don’t hesitate to reach out!
                    </p>
                    <DashboardImagesSection />
                </div>
                <DashboardDescriptionSection />
            </div>
        </div>
    );
}
export default Home;
