import React from 'react';
import PropTypes from 'prop-types';
import './_icon-button.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import classNames from 'classnames';

function IconButton({ icon, clickHandler, text, iconClassName, buttonClassName, role, tabIndex, filterExpandContentId, ariaExpanded }) {
    return text ?
        <div className="icon-button">
            <span className="icon-button__icon">
                <MaterialIcon icon={icon} className={iconClassName} ariaHidden />
            </span>
            <button
                className={classNames(['link icon-button__text', buttonClassName])}
                role={role}
                tabIndex={tabIndex}
                onClick={clickHandler}
            >
                {text}
            </button>
        </div>
        :
        <button
            className={classNames(['link icon-button--notext', buttonClassName])}
            role={role}
            tabIndex={tabIndex}
            onClick={clickHandler}
            aria-controls={filterExpandContentId}
            aria-expanded={ariaExpanded}
        >
            <MaterialIcon icon={icon} className={iconClassName} ariaHidden />
        </button>;
}

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string,
    clickHandler: PropTypes.func.isRequired,
    iconClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    role: PropTypes.string,
    tabIndex: PropTypes.string,
    filterExpandContentId: PropTypes.string,
    ariaExpanded: PropTypes.bool,
};

export default IconButton;