import * as types from './facilityPaymentDepositSectionTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as facilityPaymentDepositSectionService from './facilityPaymentDepositSectionService';
import * as partnersListService from '../../Partners/PartnersList/partnersListService';

const setProgramTypeAction = (programType) => {
    return { type: types.SET_PROGRAM_TYPE, programType };
};

function setProgramType(programType) {
    return function (dispatch) {
        dispatch(setProgramTypeAction(programType));
    };
}

const setProgramPaymentTypeAction = (programPaymentType) => {
    return { type: types.SET_PROGRAM_PAYMENT_TYPE, programPaymentType };
};

function setProgramPaymentType(programPaymentType) {
    return function (dispatch) {
        dispatch(setProgramPaymentTypeAction(programPaymentType));
    };
}

const setPopupDataAction = (popupData) => {
    return { type: types.SET_POPUP_DATA, popupData };
};

function checkFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.CHECK_FACILITY_DEPOSIT));
        return facilityPaymentDepositSectionService
            .checkFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId)
            .then((response) => { dispatch(setPopupDataAction(response.data)); })
            .finally(() => dispatch(loadingActions.unsetLoading(types.CHECK_FACILITY_DEPOSIT)));
    };
}

function sendFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId, pin) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.SEND_PAYMENT_DEPOSIT));
        return facilityPaymentDepositSectionService
            .sendFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId, pin)
            .finally(() => dispatch(loadingActions.unsetLoading(types.SEND_PAYMENT_DEPOSIT)));
    };
}

function downloadPaymentDeposit(programPaymentTypeId, partnerId, periodId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.DOWNLOAD_PAYMENT_DEPOSIT));
        return facilityPaymentDepositSectionService
            .dowloadPaymentDeposit(programPaymentTypeId, partnerId, periodId)
            .finally(() => dispatch(loadingActions.unsetLoading(types.DOWNLOAD_PAYMENT_DEPOSIT)));
    };
}

const setPartnersAction = (partners) => {
    return { type: types.SET_PARTNERS, partners };
};

function getPartnersListByProgramPaymentType(programPaymentTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramPaymentType(programPaymentTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}

function getPartnersListByProgramType(programTypeID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PARTNERS));
        return partnersListService
            .getPartnersListByProgramType(programTypeID)
            .then((partners) => {
                dispatch(setPartnersAction(partners));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNERS));
            });
    };
}


const setPartnerAction = (partner) => {
    return { type: types.SET_PARTNER, partner };
};

function setPartner(partner) {
    return function (dispatch) {
        dispatch(setPartnerAction(partner));
    };
};

export default {
    setProgramType,
    setProgramPaymentType,
    sendFacilityPaymentsDeposit,
    downloadPaymentDeposit,
    checkFacilityPaymentsDeposit,
    getPartnersListByProgramType,
    getPartnersListByProgramPaymentType,
    setPartner
};