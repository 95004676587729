import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PartnerGroupMembershipsPayments from './PartnerGroupMembershipsPayments';
import selectors from './partnerManagerGroupMembershipsPaymentsTabSelectors';

function PartnerManagerGroupMembershipsPaymentsTab({ partnerId }) {
    return <PartnerGroupMembershipsPayments partnerId={partnerId} />;
}

PartnerManagerGroupMembershipsPaymentsTab.propTypes = {
    partnerId: PropTypes.number.isRequired
};

export default connect(selectors)(PartnerManagerGroupMembershipsPaymentsTab);