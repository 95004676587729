import React from 'react';

function DashboardDescriptionSection() {
    return (
        <div className="dashboard__description">
            <p className="h5" style={{ marginTop: '20px', color: '#000' }}>
				Sincerely,
                <br />
				Your Healthy Contributions Team
                <br />
				(800) 317-2739
            </p>
        </div>
    );
}

export default DashboardDescriptionSection;
