import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import selectors from './adminAreaSelectors';
import { routeType } from '../../routing/routes';
import CustomRoute from '../../layouts/CustomRoute';

function AdminArea({ currentRoute, filteredRoutes }) {
    return <div>
        <Switch>
            {filteredRoutes.map((route, i) => (
                <CustomRoute
                    key={i}
                    path={route.path}
                    currentRoute={route}
                    filteredRoutes={filteredRoutes}
                />
            ))}

            <Redirect
                from={currentRoute.path}
                to={filteredRoutes[0].path}
            />
        </Switch>
    </div>;
};

AdminArea.propTypes = {
    currentRoute: routeType.isRequired,
    filteredRoutes: PropTypes.arrayOf(routeType)
};

export default connect(selectors)(AdminArea);
