
import React from 'react';
import PropTypes from 'prop-types';
import { IndeterminateCheckbox } from '../../components/controls/IndeterminateCheckbox';

function IndeterminateCheckboxCellHeader({ column }) {
    const props = column.getProps();
    return <IndeterminateCheckbox {...props.getToggleAllRowsSelectedProps()}/>;
}

IndeterminateCheckboxCellHeader.propTypes = {
    column: PropTypes.object.isRequired
};

export default IndeterminateCheckboxCellHeader;
