import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const InputCheckbox = ({ label, id, disabled, value,
    onChange, error, touched, name, onBlur, checked }) => {
    const controlClass = 'c-control';
    const inputTypeClass = 'c-checkbox';
    const showError = error && touched;
    return (<>
        <div className={classNames([
            `${controlClass} ${inputTypeClass}`,
            { [`${controlClass}_disabled`]: disabled }
        ])}
        >
            <div className="c-input__container">
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    value={value}
                    className={classNames([
                        `${controlClass}__field ${inputTypeClass}__field`,
                        { 'input-validation-error': showError }])}
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={checked || value === true}
                />
                <label className={`${controlClass}__label ${inputTypeClass}__label`} htmlFor={id}>
                    <span className={`${controlClass}__indicator ${inputTypeClass}__indicator`} />
                    {label}
                </label>
            </div>
            {
                showError &&
                <div className="field-validation-error">
                    {error}
                </div>
            }
        </div>
    </>
    );
};

InputCheckbox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    touched: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
};

const ReduxInputCheckbox = ({ input, meta, ...custom }) => {
    return (
        <InputCheckbox
            {...meta}
            {...input}
            {...custom}
        />
    );
};

export default ReduxInputCheckbox;