import { createSelector } from 'reselect';
import { MEMBERTRANSFER } from './MemberTransferTypes';

const getIsMemberTransferPopupOpen = state => state.memberTransferReducer.get('isMemberTransferPopupOpen');
const getSelectedMembers = state => state.memberListReducer.get('selectedMembers');
const getMembersTransferResult = state => state.memberTransferReducer.get('membersTransferResult');

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === MEMBERTRANSFER
    ).size;

    return size > 0;
};

export default createSelector(
    [getIsMemberTransferPopupOpen, getIsLoading, getSelectedMembers, getMembersTransferResult],
    (isMemberTransferPopupOpen, isLoading, selectedMembers, membersTransferResult) => ({
        isMemberTransferPopupOpen,
        isLoading,
        selectedMembers,
        membersTransferResult
    }));
