import * as types from './managePartnerTypes';
import * as managePartnerService from './managePartnerService';
import * as loadingActions from '../../Loading/loadingActions';
import {
    createPartnerSuccess,
    updatePartnersSuccess
} from '../../Partners/PartnersList/partnersListActions';
import { setPartnerForPartnerOwnerArea } from '../../PartnerOwnerArea/partnerOwnerAreaActions';

const updatePartner = (partner) => {
    return { type: types.UPDATE_PARTNER, partner };
};

export function savePartner(partner) {
    return function(dispatch, getState) {
        dispatch(loadingActions.setLoading(types.SAVE_PARTNER));
        return managePartnerService
            .savePartner(partner)
            .then((result) => {
                if (result.success) {
                    if (partner.id === 0) {
                        dispatch(createPartnerSuccess(result.data));
                    } else {
                        dispatch(updatePartner(result.data));
                        dispatch(updatePartnersSuccess(result.data));
                        dispatch(setPartnerForPartnerOwnerArea(result.data));
                    }
                }
            })
            .catch((errorResponse) => {
                dispatch(updatePartner(errorResponse.data.data));
                throw errorResponse;
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.SAVE_PARTNER));
            });
    };
}

export function getPartner(partnerId) {
    return function(dispatch, getState) {
        dispatch(loadingActions.setLoading(types.GET_PARTNER));
        return managePartnerService
            .getPartner(partnerId)
            .then((partner) => {
                dispatch(updatePartner(partner));
                dispatch(setPartnerForPartnerOwnerArea(partner));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_PARTNER));
            });
    };
}
