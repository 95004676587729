import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { API_PATHS } from '../../../http/configs';
import { connect } from 'react-redux';
import selectors from './dashboardSelectors';
import { setDashboardIsLoading } from './dashboardActions';

function Dashboard({ isLoading, setDashboardIsLoading }) {
    useEffect(() => {
        setDashboardIsLoading();
        window.location.href = API_PATHS.HCDashboard;
    }, []);
    return isLoading
        ? <Spinner />
        : null;
}

Dashboard.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    setDashboardIsLoading: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    setDashboardIsLoading
};

export default connect(selectors, mapDispatchToProps)(Dashboard);
