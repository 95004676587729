import { createSelector } from 'reselect';
import { GET_FACILITIES_FOR_MAP } from './facilityLocatorTypes';


const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_FACILITIES_FOR_MAP
    ).size;
    return size > 0;
};

const getFacilities = state => state.facilityLocatorReducer.get('facilities');

export default createSelector(
    [
        getIsLoading,
        getFacilities
    ],
    (isLoading, facilities) => ({
        isLoading,
        facilities
    })
);
