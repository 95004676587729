import React from 'react';
import PropTypes from 'prop-types';
import EditableCell from '../../components/InfoBox/EditableCell';
import { amountToBeSentRequired, amountToBeSentIsDecimalWithTwoPlaces, amountToBeSentLessThanTotalAmount, amountToBeSentIsPositive, amountToBeSentNumeric } from './editAmountToBeSentValidation';
import wellnessProgramPaymentsResources from './wellnessProgramPaymentsResources';
import paymentStatuses, { paymentStatusesNamesMap } from '../../enums/paymentStatuses';

function EditAmountToBeSentEditableCell({ value,
    row,
    column,
    updateCellHandler }) {
    const paymentStatus = row.values[wellnessProgramPaymentsResources.accessorStatus];
    const isEditable = row && (paymentStatus === paymentStatusesNamesMap[paymentStatuses.pending]
        || paymentStatus === paymentStatusesNamesMap[paymentStatuses.returnedByUSBank] || paymentStatus === paymentStatusesNamesMap[paymentStatuses.sentExternally]);
    return (
        <EditableCell value={value} row={row}
            column={column} updateCellHandler={updateCellHandler}
            validateCellRules={[amountToBeSentRequired, amountToBeSentNumeric, amountToBeSentIsPositive, amountToBeSentIsDecimalWithTwoPlaces, amountToBeSentLessThanTotalAmount]}
            isEditable={isEditable}
        />
    );
}

EditAmountToBeSentEditableCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    updateCellHandler: PropTypes.func.isRequired,
};

export default EditAmountToBeSentEditableCell;