import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect, generatePath } from 'react-router-dom';
import { routeType } from '../../../routing/routes';
import CustomRoute from '../../../layouts/CustomRoute';

function PartnerManagersTab({ currentRoute, match }) {
    const { routes } = currentRoute;
    const routesForNavigation = routes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });
    return (
        <Switch>
            {routes.map((route, i) => {
                return <CustomRoute
                    key={i}
                    path={route.path}
                    currentRoute={route}
                    filteredRoutes={routesForNavigation}
                       />;
            })}

            <Redirect from={currentRoute.path} to={routesForNavigation[0].path} />
        </Switch>
    );
}

PartnerManagersTab.propTypes = {
    currentRoute: routeType.isRequired,
    match: PropTypes.object.isRequired,
};

export default PartnerManagersTab;
