import { createSelector } from 'reselect';
import { CHECK_ACH_UPLOAD_AVAILABLE } from './wellnessProgramPaymentsTypes';
import { GET_PROGRAM_TYPES } from '../ProgramTypes/programTypeTypes';
import programTypesEnum from '../../enums/programTypes';
import programPaymentTypesEnum from '../../enums/programPaymentTypes';
import paymentTypesEnum from '../../enums/paymentTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CHECK_ACH_UPLOAD_AVAILABLE || i === GET_PROGRAM_TYPES
    ).size;

    return size > 0;
};

const getIsAchUploadAvailable = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'isAchUploadAvailable']);
const getAchUploadErrorMessage = state => state.wellnessProgramPaymentsReducer.getIn(['achUploadAvailability', 'errorMessage']);
const getProgramTypes = state => state.programTypeReducer.get('programTypes');
const getDefaultProgramType = createSelector([getProgramTypes], programTypes => programTypes.find(x => x.get('id') === programTypesEnum.groupMemberships));
const getProgramPaymentTypes = state => state.programPaymentTypeReducer.get('programPaymentTypes');
const getDefaultProgramPaymentType = createSelector([getProgramPaymentTypes], programPaymentTypes => programPaymentTypes.find(x => x.get('id') === programPaymentTypesEnum.partnerPaid));
const getDefaultPaymentType = state => state.paymentTypeReducer.get('paymentTypes').filter(pt => pt.get('id') === paymentTypesEnum.gmPartnerFees)
    .first();

export default createSelector(
    [getIsLoading, getIsAchUploadAvailable, getAchUploadErrorMessage, getProgramPaymentTypes, getProgramTypes, getDefaultProgramType, getDefaultProgramPaymentType, getDefaultPaymentType],
    (isLoading, isAchUploadAvailable, achUploadErrorMessage, programPaymentTypes, programTypes, defaultProgramType, defaultProgramPaymentType, defaultPaymentType) => ({
        isLoading,
        isAchUploadAvailable,
        achUploadErrorMessage,
        programPaymentTypes,
        programTypes,
        defaultProgramType,
        defaultProgramPaymentType,
        defaultPaymentType
    })
);