import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SidebarItem from './SidebarItem';
import { routeType } from '../../routing/routes';
import './_sidebar.scss';
import ToolTip from '../controls/ToolTip';
import classNames from 'classnames';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import SidebarResources from './SidebarResources';
import SharedResources from '../../features/Shared/resources';

const Sidebar = (props) => {
    let { filteredRoutes } = props;
    const [isActive, setIsActive] = useState(false);

    filteredRoutes = filteredRoutes.filter(
        route => !route.isHiddenOnUI
    );

    return (
        <div className={classNames([SidebarResources.containerClassName, { [`${SidebarResources.containerClassName}--hidden`]: isActive }])}>
            <ToolTip title={isActive ? SharedResources.toggleShowToolTip : SharedResources.toggleHideToolTip} arrow
                placement="right" className={SidebarResources.tooltipClassName}
            >
                <button
                    className={classNames([
                        `${SidebarResources.defaultToggleBtnClassName} ${SidebarResources.defaultIconBtnClassName}`,
                        { [`${SidebarResources.defaultToggleBtnClassName}-reset`]: isActive }])}
                    onClick={() => setIsActive(!isActive)}
                >
                    <MaterialIcon icon={isActive ? 'chevron_right' : 'chevron_left'} className={SidebarResources.iconClassName} />
                </button>
            </ToolTip>
            <div className={classNames([SidebarResources.itemsClassName, { [`${SidebarResources.itemsClassName}--hidden`]: isActive }])}>
                {
                    filteredRoutes.map((item, i) => (
                        <SidebarItem key={i} {...item} />
                    ))
                }
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    isLoading: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    match: PropTypes.object
};

export default Sidebar;
