import validationRules from '../../utilities/validationRules';
import loginResources from './loginResources';

export const userNameOrEmailRequired = validationRules.required(
    loginResources.labelUsernameOrEmail
);

export const passwordRequired = validationRules.required(
    loginResources.labelPassword
);

