import * as loadingActions from '../Loading/loadingActions';
import * as types from './facilityTypes';
import * as facilityService from './facilityService';

const setFacilities = (facilities) => {
    return { type: types.SET_FACILITIES, facilities };
};

const setFacility = (facility) => {
    return { type: types.SET_FACILITY, facility };
};

export function getFacilitiesByProgramType(programTypeId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_FACILITIES));
        return facilityService
            .getFacilitiesByProgramType(programTypeId)
            .then((facilities) => {
                dispatch(setFacilities(facilities));
            })
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_FACILITIES))
            );
    };
}

export function getFacility(facilityId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_FACILITY));
        return facilityService
            .getFacility(facilityId)
            .then(facility =>
                dispatch(setFacility(facility))
            )
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_FACILITY))
            );
    };
}

export function getMemberFacility(memberGuid) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_FACILITY));
        return facilityService
            .getMemberFacility(memberGuid)
            .then(facility =>
                dispatch(setFacility(facility))
            )
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_FACILITY))
            );
    };
}