import { postNotification } from '../../../../../components/Notifications/actions';
import { ERROR } from '../../../../../components/Notifications/constants';
import * as types from './facilityLocatorMapTypes';

export const showGeolocationIsNotSupportedAlert = () => (dispatch) => {
    dispatch(postNotification({ type: ERROR, message: 'Geolocation is not supported by this browser.' }));
};

const setFacilitiesAction = initialFacilities => ({
    type: types.SET_FACILITIES,
    initialFacilities
});

export const setFacilities = initialFacilities => (dispatch) => {
    dispatch(setFacilitiesAction(initialFacilities));
};

const toggleSelectedFacilityAction = facilityId => ({
    type: types.TOGGLE_SELECTED_FACILITY,
    facilityId
});

export const toggleSelectedFacility = facilityId => (dispatch) => {
    dispatch(toggleSelectedFacilityAction(facilityId));
};

const setFacilitiesInMapBoundsAction = (googleMap, maxClusterZoom) => ({
    type: types.SET_FACILITIES_IN_MAP_BOUNDS,
    googleMap,
    maxClusterZoom
});

export const setFacilitiesInMapBounds = (googleMap, maxClusterZoom) => (dispatch) => {
    dispatch(setFacilitiesInMapBoundsAction(googleMap, maxClusterZoom));
};

const toggleIsListView = isListView => ({
    type: types.SET_LIST_VIEW_FOR_MAP,
    isListView
});

export const toggleListView = isListView => (dispatch) => {
    dispatch(toggleIsListView(!isListView));
};
