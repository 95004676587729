import { SET_PERIODS, SET_CLOSED_USAGE_PERIODS, SET_PERIODS_UNTIL_CURRENT } from './periodsTypes';
import initialState from './periodsInitialState';
import { fromJS } from 'immutable';

export default function periodsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PERIODS:
            return state.set('periods', fromJS(action.periods));
        case SET_CLOSED_USAGE_PERIODS:
            return state.set('closedUsagePeriods', fromJS(action.periods));
        case SET_PERIODS_UNTIL_CURRENT:
            return state.set('periodsUntilCurrent', fromJS(action.periods));
        default:
            return state;
    }
}
