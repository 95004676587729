import React, { useEffect, useRef, forwardRef } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import './_indeterminate-checkbox.scss';

export const IndeterminateCheckbox = forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const resolvedRef = ref || useRef();
        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);
        return <div className="indeterminate-checkbox">
            <Checkbox
                inputRef={resolvedRef}
                indeterminate={indeterminate}
                {...rest}
            />
        </div>;
    }
);
