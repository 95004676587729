import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router-dom';
import Spinner from '../../../../components/Spinner/Spinner';
import ManagePartnerManagerForm from '../../../Shared/PartnerManager/ManagePartnerManagerForm';
import {
    createManager,
    getManager
} from '../../../Shared/PartnerManager/managePartnerManagerActions';
import selectors from './addPartnerManagerSelectors';
import routes from '../../../../routing/routes';
import CardInfo from '../../../../components/CardInfo/CardInfo';
import addPartnerManagerResources from './addPartnerManagerResources';

function AddPartnerManagerPage({
    isLoading,
    manager,
    history,
    getManager,
    createManager,
    match
}) {
    const partnerId = match.params.partnerId;
    useEffect(() => {
        getManager();
    }, []);
    const partnerManagersPath = generatePath(
        routes.partnerManagers.path,
        match.params
    );
    function handleSave(manager) {
        manager = manager.toJS();
        manager.partnerId = partnerId;
        createManager(manager).then(() => {
            history.push(partnerManagersPath);
        });
    }
    const backToPartnerManagers = function() {
        history.push(partnerManagersPath);
    };
    return isLoading
        ? (

            <Spinner />
        )
        : (
            <CardInfo title={addPartnerManagerResources.titleAddPartnerManager}>
                <div className="col-xs-12 col-lg-6">
                    <ManagePartnerManagerForm
                        onSubmit={handleSave}
                        initialValues={manager}
                        onCancel={backToPartnerManagers}
                    />
                </div>
            </CardInfo>
        );
}

AddPartnerManagerPage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    manager: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    createManager: PropTypes.func.isRequired,
    getManager: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired
};

const mapDispatchToProps = {
    createManager,
    getManager
};
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(AddPartnerManagerPage);
