const GroupMembershipResources = {
    labelUsagePeriod: 'Usage Period',
    labelVisitMonth: 'Visit Month',
    labelNumberOfMembershipsPaid: 'Number of Memberships Paid',
    labelDuesPaidForOneMember: 'Dues paid for 1 member',
    labelTotalPayment: 'Total Payment',
    labelPartnerAmountToBeSent: 'Amount to be Withdrawn',
    labelFacilityAmountToBeSent: 'Amount to be Deposited',
    labelPayeeToken: 'Payee Token',
    labelBillingInfo: 'Billing Info',
    labelStatus: 'Status',
    labelStatusDate: 'Date',
    labelMonthlyInstallment: 'Monthly Installment',
    labelProgramFee: 'Program Fee',
    labelAdminFee: 'Admin Fee',
    labelStartupFee: 'Startup Fee',
    labelRenewalFee: 'Renewal Fee',
    labelFacilityAccessFee: 'Facility Access Fee',
    labelOneTimeFee: 'One-Time Fee',
    labelOneTimeFeeReason: 'One-Time Fee Reason',
    labelBillingType: 'Billing Type'
};
export default GroupMembershipResources;
