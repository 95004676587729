import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function savePartner(partner) {
    return http.post(API_PATHS.savePartner, partner);
}

export function getPartner(partnerID) {
    return http.get(API_PATHS.getPartner, {
        params: { partnerID: partnerID ? partnerID : null }
    });
}
