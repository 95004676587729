const PREFIX = 'MEMBER_PAYMENTS_';

export const GET_PARTNERS = `${PREFIX}GET_PARTNERS`;
export const SET_PARTNERS = `${PREFIX}SET_PARTNERS`;
export const SET_PERIOD_ID = `${PREFIX}SET_PERIOD_ID`;
export const SET_PROGRAM_PAYMENT_TYPE_ID = `${PREFIX}SET_PROGRAM_PAYMENT_TYPE_ID`;
export const GET_PAYMENTS = `${PREFIX}GET`;
export const SET_PAYMENTS = `${PREFIX}SET`;
export const CHECK_PAYMENTS_PROCESSED = `${PREFIX}CHECK_PAYMENTS_PROCESSED`;
export const SEND_PAYMENTS = `${PREFIX}SEND_PAYMENTS`;
export const SET_POPUP_DATA = `${PREFIX}SET_POPUP_DATA`;
export const GET_PAYMENT_TYPES = `${PREFIX}_GET_PAYMENT_TYPES`;
export const SET_PAYMENT_TYPES = `${PREFIX}_SET_PAYMENT_TYPES`;