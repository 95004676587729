import * as types from './facilityPaymentsSectionTypes';
import initialState from './facilityPaymentsSectionInitialState';
import { fromJS } from 'immutable';
import wellnessProgramPaymentsResources from '../wellnessProgramPaymentsResources';

export default function(state = initialState, action) {
    switch (action.type) {
        case types.SET_PAYMENTS:
            return state.set('payments', fromJS(action.payments));
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.setIn(['paymentsFilter', 'programPaymentType'], fromJS(action.programPaymentType));
        case types.SET_PROGRAM_TYPE:
            return state.setIn(['paymentsFilter', 'programType'], fromJS(action.programType));
        case types.SET_PARTNER:
            return state.setIn(['paymentsFilter', 'partner'], fromJS(action.partner));
        case types.SET_PERIOD:
            return state.setIn(['paymentsFilter', 'period'], fromJS(action.period));
        case types.SET_SHOW_CORRECTED_ONLY:
            return state.setIn(['paymentsFilter', 'showCorrectedOnly'], fromJS(action.value));
        case types.SET_AMOUNT_TO_BE_SENT: {
            const payments = state.get('payments');
            const indexOfPaymentToUpdateAmount = payments.findIndex(payment => payment.get('id') === action.paymentId);
            return state.setIn(['payments', indexOfPaymentToUpdateAmount, 'amountToBeSent'], action.amountToBeSent);
        }
        case types.UNDO_AMOUNT_CORRECTION: {
            const payments = state.get('payments');
            const paymentPredicate = payment => payment.get('id') === action.paymentId;
            const paymentToUndoAmountCorrection = payments.find(paymentPredicate);
            const indexOfPaymentToUndoAmountCorrection = payments.findIndex(paymentPredicate);
            return state.setIn(['payments', indexOfPaymentToUndoAmountCorrection, 'amountToBeSent'], paymentToUndoAmountCorrection.get(wellnessProgramPaymentsResources.accessorTotalPayments));
        }
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        default:
            return state;
    }
}
