import * as loadingActions from '../../../../Loading/loadingActions';
import * as legalContractFormService from './agreementFormService';
import * as types from './agreementFormTypes';

export const setEquipmentAndFacilitiesPolicies = (equipmentAndFacilitiesPolicies) => {
    return { type: types.GET_EQUIPMENT_AND_FACILITIES_POLICIES, equipmentAndFacilitiesPolicies };
};

export function getEquipmentAndFacilitiesPolicies(documentAgreementVersion, programPaymentType, facilityAFNumber) {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_EQUIPMENT_AND_FACILITIES_POLICIES));
        return legalContractFormService
            .getEquipmentAndFacilitiesPolicies(documentAgreementVersion, programPaymentType, facilityAFNumber)
            .then((equipmentAndFacilitiesPolicies) => {
                dispatch(setEquipmentAndFacilitiesPolicies(equipmentAndFacilitiesPolicies));
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.GET_EQUIPMENT_AND_FACILITIES_POLICIES)));
    };
}