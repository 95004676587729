import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CardInfo from '../../../components/CardInfo/CardInfo';
import Spinner from '../../../components/Spinner/Spinner';
import actions from './changePasswordActions';
import ChangePasswordForm from './ChangePasswordForm';
import changePasswordSelectors from './changePasswordSelectors';
import WrappedConfirmPopup from '../../../components/Popups/ConfirmAlert/WrappedConfirmPopup';

function ChangePassword({ isLoading, changePassword }) {
    function handleSave(changePasswordModel) {
        WrappedConfirmPopup({
            body: (
                <>
                    <div className="message">You will be logged out.</div>
                    <div className="message">Please confirm to procceed.</div>
                </>
            ),
            onConfirm: () => {
                changePassword(changePasswordModel);
            }
        });
    }

    return isLoading ?
        (
            <Spinner />
        )
        : (
            <CardInfo>
                <div className="col-xs-12 col-lg-6">
                    <ChangePasswordForm onSubmit={handleSave} />
                </div>
            </CardInfo>
        );
}

ChangePassword.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    changePassword: PropTypes.func.isRequired
};

export default connect(changePasswordSelectors, actions)(ChangePassword);
