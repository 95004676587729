import { createSelector } from 'reselect';
import { CHECK_PAYMENTS_EXISTENCE, GENERATE_PAYMENTS, GET_PARTNERS } from './partnerPaymentsGenerationSectionTypes';
import partnerPaymentsGenerationSectionResources from './partnerPaymentsGenerationSectionResources';
import { format } from 'react-string-format';
import partnerProgramNameFormatter from '../../../utilities/formatters/partnerProgramNameFormatter';
import { GET_CLOSED_USAGE_PERIODS, GET_PERIODS } from '../../Shared/Periods/periodsTypes';
import programPaymentTypesEnum from '../../../enums/programPaymentTypes';
import paymentTypesEnum from '../../../enums/paymentTypes';
import wellnessProgramPaymentResources from '../../WellnessProgramPayments/wellnessProgramPaymentsResources';
import { formValueSelector } from 'redux-form';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CHECK_PAYMENTS_EXISTENCE || i === GENERATE_PAYMENTS || i === GET_PARTNERS || i === GET_PERIODS || i === GET_CLOSED_USAGE_PERIODS
    ).size;

    return size > 0;
};
const getSelf = state => state;
const getPartners = state => state.partnerPaymentsGenerationSectionReducer.get('partners');
const getPaymentsGenerationSettings = state => state.partnerPaymentsGenerationSectionReducer.get('paymentsGenerationSettings');
const getForm = formValueSelector(wellnessProgramPaymentResources.formPartnerPaymentsGeneration, state => state.form.toJS());
const getSelectedProgramTypeID = createSelector([getPaymentsGenerationSettings], settings => settings.getIn(['programType', 'id']));
const getSelectedProgramPaymentTypeID = (state) => {
    const selectedProgramPaymentType = getForm(state, wellnessProgramPaymentResources.fieldProgramPaymentType);
    if (selectedProgramPaymentType) {
        return selectedProgramPaymentType.id;
    }
};
const getSelectedPeriodID = createSelector([getPaymentsGenerationSettings], settings => settings.getIn(['period', 'id']));
const getSelectedPartnerID = createSelector([getPaymentsGenerationSettings], settings => settings.getIn(['partner', 'id']));
const getDoPaymentsExist = state => state.partnerPaymentsGenerationSectionReducer.get('doPaymentsExist');
const getPaymentsFees = state => state.partnerPaymentsGenerationSectionReducer.get('paymentsFees');
const getIsGenerationWithFees = state => state.partnerPaymentsGenerationSectionReducer.get('isGenerationWithFees');
const getisAdditionalFeesPopupVisible = state => state.partnerPaymentsGenerationSectionReducer.get('isAdditionalFeesPopupVisible');
const getConfirmationMessage = createSelector([getSelectedPartnerID, getPartners], (selectedPartnerID, partners) => {
    if (partners && partners.size > 0 && selectedPartnerID) {
        const selectedPartner = partners.find(x => x.get('id') === selectedPartnerID);
        if (selectedPartner) {
            const partnerName = selectedPartner.get('name');
            return format(partnerPaymentsGenerationSectionResources.partnerPaymentConfirmationMessageFormat, partnerProgramNameFormatter.formatGroupMembershipsPartnerName(partnerName));
        }
    }
    return '';
});
const getPaymentTypes = state => state.partnerPaymentsGenerationSectionReducer.get('paymentTypes');
const getSelectedPaymentTypeID = (state) => {
    const selectedPaymentType = getForm(state, wellnessProgramPaymentResources.fieldPaymentType);
    if (selectedPaymentType) {
        return selectedPaymentType.id;
    }
};
const getPeriods = createSelector([getSelectedProgramPaymentTypeID, getSelectedProgramTypeID, getSelectedPaymentTypeID, getSelf], (selectedProgramPaymentTypeID, selectedProgramTypeID, selectedPaymentTypeID, state) => {
    if (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerPaid
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.memberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerReimbursements)
        || (selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid && selectedPaymentTypeID === paymentTypesEnum.gmPartnerDues)) {
        return state.periodsReducer.get('closedUsagePeriods');
    }
    else if (selectedProgramPaymentTypeID === programPaymentTypesEnum.memberPaid || selectedProgramPaymentTypeID === programPaymentTypesEnum.partnerMemberPaid) {
        return state.periodsReducer.get('periodsUntilCurrent');
    }
    else if (selectedProgramTypeID) {
        return state.periodsReducer.get('closedUsagePeriods');
    }
});
const getMessage = createSelector([getPeriods, getSelectedPeriodID, getSelectedPartnerID, getPartners], (periods, selectedPeriodID, selectedPartnerID, partners) => {
    if (periods && selectedPeriodID && partners && selectedPartnerID) {
        return partnerPaymentsGenerationSectionResources.partnerPaymentWarningMessageFormat;
    }
    return '';
});

export default createSelector(
    [
        getIsLoading,
        getPaymentsGenerationSettings,
        getPartners,
        getDoPaymentsExist,
        getMessage,
        getSelectedProgramPaymentTypeID,
        getSelectedPeriodID,
        getIsGenerationWithFees,
        getPaymentsFees,
        getSelectedPartnerID,
        getConfirmationMessage,
        getisAdditionalFeesPopupVisible,
        getPeriods,
        getSelectedProgramTypeID,
        getPaymentTypes,
        getSelectedPaymentTypeID
    ],
    (
        isLoading,
        paymentsGenerationSettings,
        partners,
        doPaymentsExist,
        message,
        selectedProgramPaymentTypeID,
        selectedPeriodID,
        isGenerationWithFees,
        paymentsFees,
        selectedPartnerID,
        confirmationMessage,
        isAdditionalFeesPopupVisible,
        periods,
        selectedProgramTypeID,
        paymentTypes,
        selectedPaymentTypeID
    ) => ({
        isLoading,
        paymentsGenerationSettings,
        partners,
        doPaymentsExist,
        message,
        selectedProgramPaymentTypeID,
        selectedPeriodID,
        isGenerationWithFees,
        paymentsFees,
        selectedPartnerID,
        confirmationMessage,
        isAdditionalFeesPopupVisible,
        periods,
        selectedProgramTypeID,
        paymentTypes,
        selectedPaymentTypeID
    })
);