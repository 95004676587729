import React from 'react';
import { Route } from 'react-router-dom';

const CustomRoute = ({ currentRoute, filteredRoutes, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return currentRoute.layout ?
                    (
                        React.createElement(
                            currentRoute.layout,
                            {
                                topbarContent: currentRoute.topbarContent,
                                hideSidebar: currentRoute.hideSidebar,
                                filteredRoutes,
                                ...props,
                            },
                            React.createElement(currentRoute.component, {
                                currentRoute,
                                ...props,
                            })
                        ))
                    : (
                        React.createElement(currentRoute.component, {
                            currentRoute,
                            ...props,
                        })
                    );
            }}
        />
    );
};

export default CustomRoute;
