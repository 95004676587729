import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

function CustomLink({ className, linkPath, children, isExternalLink, isNewTab }) {
    return <>
        {
            isExternalLink
                ?
                    <a className={className} href={linkPath} target={isNewTab ? '_blank' : ''}>
                        {children}
                    </a>
                :
                    <NavLink className={className} to={linkPath} target={isNewTab ? '_blank' : ''}>
                        {children}
                    </NavLink>
        }
    </>;
};

CustomLink.propTypes = {
    className: PropTypes.string.isRequired,
    linkPath: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    isExternalLink: PropTypes.bool,
    isNewTab: PropTypes.bool
};

export default CustomLink;