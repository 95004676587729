import http from '../../http/base';
import { API_PATHS } from '../../http/configs';

export function getMembersList(pageNumber, pageSize, facilityID, sortBy, sortOrder, filter) {
    return http.get(API_PATHS.members,  { params: { page: pageNumber, pageSize, sortBy, sortOrder, facilityID, filter } });
}

export function deactivateMember(memberID) {
    return http.post(API_PATHS.deactivateMember, null, { params: { memberID, allPlansShouldBeIneligible: false } });
}
