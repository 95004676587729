import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsForgotPasswordResultPopupOpen } from './forgotPasswordActions';
import CustomPopup from '../Popups/CustomPopup/CustomPopup';
import ForgotPasswordResultBody from './ForgotPasswordResultBody';
import forgotPasswordResources from './forgotPasswordResources';
import selectors from './forgotPasswordSelectors';

function ForgotPasswordResultPopup({ isForgotPasswordResultPopupOpen, setIsForgotPasswordResultPopupOpen, isLoading }) {
    return <CustomPopup
        isLoading={isLoading}
        showPopup={isForgotPasswordResultPopupOpen}
        onCancel={() => setIsForgotPasswordResultPopupOpen(false)}
        title={forgotPasswordResources.resultTitle}
        subtitle={forgotPasswordResources.resultSubtitle}
        body={<ForgotPasswordResultBody closePopup={() => setIsForgotPasswordResultPopupOpen(false)}/>}
           />;
}

ForgotPasswordResultPopup.propTypes = {
    isForgotPasswordResultPopupOpen: PropTypes.bool.isRequired,
    setIsForgotPasswordResultPopupOpen: PropTypes.func,
    isLoading: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
    setIsForgotPasswordResultPopupOpen,
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(ForgotPasswordResultPopup);