import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './_mui-info-box.scss';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

const MuiInfoBox = (props) => {
    const {
        title, className, onEdit, name, bordered, defaultExpanded, showEditButton, expandable
    } = props;
    const [expanded, setExpanded] = useState(defaultExpanded);

    const handleClick = (event) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };
    return (
        <Accordion
            expanded={(expandable && expanded) || !expandable}
            className={classNames(
                ['mui-info-box',
                    { 'mui-info-box_bordered': bordered },
                    { [className]: className }])}
        >
            <AccordionSummary
                expandIcon={
                    expandable ?
                        <MaterialIcon role={'button'}
                            icon={'keyboard_double_arrow_down'}
                            onClick={handleClick}
                        />
                        : undefined}
                id={`${name}-header"`}
            >
                {title &&
                    <h6 aria-controls={`${name}_content`}
                        className="mui-info-box__title"
                        onClick={expandable ? handleClick : undefined}
                    >
                        {title}
                    </h6>}
                {showEditButton &&
                    <MaterialIcon icon="edit"
                        variant="filled"
                        className="mui-info-box__edit-icon"
                        onClick={onEdit}
                    />}
            </AccordionSummary>
            <AccordionDetails className="mui-info-box__content">
                {props.children}
            </AccordionDetails>
        </Accordion>
    );
};

MuiInfoBox.defaultProps = {
    name: 'defaultPanelName',
    bordered: false,
    defaultExpanded: true,
    showEditButton: false,
    expandable: false
};

MuiInfoBox.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node,
    title: PropTypes.string,
    bordered: PropTypes.bool,
    className: PropTypes.string,
    defaultExpanded: PropTypes.bool,
    showEditButton: PropTypes.bool,
    expandable: PropTypes.bool,
    onEdit: PropTypes.func
};

export default MuiInfoBox;
