import validationRules from '../../../../../utilities/validationRules';
import agreementFormResources from './agreementFormResources';
import * as generalInfoConstants from '../../../../Shared/GeneralInfo/generalInfoConstants';

export const isMemberAgreedRequired = validationRules.required(
    agreementFormResources.labelIsMemberAgreed, agreementFormResources.customValidationMessagelabelIsMemberAgreed
);
export const firstNameRequired = validationRules.required(
    agreementFormResources.labelFirstName
);
export const firstNameMaxLength = validationRules.length(
    agreementFormResources.labelFirstName,
    0,
    generalInfoConstants.MAX_FIRSTNAME_LENGTH
);
export const lastNameRequired = validationRules.required(
    agreementFormResources.labelLastName
);
export const lastNameMaxLength = validationRules.length(
    agreementFormResources.labelLastName,
    0,
    generalInfoConstants.MAX_LASTNAME_LENGTH
);
export const phoneNumberRequired = validationRules.required(
    agreementFormResources.labelContactNumber
);
export const phoneNumberMaxLength = validationRules.length(
    agreementFormResources.labelContactNumber,
    0,
    generalInfoConstants.MAX_PHONE_NUMBER_LENGTH
);
export const phoneNumberRegex = validationRules.phoneNumber(
    agreementFormResources.labelContactNumber
);
export const emailAddressRequired = validationRules.required(
    agreementFormResources.labelEmailAddress
);
export const emailAddressMaxLength = validationRules.length(
    agreementFormResources.labelEmailAddress,
    0,
    generalInfoConstants.MAX_EMAIL50_LENGTH
);
export const emailAddressRegex = validationRules.email(
    agreementFormResources.labelEmailAddress
);