import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, generatePath } from 'react-router-dom';
import selectors from './partnerOwnerAreaSelectors';
import routes, { routeType } from '../../routing/routes';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import CustomRoute from '../../layouts/CustomRoute';
import Spinner from '../../components/Spinner/Spinner';
import { getPartner } from '../Shared/Partners/managePartnerActions';
import { useFlags } from 'launchdarkly-react-client-sdk';

function PartnerOwnerArea({
    isLoading,
    history,
    match,
    currentRoute,
    shouldRedirect,
    getPartner,
    isGroupMembershipAddedToPartner
}) {
    useEffect(() => {
        getPartner(match.params.partnerId)
            .catch(() => {
                history.push(routes.partnersList.path);
            });
    }, [match.params.partnerId]);
    const { gmrAddProgram } = useFlags();
    const conditionedRoutes = currentRoute.routes.filter(
        (route) => {
            switch (route.path) {
                case routes.groupMemberships.path:
                    return isGroupMembershipAddedToPartner || gmrAddProgram;
                default:
                    return true;
            }
        }
    );
    const routesForNavigation = conditionedRoutes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });
    return <>
        {
            isLoading
                ? <Spinner />
                : <Switch>
                    {conditionedRoutes.map((route, i) => (
                        <CustomRoute
                            key={i}
                            path={route.path}
                            currentRoute={route}
                            filteredRoutes={routesForNavigation}
                        />
                    ))}

                    <ConditionalRedirect
                        condition={shouldRedirect}
                        fromRoute={currentRoute}
                        toRoute={routesForNavigation[0]}
                    />
                </Switch>
        }
    </>;
};

PartnerOwnerArea.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    currentRoute: routeType.isRequired,
    shouldRedirect: PropTypes.bool,
    getPartner: PropTypes.func.isRequired,
    isGroupMembershipAddedToPartner: PropTypes.bool
};

const actions = {
    getPartner
};

export default connect(selectors, actions)(PartnerOwnerArea);
