import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

export function createManager(manager) {
    return http.post(API_PATHS.createManager, manager);
}

export function updateManager(manager) {
    return http.put(API_PATHS.updateManager, manager);
}

export function getManager(managerID) {
    return http.get(API_PATHS.getManager, {
        params: { managerID: managerID ? managerID : null }
    });
}
