import React from 'react';
import DashboardImagesSection from './DashboardImagesSection';
import DashboardDescriptionSection from './DashboardDescriptionSection';

function DashboardPartner() {
    return (
        <div className="dashboard">
            <h2 className="layout__title">Healthy Contributions Partner Portal</h2>
            <div className="dashboard__content">
                <div className="dashboard__description">
                    <p>
						Welcome to your own personal Healthy Contributions portal and
						dashboard. We are excited and pleased to have you here!
                    </p>
                    <p>
						We want you to have the best possible experience while navigating
						your HC portal. Your Healthy Contributions team is here to help you
						as well. Don’t hesitate to reach out!
                    </p>
                    <DashboardImagesSection />
                </div>
                <DashboardDescriptionSection />
            </div>
        </div>
    );
}
export default DashboardPartner;
