import React from 'react';
import PropTypes from 'prop-types';
import { NewArrowDown, NewArrowUp } from '../Icon/Icons';

function SortIcon({ isSortedDesc, ...props }) {
    const ArrowIcon = isSortedDesc ? NewArrowDown : NewArrowUp;
    return <span className="new-arrow" {...props}>{isSortedDesc == null ? <><NewArrowUp /><NewArrowDown /></> : <ArrowIcon/>}</span>;
}
SortIcon.propTypes = {
    isSortedDesc: PropTypes.bool,
    props: PropTypes.object
};

export default SortIcon;
