export const MIN_FULL_NAME_LENGTH = 0;
export const MAX_FULL_NAME_LENGTH = 50;
export const MIN_EMAIL_LENGTH = 0;
export const MAX_EMAIL_LENGTH = 300;
export const MIN_PROVIDER_NAME_LENGTH = 0;
export const MAX_PROVIDER_NAME_LENGTH = 300;
export const MIN_CLUB_NAME_LENGTH = 0;
export const MAX_CLUB_NAME_LENGTH = 300;
export const MIN_CITY_LENGTH = 0;
export const MAX_CITY_LENGTH = 50;
export const MIN_CONTACT_NAME_LENGTH = 0;
export const MAX_CONTACT_NAME_LENGTH = 300;
export const MIN_CONTACT_EMAIL_LENGTH = 0;
export const MAX_CONTACT_EMAIL_LENGTH = 300;