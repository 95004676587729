const paymentStatuses = Object.freeze({
    pending: 0,
    receivedByUSBank: 1,
    submittedToACH: 2,
    returnedByUSBank: 3,
    canceledByHC: 4,
    completed: 5,
    sentExternally: 6
});

export const paymentStatusesNamesMap = Object.freeze({
    [paymentStatuses.pending]: 'Pending',
    [paymentStatuses.receivedByUSBank]: 'Received by US Bank',
    [paymentStatuses.submittedToACH]: 'Submitted to ACH',
    [paymentStatuses.returnedByUSBank]: 'Returned by US Bank',
    [paymentStatuses.canceledByHC]: 'Canceled by HC',
    [paymentStatuses.completed]: 'Request completed',
    [paymentStatuses.sentExternally]: 'Sent Externally',
});

export const paymentStatusesColorsMap = Object.freeze({
    [paymentStatuses.pending]: 'yellow',
    [paymentStatuses.receivedByUSBank]: 'orange',
    [paymentStatuses.submittedToACH]: 'orange',
    [paymentStatuses.returnedByUSBank]: 'beet',
    [paymentStatuses.canceledByHC]: 'beet',
    [paymentStatuses.completed]: 'green',
    [paymentStatuses.sentExternally]: 'green',
});

export default paymentStatuses;
