import { postNotification } from '../components/Notifications/actions';
import { setIsLoginPopupOpen } from '../components/Login/loginActions';
import { ERROR, OK } from '../components/Notifications/constants';
import { resources } from './resources';
import { APPLICATION_JSON, APPLICATION_OCTET_STREAM } from '../constants/contentTypes';

export const handleSucceededResponse = dispatch => (response) => {
    /** Here we handle < 500 error codes treating those that have 'success: false' as error-ish, dispatching red toast
     *  Otherwise dispatching green toast but only if dealing with response from GET request.
     */
    const {
        status = 500,
        data = {},
        config: { method }
    } = response;
    if (status >= 400) {
        switch (status) {
            case 401: {
                dispatch(setIsLoginPopupOpen(true));
                break;
            }
            case 403: {
                dispatch(postNotification({ type: ERROR, message: resources.forbidenErrorMessage }));
                break;
            }
            case 404: {
                dispatch(postNotification({ type: ERROR, message: resources.notFoundErrorMessage }));
                break;
            }
            default: {
                dispatch(postNotification({ type: ERROR, message: data.message }));
            }
        }
        return Promise.reject(response);
    } else {
        if (!response.headers['content-type']) {
            return data;
        }
        if (response.headers['content-type'].includes(APPLICATION_JSON) && (
            method === 'post' || method === 'delete' || method === 'put' || method === 'patch')) {
            const success = data.success;
            const message = !data.message && !success ? resources.commonErrorMessage : data.message;
            if (message) {
                dispatch(postNotification({ type: success ? OK : ERROR, message }));
            }
            return success ? data : Promise.reject(response);
        } else if (response.headers['content-type'].includes(APPLICATION_OCTET_STREAM)) {
            const searchStart = 'filename=';
            const filename = response.headers['content-disposition']
                .split(';')
                .find(element => element.includes(searchStart))
                .replace(searchStart, '');
            if (filename.indexOf('.html') >= 0) {
                const newWindow = window.open();
                if (newWindow) {
                    newWindow.document.documentElement.innerHTML = response.data;
                }
            }
            else {
                const anchor = document.createElement('a');
                anchor.href = window.URL.createObjectURL(new Blob([response.data]));
                anchor.download = filename.slice(1, -1);
                anchor.click();
            }
        } else {
            return data;
        }
    }
};

export const handleFailedResponse = dispatch => (err) => {
    /** Here we handle > 500 codes (server errors) dispatching red toast*/
    dispatch(postNotification({ type: ERROR, message: 'Request failed. Your changes have not been saved.' }));
    return Promise.reject(err);
};
