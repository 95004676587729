import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import selectors from './partnerManagerVisitLogTabSelectors';
import VisitLogTab from './VisitLogTab';

function PartnerManagerVisitLogTab({ partnerId })
{
    return <VisitLogTab partnerID={partnerId}/>;
}

PartnerManagerVisitLogTab.propTypes = {
    partnerId: PropTypes.number.isRequired
};
const connectStateToProps = connect(selectors);
export default connectStateToProps(PartnerManagerVisitLogTab);