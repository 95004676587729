import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../../../../components/controls/FormGroup';
import { Field, reduxForm } from 'redux-form/immutable';
import { MuiInputText } from '../../../../../components/controls/MuiInputText';
import InputCheckbox from '../../../../../components/controls/InputCheckbox';
import Button from '../../../../../components/controls/Button';
import { defaultFormProperties } from '../../../../../utilities/form';
import legalContractFormResources from './legalContractFormResources';
import * as validationRules from './legalContractFormValidations';
import { connect } from 'react-redux';
import selectors from './legalContractFormSelectors';
import { getEquipmentAndFacilitiesPolicies } from './legalContractFormActions';
import Spinner from '../../../../../components/Spinner/Spinner';

function LegalContractForm({
    isLoading,
    equipmentAndFacilitiesPolicies,
    pristine,
    submitting,
    handleSubmit,
    readOnly,
    documentAgreementVersion,
    programPaymentType,
    facilityAFNumber,
    getEquipmentAndFacilitiesPolicies }) {
    useEffect(() => {
        getEquipmentAndFacilitiesPolicies(documentAgreementVersion, programPaymentType, facilityAFNumber);
    }, [documentAgreementVersion, programPaymentType, facilityAFNumber]);
    return isLoading
        ? <Spinner />
        : <FormGroup onSubmit={handleSubmit} >
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelKeyfob}
                        name="facilityMemberID"
                        component={MuiInputText}
                        validate={[
                            validationRules.keyfobRequired,
                            validationRules.keyfobMinMaxLength,
                            validationRules.keyfobValidRadixNumber,
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-9">
                    <div className="equipment-and-facility-policies__container" dangerouslySetInnerHTML={{ __html: equipmentAndFacilitiesPolicies }} />
                    <Field
                        id="is-agreed"
                        label={legalContractFormResources.labelIsMemberAgreed}
                        name="isMemberAgreed"
                        component={InputCheckbox}
                        validate={[
                            validationRules.isMemberAgreedRequired
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelFirstName}
                        name="firstName"
                        component={MuiInputText}
                        validate={[
                            validationRules.firstNameRequired,
                            validationRules.firstNameMaxLength
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelLastName}
                        name="lastName"
                        component={MuiInputText}
                        validate={[
                            validationRules.lastNameRequired,
                            validationRules.lastNameMaxLength
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelContactNumber}
                        name="contactNumber"
                        component={MuiInputText}
                        validate={[
                            validationRules.phoneNumberRequired,
                            validationRules.phoneNumberRegex,
                            validationRules.phoneNumberMaxLength
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelEmailAddress}
                        name="email"
                        component={MuiInputText}
                        validate={[
                            validationRules.emailAddressRequired,
                            validationRules.emailAddressRegex,
                            validationRules.emailAddressMaxLength
                        ]}
                        disabled={readOnly}
                    />
                </div>
            </div>
            <div className="grid__row">
                <div className="col-lg-3">
                    <Field
                        label={legalContractFormResources.labelDate}
                        name="date"
                        component={MuiInputText}
                        disabled
                    />
                </div>
            </div>
            {
                readOnly
                    ? (<></>)
                    : (<div className="grid__row">
                        <div className="col-lg-3">
                            <Button
                                className="btn_new btn_new--blue"
                                size="medium"
                                disabled={!readOnly && (pristine || submitting)}
                            >
                                {legalContractFormResources.labelButton}
                            </Button>
                        </div>
                    </div>)
            }
        </FormGroup>;
}

LegalContractForm.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    equipmentAndFacilitiesPolicies:PropTypes.string,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    getEquipmentAndFacilitiesPolicies: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    documentAgreementVersion: PropTypes.number.isRequired,
    programPaymentType: PropTypes.number.isRequired,
    facilityAFNumber: PropTypes.string.isRequired
};

const mapDispatchToProps = {
    getEquipmentAndFacilitiesPolicies
};

const connectStateToProps = connect(selectors, mapDispatchToProps);

export default reduxForm({
    form: 'legalContractForm',
    ...defaultFormProperties
})(connectStateToProps(LegalContractForm));
