import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getPartnerPayments(programPaymentTypeId, partnerId, periodIdFrom, periodIdTo) {
    return http.get(replaceRouteParams(API_PATHS.getPartnerSpecificPayments, programPaymentTypeId), { params: { partnerId, periodIdFrom, periodIdTo } });
};

export function dowloadPartnerPayments(partnerId, periodIdFrom, periodIdTo) {
    const config = {
        responseType: 'blob',
        params: { partnerId, periodIdFrom, periodIdTo }
    };
    return http.get(API_PATHS.downloadPartnerSpecificPayments, config);
};

export function getProgramPaymentTypeID(partnerID) {
    return http.get(API_PATHS.getProgramPaymentTypeID, { params: { partnerID } });
};