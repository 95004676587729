const SharedResources = {
    imageTextExportToExcel: 'Export to Excel',
    showButtonText: 'show',
    addButtonText: 'Add',
    saveButtonText: 'Save',
    saveChangesButtonText: 'Save Changes',
    updateButtonText: 'Update',
    cancelButtonText: 'Cancel',
    closeButtonText: 'Close',
    uploadButtonText: 'Upload',
    backButtonText: 'Back',
    editLabel: 'Edit',
    okLabel: 'Ok',
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    toggleShowToolTip: 'Expand side panel',
    toggleHideToolTip: 'Collapse side panel',
    resetButtonText: 'Reset',
    showMoreButtonText: '... show more',
    showLessButtonText: '... show less',
    applyButtonText: 'Apply',
    clearButtonText: 'Clear',
    downloadButtonText: 'Download'
};
export default SharedResources;
