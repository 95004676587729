import React from 'react';
import PropTypes from 'prop-types';

function BankingInfoStatus({ bankingInfo }) {
    const bankAccount = bankingInfo.get('bankAccount');
    if (!bankAccount) {
        return (
            <div className="red-text bold">
				Banking information is required. Please, update!
            </div>
        );
    }
    return <></>;
}

BankingInfoStatus.propTypes = {
    bankingInfo: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired
};

export default BankingInfoStatus;
