const prefix = 'PARTNER_PAYMENTS';
export const SET_PERIOD = `${prefix}_GENERATION_SET_PERIOD`;
export const SET_PROGRAM_TYPE = `${prefix}_GENERATION_SET_PROGRAM_TYPE`;
export const SET_PROGRAM_PAYMENT_TYPE = `${prefix}_GENERATION_SET_PROGRAM_PAYMENT_TYPE`;
export const GENERATE_PAYMENTS = `${prefix}_GENERATION_GENERATE`;
export const CHECK_PAYMENTS_EXISTENCE = `CHECK_${prefix}_EXISTENCE`;
export const SET_PAYMENTS_EXIST = `${prefix}_EXIST`;
export const GET_PARTNERS = `${prefix}_GET_PARTNERS`;
export const SET_PARTNERS = `${prefix}_SET_PARTNERS`;
export const SET_PARTNER = `${prefix}_SET_PARTNER`;
export const SET_IS_GENERATION_WITH_FEES = `${prefix}_IS_GENERATION_WITH_FEES`;
export const SET_IS_ADDITIONAL_FEES_POPUP_VISIBLE = `${prefix}_IS_ADDITIONAL_FEES_POPUP_VISIBLE`;
export const GET_PAYMENT_TYPES = `${prefix}_GET_PAYMENT_TYPES`;
export const SET_PAYMENT_TYPES = `${prefix}_SET_PAYMENT_TYPES`;
export const SET_PAYMENT_TYPE = `${prefix}_SET_PAYMENT_TYPE`;
