import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import MuiButton from '../../../components/controls/MuiButton';
import { defaultFormProperties } from '../../../utilities/form';
import InputSelect from '../../../components/controls/InputSelect';
import PartnerResources from './partnerResources';
import * as validationRules from './partnerValidation';
import { MuiTextArea } from '../../../components/controls/MuiTextArea';
import InfoBox from '../../../components/InfoBox/InfoBox';
import CheckBoxGroup from '../../../components/controls/CheckBoxGroup';
import routes from '../../../routing/routes';
import resources from '../../Shared/resources';
import { useFlags } from 'launchdarkly-react-client-sdk';

const ManagePartnerForm = ({
    pristine,
    submitting,
    handleSubmit,
    states,
    programTypes,
    history,
    isAdd
}) => {
    const backToPartners = function () {
        history.push(routes.partners.path);
    };
    const { gmrAddProgram } = useFlags();
    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className="grid__row">
                <div className="col-lg-6">
                    <InfoBox title="Company General Information" bordered>
                        <Field
                            label={PartnerResources.labelPartnerIdNumber}
                            name="partnerIDNumber"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerIdNumberRequired,
                                validationRules.partnerIdNumberLength,
                                validationRules.partnerIdNumberAlphanumeric
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelName}
                            name="name"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerNameRequired,
                                validationRules.partnerNameMaxLength
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelUSBankPartnerCode}
                            name="usBankCode"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerUSBankPartnerCodeRequired,
                                validationRules.partnerUSBankPartnerCodeFormat
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelIndustry}
                            name="industry"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerIndustryRequired,
                                validationRules.partnerIndustryMaxLength
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelAddress1}
                            name="address1"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerAddress1Required,
                                validationRules.partnerAddress1MaxLength
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelAddress2}
                            name="address2"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerAddress2MaxLength
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelCity}
                            name="city"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerCityRequired,
                                validationRules.partnerCityMaxLength
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelState}
                            name="state"
                            options={states}
                            getOptionLabel={option => option.get('name')}
                            getOptionValue={option => option.get('abbr')}
                            component={InputSelect}
                            validate={validationRules.partnerStateRequired}
                            isSearchable
                        />
                        <Field
                            label={PartnerResources.labelZipCode}
                            name="zipCode"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerZipCodeRequired,
                                validationRules.partnerZipCodeLength,
                                validationRules.partnerZipCodeNumeric
                            ]}
                        />
                        <Field
                            label={PartnerResources.labelCountry}
                            name="country"
                            component={MuiInputText}
                            validate={[
                                validationRules.partnerCountryRequired,
                                validationRules.partnerCountryMaxLength
                            ]}
                            disabled
                        />
                        <Field
                            label={PartnerResources.labelWebsite}
                            name="website"
                            component={MuiInputText}
                            validate={[validationRules.partnerWebsiteMaxLength]}
                        />
                    </InfoBox>
                </div>
                {programTypes && !gmrAddProgram && (
                    <div className="col-lg-6">
                        <InfoBox title="Programs" bordered>
                            <Field
                                component={CheckBoxGroup}
                                name="programTypes"
                                options={programTypes
                                    .map((pt) => {
                                        return {
                                            id: pt.get('id').toString(),
                                            name: pt.get('id').toString(),
                                            label: pt.get('name')
                                        };
                                    })
                                    .toJS()}
                                areDisabledCheckedInitials
                            />
                        </InfoBox>
                    </div>
                )}
                <div className="col-lg-6">
                    <div className="col">
                        <InfoBox title="Primary Contact Information" bordered>
                            <Field
                                label={PartnerResources.labelFirstName}
                                name="firstName"
                                component={MuiInputText}
                                validate={[
                                    validationRules.partnerFirstNameRequired,
                                    validationRules.partnerFirstNameMaxLength
                                ]}
                            />
                            <Field
                                label={PartnerResources.labelLastName}
                                name="lastName"
                                component={MuiInputText}
                                validate={[
                                    validationRules.partnerLastNameRequired,
                                    validationRules.partnerLastNameMaxLength
                                ]}
                            />
                            <Field
                                label={PartnerResources.labelPhoneNumber}
                                name="contactNumber"
                                component={MuiInputText}
                                validate={[
                                    validationRules.partnerPhoneNumberRequired,
                                    validationRules.partnerPhoneNumberFormat,
                                    validationRules.partnerPhoneNumberMaxLength
                                ]}
                            />
                            <Field
                                label={PartnerResources.labelEmailAddress}
                                name="email"
                                component={MuiInputText}
                                validate={[
                                    validationRules.partnerEmailAddressRequired,
                                    validationRules.partnerEmailAddressFormat,
                                    validationRules.partnerEmailAddressMaxLength
                                ]}
                            />
                        </InfoBox>
                    </div>
                    <div className="col">
                        <InfoBox title="Notes" bordered>
                            <Field
                                label={PartnerResources.labelNotes}
                                name="notes"
                                component={MuiTextArea}
                                validate={[validationRules.partnerNotesMaxLength]}
                                rows={'4'}
                            />
                        </InfoBox>
                    </div>
                </div>
            </div>
            <div className="grid__row">
                <div className="col-xs-12 col-md-3 col-lg-2">
                    <MuiButton
                        className="btn_new btn_new--blue"
                        size="medium"
                        type="submit"
                        disabled={pristine || submitting}
                    >
                        {resources.saveButtonText}
                    </MuiButton>
                </div>
                <div className="col-xs-12 col-md-3 col-lg-2">
                    {isAdd && (
                        <MuiButton
                            className="btn_new btn_new--blue"
                            size="medium"
                            type="button"
                            onClick={backToPartners}
                        >
                            {resources.cancelButtonText}
                        </MuiButton>
                    )}
                </div>
            </div>
        </FormGroup>
    );
};

ManagePartnerForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    states: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    programTypes: PropTypes.object,
    isAdd: PropTypes.bool
};

export default reduxForm({
    form: 'managePartnerForm',
    ...defaultFormProperties
})(ManagePartnerForm);
