import { fromJS } from 'immutable';
import programPaymentTypes, { programPaymentTypesFullNamesMap } from '../../../enums/programPaymentTypes';

export default fromJS({
    paymentsFilter: {
        period: {},
        partner: undefined,
        programPaymentType: fromJS({ id: Number(programPaymentTypes.partnerPaid), name: programPaymentTypesFullNamesMap[programPaymentTypes.partnerPaid] }),
        programType: {},
        showCorrectedOnly: false
    },
    payments: [],
    partners: []
});
