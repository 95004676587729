import validationRules from '../../../../../utilities/validationRules';
import keyfobsAndEmployeesListResources from '../keyfobsAndEmployeesListResources';
import * as keyfobConstants from '../../../../Shared/Keyfobs/keyfobConstants';

export const keyfobRequired = validationRules.required(
    keyfobsAndEmployeesListResources.labelKeyfob
);

export const keyfobMinMaxLength = validationRules.length(
    keyfobsAndEmployeesListResources.labelKeyfob,
    keyfobConstants.MIN_KEYFOB_LENGTH,
    keyfobConstants.MAX_KEYFOB_LENGTH
);

export const keyfobValidRadixNumber = validationRules.bigInt(
    keyfobConstants.KEYFOB_RADIX,
    keyfobsAndEmployeesListResources.labelKeyfob
);

export const newKeyfobNotEqualToOldKeyfob = validationRules.notEqualTo(
    'facilityMemberID',
    'New keyfob cannot match old keyfob.'
);