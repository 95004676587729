import { fromJS } from 'immutable';

export default fromJS({
    vouchers: [],
    recipientsFile: undefined,
    recipientsFileContentValidationErrors: [],
    voucherEmailPeview: '',
    isPublishPopupVisible: false,
    isVoucherProgramPublished: false,
    isPreviewPopupVisible: false
});
