import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';
import { GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER } from './partnerManagerAreaTypes';
import { GET_MANAGER } from '../Shared/PartnerManager/managePartnerManagerTypes';

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

const getIsGroupMembershipAddedToPartner = (state) => {
    return state.partnerManagerAreaReducer.get('isGroupMembershipAddedToPartner');
};

const selectFilteredRoutes = createSelector(
    [getIsGroupMembershipAddedToPartner, getRoutes],
    (isGroupMembershipAddedToPartner, routes) => {
        return routes.filter(
            (route) => {
                if (route.path === allRoutes.partnerManagerGroupMemberships.path) {
                    return isGroupMembershipAddedToPartner;
                }
                return true;
            }
        );
    }
);

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER || i === GET_MANAGER
    ).size;
    return size > 0;
};

const getIsLoadingOrRoutesNotFilteredYet = createSelector(
    [getIsGroupMembershipAddedToPartner, getIsLoading],
    (isGroupMembershipAddedToPartner, isLoading) => {
        return isGroupMembershipAddedToPartner === undefined || isLoading;
    }
);

const getMe = (state) => {
    return state.app.get('me');
};

const getUserId = createSelector(
    [getMe], (me) => {
        return me.userId;
    }
);

const getPartnerId = (state) => {
    return state.partnerManagerReducer.getIn(['manager', 'partnerID']);
};

export default createSelector(
    [
        getIsLoadingOrRoutesNotFilteredYet,
        selectFilteredRoutes,
        getUserId,
        getPartnerId
    ],
    (isLoading, filteredRoutes, userId, partnerId) => ({
        isLoading,
        filteredRoutes,
        userId,
        partnerId
    })
);
