import React from 'react';
import PropTypes from 'prop-types';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import './_warning-box.scss';

const WarningBox = (props) => {
    const {
        title,
        description
    } = props;
    return <div className="warning-box">
        <div className="warning-box__icon-container">
            <MaterialIcon className="warning-box__icon" icon="warning" />
        </div>
        <div className="warning-box__text-container">
            {title && <div className="warning-box__title">{title}</div>}
            <div className="warning-box__description">{description}</div>
        </div>
    </div>;
};

WarningBox.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string.isRequired
};

export default WarningBox;
