const FacilityPaymentsSectionResources = {
    accessorPartnerIdNumber: 'partnerIDNumber',
    accessorFacilityIdNumber: 'facilityIDNumber',
    accessorUsagePeriod: 'usagePeriod',
    accessorTotalFacilityPayments: 'totalPayment',
    accessorStatus: 'status',
    accessorPayeeToken: 'payeeToken',
    accessorStatusDate: 'paymentStatusDate',
    billingInfo: 'billingInfo'
};
export default FacilityPaymentsSectionResources;