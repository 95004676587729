export const CREATE_KEYFOBS = 'CREATE_KEYFOBS';
export const GET_KEYFOBS_LIST = 'GET_KEYFOBS_LIST';
export const SET_KEYFOBS_LIST = 'SET_KEYFOBS_LIST';
export const SET_MEMBER_TO_DEACTIVATE_MEMBERSHIP = 'SET_MEMBER_TO_DEACTIVATE_MEMBERSHIP';
export const SET_KEYFOB_TO_REASSIGN = 'SET_KEYFOB_TO_REASSIGN';
export const DELETE_KEYFOB = 'DELETE_KEYFOB';
export const DELETE_MEMBERSHIP = 'DELETE_MEMBERSHIP';
export const DEACTIVATE_MEMBERSHIP = 'DEACTIVATE_MEMBERSHIP';
export const REASSIGN_KEYFOB = 'REASSIGN_KEYFOB';
export const GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED = 'GET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED';
export const SET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED = 'SET_IS_REASSIGN_KEYFOB_FEATURE_ENABLED';
export const SET_MEMBER_TO_BAN_KEYFOB = 'SET_MEMBER_TO_BAN_KEYFOB';
export const SET_MEMBER_TO_DELETE = 'SET_MEMBER_TO_DELETE';
export const GET_KEYFOBS_EMPLOYEES_REPORT = 'GET_KEYFOBS_EMPLOYEES_REPORT';
export const SET_KEYFOBS_TOTAL_COUNT = 'SET_KEYFOBS_TOTAL_COUNT';
export const JOIN_KEYFOBS_LIST = 'JOIN_KEYFOBS_LIST';
export const UPLOAD_MEMBER_RECIPIENTS = 'UPLOAD_MEMBER_RECIPIENTS';
export const GET_MEMBERS_WAITING_TO_INVITE_COUNT = 'GET_MEMBERS_WAITING_TO_INVITE_COUNT';
export const SET_MEMBERS_WAITING_TO_INVITE_COUNT = 'SET_MEMBERS_WAITING_TO_INVITE_COUNT';
export const GET_PROGRAM_PAYMENT_TYPE_ID = 'GET_PROGRAM_PAYMENT_TYPE_ID';
export const SET_PROGRAM_PAYMENT_TYPE_ID = 'SET_PROGRAM_PAYMENT_TYPE_ID';
export const UPDATE_EMPLOYEE_DATA = 'UPDATE_EMPLOYEE_DATA';