import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import classNames from 'classnames';
import enclose from '../../utilities/enclose';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import { Close } from '../Icon/Icons';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import { ERROR } from './constants';

export function Notification(props) {
    const {
        notification,
        onClearNotification,
        displayTimeout,
        minAnimationTime,
        defaultAnimationTime
    } = props;
    const notificationType = notification.get('type');
    const [isAppeared, setIsAppeared] = useState(false);
    const [clearTimer, setClearTimer] = useState(null);
    const handleClearNotification = () => {
        setIsAppeared(false);
        if (clearTimer) {
            clearTimeout(clearTimer);
        }
        setTimeout(() => onClearNotification(notification.get('id')), getClearAnimationTime());
    };

    const getClearAnimationTime = () => {
        return displayTimeout
            ? enclose(displayTimeout, minAnimationTime, defaultAnimationTime)
            : defaultAnimationTime;
    };

    useEffect(() => {
        if (displayTimeout) {
            setClearTimer(setTimeout(handleClearNotification, displayTimeout));
        }
        setTimeout(() => setIsAppeared(true), 0);
    }, []);

    return (
        <div
            className={classNames([
                'notification',
                { 'notification__appeared': isAppeared },
                { [`notification__${notificationType}`]: notificationType }
            ])}
        >
            <MaterialIcon className="notification_status-icon" icon={notificationType === ERROR ? 'error_outline' : 'check_circle'}/>
            <span className="notification_message">{notification.get('message')}</span>
            <Icon
                className="notification_clear-icon"
                onClick={handleClearNotification}
                icon={<Close/>}
            />
        </div>
    );
}

Notification.defaultProps = {
    defaultAnimationTime: 400,
    minAnimationTime: 200
};

Notification.propTypes = {
    notification: PropTypes.instanceOf(Map),
    onClearNotification: PropTypes.func,
    displayTimeout: PropTypes.number,
    minAnimationTime: PropTypes.number,
    defaultAnimationTime: PropTypes.number
};
