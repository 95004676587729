import validationRules from '../../utilities/validationRules';
import groupMembershipResources from '../Shared/Programs/groupMembershipResources';
import wellnessProgramPaymentsResources from './wellnessProgramPaymentsResources';

export const amountToBeSentRequired = validationRules.required();

export const amountToBeSentNumeric = validationRules.numericOrEmpty();

export const amountToBeSentLessThanTotalAmount = validationRules.lessThanOrEqual(
    wellnessProgramPaymentsResources.accessorTotalPayments, '', groupMembershipResources.labelTotalPayment
);

export const amountToBeSentIsPositive = validationRules.greaterThanOrEqualSpecificValue(0);

export const amountToBeSentIsDecimalWithTwoPlaces = validationRules.decimalWithTwoPlaces();
