const VoucherProgramFormResources = {
    headerCreateVoucherProgram: 'voucher program',
    labelProgramName: 'Program Name',
    labelRedemptionAmount: 'Redemption Amount',
    labelStartDate: 'Start Date',
    labelEndDate: 'Expiration Date',
    recipientsButtonText: 'Recipients',
    partnerLogo: 'Upload Partner',
    existingPartnerLogo: 'Existing Partner:'
};
export default VoucherProgramFormResources;
