import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import InputText from '../controls/InputText';
import './_editable-cell.scss';
import { fromJS } from 'immutable';

function EditableCell({
    value: initialValue,
    row,
    column,
    updateCellHandler,
    validateCellRules,
    isEditable
}) {
    useEffect(() => {
        setValue(initialValue.toString());
    }, [initialValue]);
    const [value, setValue] = useState();
    const [validationError, setValidationError] = useState('');
    const onBlur = () => {
        let error = null;
        for (const validate of validateCellRules) {
            const errorMessage = validate(value, fromJS(row.original));
            if (errorMessage) {
                error = `${column.render('Header')} ${errorMessage}`;
			    break;
            }
        }
        setValidationError(error);
        if (!error) {
            updateCellHandler(row.index, column.id, value, row.original);
        }
    };
    const onChange = (e) => {
        setValue(e.target.value);
    };
    return <div className="editable-cell">
        {
            isEditable
                ? <InputText value={value || ''} onBlur={onBlur}
                    onChange={onChange}
                    error={validationError}
                    touched
                    noMargin
                    transparentBorder
                  />
                : <div className="editable-cell__plain-value">{value}</div>
        }
    </div>;
};

EditableCell.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    row: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    updateCellHandler: PropTypes.func.isRequired,
    validateCellRules: PropTypes.arrayOf(PropTypes.func),
    isEditable: PropTypes.bool.isRequired,
};

export default EditableCell;