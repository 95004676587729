import validationRules from '../../../utilities/validationRules';
import PartnerResources from './partnerResources';

export const partnerIdNumberRequired = validationRules.required(
    PartnerResources.labelPartnerIdNumber
);
export const partnerIdNumberLength = validationRules.length(
    PartnerResources.labelPartnerIdNumber,
    3,
    8
);
export const partnerIdNumberAlphanumeric = validationRules.alphanumeric(
    PartnerResources.labelPartnerIdNumber
);
export const partnerNameRequired = validationRules.required(
    PartnerResources.labelName
);
export const partnerNameMaxLength = validationRules.length(
    PartnerResources.labelName,
    null,
    300
);
export const partnerUSBankPartnerCodeRequired = validationRules.required(
    PartnerResources.labelUSBankPartnerCode
);
export const partnerUSBankPartnerCodeFormat = validationRules.usBankCode();
export const partnerIndustryRequired = validationRules.required(
    PartnerResources.labelIndustry
);
export const partnerIndustryMaxLength = validationRules.length(
    PartnerResources.labelIndustry,
    null,
    100
);
export const partnerAddress1Required = validationRules.required(
    PartnerResources.labelAddress1
);
export const partnerAddress1MaxLength = validationRules.length(
    PartnerResources.labelAddress1,
    null,
    150
);
export const partnerAddress2MaxLength = validationRules.length(
    PartnerResources.labelAddress2,
    null,
    150
);
export const partnerCityRequired = validationRules.required(
    PartnerResources.labelCity
);
export const partnerCityMaxLength = validationRules.length(
    PartnerResources.labelCity,
    null,
    50
);
export const partnerStateRequired = validationRules.required(
    PartnerResources.labelState
);
export const partnerZipCodeRequired = validationRules.required(
    PartnerResources.labelZipCode
);
export const partnerZipCodeNumeric = validationRules.numericOrEmpty(
    PartnerResources.labelZipCode
);
export const partnerZipCodeLength = validationRules.length(
    PartnerResources.labelZipCode,
    5,
    5
);
export const partnerCountryRequired = validationRules.required(
    PartnerResources.labelCountry
);
export const partnerCountryMaxLength = validationRules.length(
    PartnerResources.labelCountry,
    null,
    10
);
export const partnerWebsiteMaxLength = validationRules.length(
    PartnerResources.labelWebsite,
    null,
    100
);
export const partnerFirstNameRequired = validationRules.required(
    PartnerResources.labelFirstName
);
export const partnerFirstNameMaxLength = validationRules.length(
    PartnerResources.labelFirstName,
    null,
    50
);
export const partnerLastNameRequired = validationRules.required(
    PartnerResources.labelLastName
);
export const partnerLastNameMaxLength = validationRules.length(
    PartnerResources.labelLastName,
    null,
    50
);
export const partnerPhoneNumberRequired = validationRules.required(
    PartnerResources.labelPhoneNumber
);
export const partnerPhoneNumberMaxLength = validationRules.length(
    PartnerResources.labelPhoneNumber,
    null,
    25
);
export const partnerPhoneNumberFormat = validationRules.phoneNumber(
    PartnerResources.labelPhoneNumber
);
export const partnerEmailAddressRequired = validationRules.required(
    PartnerResources.labelEmailAddress
);
export const partnerEmailAddressMaxLength = validationRules.length(
    PartnerResources.labelEmailAddress,
    null,
    320
);
export const partnerEmailAddressFormat = validationRules.email(
    PartnerResources.labelEmailAddress
);
export const partnerNotesMaxLength = validationRules.length(
    PartnerResources.labelNotes,
    null,
    2020
);
