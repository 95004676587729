import http from '../../http/base';
import { API_PATHS } from '../../http/configs';

export default {
    getFacilitiesForMap: async (planIds, programIds, hasGroupMemberships) => await http.get(
        `${API_PATHS.getFacilitiesForClubLocatorMap}?hasGroupMemberships=${hasGroupMemberships === true}`,
        {
            params: {
                planIds: planIds && planIds.length > 0 ? planIds : [],
                programIds: programIds && programIds.length > 0 ? programIds : [] },
            paramsSerializer: { indexes: null }
        }
    ),
    getPlansForMap: () => http.get(API_PATHS.getPlansForClubLocatorMap)
};