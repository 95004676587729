import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { generatePath } from 'react-router';
import FormGroup from '../../../components/controls/FormGroup';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import InputDatePicker from '../../../components/controls/InputDatePicker';
import Button from '../../../components/controls/Button';
import { defaultFormProperties } from '../../../utilities/form';
import InfoBox from '../../../components/InfoBox/InfoBox';
import routes from '../../../routing/routes';
import voucherProgramFormResources from './voucherProgramFormResources';
import * as validationRules from './voucherProgramFormValidation';
import sharedResources from '../../Shared/resources';
import InputFile from '../../../components/controls/InputFile';
import { ALLOWED_FILE_EXTENSIONS, DIMENSIONS, MAX_SIZE_MB } from '../../../constants/partnerLogo';
import './_voucher-program-form.scss';

const VoucherProgramForm = ({
    pristine,
    submitting,
    handleSubmit,
    history,
    match,
    isPublished,
    isAdd,
    partnerLogoFileName
}) => {
    const toVoucherProgramsList = function () {
        history.push(generatePath(routes.voucherProgramsList.path, match.params));
    };
    const toRecipientsList = function () {
        history.push(generatePath(routes.voucherProgramRecipientsList.path, match.params));
    };
    return (
        <FormGroup onSubmit={handleSubmit}>
            <div className="grid__row">
                <div className="col-lg-7">
                    <InfoBox title={voucherProgramFormResources.headerCreateVoucherProgram} bordered>
                        <Field
                            label={voucherProgramFormResources.labelProgramName}
                            name="displayName"
                            component={MuiInputText}
                            disabled={isPublished}
                            validate={[
                                validationRules.programNameRequired,
                                validationRules.programNameMaxLength
                            ]}
                        />
                        <Field
                            label={voucherProgramFormResources.labelRedemptionAmount}
                            name="redemptionValue"
                            component={MuiInputText}
                            disabled={isPublished}
                            validate={[
                                validationRules.redemptionAmountRequired,
                                validationRules.redemptionAmountNumeric,
                                validationRules.redemptionAmountIsPositive,
                                validationRules.redemptionAmountIsDecimalWithTwoPlaces,
                                validationRules.redemptionAmountRange
                            ]}
                        />
                        <Field
                            label={voucherProgramFormResources.labelStartDate}
                            name="startDate"
                            component={InputDatePicker}
                            disabled={isPublished}
                            validate={[
                                validationRules.startDateRequired,
                                validationRules.startDateLessThanOrEqualEndDate
                            ]}
                        />
                        <Field
                            label={voucherProgramFormResources.labelEndDate}
                            name="endDate"
                            component={InputDatePicker}
                            disabled={isPublished}
                            validate={[
                                validationRules.endDateRequired,
                                validationRules.endDateGreaterThanOrEqualStartDate
                            ]}
                        />
                        <Field
                            label={voucherProgramFormResources.partnerLogo}
                            name="partnerLogo"
                            note={<>Please be aware that supported file formats are <span className="bold">{ALLOWED_FILE_EXTENSIONS} </span>;
                                the file must be up to <span className="bold">{MAX_SIZE_MB} MB </span>
                                and recommended size of picture is <span className="bold">{DIMENSIONS}</span></>}
                            component={InputFile}
                            validate={[
                                validationRules.fileMaxSize,
                                validationRules.fileType,
                            ]}
                            noMargin={!!partnerLogoFileName}
                        />
                        {partnerLogoFileName && (<div className="voucher-program-form__file-name-container">
                            <label>{voucherProgramFormResources.existingPartnerLogo}</label>
                            <p>{partnerLogoFileName}</p>
                        </div>
                        )}
                    </InfoBox>
                </div>
            </div>

            <div className="grid__row">
                <div className="col-xs-12 col-md-3 col-lg-2">
                    <Button
                        className="btn_new btn_new--blue"
                        size="medium"
                        type="submit"
                        disabled={isPublished || pristine || submitting}
                    >
                        {sharedResources.saveButtonText}
                    </Button>
                </div>
                {!isAdd && <div className="col-xs-12 col-md-3 col-lg-2">
                    <Button
                        className="btn_new btn_new--blue"
                        size="medium"
                        type="button"
                        onClick={toRecipientsList}
                    >
                        {voucherProgramFormResources.recipientsButtonText}
                    </Button>
                </div>}
                <div className="col-xs-12 col-md-3 col-lg-2">
                    <Button
                        className="btn_new btn_new--blue"
                        size="medium"
                        type="button"
                        onClick={toVoucherProgramsList}
                    >
                        {isPublished ? sharedResources.backButtonText : sharedResources.cancelButtonText}
                    </Button>
                </div>
            </div>
        </FormGroup>
    );
};

VoucherProgramForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isPublished: PropTypes.bool,
    isAdd: PropTypes.bool,
    partnerLogoFileName: PropTypes.string
};

export default reduxForm({
    form: 'voucherProgramForm',
    ...defaultFormProperties
})(VoucherProgramForm);
