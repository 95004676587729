import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../../components/InfoBox/InfoBoxRow';
import InfoBoxTable from '../../../../components/InfoBox/InfoBoxTable';
import AdditionalDetails from '../../../../components/AdditionalDetails/AdditionalDetails';
import CardInfo from '../../../../components/CardInfo/CardInfo';
import Icon from '../../../../components/Icon/Icon';
import Spinner from '../../../../components/Spinner/Spinner';
import InputSelect from '../../../../components/controls/InputSelect';
import facilityUsagesGroupResources from './facilityUsagesGroupResources';
import { Attention } from '../../../../components/Icon/Icons';

class FacilityUsagesGroup extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(selectedOption) {
        this.props.onRefresh(selectedOption, this.props.facilityUsagesGroup.get('memberGuid'));
    }

    render() {
        return (
            <CardInfo title={this.props.facilityUsagesGroup.get('facilityName')}>
                {this.props.facilityUsagesGroup.get('isLoading') && <div><Spinner /></div>}
                <InfoBox className="col-xs-12">
                    <div>
                        <InfoBoxRow label={facilityUsagesGroupResources.labelAddress} content={this.props.facilityUsagesGroup.get('facilityAddress')} />
                        <InfoBoxRow label={facilityUsagesGroupResources.labelVisitMonth}>
                            <InputSelect
                                value={this.props.facilityUsagesGroup.get('usagePeriod')}
                                options={this.props.availableUsagePeriods}
                                getOptionLabel={option => option.get('yearMonth')}
                                getOptionValue={option => option.get('id')}
                                onChange={this.handleChange}
                            />
                        </InfoBoxRow>
                    </div>
                    {!this.props.facilityUsagesGroup.get('dailyUsages').isEmpty() && <hr />}
                    <InfoBoxTable
                        header={[facilityUsagesGroupResources.headerVisitDate, facilityUsagesGroupResources.headerVisitTime]}

                        data={this.props.facilityUsagesGroup.get('dailyUsages').toJS()}
                    />
                    <hr />
                    <InfoBoxRow label={facilityUsagesGroupResources.labelMonthlyTotal} content={this.props.facilityUsagesGroup.get('monthlyTotal')} />
                    {
                        this.props.facilityUsagesGroup.get('isAfFacility') &&
                        <AdditionalDetails
                            icon={<Icon icon={<Attention/>} />}
                            text={facilityUsagesGroupResources.warningMessage}
                        />
                    }
                </InfoBox>
            </CardInfo>
        );
    }
}

FacilityUsagesGroup.defaultProps = {
    onRefresh: () => {
    }
};

FacilityUsagesGroup.propTypes = {
    onRefresh: PropTypes.func,
    facilityUsagesGroup: PropTypes.instanceOf(Map),
    availableUsagePeriods: PropTypes.instanceOf(List)
};

export default FacilityUsagesGroup;
