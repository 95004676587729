import { fromJS } from 'immutable';

import {
    SET_LOADING,
    UNSET_LOADING,
    GET_USAGE_LOG_SUCCESS,
    SET_FACILITY_USAGES_GROUP,
    SET_SELECTED_USAGE_PERIOD,
    SET_FACILITY_USAGES_GROUP_LOADING,
    UNSET_FACILITY_USAGES_GROUP_LOADING
} from './types';

import { initialState } from './initials';

const getFacilityUsagesGroupIndex = (state, memberGuid) => {
    return state.getIn(['usageLog', 'facilityUsagesGroups'])
        .findIndex(facilityUsagesGroup => facilityUsagesGroup.get('memberGuid') === memberGuid);
};

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case SET_LOADING:
            return state.set('isLoading', true);

        case UNSET_LOADING:
            return state.set('isLoading', false);

        case GET_USAGE_LOG_SUCCESS:
            return state.set('usageLog', fromJS(payload.usageLog));

        case SET_FACILITY_USAGES_GROUP: {
            const { facilityUsagesGroup: { memberGuid, dailyUsages, monthlyTotal } } = payload;

            const index = getFacilityUsagesGroupIndex(state, memberGuid);

            return state.mergeIn(['usageLog', 'facilityUsagesGroups', index], { dailyUsages: fromJS(dailyUsages), monthlyTotal });
        }

        case SET_SELECTED_USAGE_PERIOD: {
            const { selectedPeriodData: { usagePeriod, memberGuid } } = payload;

            const index = getFacilityUsagesGroupIndex(state, memberGuid);

            return state.mergeIn(['usageLog', 'facilityUsagesGroups', index], { usagePeriod });
        }

        case SET_FACILITY_USAGES_GROUP_LOADING: {
            const { memberGuid } = payload;

            const index = getFacilityUsagesGroupIndex(state, memberGuid);

            return state.mergeIn(['usageLog', 'facilityUsagesGroups', index], { isLoading: true });
        }

        case UNSET_FACILITY_USAGES_GROUP_LOADING: {
            const { memberGuid } = payload;

            const index = getFacilityUsagesGroupIndex(state, memberGuid);

            return state.mergeIn(['usageLog', 'facilityUsagesGroups', index], { isLoading: false });
        }

        default:
            return state;
    }
}