
import React from 'react';
import PropTypes from 'prop-types';
import ToolTip from '../../components/controls/ToolTip';
import './_tooltip-cell.scss';
import { decode } from 'html-entities';

function TooltipCell({ value }) {
    const decodedValue = decode(value);
    return decodedValue
        ? <ToolTip title={decodedValue} arrow
            placement="top"
          >
            <div className="tooltip-cell">{decodedValue}</div>
        </ToolTip>
        : <></>;
}

TooltipCell.propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
};

export default TooltipCell;
