import { createSelector } from 'reselect';
import { SAVE_VOUCHER_PROGRAM, GET_VOUCHER_PROGRAM } from './voucherProgramTypes';
import voucherProgramResources from './voucherProgramResources';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === SAVE_VOUCHER_PROGRAM || i === GET_VOUCHER_PROGRAM)
        .size;

    return size > 0;
};

const getVoucherProgram = state => state.voucherProgramReducer.get('voucherProgram');

const getIsPublished = createSelector(
    [getVoucherProgram],
    (voucherProgram) => {
        if (!voucherProgram) {
            return false;
        };
        const voucherProgramJs = voucherProgram.toJS();
        return !!(voucherProgramJs.id && voucherProgramJs.publishedOn);
    }
);

const getIsAdd = (_, props) => { return props.match.params.voucherProgramId === undefined; };

const getTitle = createSelector(
    [getIsPublished, getIsAdd],
    (isPublished, isAdd) => {
        if (isAdd) {
            return voucherProgramResources.headerCreateVoucherProgram;
        } else {
            return isPublished ? voucherProgramResources.headerViewVoucherProgram : voucherProgramResources.headerEditVoucherProgram;
        }
    }
);

const getPartnerLogoFileName = createSelector(
    [getVoucherProgram],
    (voucherProgram) => {
        if (!voucherProgram) {
            return null;
        };
        const voucherProgramJs = voucherProgram.toJS();
        return voucherProgramJs.partnerLogoFileName;
    }
);

export default createSelector(
    [
        getIsLoading,
        getVoucherProgram,
        getIsPublished,
        getIsAdd,
        getTitle,
        getPartnerLogoFileName],
    (
        isLoading,
        voucherProgram,
        isPublished,
        isAdd,
        title,
        partnerLogoFileName
    ) => (
        {
            isLoading,
            voucherProgram,
            isPublished,
            isAdd,
            title,
            partnerLogoFileName
        }
    )
);
