import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './rootReducer';
import services from '../http/services';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
    return createStore(
        rootReducer(history),
        initialState,
        composeWithDevTools(
            applyMiddleware(thunk.withExtraArgument(services), routerMiddleware(history))
        )
    );
};