import * as types from './MemberTransferTypes';
import initialState from './MemberTransferInitialState';
import { fromJS } from 'immutable';

export default function memberTransferReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_IS_MEMBERTRANSFER_POPUP_OPEN:
            return state.set('isMemberTransferPopupOpen', action.isMemberTransferPopupOpen);
        case types.MEMBERSTRANSFER_RESULT:
            return state.set('membersTransferResult', fromJS(action.membersTransferResult));
        default:
            return state;
    }
}
