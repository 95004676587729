import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import {
    showGeolocationIsNotSupportedAlert,
    toggleSelectedFacility,
    setFacilitiesInMapBounds,
    setFacilities
} from './facilityLocatorMapActions';
import MaterialIcon from '../../../../../components/MaterialIcon/MaterialIcon';
import FacilityPin from './FacilityPin/FacilityPin';
import selectors from './facilityLocatorMapSelectors';
import FacilitiesInMapPanel from './FacilitiesInMapPanel/FacilitiesInMapPanel';
import './_facility-locator-map.scss';
import useSupercluster from 'react-supercluster';
import FacilityCluster from './FacilityCluster/FacilityCluster';
import classNames from 'classnames';

function FacilityLocatorMap({
    showGeolocationIsNotSupportedAlert,
    facilitiesInitial,
    history,
    toggleSelectedFacility,
    selectedFacility,
    setFacilitiesInMapBounds,
    facilitiesInMapBounds,
    isEnrollButtonVisible,
    maxClusterZoom,
    clusterRadius,
    clusterSize,
    isListView,
    facilities,
    setFacilities,
    checkedPlans,
    showFilter
}) {
    const defaultProps = {
        zoom: 4,
        // Center of USA
        center: {
            lat: 39.8097343,
            lng: -98.5556199,
        }
    };
    const onGoogleApiLoadedCallback = (apiLoadedParams) => {
        setGoogleMap(apiLoadedParams.map);
        setGoogleMapApi(apiLoadedParams.maps);
    };
    const onChildClickCallback = (facilityIndex) => {
        toggleSelectedFacility(parseInt(facilityIndex));
    };
    const onChangeCallback = ({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
        ]);
    };
    const Marker = ({ children }) => children;
    const [googleMap, setGoogleMap] = useState(undefined);
    const [googleMapApi, setGoogleMapApi] = useState(undefined);
    const [resetLocationIsVisible, setResetLocationIsVisible] = useState(false);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(4);

    const points = useMemo(() => {
        return facilitiesInitial.toJS()
            .map(facility => ({
                type: 'Feature',
                properties: {
                    cluster: false,
                    facility
                },
                geometry: {
                    type: 'Point',
                    coordinates: [
                        facility.longitude,
                        facility.latitude
                    ]
                }
            }));
    }, [facilitiesInitial]);
    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: clusterRadius, maxZoom: maxClusterZoom }
    });
    const resetLocation = () => {
        if (googleMap && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setResetLocationIsVisible(true);
                    googleMap.setCenter({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                    googleMap.setZoom(11);
                },
                (error) => {
                    setResetLocationIsVisible(error.code !== 1);// User denied Geolocation
                }
            );
        } else {
            showGeolocationIsNotSupportedAlert();
        }
    };

    // affects distance of triggering marker events such as click, hover, etc
    const distanceToMouse = (markerPos, mousePos) => {
        const x = markerPos.x;
        const y = markerPos.y - 30;

        return Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
    };

    const refreshSearchResultsPanel = () => {
        if (googleMap) {
            setFacilitiesInMapBounds(googleMap, maxClusterZoom);
        }
    };

    useEffect(() => {
        if (googleMap) {
            resetLocation();
            googleMap.addListener('idle', refreshSearchResultsPanel);
        }
    }, [googleMap]);

    useEffect(() => {
        if (googleMap && selectedFacility) {
            const facility = selectedFacility.toJS();
            googleMap.panTo({ lat: facility.latitude, lng: facility.longitude });
        }
    }, [googleMap, selectedFacility]);

    useEffect(() => {
        setFacilities(facilitiesInitial);
        refreshSearchResultsPanel();
    }, [facilitiesInitial]);

    useEffect(() => {
        refreshSearchResultsPanel();
    }, [checkedPlans]);

    return <div className="facility-locator__map">
        {
            googleMap && googleMapApi &&
            <FacilitiesInMapPanel map={googleMap} mapApi={googleMapApi}
                facilities={facilitiesInMapBounds ? facilitiesInMapBounds.toJS() : undefined}
                selectedFacility={selectedFacility ? selectedFacility.toJS() : undefined}
                history={history}
                isEnrollButtonVisible={isEnrollButtonVisible}
                isListView={isListView}
                showFilter={showFilter}
            />
        }
        {resetLocationIsVisible && <button className="facility-locator__reset-current-location" onClick={resetLocation}>
            <MaterialIcon icon="my_location" />
        </button>}
        <div className={classNames(['facility-locator__map-viewport', { 'facility-locator__map-viewport--hidden-on-mobile': isListView }])}>
            <GoogleMapReact
                defaultZoom={defaultProps.zoom}
                defaultCenter={defaultProps.center}
                bootstrapURLKeys={{
                    key: 'AIzaSyDXUQunSAFZii1XXF_7UWSKp9P9o3UAXcs',
                    language: 'en',
                    region: 'usa',
                    libraries: ['places']
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoadedCallback}
                onChildClick={onChildClickCallback}
                distanceToMouse={distanceToMouse}
                options={{
                    fullscreenControl: false,
                    scaleControl: true,
                }}
                onChange={onChangeCallback}
            >
                {clusters && clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const {
                        cluster: isCluster,
                        point_count: pointCount,
                        facility
                    } = cluster.properties;

                    if (isCluster) {
                        return (
                            <Marker
                                key={`cluster-${cluster.id}`}
                                lat={latitude}
                                lng={longitude}
                            >
                                <FacilityCluster
                                    pointCount={pointCount}
                                    facilitiesCount={facilities.size}
                                    supercluster={supercluster}
                                    latitude={latitude}
                                    longitude={longitude}
                                    clusterId={cluster.id}
                                    googleMap={googleMap}
                                    clusterSize={clusterSize}
                                />
                            </Marker>
                        );
                    }
                    return (
                        <FacilityPin
                            key={facility.id}
                            lat={latitude}
                            lng={longitude}
                            facility={facility}
                            history={history}
                            isActive={selectedFacility ? selectedFacility.toJS().id === facility.id : false}
                        />
                    );
                })}
            </GoogleMapReact>
        </div>
    </div>;
}

FacilityLocatorMap.propTypes = {
    showGeolocationIsNotSupportedAlert: PropTypes.func.isRequired,
    toggleSelectedFacility: PropTypes.func.isRequired,
    setFacilitiesInMapBounds: PropTypes.func.isRequired,
    facilitiesInitial: PropTypes.object,
    facilitiesInMapBounds: PropTypes.object,
    history: PropTypes.object.isRequired,
    selectedFacility: PropTypes.object,
    isEnrollButtonVisible: PropTypes.bool.isRequired,
    maxClusterZoom: PropTypes.number.isRequired,
    clusterSize: PropTypes.number.isRequired,
    clusterRadius: PropTypes.number.isRequired,
    isListView: PropTypes.bool,
    setFacilities: PropTypes.func.isRequired,
    facilities: PropTypes.object,
    checkedPlans: PropTypes.object,
    showFilter: PropTypes.bool
};

const actions = {
    showGeolocationIsNotSupportedAlert,
    toggleSelectedFacility,
    setFacilitiesInMapBounds,
    setFacilities
};

export default connect(selectors, actions)(FacilityLocatorMap);