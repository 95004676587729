import { fromJS } from 'immutable';
import programPaymentTypes, { programPaymentTypesFullNamesMap } from '../../../enums/programPaymentTypes';
import programTypes, { programTypesNamesMap } from '../../../enums/programTypes';

export default fromJS({
    paymentsFilter: {
        showCorrectedOnly: false,
        programPaymentType: fromJS({ id: Number(programPaymentTypes.partnerPaid), name: programPaymentTypesFullNamesMap[programPaymentTypes.partnerPaid] }),
        programType: fromJS({ id: Number(programTypes.groupMemberships), name: programTypesNamesMap[programTypes.groupMemberships] }),
    },
    partners: [],
    payments: [],
    paymentTypes: []
});
