import qs from 'qs';
import axios from 'axios';
import { BASE_URLS } from './configs';
import { handleFailedResponse, handleSucceededResponse } from './helpers';
import { APPLICATION_JSON } from '../constants/contentTypes';

const defaultParams = {
    withCredentials: true,
    paramsSerializer: params => qs.stringify(params, { indices: false }),
    validateStatus: status => status < 500
};

const axiosInstance = axios.create();

let httpRequestsInterceptor = null;
let httpResponseInterceptor = null;

const resetInterceptor = (type, interceptor) => axiosInstance.interceptors[type].eject(interceptor);

export const resetInterceptors = () => {
    resetInterceptor('request', httpRequestsInterceptor);
    resetInterceptor('response', httpResponseInterceptor);
};

export const configureInterception = ({ endpoint = BASE_URLS.api, dispatch }) => {

    if (!endpoint) {
        throw new Error('No endpoint was specified for http!!!');
    } else {
        axiosInstance.defaults.baseURL = endpoint;
    }

    axiosInstance.defaults.headers.post['Content-Type'] = APPLICATION_JSON;
    axiosInstance.defaults.headers['Pragma'] = 'no-cache';

    httpRequestsInterceptor = axiosInstance.interceptors.request.use((config) =>
    {
        return {
            ...config,
            ...defaultParams
        };
    }, Promise.reject
    );

    httpResponseInterceptor = axiosInstance.interceptors.response.use(
        handleSucceededResponse(dispatch), handleFailedResponse(dispatch)
    );
};

export default axiosInstance;