import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID) {
    return http.get(replaceRouteParams(API_PATHS.getMemberPayments, programPaymentTypeID, paymentTypeID, partnerID, periodID));
};

export function checkMemberPaymentsProcessed(programPaymentTypeID, paymentTypeID, partnerID, periodID) {
    return http.get(replaceRouteParams(API_PATHS.checkMemberPaymentsProcessed, programPaymentTypeID, paymentTypeID, partnerID, periodID));
};

export function sendMemberPayments(programPaymentTypeID, paymentTypeID, partnerID, periodID, pin) {
    return http.post(replaceRouteParams(API_PATHS.sendMemberPayments, programPaymentTypeID, paymentTypeID, partnerID, periodID), { pin });
};