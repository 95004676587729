import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setIsMemberTransferPopupOpen } from './MemberTransferActions';
import CustomPopup from '../../../components/Popups/CustomPopup/CustomPopup';
import MemberTransfer from './MemberTransfer';
import selectors from './memberTransferSelectors';

function MemberTransferPopup({ isMemberTransferPopupOpen, setIsMemberTransferPopupOpen, isLoading, history }) {
    return <CustomPopup
        isLoading={isLoading}
        showPopup={isMemberTransferPopupOpen}
        onCancel={() => setIsMemberTransferPopupOpen(false)}
        title="Members transfer"
        body={<MemberTransfer closePopup={() => setIsMemberTransferPopupOpen(false)} history={history} />}
           />;
}

MemberTransferPopup.propTypes = {
    isMemberTransferPopupOpen: PropTypes.bool.isRequired,
    setIsMemberTransferPopupOpen: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

const mapDispatchToProps = {
    setIsMemberTransferPopupOpen,
};

const connectStateToProps = connect(selectors, mapDispatchToProps);
export default connectStateToProps(MemberTransferPopup);
