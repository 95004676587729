import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';
import { CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS } from './memberAreaTypes';

const getMemberHasActiveFacilityEnrollments = (state) => {
    return state.memberAreaReducer.get('memberHasActiveFacilityEnrollments');
};

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CHECK_MEMBER_HAS_ACTIVE_FACILITY_ENROLLMENTS
    ).size;

    return size > 0;
};

const checkShouldRedirect = createSelector(
    [getMemberHasActiveFacilityEnrollments],
    (memberHasActiveFacilityEnrollments) => {
        return memberHasActiveFacilityEnrollments !== undefined;
    }
);

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

const selectFilteredRoutes = createSelector(
    [getMemberHasActiveFacilityEnrollments, getRoutes],
    (memberHasActiveFacilityEnrollments, routes) => {
        return routes.filter(
            (route) => {
                if (route.path === allRoutes.account.path) {
                    return memberHasActiveFacilityEnrollments;
                }

                return true;
            }
        );
    }
);

const getSelectedMemberGroup = (state) => {
    return state.app.get('selectedMemberGroup');
};

export default createSelector(
    [
        getIsLoading,
        checkShouldRedirect,
        selectFilteredRoutes,
        getSelectedMemberGroup
    ],
    (isLoading, shouldRedirect, filteredRoutes, selectedMemberGroup) => ({
        isLoading,
        shouldRedirect,
        filteredRoutes,
        selectedMemberGroup
    })
);
