import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { routeType } from '../routing/routes';

const ConditionalRedirect = ({ condition, fromRoute, toRoute }) => {
    return condition ?
        (
            <Redirect from={fromRoute && fromRoute.path} to={toRoute && toRoute.path} />
        )
        : (
            ''
        );
};

ConditionalRedirect.propTypes = {
    condition: PropTypes.bool.isRequired,
    fromRoute: routeType,
    toRoute: routeType,
};

export default ConditionalRedirect;
