import React from 'react';
import PropTypes from 'prop-types';
import './_form-group.scss';

const FormGroup = ({ children, label, helperText, onSubmit, name }) => {
    return (
        <form className="form-group" onSubmit={onSubmit}
            name={name}
        >
            {label && <label className="form-group__label">{label}</label>}
            {children}
            {helperText && <span className="form-group__helper-text">{helperText}</span>}
        </form>
    );
};

FormGroup.propTypes = {
    label: PropTypes.string,
    helperText: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    onSubmit: PropTypes.func,
    name: PropTypes.string
};

export default FormGroup;