const visitLogResources = {
    labelVisitMonth: 'Visit Month',
    labelKeyfob: 'Keyfob',
    labelFacilityID: 'Facility ID',
    labelFaciityName: 'Facility Name',
    labelEmployeeFirstName: 'Employee First Name',
    labelEmployeeLastName: 'Employee Last Name',
    labelVisits: 'Visits',
    labelEmployeeName: 'Employee Name',
    labelStatus: 'Last Status',
    labelEmail: 'Email',
    formVisitMonth: 'visitMonthForm',
    fieldPeriod: 'id',
};
export default visitLogResources;