import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import { defaultFormProperties } from '../../../utilities/form';
import { MuiInputText } from '../../../components/controls/MuiInputText';
import MuiButton from '../../../components/controls/MuiButton';
import FormGroup from '../../../components/controls/FormGroup';
import changePasswordResources from './changePasswordResources';
import validate, {
    oldPasswordRequired,
    newPasswordRequired,
    confirmPasswordRequired,
    passwordRegex,
    oldPasswordMaxLength30,
    newPasswordMaxLength30,
    confirmPasswordMaxLength30
} from './changePasswordValidate';

function ChangePasswordForm({ handleSubmit, submitting }) {
    return (
        <FormGroup onSubmit={handleSubmit}>
            <Field
                id="old_pass"
                name="oldPassword"
                label={changePasswordResources.labelOldPassword}
                secureTextEntry
                component={MuiInputText}
                validate={[
                    oldPasswordRequired,
                    oldPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <Field
                id="new_pass"
                name="newPassword"
                label={changePasswordResources.labelNewPassword}
                secureTextEntry
                component={MuiInputText}
                validate={[
                    newPasswordRequired,
                    newPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <Field
                id="confirm_pass"
                name="confirmPassword"
                label={changePasswordResources.labelConfirmPassword}
                secureTextEntry
                component={MuiInputText}
                validate={[
                    confirmPasswordRequired,
                    confirmPasswordMaxLength30,
                    passwordRegex
                ]}
            />
            <MuiButton className="btn_new btn_new--blue" size="medium" disabled={submitting} type="submit">
                {changePasswordResources.buttonSave}
            </MuiButton>
        </FormGroup>
    );
}

ChangePasswordForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

export default reduxForm({
    form: 'changePassword',
    validate,
    ...defaultFormProperties
})(ChangePasswordForm);
