/*eslint-disable react/no-multi-comp*/
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import { Yes, No } from '../Icon/Icons';
import './_info-box.scss';

const InfoBoxTable = (props) => {
    const { header, data } = props;

    const getTableCellContent = (item) => {
        return typeof item === 'boolean'
            ? (
                <Icon
                    icon={item ? <Yes/> : <No/>}
                />
            )
            : item;
    };

    return (
        <div className="info-box__table-container">
            <table className="info-box__table">
                {
                    data.length > 0 &&
                    <React.Fragment>
                        {
                            header &&
                            <thead>
                                <tr>
                                    {
                                        header.map((columnName, i) => (
                                            <th key={i} className="info-box__table-cell info-box__table-cell_head">
                                                {columnName}
                                            </th>
                                        ))
                                    }
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {
                                data.map((object, i) => (
                                    <tr key={i} className="info-box__table-row">
                                        {
                                            Object.keys(object).map((propertyName, columnIndex) => (
                                                <td key={columnIndex} className="info-box__table-cell">
                                                    {getTableCellContent(object[propertyName])}
                                                </td>
                                            ))
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </React.Fragment>
                }
            </table>
        </div>
    );
};

InfoBoxTable.propTypes = {
    header: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.arrayOf(PropTypes.object)
};

export default InfoBoxTable;