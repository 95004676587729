import initialState from './partnerPaymentsGenerationSectionInitialState';
import * as types from './partnerPaymentsGenerationSectionTypes';
import { fromJS } from 'immutable';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PERIOD:
            return state.setIn(['paymentsGenerationSettings', 'period'], action.period);
        case types.SET_PARTNER:
            return state.setIn(['paymentsGenerationSettings', 'partner'], action.partner);
        case types.SET_PROGRAM_TYPE:
            return state.setIn(['paymentsGenerationSettings', 'programType'], action.programType);
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.setIn(['paymentsGenerationSettings', 'programPaymentType'], action.programPaymentType);
        case types.SET_PAYMENT_TYPE:
            return state.setIn(['paymentsGenerationSettings', 'paymentType'], action.paymentType);
        case types.SET_PAYMENTS_EXIST:
            return state.set('doPaymentsExist', action.doPaymentsExist);
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        case types.SET_IS_GENERATION_WITH_FEES:
            return state.set('isGenerationWithFees', action.isGenerationWithFees);
        case types.SET_IS_ADDITIONAL_FEES_POPUP_VISIBLE:
            return state.set('isAdditionalFeesPopupVisible', action.isAdditionalFeesPopupVisible);
        case types.SET_PAYMENT_TYPES:
            return state.set('paymentTypes', fromJS(action.paymentTypes));
        default:
            return state;
    }
}