import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollTo() {
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (!hash) {
            window.scrollTo(0, 0);
        }
        else {
            const elementId = hash.slice(1);
            const interval = setInterval(() => {
                const $element = document.getElementById(elementId);
                if ($element) {
                    clearInterval(interval);
                    $element.scrollIntoView({
                        behavior: 'instant'
                    });
                }
            }, 100);
            return () => clearInterval(interval);
        }
    }, [location.pathname, location.hash]);

    return null;
}