import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import InfoBox from '../../../components/InfoBox/InfoBox';
import ConnectedConfirmPopup from '../../../components/Popups/ConfirmAlert/ConnectedConfirmPopup';
import Spinner from '../../../components/Spinner/Spinner';
import PaymentViaACHForm from '../PaymentViaACHForm/PaymentViaACHForm';
import PaymentConfirmationForm from '../PaymentConfirmationForm/PaymentConfirmationForm';
import selectors from './facilityPaymentDepositSectionSelectors';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import actions from './facilityPaymentDepositSectionActions';
import { getPartnersListByProgramType } from '../../Partners/PartnersList/partnersListActions';
import { useFlags } from 'launchdarkly-react-client-sdk';

function FacilityPaymentDepositSection({
    isLoading,
    programTypes,
    programPaymentTypes,
    partners,
    periods,
    paymentDeposit,
    defaultProgramType,
    setProgramType,
    setProgramPaymentType,
    getPartnersListByProgramType,
    getPartnersListByProgramPaymentType,
    sendFacilityPaymentsDeposit,
    downloadPaymentDeposit,
    checkFacilityPaymentsDeposit,
    popupMessage,
    popupSubmitText,
    isAchUploadAvailable,
    achUploadErrorMessage,
    defaultProgramPaymentType,
    programTypeId,
    programPaymentTypeId,
    partnerId,
    periodId,
    setPartner
}) {
    const { gmrAddProgram } = useFlags();
    useEffect(() => {
        if (gmrAddProgram) {
            setProgramPaymentType(defaultProgramPaymentType);
        } else {
            setProgramType(defaultProgramType);
        }
    }, []);
    useEffect(() => {
        if (programPaymentTypeId) {
            getPartnersListByProgramPaymentType(programPaymentTypeId);
            setPartner(null);
        }
    }, [programPaymentTypeId]);
    useEffect(() => {
        if (programTypeId) {
            getPartnersListByProgramType(programTypeId);
        }
    }, [programTypeId]);
    const [isPopupOpened, setIsPopupOpened] = useState(false);
    const [paymentDepositWithPIN] = useState(paymentDeposit);
    const handleSubmitSend = () => {
        checkFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId).then(() => {
            setIsPopupOpened(true);
        });
    };
    const handlePINSubmit = (confirmationData) => {
        sendFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId, confirmationData.get('pin'));
        setIsPopupOpened(false);
    };
    const handleSubmitDownload = () => {
        downloadPaymentDeposit(programPaymentTypeId, partnerId, periodId);
    };
    return <InfoBox title={wellnessProgramPaymentResources.titleFacilityPaymentDepositViaACHFiles} bordered>
        {isLoading
            ? <Spinner />
            : <></>
        }
        {
            <ConnectedConfirmPopup
                showPopup={isPopupOpened}
                title={wellnessProgramPaymentResources.titleConfirmACHUpload}
                body={
                    <PaymentConfirmationForm
                        message={popupMessage}
                        form={wellnessProgramPaymentResources.formfacilityPaymentDepositConfirmation}
                        initialValues={paymentDepositWithPIN}
                        onSubmit={handlePINSubmit}
                    />
                }
                onConfirm={(dispatch) => {
                    dispatch(submit(wellnessProgramPaymentResources.formfacilityPaymentDepositConfirmation));
                }}
                onCancel={() => setIsPopupOpened(false)}
                labelOk={popupSubmitText}
                labelCancel={wellnessProgramPaymentResources.buttonCancel}
            />
        }
        <PaymentViaACHForm
            form={wellnessProgramPaymentResources.formFacilityPaymentDepositViaACH}
            programTypes={programTypes}
            programPaymentTypes={programPaymentTypes}
            partners={partners}
            periods={periods}
            onSubmitSend={handleSubmitSend}
            onSubmitDownload={handleSubmitDownload}
            initialValues={paymentDeposit}
            isSendDisabled={!isAchUploadAvailable}
            sendErrorMessage={achUploadErrorMessage}
            gmrAddProgram={gmrAddProgram}
        />
    </InfoBox>;
}

FacilityPaymentDepositSection.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    paymentDeposit: PropTypes.object.isRequired,
    setProgramType: PropTypes.func.isRequired,
    setProgramPaymentType: PropTypes.func.isRequired,
    getPartnersListByProgramType: PropTypes.func.isRequired,
    getPartnersListByProgramPaymentType: PropTypes.func.isRequired,
    defaultProgramType: PropTypes.object,
    sendFacilityPaymentsDeposit: PropTypes.func.isRequired,
    downloadPaymentDeposit: PropTypes.func.isRequired,
    checkFacilityPaymentsDeposit: PropTypes.func.isRequired,
    popupMessage: PropTypes.string,
    popupSubmitText: PropTypes.string,
    isAchUploadAvailable: PropTypes.bool.isRequired,
    achUploadErrorMessage: PropTypes.string,
    defaultProgramPaymentType: PropTypes.object,
    programTypeId: PropTypes.number,
    programPaymentTypeId: PropTypes.number,
    setPartner: PropTypes.func,
    partnerId: PropTypes.number,
    periodId: PropTypes.number,
};

const mapDispatchToProps = {
    getPartnersListByProgramType,
    ...actions
};

export default connect(selectors, mapDispatchToProps)(FacilityPaymentDepositSection);