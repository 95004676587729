/* eslint-disable react/no-multi-comp*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { EyeVisible, EyeInvisible } from '../Icon/Icons';

export class InputText extends Component {
    constructor(props) {
        super(props);

        this.handleChangeSecureMode = this.handleChangeSecureMode.bind(this);

        this.state = {
            secureMode: this.props.secureTextEntry
        };
    }

    handleChangeSecureMode() {
        this.setState(({ secureMode }) => ({ secureMode: !secureMode }));
    }

    render() {
        const { secureMode } = this.state;
        const { label, size, id, value,
            onChange, error, touched,
            disabled, name, onBlur,
            noMargin, transparentBorder, defaultValue, onClick, highlightInputValidationError, readOnly, hidden } = this.props;
        const isNotEmpty = value && value.length;
        const showError = error && touched;
        const showSecureModeSwitcher = this.props.secureTextEntry && isNotEmpty;

        return (
            <div
                className={classNames([
                    `c-input c-input_${size}`,
                    { 'c-input_password': this.props.secureTextEntry },
                    { filled: isNotEmpty },
                    { 'c-input--no-margin': noMargin },
                    { 'c-input--transparent-border': transparentBorder }])}
            >
                {label && <label className="c-input__label" htmlFor={id}>{label}</label>}
                <div className="c-input__container">
                    <input
                        className={classNames([
                            'c-input__field',
                            { 'input-validation-error': showError || highlightInputValidationError }])}
                        id={id}
                        value={value}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        onBlur={onBlur}
                        onClick={onClick}
                        type={secureMode ? 'password' : 'text'}
                        disabled={disabled}
                        name={name}
                        readOnly={readOnly}
                        hidden={hidden}
                    />
                    {
                        showSecureModeSwitcher &&
                        <div className="c-input__toggle-pass js-toggle-pass" onClick={this.handleChangeSecureMode}>
                            <Icon icon={secureMode ? <EyeVisible/> : <EyeInvisible/>}/>
                        </div>
                    }
                </div>
                {
                    showError &&
                    <div className="field-validation-error">
                        {error}
                    </div>
                }
            </div>
        );
    }
}

InputText.defaultProps = {
    onChange: () => {
    },
    onBlur: () => {
    },
    onClick: () => {
    },
    size: 'medium',
    secureTextEntry: false,
    highlightInputValidationError: false
};

InputText.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    secureTextEntry: PropTypes.bool,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.string,
    id: PropTypes.string,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    noMargin: PropTypes.bool,
    transparentBorder: PropTypes.bool,
    highlightInputValidationError: PropTypes.bool,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
};

const ReduxTextInput = ({ input, meta, ...custom }) => {
    return (
        <InputText
            {...meta}
            {...input}
            {...custom}
        />
    );
};

export default ReduxTextInput;
