import { fromJS } from 'immutable';
import initialState from './facilityPaymentDepositSectionInitialState';
import * as types from './facilityPaymentDepositSectionTypes';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PROGRAM_TYPE:
            return state.setIn(['paymentDeposit', 'programType'], fromJS(action.programType));
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.setIn(['paymentDeposit', 'programPaymentType'], fromJS(action.programPaymentType));
        case types.SET_PARTNER:
            return state.setIn(['paymentDeposit', 'partner'], fromJS(action.partner));
        case types.SET_POPUP_DATA:
            return state.set('popupData', fromJS(action.popupData));
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        default:
            return state;
    }
}