import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import './_excelImage.scss';
import sharedResources from '../../../features/Shared/resources';

function ExcelImage({ onClick }) {
    return (
        <div className="excel-image-wrapper">
            <Image
                alt={sharedResources.imageTextExportToExcel}
                image="excel"
                className="excel-image"
                onClick={onClick}
            />
        </div>
    );
}

ExcelImage.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ExcelImage;