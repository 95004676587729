import React, { Component } from 'react';
import { Map, fromJS } from 'immutable';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import InputCheckbox from './InputCheckbox';
import './_checkbox-group.scss';

class CheckBoxGroup extends Component {
    static getInitialValues({ meta, options }, isImmutable) {
        return meta
            ? meta.initial
            : options.reduce(
                (values, { name, initialValue }) => {
                    if (initialValue) {
                        isImmutable
                            ? (values = values.withMutations(map =>
                                map.set(name, !!initialValue)
								  ))
                            : (values[name] = !!initialValue);
                    }
                    return values;
                },
                isImmutable ? fromJS({}) : {}
			  );
    }

    constructor(props) {
        super(props);
        this.isImmutable = props.meta.initial instanceof Map;
        const initialValues = CheckBoxGroup.getInitialValues(this.props, this.isImmutable);
        this.state = {
            values: initialValues,
            initialValues
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleBlur() {
        const { input = {}, onBlur } = this.props;
        (input.onBlur || onBlur)(this.state.values);
    }

    handleChange({ target: { checked, name } }) {
        const { input = {}, onChange } = this.props;
        this.setState(
            ({ values }) => ({
                values: this.isImmutable
                    ? values.set(name, checked)
                    : { ...values, [name]: checked }
            }),
            () => {
                (input.onChange || onChange)(this.state.values);
            }
        );
    }

    render() {
        const {
            meta = {},
            input = {},
            options,
            label,
            inline,
            ...props
        } = this.props;
        return (
            <div className="checkbox-group">
                {label && (
                    <label className="checkbox-group__label">{label}</label>
                )}
                <div
                    className={classNames([
                        'checkbox-group__content',
                        { 'checkbox-group__content_inline': inline }
                    ])}
                >
                    {options.map(option => (
                        <InputCheckbox
                            key={option.id}
                            {...input}
                            {...props}
                            {...option}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            checked={
                                this.isImmutable
                                    ? !!(
                                        this.state.values &&
											this.state.values.get(option.name)
									  )
                                    : !!(
                                        this.state.values &&
											this.state.values[option.name]
									  )
                            }
                            disabled={this.props.areDisabledCheckedInitials && this.isImmutable
                                ? (
                                    this.state.initialValues &&
										this.state.initialValues.get(option.name)
								  )
                                : (
                                    this.state.initialValues &&
										this.state.initialValues[option.name]
								  )
								  }
                        />
                    ))}
                </div>
                {meta.submitFailed && meta.error && (
                    <div className="field-validation-error">{meta.error}</div>
                )}
            </div>
        );
    }
}

CheckBoxGroup.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired
        })
    ).isRequired,
    inline: PropTypes.bool,
    input: PropTypes.object,
    meta: PropTypes.object,
    areDisabledCheckedInitials: PropTypes.bool
};

CheckBoxGroup.defaultProps = {
    onChange: () => undefined,
    onBlur: () => undefined
};

export default CheckBoxGroup;
