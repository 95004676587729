import * as types from './facilityLocatorMapTypes';
import { initialState } from './facilityLocatorMapInitials';
import { fromJS } from 'immutable';
import { getDistanceFromLatLngInMiles } from '../../../../../utilities/geometry';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FACILITIES: {
            return state.set(
                'facilities', fromJS(action.initialFacilities)
            );
        }
        case types.TOGGLE_SELECTED_FACILITY: {
            const allFacilities = state.get('facilities').toJS();
            const selectedFacility = allFacilities.find((facility) => {
                return facility.id === action.facilityId;
            });
            const oldSelectedFacility = state.get('selectedFacility');
            return state.set(
                'selectedFacility',
                selectedFacility && oldSelectedFacility && selectedFacility.id === oldSelectedFacility.toJS().id
                    ? undefined
                    : fromJS(selectedFacility)
            );
        }
        case types.SET_FACILITIES_IN_MAP_BOUNDS: {
            const facilitiesInMapBounds = [];
            const facilitiesNearest = [];
            const nearestLocationDistanceInMiles = 40;
            if (action.googleMap.getZoom() <= action.maxClusterZoom) {
                return state.set(
                    'facilitiesInMapBounds',
                    fromJS(facilitiesInMapBounds)
                );
            }
            const allFacilities = state.get('facilities').toJS();
            const mapCenter = action.googleMap.getCenter();
            for (let i = 0; i < allFacilities.length; i++) {
                const facility = allFacilities[i];
                const distanceToCenter = getDistanceFromLatLngInMiles(mapCenter.lat(), mapCenter.lng(), facility.latitude, facility.longitude);
                facility.distanceToCenter = distanceToCenter;
                if(facility.distanceToCenter <= nearestLocationDistanceInMiles) {
                    facilitiesNearest.push(facility);
                }
            }

            if(facilitiesNearest.length > 0) {
                facilitiesNearest.sort((facility1, facility2) => {
                    return facility1.distanceToCenter - facility2.distanceToCenter;
                });
                action.googleMap.setCenter({ lat: facilitiesNearest[0].latitude, lng: facilitiesNearest[0].longitude });
            }
            for (let i = 0; i < allFacilities.length; i++) {
                const facility = allFacilities[i];
                if (action.googleMap.getBounds().contains({ lat: facility.latitude, lng: facility.longitude })) {
                    const distanceToCenter = getDistanceFromLatLngInMiles(mapCenter.lat(), mapCenter.lng(), facility.latitude, facility.longitude);
                    facility.distanceToCenter = distanceToCenter;
                    facility.distanceToCenterForDisplay = `${distanceToCenter.toFixed(2)} miles`;
                    facilitiesInMapBounds.push(facility);
                }
            }
            facilitiesInMapBounds.sort((facility1, facility2) => {
                return facility1.distanceToCenter - facility2.distanceToCenter;
            });
            return state.set(
                'facilitiesInMapBounds',
                fromJS(facilitiesInMapBounds)
            );
        }
        case types.SET_LIST_VIEW_FOR_MAP: {
            return state.set('isListView', action.isListView);
        }
        default:
            return state;
    }
}
