import * as types from './loadingTypes';
import initialState from './loadingInitialState';
import { fromJS } from 'immutable';

export default function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LOADING: {
            return state.update('loadings', loadings =>
                loadings.push(fromJS(action.key))
            );
        }
        case types.UNSET_LOADING:
            return state.update('loadings', loadings =>
                loadings.filter(key => key !== action.key)
            );
        default:
            return state;
    }
}
