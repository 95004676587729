import * as types from './MemberTransferTypes';
import * as memberService from './memberTransferService';
import * as loadingActions from '../../Loading/loadingActions';
import { ERROR } from '../../../components/Notifications/constants';
import { postNotification } from '../../../components/Notifications/actions';

export const setIsMemberTransferPopupOpen = isMemberTransferPopupOpen => ({
    type: types.SET_IS_MEMBERTRANSFER_POPUP_OPEN,
    isMemberTransferPopupOpen
});

export function showErrorAlert(message) {
    return function (dispatch) {
        dispatch(postNotification({ type: ERROR, message }));
    };
}

export const setMembersTransferResult = (membersTransferResult) => {
    return { type: types.MEMBERSTRANSFER_RESULT, membersTransferResult };
};

export function transferMembers(facility, selectedMembers) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.MEMBERTRANSFER));
        return memberService.transferMembers(facility.id, selectedMembers.toJS().map(member => member.memberID))
            .then((result) => {
                if (result.success) {
                    dispatch(setMembersTransferResult(result.data.map((res) => {
                        const member = selectedMembers.toJS().find(member => member.memberID === res.memberID);
                        return {
                            facilityMemberID: member.facilityMemberID,
                            memberFirstName: member.memberFirstName,
                            memberLastName: member.memberLastName,
                            planName: member.planName,
                            facilityFromName: member.facilityName,
                            facilityFromIDNumber: member.facilityIDNumber,
                            facilityToName: facility.name,
                            facilityToIDNumber: facility.facilityIDNumber,
                            transferStatus: res.success,
                            transferMessage: res.message
                        };
                    })));
                    dispatch(setIsMemberTransferPopupOpen(false));
                }
            })
            .finally(() => dispatch(loadingActions.unsetLoading(types.MEMBERTRANSFER)));
    };
}
