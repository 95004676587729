import React, { useEffect } from 'react';
import NextArrow from '../Slider/CustomArrow/NextArrow';
import PrevArrow from '../Slider/CustomArrow/PrevArrow';
import CustomPaging from '../Slider/CustomPaging';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './_testimonial-section.scss';
import '../Slider/_slider.scss';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

function TestimonialSection({ header, children }) {
    useEffect(() => {
        const activeDot = document.getElementById('slider_0');

        activeDot.setAttribute('aria-current', 'true');
    }, []);

    return (
        <div className="testimonial-section" role="region">
            <h2 className="testimonial-section__header">{header}</h2>
            <div className="testimonial-section__group" role="region" aria-label="carousel">
                <p className="sr-only">This is a carousel. Use Next and Previous buttons to navigate, or jump to a slide with the slide dots.</p>
                <hr className="testimonial-section__divider" />
                <Slider
                    nextArrow={<NextArrow/>}
                    prevArrow={<PrevArrow/>}
                    dots
                    speed={400}
                    dotsClass={'custom-slick-dots testimonial-section__custom-slick-dots'}
                    customPaging={CustomPaging}
                    accessibility
                >
                    {children}
                </Slider>
            </div>
        </div>
    );
}

TestimonialSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.arrayOf(PropTypes.node)
};

export default TestimonialSection;