import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import selectors from './partnerBankingInfoSelectors';
import actions from './partnerBankingInfoActions';
import Spinner from '../../../components/Spinner/Spinner';
import CardInfo from '../../../components/CardInfo/CardInfo';
import InfoBox from '../../../components/InfoBox/InfoBox';
import BankingInfoForm from '../../Shared/BankingInfo/BankingInfoForm';
import BankingInfoSecurityNote from '../../Shared/BankingInfo/BankingInfoSecurityNote';
import BankingInfoStatus from '../../Shared/BankingInfo/BankingInfoStatus';
import '../../Shared/BankingInfo/_banking-info.scss';

function PartnerBankingInfoTab({
    match,
    isLoading,
    submit,
    bankingInfo,
    getBankingInfo,
    getBankAccountDetails,
    payeeToken,
    accountNumber,
    routingNumber,
    isAdmin
}) {
    useEffect(() => {
        getBankingInfo(match.params.partnerId);
    }, []);

    return (
        <div className="banking-info grid__row" id="banking_info">
            {isLoading ?
                (
                    <Spinner />
                )
                : (
                    <Fragment>
                        <div className="col-md-8">
                            <CardInfo title={bankingInfo.get('partnerName')}>
                                <div className="col-xs-12">
                                    <BankingInfoStatus bankingInfo={bankingInfo} />
                                </div>
                                <div className="col-xs-12">
                                    <InfoBox title="Withdrawal From Banking Information" bordered>
                                        {
                                            <BankingInfoForm
                                                form={'bankingInfo'}
                                                initialValues={bankingInfo}
                                                onSubmit={submit}
                                                getBankAccountDetails={getBankAccountDetails}
                                                payeeToken={payeeToken}
                                                accountNumber={accountNumber}
                                                routingNumber={routingNumber}
                                                isAdmin={isAdmin}
                                            />
                                        }
                                    </InfoBox>
                                </div>
                            </CardInfo>
                        </div>
                        <div className="col-md-4">
                            <BankingInfoSecurityNote />
                        </div>
                    </Fragment>
                )}
        </div>
    );
}

PartnerBankingInfoTab.propTypes = {
    match: PropTypes.object.isRequired,
    getBankingInfo: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    bankingInfo: PropTypes.object.isRequired,
    payeeToken: PropTypes.string,
    getBankAccountDetails: PropTypes.func,
    accountNumber: PropTypes.string,
    routingNumber: PropTypes.string,
    isAdmin: PropTypes.bool
};

export default connect(selectors, actions)(PartnerBankingInfoTab);
