import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import selectors from './partnerBillingInfoSelectors';
import Spinner from '../../../components/Spinner/Spinner';
import PartnerBillingInfoForm from './PartnerBillingInfoForm';
import { getPaymentTypesList, getBillingInfo, updateBillingInfo } from './partnerBillingInfoActions';
import './_partner-billing-info.scss';

function PartnerBillingInfoTab({
    isLoading,
    match,
    billingInfo,
    getPaymentTypesList,
    getBillingInfo,
    updateBillingInfo
}) {
    useEffect(() => {
        getPaymentTypesList();
    }, []);
    useEffect(() => {
        getBillingInfo(match.params.partnerId);
    }, [match.params.partnerId]);

    function handleUpdateBillingInfo(billingInfo) {
        updateBillingInfo(match.params.partnerId, billingInfo.toJS());
    }
    return (
        <div className="partner-billing-info">
            {
                isLoading ?
                    <div>
                        <Spinner />
                    </div>
                    : <></>
            }
            <PartnerBillingInfoForm initialValues={billingInfo} onSubmit={handleUpdateBillingInfo} />
        </div>
    );
}

PartnerBillingInfoTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    billingInfo: PropTypes.object.isRequired,
    getPaymentTypesList: PropTypes.func.isRequired,
    getBillingInfo: PropTypes.func.isRequired,
    updateBillingInfo: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    getPaymentTypesList,
    getBillingInfo,
    updateBillingInfo
};

export default connect(selectors, mapDispatchToProps)(PartnerBillingInfoTab);
