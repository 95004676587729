import * as loadingActions from '../../Loading/loadingActions';
import * as types from './voucherProgramTypes';
import * as voucherProgramsCreateService from './voucherProgramService';

const setVoucherProgramAction = (voucherProgram) => {
    return { type: types.SET_VOUCHER_PROGRAM, voucherProgram };
};

export function getVoucherProgram(voucherProgramId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_VOUCHER_PROGRAM));
        return voucherProgramsCreateService
            .getVoucherProgram(voucherProgramId)
            .then((voucherProgram) => {
                dispatch(setVoucherProgramAction(voucherProgram));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_VOUCHER_PROGRAM));
            });
    };
}

export function saveVoucherProgram(voucherProgram, partnerLogoFile) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.SAVE_VOUCHER_PROGRAM));
        return voucherProgramsCreateService
            .saveVoucherProgram(voucherProgram, partnerLogoFile)
            .then((result) => {
                if (result.success) {
                    dispatch(setVoucherProgramAction(result.data));
                    return result.data;
                }
            })
            .catch((errorResponse) => {
                dispatch(setVoucherProgramAction(errorResponse.data.data));
                throw errorResponse;
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.SAVE_VOUCHER_PROGRAM));
            });
    };
}

export function setVoucherProgram(voucherProgram) {
    return function (dispatch) {
        dispatch(setVoucherProgramAction(voucherProgram));
    };
}