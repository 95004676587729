import * as types from './programTypeTypes';
import * as programTypeService from './programTypeService';
import * as loadingActions from '../Loading/loadingActions';

const getProgramTypesSuccess = (programTypes) => {
    return { type: types.GET_PROGRAM_TYPES_SUCCESS, programTypes };
};

export function getProgramTypes() {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_PROGRAM_TYPES));
        return programTypeService
            .getProgramTypes()
            .then((programTypes) => {
                dispatch(getProgramTypesSuccess(programTypes));
            })
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_PROGRAM_TYPES))
            );
    };
}
