import React from 'react';
import PropTypes from 'prop-types';
import './_topbar.scss';

function Topbar({ topbarContent }) {
    return topbarContent ?
        (
            <div className="topbar">
                <div className="topbar__content">
                    {React.createElement(topbarContent)}
                </div>
            </div>
        )
        : (
            ''
        );
}

Topbar.propTypes = {
    topbarContent: PropTypes.object
};

export default Topbar;
