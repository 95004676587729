import React from 'react';
import PropTypes from 'prop-types';
import CustomArrow from './CustomArrow';

function NextArrow(props) {
    return (
        <CustomArrow className="next-arrow" icon="arrow_forward"
            onClick={props.onClick}
        />
    );
}

NextArrow.propTypes = {
    onClick: PropTypes.func
};

export default NextArrow;