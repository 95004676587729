import { createSelector } from 'reselect';

const getIsGroupMembershipAddedToPartner = (state) => {
    return state.partnerOwnerAreaReducer.get('isGroupMembershipAddedToPartner');
};

const checkShouldRedirect = createSelector(
    [getIsGroupMembershipAddedToPartner],
    (isGroupMembershipAddedToPartner) => {
        return isGroupMembershipAddedToPartner !== undefined;
    }
);

const getRoutes = (state, props) => {
    return props.currentRoute.routes;
};

export default createSelector(
    [
        checkShouldRedirect,
        getRoutes,
        getIsGroupMembershipAddedToPartner
    ],
    (shouldRedirect, filteredRoutes, isGroupMembershipAddedToPartner) => ({
        shouldRedirect, filteredRoutes, isGroupMembershipAddedToPartner
    })
);
