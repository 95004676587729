import { fromJS } from 'immutable';
import {
    SET_LOADING,
    UNSET_LOADING,
    GET_INSURANCE_INFO_SUCCESS,
} from './types';

import { initialState } from './initials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;

    switch (type) {
        case SET_LOADING:
            return state.set('isLoading', true);

        case UNSET_LOADING:
            return state.set('isLoading', false);

        case GET_INSURANCE_INFO_SUCCESS: {
            return state.set('insuranceInfo', fromJS(payload.insuranceInfo));
        }
        default:
            return state;
    }
}