import { GET_BANKING_INFO_SUCCESS, SET_BANK_ACCOUNT } from './partnerBankingInfoTypes';
import { fromJS } from 'immutable';
import { initialState } from './partnerBankingInfoInitials';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_BANKING_INFO_SUCCESS:
            return state.set('bankingInfo', fromJS(action.payload.bankingInfo));
        case SET_BANK_ACCOUNT:
            return state.set('bankAccount', fromJS(action.payload.bankAccount));
        default:
            return state;
    }
}