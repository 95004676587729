import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form/immutable';
import FormGroup from '../../../components/controls/FormGroup';
import InputSelect from '../../../components/controls/InputSelect';
import MuiButton from '../../../components/controls/MuiButton';
import { defaultFormProperties } from '../../../utilities/form';
import wellnessProgramPaymentResources from '../wellnessProgramPaymentsResources';
import * as validationRules from './facilityPaymentsGenerationFormValidation';

const FacilityPatnerPaymentsGenerationForm = ({
    gmrAddProgram,
    pristine,
    submitting,
    handleSubmit,
    programTypes,
    programPaymentTypes,
    periods,
    partners,
    onProgramPaymentTypeChange }) => {
    return <FormGroup onSubmit={handleSubmit}>
        {
            gmrAddProgram ?
                <>
                    <Field
                        label={wellnessProgramPaymentResources.labelProgramType}
                        name={wellnessProgramPaymentResources.fieldProgramPaymentType}
                        options={programPaymentTypes}
                        getOptionLabel={option => option.get('name')}
                        getOptionValue={option => option.get('id')}
                        component={InputSelect}
                        validate={[
                            validationRules.programTypeRequired
                        ]}
                        onChange={(option) => {
                            const programPaymentTypeID = option.get('id');
                            onProgramPaymentTypeChange(programPaymentTypeID);
                        }}
                    />
                </>
                :
                <Field
                    label={wellnessProgramPaymentResources.labelForProgramType}
                    name="programType"
                    options={programTypes}
                    getOptionLabel={option => option.get('name')}
                    getOptionValue={option => option.get('id')}
                    component={InputSelect}
                    isDisabled
                />
        }
        <Field
            component={InputSelect}
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelPartner : wellnessProgramPaymentResources.labelForPartner}
            name="partner"
            options={partners}
            getOptionLabel={option => option.get('name')}
            getOptionValue={option => option.get('id')}
            validate={[
                gmrAddProgram ? validationRules.partnerRequired : validationRules.forPartnerRequired
            ]}
        />
        <Field
            component={InputSelect}
            label={gmrAddProgram ? wellnessProgramPaymentResources.labelVisitMonth : wellnessProgramPaymentResources.labelForUsagePeriod}
            name="period"
            options={periods}
            getOptionLabel={option => option.get('yearMonth')}
            getOptionValue={option => option.get('id')}
            validate={[
                gmrAddProgram ? validationRules.periodRequired : validationRules.forPeriodRequired
            ]}
        />
        <div className="grid__row">
            <div className="col-lg-4">
                <MuiButton
                    className="btn_new btn_new--blue"
                    name="buttonGenerate"
                    type="submit"
                    disabled={submitting}
                >
                    {wellnessProgramPaymentResources.buttonGenerate}
                </MuiButton>
            </div>
        </div>
    </FormGroup>;
};

FacilityPatnerPaymentsGenerationForm.propTypes = {
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    programTypes: PropTypes.object.isRequired,
    programPaymentTypes: PropTypes.object.isRequired,
    periods: PropTypes.object.isRequired,
    partners: PropTypes.object.isRequired,
    gmrAddProgram: PropTypes.bool,
    onProgramPaymentTypeChange: PropTypes.func.isRequired
};

export default reduxForm({
    form: wellnessProgramPaymentResources.formFacilityPaymentsGeneration,
    ...defaultFormProperties
})(FacilityPatnerPaymentsGenerationForm);
