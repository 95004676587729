import * as types from './forgotPasswordTypes';
import * as forgotPasswordService from './forgotPasswordService';
import * as loadingActions from '../../features/Loading/loadingActions';

export const setIsForgotPasswordPopupOpen = isForgotPasswordPopupOpen => ({
    type: types.SET_IS_FORGOT_PASSWORD_POPUP_OPEN,
    isForgotPasswordPopupOpen
});

export const setIsForgotPasswordResultPopupOpen = isForgotPasswordResultPopupOpen => ({
    type: types.SET_IS_FORGOT_PASSWORD_RESULT_POPUP_OPEN,
    isForgotPasswordResultPopupOpen
});

export const setUsername = username => ({
    type: types.SET_USERNAME,
    username
});

export function forgotPassword(username, captchaToken) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.FORGOT_PASSWORD));
        return forgotPasswordService.forgotPassword(username, captchaToken)
            .then((result) => {
                if (result.success) {
                    dispatch(setUsername(username));
                    dispatch(setIsForgotPasswordPopupOpen(false));
                    dispatch(setIsForgotPasswordResultPopupOpen(true));
                }
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.FORGOT_PASSWORD));
            });

    };
}

export function resend(username) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.RESEND));
        return forgotPasswordService.resend(username, true)
            .finally(() => dispatch(loadingActions.unsetLoading(types.RESEND)));
    };
}
