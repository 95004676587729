import { initialState } from './plansFilterInitials';
import { fromJS } from 'immutable';
import * as types from './plansFilterTypes';

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_PLANS_FILTERS: {
            return state.set(
                'plansFilters', fromJS(action.plansFilters)
            );
        }
        default:
            return state;
    }
}