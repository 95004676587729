import React from 'react';
import CustomArrow from './CustomArrow';
import PropTypes from 'prop-types';

function PrevArrow(props) {
    return (
        <CustomArrow className="prev-arrow" icon="arrow_back"
            onClick={props.onClick}
        />
    );
}

PrevArrow.propTypes = {
    onClick: PropTypes.func
};
export default PrevArrow;