const VoucherProgramFormResources = {
    headerActions: 'Actions',
    headerRecipients: 'Voucher Codes and Recipients',
    columnNameVoucherCode: 'Voucher Code',
    columnNameEmailAddress: 'Email',
    columnNameStatus: 'Status',
    columnNameFacilityID: 'Facility ID',
    columnNameFacilityName: 'Facility Name',
    columnNameFacilityManagerName: 'Facility Manager Name',
    columnNameRedemptionDate: 'Redemption Date',
    labelPreview: 'Email Preview',
    labelBack: 'Back',
    labelPublish: 'Publish',
    textPublishConfirmation: 'Are you sure you want to publish the program? After this action, all vouchers will be sent to the specified email addresses and you will not be able to edit anything.'
};
export default VoucherProgramFormResources;
