import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../controls/Button';
import MaterialIcon from '../MaterialIcon/MaterialIcon';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './_popup-body.scss';
import sharedResources from '../../features/Shared/resources';

export function PopupBody({ title, body, labelCancel, labelOk, onOk, onClose, onCancel, dispatch, buttonSize }) {
    return (
        <div className="react-confirm-alert">
            <div className="react-confirm-alert-body">
                <button className="popup-body__close-icon-button" onClick={onClose ? onClose : onCancel}>
                    <MaterialIcon icon="clear" />
                </button>
                {title && <div className="popup-body__title">{title}</div>}
                <div className="popup-body__body">{body}</div>
                {(onOk || onCancel) && <div className="popup-body__button-group">
                    {onOk && (
                        <Button
                            theme="new btn_new--blue"
                            size={buttonSize}
                            className="btn_new--squared no-wrap"
                            onClick={() => {
                                onOk(dispatch);
                                if (onClose) {
                                    onClose();
                                }
                            }}
                        >
                            {labelOk}
                        </Button>
                    )}
                    {
                        onCancel && (
                            <Button
                                theme="new btn_new--white no-wrap"
                                size={buttonSize}
                                className="btn_new--squared"
                                onClick={onClose ? onClose : onCancel}
                            >
                                {labelCancel}
                            </Button>
                        )}
                </div>}
            </div>
        </div>
    );
}

PopupBody.defaultProps = {
    labelCancel: sharedResources.cancelLabel,
    labelOk: sharedResources.okLabel,
    buttonSize: 'small',
};

PopupBody.propTypes = {
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    labelCancel: PropTypes.string.isRequired,
    labelOk: PropTypes.string.isRequired,
    onOk: PropTypes.func,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
    dispatch: PropTypes.func,
    buttonSize: PropTypes.string,
};

export default connect()(PopupBody);
