import {
    SET_LOADING,
    UNSET_LOADING,
    SET_FIELDS
} from './types';

const setLoading = () => ({
    type: SET_LOADING
});

const unsetLoading = () => ({
    type: UNSET_LOADING
});

const setFields = fieldsData => ({
    type: SET_FIELDS,
    payload: { fieldsData }
});

const loadFieldsData = memberIds => (dispatch, getState, { GeneralInfoService }) => {
    dispatch(setLoading());
    GeneralInfoService.getGeneralInfo(memberIds[0])
        .then((data) => {
            dispatch(setFields(data));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

export default {
    setLoading,
    unsetLoading,
    loadFieldsData,
    setFields
};
