import validationRules from '../../../../../utilities/validationRules';
import keyfobFormResources from './keyfobFormResources';
import * as keyfobConstants from '../../../../Shared/Keyfobs/keyfobConstants';

export const keyfobRequired = validationRules.required(
    keyfobFormResources.labelKeyfob
);
export const keyfobMinMaxLength = validationRules.length(
    keyfobFormResources.labelKeyfob,
    keyfobConstants.MIN_KEYFOB_LENGTH,
    keyfobConstants.MAX_KEYFOB_LENGTH
);
export const keyfobValidRadixNumber = validationRules.bigInt(
    keyfobConstants.KEYFOB_RADIX,
    keyfobFormResources.labelKeyfob
);