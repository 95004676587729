import * as types from './types';
import {
    extractMemberGroup,
    preserveMemberGroup
} from '../../utilities/localStorage';
import AppService from './service';

const setLoadingAction = () => ({
    type: types.SET_LOADING
});

const unsetLoadingAction = () => ({
    type: types.UNSET_LOADING
});

const loadMeAction = me => ({
    type: types.LOAD_ME,
    payload: { me }
});

const changeSelectedMemberGroupAction = selectedMemberGroup => ({
    type: types.CHANGE_SELECTED_MEMBER_GROUP,
    payload: { selectedMemberGroup }
});

const changeSelectedMemberGroup = selectedMemberGroup => (dispatch) => {
    preserveMemberGroup(selectedMemberGroup);
    dispatch(changeSelectedMemberGroupAction(selectedMemberGroup));
};

const loadMe = () => (dispatch) => {
    dispatch(setLoadingAction());
    AppService.getMe()
        .then((me) => {
            if (!me) {
                // 'me' wasn't loaded - this means that we are not authorized (and redirection is in progress) or server is unavailable;
                // in this case we need to leave global spinner, because 'me' is critical for the entire application's code;
                // so just return from function.
                return;
            }
            dispatch(loadMeAction(me));
            dispatch(
                changeSelectedMemberGroup(
                    me.memberGroups && me.memberGroups.length ? extractMemberGroup(me.memberGroups) : me
                )
            );
            dispatch(unsetLoadingAction());
        })
        // don't use finally here to unset loading, otherwise you'll see yellow error page for a second before redirect to login page;
        .catch(() => {
            dispatch(unsetLoadingAction());
        });
};

const unsetAppLoading = () => (dispatch) => {
    dispatch(unsetLoadingAction());
};

const resetMe = () => (dispatch) => {
    dispatch(loadMeAction(null));
};

export default {
    setLoadingAction,
    unsetAppLoading,
    loadMe,
    changeSelectedMemberGroup,
    resetMe
};