import { GET_FACILITIES, RENDER_FACILITIES } from './types';
import * as loadingActions from '../../../Loading/loadingActions';

const renderFacilities = facilities => ({
    type: RENDER_FACILITIES,
    payload: { facilities }
});

const loadFacilities = membersIds => (
    dispatch,
    getState,
    { FacilityInfoService }
) => {
    dispatch(loadingActions.setLoading(GET_FACILITIES));
    FacilityInfoService.getFacilityInfo(membersIds)
        .then((facilities) => {
            dispatch(renderFacilities(facilities));
        })
        .finally(() => {
            dispatch(loadingActions.unsetLoading(GET_FACILITIES));
        });
};

export default {
    renderFacilities,
    loadFacilities
};
