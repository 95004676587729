import * as types from './facilityLocatorTypes';
import FacilityLocatorService from './facilityLocatorService';
import * as loadingActions from '../../../Loading/loadingActions';

const setFacilitiesForMap = facilities => ({
    type: types.SET_FACILITIES_FOR_MAP,
    facilities
});

export const getFacilitiesForMap = () => (dispatch, getState) => {
    dispatch(loadingActions.setLoading(types.GET_FACILITIES_FOR_MAP));
    FacilityLocatorService.getFacilitiesForMap()
        .then((facilities) => {
            dispatch(setFacilitiesForMap(facilities));
        })
        .finally(() => dispatch(loadingActions.unsetLoading(types.GET_FACILITIES_FOR_MAP)));
};