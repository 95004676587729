import { fromJS } from 'immutable';
import initialState from './facilityPaymentsGenerationSectionInitialState';
import * as types from './facilityPaymentsGenerationSectionTypes';

export default function (state = initialState, action) {
    switch (action.type) {
        case types.SET_PERIOD:
            return state.setIn(['paymentsGenerationSettings', 'period'], action.period);
        case types.SET_PROGRAM_TYPE:
            return state.setIn(['paymentsGenerationSettings', 'programType'], action.programType);
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.setIn(['paymentsGenerationSettings', 'programPaymentType'], action.programPaymentType);
        case types.SET_PARTNER:
            return state.setIn(['paymentsGenerationSettings', 'partner'], action.partner);
        case types.SET_PARTNERS:
            return state.set('partners', fromJS(action.partners));
        case types.SET_PAYMENTS_EXIST:
            return state.set('doPaymentsExist', action.doPaymentsExist);
        default:
            return state;
    }
}