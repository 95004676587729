import * as types from './stateTypes';
import * as StatesService from './stateService';
import * as loadingActions from '../Loading/loadingActions';

const getStatesListSuccess = (states) => {
    return { type: types.GET_STATES_LIST_SUCCESS, states };
};

export function getStatesList() {
    return function(dispatch) {
        dispatch(loadingActions.setLoading(types.GET_STATES_LIST));
        return StatesService.getStatesList()
            .then((states) => {
                dispatch(getStatesListSuccess(states));
            })
            .finally(() =>
                dispatch(loadingActions.unsetLoading(types.GET_STATES_LIST))
            );
    };
}
