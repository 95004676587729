import * as types from './partnerOwnerAreaTypes';
import { initialState } from './partnerOwnerAreaInitials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case types.SET_IS_GROUP_MEMBERSHIP_ADDED_TO_PARTNER:
            return state.set(
                'isGroupMembershipAddedToPartner',
                payload.isGroupMembershipAddedToPartner
            );
        case types.SET_PARTNER_NAME:
            return state.set(
                'partnerName',
                payload.partnerName
            );
        case types.SET_PROGRAM_PAYMENT_TYPE:
            return state.set(
                'programPaymentTypeID',
                payload.programPaymentType
            );
        default:
            return state;
    }
}
