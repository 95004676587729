import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../../components/Spinner/Spinner';
import CardInfo from '../../../components/CardInfo/CardInfo';
import selectors from './partnerManagerProfileGeneralInfoTabSelectors.js';
import ManagePartnerManagerForm from '../../Shared/PartnerManager/ManagePartnerManagerForm';
import {
    updateManager,
    getManager
} from '../../Shared/PartnerManager/managePartnerManagerActions';
import WrappedConfirmPopup from '../../../components/Popups/ConfirmAlert/WrappedConfirmPopup';

function PartnerManagerProfileGeneralInfoTab({
    isLoading,
    manager,
    updateManager
}) {
    function handleSave(updatedManager) {
        const oldManager = manager.toJS();
        updatedManager = updatedManager.toJS();
        if (
            updatedManager.email
                .toLowerCase()
                .localeCompare(oldManager.email.toLowerCase()) !== 0
        ) {
            WrappedConfirmPopup({
                body: (
                    <>
                        <div className="message">
                            You will be logged out as we've noticed email
                            address change.
                        </div>
                        <div className="message">Please confirm to procceed.</div>
                    </>
                ),
                onConfirm: () => {
                    updateManager(updatedManager, true);
                }
            });
        } else {
            updateManager(updatedManager);
        }
    }
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <CardInfo>
                <div className="col-xs-12 col-lg-6">
                    <ManagePartnerManagerForm
                        isShowPasswordControls={false}
                        onSubmit={handleSave}
                        initialValues={manager}
                    />
                </div>
            </CardInfo>
        );
}

PartnerManagerProfileGeneralInfoTab.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    manager: PropTypes.object.isRequired,
    updateManager: PropTypes.func.isRequired
};

const mapDispatchToProps = { getManager, updateManager };
const connectStateToProps = connect(selectors, mapDispatchToProps);

export default connectStateToProps(PartnerManagerProfileGeneralInfoTab);
