import * as types from './managePartnerManagerTypes';
import initialState from './managePartnerManagerInitialState';
import { fromJS } from 'immutable';

export default function partnerManagerReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_MANAGER:
            return state.set('manager', fromJS(action.manager));
        default:
            return state;
    }
}
