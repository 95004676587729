import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { generatePath } from 'react-router';
import routes from '../../../routing/routes';
import {
    getVoucherProgram,
    saveVoucherProgram,
    setVoucherProgram
} from './voucherProgramActions';
import VoucherProgramForm from '../VoucherProgramForm/VoucherProgramForm';
import Spinner from '../../../components/Spinner/Spinner';
import selectors from './voucherProgramSelectors';
import CardInfo from '../../../components/CardInfo/CardInfo';
import './_voucher-program.scss';

function VoucherProgram({
    getVoucherProgram,
    saveVoucherProgram,
    setVoucherProgram,
    history,
    isLoading,
    voucherProgram,
    match,
    isPublished,
    isAdd,
    title,
    partnerLogoFileName
}) {
    useEffect(() => {
        if (isAdd) {
            setVoucherProgram({});
        } else {
            getVoucherProgram(match.params.voucherProgramId);
        }
    }, [match.params.voucherProgramId]);
    function handleSave(voucherProgramFromForm) {
        const voucherProgramFromFormJs = voucherProgramFromForm.toJS();
        voucherProgramFromFormJs.partnerID = match.params.partnerId;
        voucherProgramFromFormJs.id = match.params.voucherProgramId;
        const partnerLogoFile = voucherProgramFromFormJs.partnerLogo ? voucherProgramFromFormJs.partnerLogo[0] : null;
        const savePromise = saveVoucherProgram(voucherProgramFromFormJs, partnerLogoFile);
        if (isAdd) {
            savePromise.then(() => {
                history.push(generatePath(routes.voucherProgramsList.path, match.params));
            });
        }
    }
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div className="voucher-program">
                <h2 className="layout__title">{title}</h2>
                <CardInfo shadow={false}>
                    <div className="col-xs">
                        <VoucherProgramForm
                            onSubmit={handleSave}
                            initialValues={voucherProgram}
                            history={history}
                            match={match}
                            isAdd={isAdd}
                            isPublished={isPublished}
                            partnerLogoFileName={partnerLogoFileName}
                        />
                    </div>
                </CardInfo>
            </div>
        );
}

VoucherProgram.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isPublished: PropTypes.bool.isRequired,
    getVoucherProgram: PropTypes.func.isRequired,
    saveVoucherProgram: PropTypes.func.isRequired,
    setVoucherProgram: PropTypes.func.isRequired,
    voucherProgram: PropTypes.object.isRequired,
    isAdd: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    partnerLogoFileName: PropTypes.string
};

const actions = {
    getVoucherProgram,
    saveVoucherProgram,
    setVoucherProgram
};

export default connect(selectors, actions)(VoucherProgram);
