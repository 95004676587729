import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// All available icons: https://fonts.google.com/icons?icon.style=Outlined&icon.set=Material+Icons and https://fonts.google.com/icons?icon.style=Filled&icon.set=Material+Icons
function MaterialIcon({ id, role, icon, className, variant, onClick, ariaHidden, tabIndex, filterExpandContentId, ariaExpanded, label }) {
    return (
        <span
            id={id}
            role={role}
            onClick={onClick}
            className={classNames([
                { [`material-icons-${variant}`]: variant !== 'filled' },
                { 'material-icons': variant === 'filled' },
                'material-icon', className])}
            aria-hidden={ariaHidden ? 'true' : 'false'}
            tabIndex={tabIndex}
            aria-controls={filterExpandContentId}
            aria-expanded={ariaExpanded}
            aria-label={label ? label : icon}
        >
            {icon}
        </span>
    );
}

MaterialIcon.defaultProps = {
    variant: 'outlined'
};

MaterialIcon.propTypes = {
    id: PropTypes.string,
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    role: PropTypes.string,
    ariaHidden: PropTypes.bool,
    tabIndex: PropTypes.string,
    filterExpandContentId: PropTypes.string,
    ariaExpanded: PropTypes.bool,
    label: PropTypes.string
};

export default MaterialIcon;
