import { createSelector } from 'reselect';
import { CHECK_PAYMENTS_EXISTENCE, GENERATE_PAYMENTS, GET_PARTNERS } from './facilityPaymentsGenerationSectionTypes';
import baseSelectors from '../FacilityPaymentsTab/facilityPaymentsSelectors';
import { GET_CLOSED_USAGE_PERIODS } from '../../Shared/Periods/periodsTypes';
import facilityPaymentsGenerationSectionResources from './facilityPaymentsGenerationSectionResources';
import wellnessProgramPaymentResources from '../../WellnessProgramPayments/wellnessProgramPaymentsResources';
import { formValueSelector } from 'redux-form';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CHECK_PAYMENTS_EXISTENCE || i === GENERATE_PAYMENTS || i === GET_PARTNERS || i === GET_CLOSED_USAGE_PERIODS
    ).size;

    return size > 0;
};

const getPaymentsGenerationSettings = state => state.facilityPaymentsGenerationSectionReducer.get('paymentsGenerationSettings');
const getForm = formValueSelector(wellnessProgramPaymentResources.formFacilityPaymentsGeneration, state => state.form.toJS());
const getSelectedProgramTypeID = state => state.facilityPaymentsGenerationSectionReducer.getIn(['paymentsGenerationSettings', 'programType', 'id']);
const getSelectedProgramPaymentTypeID = (state) => {
    const selectedProgramPaymentType = getForm(state, wellnessProgramPaymentResources.fieldProgramPaymentType);
    if (selectedProgramPaymentType) {
        return selectedProgramPaymentType.id;
    }
};
const getSelectedPeriodID = state => state.facilityPaymentsGenerationSectionReducer.getIn(['paymentsGenerationSettings', 'period', 'id']);
const getSelectedPartnerID = state => state.facilityPaymentsGenerationSectionReducer.getIn(['paymentsGenerationSettings', 'partner', 'id']);
const getPartners = state => state.facilityPaymentsGenerationSectionReducer.get('partners');
const getDoPaymentsExist = state => state.facilityPaymentsGenerationSectionReducer.get('doPaymentsExist');

const getMessage = createSelector([baseSelectors, getSelectedPeriodID, getPartners, getSelectedPartnerID], (baseProps, selectedPeriodID, partners, selectedPartnerID) => {
    if (baseProps.periods && selectedPeriodID && partners && selectedPartnerID) {
        return facilityPaymentsGenerationSectionResources.facilityPaymentWarningMessageFormat;
    }
    return '';
});

export default createSelector(
    [
        getIsLoading,
        getPaymentsGenerationSettings,
        getMessage,
        getSelectedProgramTypeID,
        getSelectedProgramPaymentTypeID,
        getSelectedPeriodID,
        getSelectedPartnerID,
        getPartners,
        getDoPaymentsExist
    ],
    (
        isLoading,
        paymentsGenerationSettings,
        message,
        selectedProgramTypeID,
        selectedProgramPaymentTypeID,
        selectedPeriodID,
        selectedPartnerID,
        partners,
        doPaymentsExist
    ) => ({
        isLoading,
        paymentsGenerationSettings,
        message,
        selectedProgramTypeID,
        selectedProgramPaymentTypeID,
        selectedPeriodID,
        selectedPartnerID,
        partners,
        doPaymentsExist
    })
);