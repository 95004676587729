import validationRules from '../../../utilities/validationRules';
import * as generalInfoConstants from '../GeneralInfo/generalInfoConstants';

export const emailRegex = (labelEmail) => {
    return validationRules.email(
        labelEmail
    );
};

export const emailRequired = (labelEmail) => {
    return validationRules.required(
        labelEmail
    );
};

export const emailMaxLength = (labelEmail, maxLength = generalInfoConstants.MAX_EMAIL320_LENGTH) => validationRules.length(
    labelEmail,
    0,
    maxLength
);