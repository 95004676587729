import { matchPath } from 'react-router-dom';

export const authorizeRoutes = (me, routesToRender) => {
    const authorizedRoutes = routesToRender.filter(route =>
        checkIfRouteIsAuthorized(route, me ? me.role : undefined)
    );
    authorizedRoutes.forEach((route) => {
        if (route.routes && route.routes.length > 0) {
            route.routes = authorizeRoutes(me, route.routes);
        }
    });
    return authorizedRoutes;
};

function checkIfRouteIsAuthorized(route, role) {
    return route.authorize === undefined
        ? true
        : hasRole(route.authorize, role);
}

const hasRole = (requiredRoles, userRole) => {
    return requiredRoles.includes(userRole);
};

export function checkIfPathMatchesAnyInCollection(currentPath, pathsCollection) {
    for (let i = 0; i < pathsCollection.length; i++) {
        if (matchPath(currentPath, {
            path: pathsCollection[i],
            exact: true
        })) {
            return true;
        }
    }
    return false;
}
