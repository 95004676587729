import { createSelector } from 'reselect';
import { GET_EQUIPMENT_AND_FACILITIES_POLICIES } from './legalContractFormTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_EQUIPMENT_AND_FACILITIES_POLICIES)
        .size;
    return size > 0;
};

const getEquipmentAndFacilitiesPolicies = state => state.legalContractFormReducer.get('equipmentAndFacilitiesPolicies');

export default createSelector(
    [
        getIsLoading,
        getEquipmentAndFacilitiesPolicies,
    ],
    (
        isLoading,
        equipmentAndFacilitiesPolicies
    ) => (
        {
            isLoading,
            equipmentAndFacilitiesPolicies
        }));

