import React from 'react';
import PropTypes from 'prop-types';
import VisitLogTab from './VisitLogTab';

function HCAdminVisitLogTab({ match })
{
    return <VisitLogTab partnerID={Number(match.params.partnerId)}/>;
}

HCAdminVisitLogTab.propTypes = {
    match: PropTypes.object.isRequired
};

export default HCAdminVisitLogTab;