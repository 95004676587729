const nominateClubResources = {
    labelName: 'Full Name',
    labelEmail: 'Email Address',
    labelProvider: 'Program or Provider Name',
    labelClubName: 'Club Name',
    labelCity: 'City',
    labelState: 'State',
    labelContactName: 'Contact Name',
    labelPhone: 'Contact number',
    labelClubEmail: 'Email Address',
    fieldName: 'name',
    fieldEmail: 'email',
    fieldProvider: 'provider',
    fieldClubName: 'clubName',
    fieldCity: 'city',
    fieldState: 'state',
    fieldContactName: 'clubContactName',
    fieldPhone: 'clubContactNumber',
    fieldClubEmail: 'clubEmail',
    linkButtonNextText: 'Next',
    linkButtonPreviousText: 'Back',
    nominateButtonSubmitText: 'Submit',
    nominateClubInfo: 'nominateClubBody',
    generalInfoText: 'General Info',
    clubInfoText: 'Club Info',
    generalInfoStepText: 'Please enter general information below',
    clubInfoStepText: 'Please enter club related information below'
};
export default nominateClubResources;