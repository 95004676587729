import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';
import { MULTIPART_FORM_DATA } from '../../../constants/contentTypes';

export function getVouchers(voucherProgramID) {
    return http.get(replaceRouteParams(API_PATHS.getVoucherProgramVouchers, voucherProgramID));
}

export function uploadRecipients(voucherProgramId, recipientsFile) {
    const formData = new FormData();
    formData.append('recipientsFile', recipientsFile);
    const config = {
        headers: {
            'content-type': MULTIPART_FORM_DATA
        },
        params: { voucherProgramId }
    };
    return http.post(API_PATHS.uploadRecipients, formData, config);
}

export function previewVoucherEmail(voucherProgramId) {
    return http.get(replaceRouteParams(API_PATHS.previewVoucherEmail, voucherProgramId));
}

export function publishVoucherProgram(voucherProgramID) {
    return http.put(replaceRouteParams(API_PATHS.publishVoucherProgram, voucherProgramID));
}

export function checkVoucherProgramPublished(voucherProgramID) {
    return http.get(replaceRouteParams(API_PATHS.checkVoucherProgramPublished, voucherProgramID));
}
