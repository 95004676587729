import { createSelector } from 'reselect';
import { GET_PARTNER } from '../Shared/Partners/managePartnerTypes';

const getIsGroupMembershipAddedToPartner = state => state.partnerOwnerAreaReducer.get('isGroupMembershipAddedToPartner');

const getPartnerName = state => state.partnerOwnerAreaReducer.get('partnerName');

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === GET_PARTNER
    ).size;

    return size > 0;
};

const checkShouldRedirect = createSelector(
    [getIsGroupMembershipAddedToPartner, getPartnerName],
    (isGroupMembershipAddedToPartner, partnerName) => {
        return isGroupMembershipAddedToPartner !== undefined && partnerName !== undefined;
    }
);

export default createSelector(
    [
        getIsLoading,
        checkShouldRedirect,
        getIsGroupMembershipAddedToPartner
    ],
    (
        isLoading,
        shouldRedirect,
        isGroupMembershipAddedToPartner
    ) => ({
        isLoading,
        shouldRedirect,
        isGroupMembershipAddedToPartner
    })
);
