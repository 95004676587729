import { createSelector } from 'reselect';
import allRoutes from '../../routing/routes';

const getFilteredRoutes = (state, props) => {
    return props.currentRoute.routes.filter((route) => {
        return route.name !== allRoutes.voucherProgramsEdit.name
            && route.name !== allRoutes.voucherProgramsCreate.name
            && route.name !== allRoutes.voucherProgramRecipientsList.name;
    });
};

export default createSelector(
    [
        getFilteredRoutes,
    ],
    filteredRoutes => ({
        filteredRoutes
    })
);