import validationRules from '../../../utilities/validationRules';
import partnerPaymentsFeesConfirmationFormResources from './partnerPaymentsFeesConfirmationFormResources';

export const startupFeeAmountRange  = validationRules.range(
    partnerPaymentsFeesConfirmationFormResources.labelForStartupFeeAmount,
    0,
    999999.99
);
export const startupFeeAmountDecimalWithTwoPlaces  = validationRules.decimalWithTwoPlaces(partnerPaymentsFeesConfirmationFormResources.labelForStartupFeeAmount);
export const startupFeeAmountNumeric = validationRules.numericOrEmpty(partnerPaymentsFeesConfirmationFormResources.labelForStartupFeeAmount);

export const renewalFeeAmountRange  = validationRules.range(
    partnerPaymentsFeesConfirmationFormResources.labelForRenewalFeeAmount,
    0,
    999999.99
);
export const renewalFeeAmountDecimalWithTwoPlaces  = validationRules.decimalWithTwoPlaces(partnerPaymentsFeesConfirmationFormResources.labelForRenewalFeeAmount);
export const renewalFeeAmountNumeric = validationRules.numericOrEmpty(partnerPaymentsFeesConfirmationFormResources.labelForRenewalFeeAmount);

export const facilityAccessFeeAmountRange  = validationRules.range(
    partnerPaymentsFeesConfirmationFormResources.labelForFacilityAccessFeeAmount,
    0,
    999999.99
);
export const facilityAccessFeeAmountDecimalWithTwoPlaces  = validationRules.decimalWithTwoPlaces(partnerPaymentsFeesConfirmationFormResources.labelForFacilityAccessFeeAmount);
export const facilityAccessFeeAmountNumeric = validationRules.numericOrEmpty(partnerPaymentsFeesConfirmationFormResources.labelForFacilityAccessFeeAmount);

export const oneTimeFeeAmountRange  = validationRules.range(
    partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeAmount,
    0,
    999999.99
);
export const oneTimeFeeAmountDecimalWithTwoPlaces  = validationRules.decimalWithTwoPlaces(partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeAmount);
export const oneTimeFeeAmountNumeric = validationRules.numericOrEmpty(partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeAmount);

export const oneTimeFeeReasonRequiredIfGreaterThan = validationRules.requiredIfGreaterThan(
    'oneTimeFeeAmount',
    partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeReason,
    0
);

export const oneTimeFeeReasonMaxLength = validationRules.length(
    partnerPaymentsFeesConfirmationFormResources.labelForOneTimeFeeReason,
    null,
    250
);

export default (values, props) => {
    const errors = {};

    if (values.get('startupFeeAmount') <= 0 && values.get('renewalFeeAmount') <= 0
        && values.get('facilityAccessFeeAmount') <= 0 && values.get('oneTimeFeeAmount') <= 0) {
        const errorMessage = 'At least one Fee must be greater than $0.00.';
        errors._error = errorMessage;
    }

    return errors;
};