import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Quotes } from '../../assets/icons/quotes.svg';
import './_quotes-section.scss';

function QuotesSection({ header, description, text, signature }) {
    return (
        <div className="quotes-section">
            <div className="quotes-section__description-wrapper">
                <h2 className="quotes-section__header">{header}</h2>
                <div className="quotes-section__description">{description}</div>
            </div>
            <div className="quotes-section__item-wrapper">
                <div className="quotes-section__item">
                    <div className="quotes-section__quotes">
                        <Quotes aria-hidden="true" />
                    </div>
                    <blockquote className="quotes-section__item-text">
                        {text}
                    </blockquote>
                    <div className="quotes-section__item-signature">
                        {signature}
                    </div>
                </div>
            </div>
        </div>
    );
}

QuotesSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired
};

export default QuotesSection;