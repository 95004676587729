import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import membersTransferResources from './membersTransferResources';
import Spinner from '../../../components/Spinner/Spinner';
import NewTable from '../../../components/NewInfoBox/NewTable';
import InfoBox from '../../../components/InfoBox/InfoBox';
import routes from '../../../routing/routes';
import selectors from './memberTransferSelectors';
import TooltipCell from '../../../components/NewInfoBox/TooltipCell';
import InfoBoxRow from '../../../components/InfoBox/InfoBoxRow';
import Button from '../../../components/controls/Button';
import './_member-transfer.scss';
import MaterialIcon from '../../../components/MaterialIcon/MaterialIcon';

function MembersTransferResult({ membersTransferResult, isLoading, history }) {
    const columns = useMemo(
        () => [
            {
                Header: membersTransferResources.labelFacilityMemberID,
                accessor: membersTransferResources.accessorFacilityMemberID,
                minWidth: 128,
                width: 128
            },
            {
                id: membersTransferResources.accessorEmployeeName,
                Header: membersTransferResources.labelEmployeeName,
                accessor: (x) => {
                    return x.memberFirstName ? `${x.memberFirstName} ${x.memberLastName}` : '';
                },
                minWidth: 200,
                width: 200
            },
            {
                Header: membersTransferResources.labelPlans,
                accessor: membersTransferResources.accessorPlanName,
                width: 200,
                maxWidth: 400,
                disableSortBy: true,
                Cell: TooltipCell
            },
            {
                Header: membersTransferResources.labelFacilityFromName,
                accessor: membersTransferResources.accessorFacilityFromName,
                width: 128,
                maxWidth: 224,
                Cell: TooltipCell
            },
            {
                Header: membersTransferResources.labelFacilityFromID,
                accessor: membersTransferResources.accessorFacilityFromIDNumber,
                minWidth: 102,
                width: 102
            },
            {
                Header: membersTransferResources.labelFacilityToName,
                accessor: membersTransferResources.accessorFacilityToName,
                width: 128,
                maxWidth: 224,
                Cell: TooltipCell
            },
            {
                Header: membersTransferResources.labelFacilityToID,
                accessor: membersTransferResources.accessorFacilityToIDNumber,
                minWidth: 102,
                width: 102
            },
            {
                Header: membersTransferResources.labelTransferStatus,
                accessor: (x) => {
                    return x.transferStatus ? 'transferred' : 'failed';
                },
                minWidth: 102,
                width: 102
            },
            {
                Header: membersTransferResources.labelTransferReason,
                accessor: membersTransferResources.accessorTransferReason,
                minWidth: 102,
                width: 102
            }
        ],
        [membersTransferResources]
    );
    const getMemberId = (item) => {
        return item.facilityMemberID;
    };
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <div className="members-transfer-result">
                <h2 className="members-transfer-result__title">{membersTransferResources.title}</h2>
                <InfoBox>
                    <NewTable
                        columns={columns}
                        initialSortBy={{
                            id: membersTransferResources.defaultSortColumn,
                            desc: false
                        }}
                        initialData={membersTransferResult}
                        errorRowCondition={row => row.original.transferStatus === false}
                        update={() => { }}
                        updateSelectedItems={() => {}}
                        totalCount={membersTransferResult.toJS().length}
                        getItemId={getMemberId}
                    />
                    <InfoBoxRow>
                        <Button className="btn_new btn_new--white"
                            onClick={() => {
                                history.push(routes.members.path);
                            }}
                            type="button"
                        >
                            <MaterialIcon className="btn_new-icon btn_new-icon--left" icon="arrow_back" />
                            {membersTransferResources.backToMemberButtonText}
                        </Button>
                    </InfoBoxRow>
                </InfoBox>
            </div>
        );
}

MembersTransferResult.propTypes = {
    membersTransferResult: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired
};

const connectStateToProps = connect(selectors, {});

export default connectStateToProps(MembersTransferResult);