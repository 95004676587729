import * as types from './types';

import { initialState } from './initials';

export default function reducer(state = initialState, action) {
    const { type, payload = {} } = action;
    switch (type) {
        case types.SET_LOADING:
            return state.set('isLoading', true);
        case types.UNSET_LOADING:
            return state.set('isLoading', false);
        case types.SET_IS_BANKING_REQUIRED:
            return state.set('isBankingRequired', payload.isBankingRequired);
        case types.SET_IS_REIMBURSEMENT_ENABLED:
            return state.set('isReimbursementEnabled', payload.isReimbursementEnabled);
        default:
            return state;
    }
}
