import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function getPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return http.get(replaceRouteParams(API_PATHS.getFacilityPayments, programPaymentTypeId, periodId), { params: { partnerId, showCorrectedOnly } });
};

export function downloadPayments(programPaymentTypeId, periodId, partnerId, showCorrectedOnly) {
    return http.get(replaceRouteParams(API_PATHS.downloadFacilityProgramPayments, programPaymentTypeId, periodId), { responseType: 'blob', params: { partnerId, showCorrectedOnly } });
};

export function updateFacilityPaymentAmountToBeSent(programTypeId, programPaymentTypeId, paymentId, amountToBeSent) {
    return http.put(API_PATHS.updateFacilityPaymentAmountToBeSent, null, { params: { programTypeId, programPaymentTypeId, paymentId, amountToBeSent } } );
};

export function undoFacilityPaymentAmountCorrection(programTypeId, programPaymentTypeId, paymentId) {
    return http.put(API_PATHS.undoFacilityPaymentAmountCorrection, null, { params: { programTypeId, programPaymentTypeId, paymentId } } );
};