import useWindowSize from '../hooks/useWindowSize';
import { MEDIUM, LARGE } from '../constants/responsiveBreakpoints';

export function isMobileSize() {
    const windowSize = useWindowSize();
    return windowSize.width < MEDIUM;
}

export function isTabletSize() {
    const windowSize = useWindowSize();
    return windowSize.width >= MEDIUM && windowSize.width < LARGE;
}

export function isDesktopSize() {
    const windowSize = useWindowSize();
    return windowSize.width >= LARGE;
}