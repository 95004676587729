import { createSelector } from 'reselect';
import { GET_FACILITIES } from './types';
import { ENROLL_MEMBER_IN_FACILITTY } from '../FacilityEnrollmentPage/facilityEnrollmentPageTypes';

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === ENROLL_MEMBER_IN_FACILITTY || i === GET_FACILITIES)
        .size;
    return size > 0;
};

export function getFacilities(state) {
    return state.memberProfileFacilityInfo.get('facilities');
}
export const getSelectedMemberGroup = state => state.app.get('selectedMemberGroup');

export const getMemberHasFacilityEnrollments = createSelector(
    [getFacilities],
    (facilities) => {
        return  facilities && facilities.size !== 0;
    }
);

export default createSelector(
    [
        getIsLoading,
        getFacilities,
        getSelectedMemberGroup,
        getMemberHasFacilityEnrollments
    ],
    (
        isLoading,
        facilities,
        selectedMemberGroup,
        memberHasFacilityEnrollments
    ) => ({
        isLoading,
        facilities,
        selectedMemberGroup,
        memberHasFacilityEnrollments
    })
);