import { createSelector } from 'reselect';
import { CONTACT_US_NOTIFICATION, GET_USER_DESCRIPTION_LIST } from './contactUsTypes';

export const getUserDescriptions = (state) => {
    return state.contactUsReducer.get('userDescriptions');
};

export const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === CONTACT_US_NOTIFICATION || i === GET_USER_DESCRIPTION_LIST
    ).size;
    return size > 0;
};

export default createSelector([getUserDescriptions, getIsLoading], (userDescriptions, isLoading) => ({
    userDescriptions,
    isLoading
}));
