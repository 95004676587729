import React from 'react';
import styles from './_muibutton.module.scss';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import './_muibutton.scss';

function MuiButton({ theme, label, size, isMaxWidth, keepLabelCase, icon, className, noPadding, ...props }) {
    return (<div className={classNames(
        [
            styles.mui_button_container,
            { [styles.mui_button_container_no_padding]: noPadding }
        ])} >
        <Button className={classNames([
            { 'btn': theme || !className },
            { 'btn_blue': !theme && !className },
            { [`btn_${theme}`]: theme },
            { 'btn_medium': !theme && !className },
            { [`btn_${size}`]: theme && size },
            { 'btn_max-width': isMaxWidth },
            { 'btn_original-case': keepLabelCase },
            { [className]: className }])}
            {...props}
        >{props.children}</Button>
    </div>);
};

MuiButton.defaultProps = {
    size: 'medium',
    isMaxWidth: false,
    keepLabelCase: false,
    className: '',
    noPadding: false
};

export default MuiButton;
