import { createSelector } from 'reselect';
import { GET_PARTNERS_LIST } from './partnerListTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_PARTNERS_LIST)
        .size;

    return size > 0;
};

const getPartners = state => state.partnersListReducer.get('partners');

export default createSelector(
    [getIsLoading, getPartners],
    (isLoading, partners) => ({
        isLoading,
        partners,
    })
);
