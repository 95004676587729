import * as types from './loginTypes';
import initialState from './loginInitialState';

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_IS_LOGIN_POPUP_OPEN:
            return state.set('isLoginPopupOpen', action.isLoginPopupOpen);
        case types.SET_LOGIN_REDIRECT_URL:
            return state.set('redirectUrl', action.redirectUrl);
        default:
            return state;
    }
}
