import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import routes from '../../../routing/routes';
import * as actions from './storageActions';
import selectors from './storageSelectors';
import { authUserType } from '../../../propTypes/customTypes';
import roles from '../../../enums/roles';

function Storage({ history, match, me, getFile }) {
    useEffect(() => {
        let redirectPath;
        switch (me.role) {
            case roles.systemAdmin:
                redirectPath = routes.adminArea.path;
                break;
            case roles.member:
                redirectPath = routes.memberArea.path;
                break;
            default:
                redirectPath = routes.root.path;
        };
        getFile(match.params.filePartitionKey, match.params.fileRowKey)
            .then(() => {
                history.push(redirectPath);
            });
    }, []);
    return <Fragment />;
};

Storage.propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    me: authUserType,
    getFile: PropTypes.func
};

const mapDispatchToProps = {
    ...actions
};

export default connect(selectors, mapDispatchToProps)(Storage);