import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import {
    GET_BILLING_INFO,
    GET_PAYMENT_TYPES_LIST,
    UPDATE_BILLING_INFO,
    GET_BANK_ACCOUNT_DETAILS
} from './partnerBillingInfoTypes';
import partnerBillingTypes from '../../../enums/partnerBillingTypes';
import partnerBillingInfoResources from './partnerBillingInfoResources';

export const getIsLoading = (state) => {
    return state.loadingReducer.get('loadings').filter(
        i => i === GET_BILLING_INFO || i === GET_PAYMENT_TYPES_LIST || i === UPDATE_BILLING_INFO || i === GET_BANK_ACCOUNT_DETAILS
    ).size > 0;
};

export const getBillingInfoSelector = state => state.partnerBillingInfoReducer.get('billingInfo');
export const getPaymentTypesListSelector = state => state.partnerBillingInfoReducer.get('paymentTypesList');

const getPayeeToken = createSelector([getBillingInfoSelector], billingInfo => billingInfo.getIn(['bankAccount', 'payeeToken']));
const getAccountNumber = state => state.partnerBillingInfoReducer.getIn(['bankAccountDetails', 'accountNumber']);
const getRoutingNumber = state => state.partnerBillingInfoReducer.getIn(['bankAccountDetails', 'routingNumber']);

const formSelector = formValueSelector(partnerBillingInfoResources.formPartnerBillingInfo, state => state.form.toJS());
const getPartnerBillingTypeID = state => formSelector(state, partnerBillingInfoResources.fieldPartnerBillingTypeID);
const getIsWithdrawalInfoVisible = createSelector([getPartnerBillingTypeID],
    partnerBillingTypeID => partnerBillingTypeID === partnerBillingTypes.DirectFromBankAccount);

export default createSelector(
    [getIsLoading, getBillingInfoSelector, getPaymentTypesListSelector, getIsWithdrawalInfoVisible, getPayeeToken, getAccountNumber, getRoutingNumber],
    (isLoading, billingInfo, paymentTypesList, isWithdrawalInfoVisible, payeeToken, accountNumber, routingNumber) => ({
        isLoading,
        billingInfo,
        paymentTypesList,
        isWithdrawalInfoVisible,
        payeeToken,
        accountNumber,
        routingNumber
    })
);
