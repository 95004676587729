import { createSelector } from 'reselect';
import { GET_VOUCHERS, UPLOAD_RECIPIENTS_LIST, PUBLISH_VOUCHER_PROGRAM, PREVIEW_VOUCHER } from './recipientsListTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === GET_VOUCHERS || i === UPLOAD_RECIPIENTS_LIST || i === PUBLISH_VOUCHER_PROGRAM)
        .size;
    return size > 0;
};

const getIsEmailPreviewLoading = (state) => {
    const size = state.loadingReducer.get('loadings')
        .filter(i => i === PREVIEW_VOUCHER)
        .size;
    return size > 0;
};

const getRecipientsFile = (state) => {
    return state.recipientsListReducer.get('recipientsFile');
};

const getRecipientsFileContentValidationErrors = (state) => {
    return state.recipientsListReducer.get('recipientsFileContentValidationErrors');
};

const getVouchers = (state) => {
    return state.recipientsListReducer.get('vouchers');
};

const getVoucherEmailPeview = (state) => {
    return state.recipientsListReducer.get('voucherEmailPeview');
};

const getIsPublishPopupVisible = (state) => {
    return state.recipientsListReducer.get('isPublishPopupVisible');
};

const getIsVoucherProgramPublished = (state) => {
    return state.recipientsListReducer.get('isVoucherProgramPublished');
};

const getRecipientMetadataDictionary = createSelector([getVouchers], (vouchers) => {
    const firstVoucher = vouchers && vouchers.toJS()[0];
    return firstVoucher
        ? firstVoucher.recipientMetaDataDictionary
        : {};
});

const getHasVouchers = createSelector([getVouchers], (vouchers) => {
    const firstVoucher = vouchers && vouchers.toJS()[0];
    return !!firstVoucher;
});

const getIsPreviewPopupVisible = (state) => {
    return state.recipientsListReducer.get('isPreviewPopupVisible');
};

export default createSelector(
    [getIsLoading, getRecipientsFile, getRecipientsFileContentValidationErrors,
        getVouchers, getVoucherEmailPeview, getIsPublishPopupVisible,
        getIsVoucherProgramPublished, getRecipientMetadataDictionary, getHasVouchers, getIsPreviewPopupVisible, getIsEmailPreviewLoading],
    (isLoading, recipientsFile, recipientsFileContentValidationErrors,
        vouchers, voucherEmailPeview, isPublishPopupVisible,
        isVoucherProgramPublished, recipientMetaDataDictionary, hasVouchers, isPreviewPopupVisible, isEmailPreviewLoading) => (
        {
            isLoading,
            recipientsFile,
            recipientsFileContentValidationErrors,
            vouchers,
            voucherEmailPeview,
            isPublishPopupVisible,
            isVoucherProgramPublished,
            recipientMetaDataDictionary,
            hasVouchers,
            isPreviewPopupVisible,
            isEmailPreviewLoading
        }
    )
);
