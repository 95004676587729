import { createSelector } from 'reselect';
import {
    SAVE_PARTNER,
    GET_PARTNER
} from '../../Shared/Partners/managePartnerTypes';
import { GET_PROGRAM_TYPES } from '../../ProgramTypes/programTypeTypes';
import { GET_STATES_LIST } from '../../States/stateTypes';

const getIsLoading = (state) => {
    const size = state.loadingReducer.get('loadings').filter(
        i => i === SAVE_PARTNER ||
				i === GET_PARTNER ||
				i === GET_PROGRAM_TYPES ||
				i === GET_STATES_LIST
    ).size;

    return size > 0;
};
const getPartner = state => state.managePartnerReducer.get('partner');
const getStates = state => state.stateReducer.get('states');
const getProgramTypes = state => state.programTypeReducer.get('programTypes');

export default createSelector(
    [getIsLoading, getPartner, getStates, getProgramTypes],
    (isLoading, partner, states, programTypes) => ({
        isLoading,
        partner,
        states,
        programTypes,
    })
);
