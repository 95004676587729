import validationRules from '../../../utilities/validationRules';
import partnerBillingInfoResources from './partnerBillingInfoResources';

export const partnerBillingTypeRequired = validationRules.required(
    partnerBillingInfoResources.labelBillingType
);

export const routingNumberRequired = validationRules.required(
    partnerBillingInfoResources.labelRoutingNumber
);

export const accountNumberRequired = validationRules.required(
    partnerBillingInfoResources.labelAccountNumber
);

export const accountTypeRequired = validationRules.required(
    partnerBillingInfoResources.labelAccountType
);