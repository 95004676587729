import validationRules from '../../utilities/validationRules';
import confirmPasswordResources from './confirmPasswordResources';

export const passwordRegex = validationRules.passwordRegex;

export const newPasswordRequired = validationRules.required(
    confirmPasswordResources.labelNewPassword
);
export const passwordRequired = validationRules.required(
    confirmPasswordResources.labelPassword
);
export const confirmPasswordRequired = validationRules.required(
    confirmPasswordResources.labelConfirmPassword
);
export const newPasswordMaxLength30 = validationRules.length(
    confirmPasswordResources.labelNewPassword,
    undefined,
    30
);
export const passwordMaxLength30 = validationRules.length(
    confirmPasswordResources.labelPassword,
    undefined,
    30
);
export const confirmPasswordMaxLength30 = validationRules.length(
    confirmPasswordResources.labelConfirmPassword,
    undefined,
    30
);
