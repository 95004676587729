import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';

function getVisits(partnerID, periodID) {
    return http.get(API_PATHS.getVisits, { params: { partnerID, periodID } });
}

function downloadVisits(partnerID, periodID) {
    return http.get(API_PATHS.downloadVisits, { responseType: 'blob', params: { partnerID, usagePeriodID: periodID } });
};

export default {
    getVisits,
    downloadVisits
};