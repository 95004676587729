import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, generatePath } from 'react-router-dom';
import selectors from './partnerInfoSelectors';
import NavigationTabControl from '../../components/NavigationTabControl/NavigationTabControl';
import routes, { routeType } from '../../routing/routes';
import ConditionalRedirect from '../../routing/conditionalRedirect';
import CustomRoute from '../../layouts/CustomRoute';
import { resetBankAccount } from './BankingInfo/partnerBankingInfoActions';
import { useFlags } from 'launchdarkly-react-client-sdk';

function PartnerInfoPage({ match, history, currentRoute, filteredRoutes, shouldRedirect, resetBankAccount, isGroupMembershipAddedToPartner }) {
    useEffect(() => {
        resetBankAccount();
    }, []);
    const { gmrAddProgram } = useFlags();
    const conditionedRoutes = currentRoute.routes.filter(
        (route) => {
            switch (route.path) {
                case routes.partnerBankingInfo.path:
                    return !gmrAddProgram && isGroupMembershipAddedToPartner;
                case routes.partnerBillingInfo.path:
                    return gmrAddProgram;
                default:
                    return true;
            }
        }
    );
    const routesForNavigation = conditionedRoutes.map((route) => {
        return { ...route, path: generatePath(route.path, match.params) };
    });
    return <div className="layout__new-container">
        <h2 className="layout__new-title">Partner Information</h2>
        <NavigationTabControl
            tabs={routesForNavigation}
            onNavigate={history.push}
        />
        <Switch>
            {filteredRoutes.map((route, i) => (
                <CustomRoute
                    key={i}
                    path={route.path}
                    currentRoute={route}
                    filteredRoutes={routesForNavigation}
                />
            ))}

            <ConditionalRedirect
                condition={shouldRedirect}
                fromRoute={currentRoute}
                toRoute={routesForNavigation[0]}
            />
        </Switch>
    </div>;
};

PartnerInfoPage.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    currentRoute: routeType.isRequired,
    shouldRedirect: PropTypes.bool,
    filteredRoutes: PropTypes.arrayOf(routeType),
    resetBankAccount: PropTypes.func.isRequired,
    isGroupMembershipAddedToPartner: PropTypes.bool
};

export default connect(selectors, { resetBankAccount })(PartnerInfoPage);
