export const BASE_URLS = {
    HC_Site: process.env.REACT_APP_HC_SITE,
    api: process.env.REACT_APP_API_URL
};

export const API_PATHS = {
    login: `${BASE_URLS.HC_Site}/HCLogin/login`,
    logout: `${BASE_URLS.HC_Site}/HCLogin/logout`,
    homeSiteMap: `${BASE_URLS.HC_Site}/Home/SiteMap`,
    accessibilityMaintenance: `${BASE_URLS.HC_Site}/Info/Accessibility`,
    privacyNotice: '/privacy',
    termsOfUse: '/termsofuse',
    HCDashboard: `${BASE_URLS.HC_Site}/HCLogin/Dashboard`,
    facilityEnrollment: `${BASE_URLS.HC_Site}/OnlineFacilityEnrollment/LocationStep?isNew=True`,
    memberEnrollment: `${BASE_URLS.HC_Site}/Member/CheckMemberEnrollment?isNew=True`,
    calendlyUrl: 'https://calendly.com/healthycontributions',
    calendlyCalenderUrl: 'https://calendly.com/hcmarketing-qyuy/30min',
    allyant: 'https://allyant.com/',
    me: 'user/me',
    changePassword: '/user/changePassword',
    generalInfo: '/profile/generalInfo',
    bankingInfo: '/account/bankingInfo',
    facilityInfo: '/profile/facilityInfo',
    bankingCheck: '/account/bankingcheck',
    reimbursementCheck: '/account/reimbursementcheck',
    memberHasActiveFacilityEnrollments: '/members/hasActiveFacilityEnrollments',
    usageLog: '/profile/usageLog',
    facilityUsagesGroup: '/profile/facilityUsagesGroup',
    reimbursementHistory: '/account/reimbursementHistory',
    reimbursement: '/account/reimbursement',
    planReimbursementsGroup: '/account/reimbursementsGroup/{enrollmentEntity}/{planOrProgramID}',
    insuranceInfo: '/account/insuranceInfo',
    listPartners: '/partners/list',
    listPartnersByProgramType: '/partners/list/programtype',
    listPartnersByProgramPaymentType: '/partners/programpaymenttype/{programPaymentTypeId}',
    members: 'members',
    savePartner: '/partners/save',
    getPartner: '/partners/get',
    partnerBankingInfo: 'partners/bankingInfo',
    partnerBillingInfo: 'partners/{partnerID}/billingInfo',
    partnerBillingTypes: 'partners/billingTypes',
    updatePartnerBillingInfo: 'partners/{partnerID}/billingInfo',
    partnerBankAccountDetails: 'partners/bankAccountDetails',
    listStates: '/states/list',
    getManager: '/partnermanagers/get',
    createManager: '/partnermanagers/create',
    updateManager: '/partnermanagers/update',
    listManagers: '/partnermanagers/list',
    deleteManager: '/partnermanagers/delete',
    listProgramTypes: '/programtypes/list',
    getGroupMembership: '/groupmemberships/get',
    updateGroupMembership: '/groupmemberships/save',
    addGroupMembership: '/groupmemberships',
    createKeyfobs: '/employeemanagement/keyfobs/create',
    listKeyfobs: '/employeemanagement/keyfobs',
    keyfobsCount: '/employeemanagement/keyfobs/count',
    deactivateMembership: '/employeemanagement/membership/deactivate',
    deleteMemberGroupMembership: '/employeemanagement/membership/enrollment/{groupMembershipEnrollmentID}/{programPaymentTypeID}',
    updateMember: '/employeemanagement/members/{memberID}',
    reassignKeyfob: '/employeemanagement/membership/reassign',
    deleteKeyfob: '/employeemanagement/keyfob/{keyfobID}',
    uploadMemberRecipients: '/employeemanagement/members/upload',
    listFacilities: '/facility/list',
    getFacility: '/facility/get',
    getMemberFacility: '/facility/memberfacility',
    getFacilitiesForMap: '/facility/map',
    getPartnerPayments: '/payments/partner/groupMemberships/{programPaymentTypeId}/paymentType/{paymentTypeId}/periods/{periodId}',
    downloadPartnerPayments: '/payments/partner/groupMemberships/{programPaymentTypeId}/paymentType/{paymentTypeId}/periods/{periodId}/download',
    getPartnerSpecificPayments: '/payments/partner/groupmemberships/{programPaymentTypeId}/list',
    downloadPartnerSpecificPayments: '/payments/partner/groupmemberships/download',
    getPeriods: '/periods/list',
    getClosedUsagePeriods: '/periods/closedusageperiod/list',
    getPeriodsUntilCurrent: '/periods/untilCurrent',
    checkPartnerPaymentsWithdrawal: '/payments/partner/ach/check',
    sendPartnerPaymentsWithdrawal: '/payments/partner/ach/send',
    dowloadPaymentWithdrawal: '/payments/partner/ach/download',
    checkFacilityPaymentsDeposit: '/payments/facility/groupMemberships/{programPaymentTypeId}/partners/{partnerId}/periods/{periodId}/ach/check',
    sendFacilityPaymentsDeposit: '/payments/facility/groupMemberships/{programPaymentTypeId}/partners/{partnerId}/periods/{periodId}/ach/send',
    dowloadPaymentDeposit: '/payments/facility/groupMemberships/{programPaymentTypeId}/partners/{partnerId}/periods/{periodId}/ach/download',
    enrollIntoAFFacilityWithinProgram: '/members/membersaffacilitywithinprogram/enroll',
    getMemberLegalContractInfo: '/members/memberlegalcontract',
    getVisits: '/visits/list',
    downloadVisits: '/visits/download',
    downloadKeyfobsAndEmployees: '/employeemanagement/keyfobs/excelreport',
    generatePartnerPayments: '/payments/partner/generate',
    checkPartnerPaymentsExist: '/payments/partner/check',
    generateFacilityPayments: '/payments/facility/generate',
    checkFacilityPaymentsExist: '/payments/facility/check',
    downloadFacilityProgramPayments: '/payments/facility/groupMemberships/{programPaymentTypeId}/periods/{periodId}/download',
    getFacilityPayments: '/payments/facility/groupMemberships/{programPaymentTypeId}/periods/{periodId}',
    getMemberPayments: 'v1/payments/members/groupMemberships/{programPaymentTypeID}/paymentTypes/{paymentTypeID}/partners/{partnerID}/periods/{periodID}',
    checkMemberPaymentsProcessed: 'v1/payments/members/groupMemberships/{programPaymentTypeID}/paymentTypes/{paymentTypeID}/partners/{partnerId}/periods/{periodId}/ach/check',
    sendMemberPayments: 'v1/payments/members/groupMemberships/{programPaymentTypeID}/paymentTypes/{paymentTypeID}/partners/{partnerId}/periods/{periodId}/ach/send',
    updateFacilityPaymentAmountToBeSent: '/payments/facility/amount/correct',
    undoFacilityPaymentAmountCorrection: '/payments/facility/amount/undo',
    updatePartnerPaymentAmountToBeSent: '/payments/partner/amount/correct',
    undoPartnerPaymentAmountCorrection: '/payments/partner/amount/undo',
    getEquipmentAndFacilitiesPolicies: '/facility/equipmentandfacilitiespolicies',
    isGroupMembershipAddedToPartner: '/groupmemberships/isgroupmembershipaddedtopartner',
    getVoucherPrograms: '/voucherprograms/partner',
    publishVoucherProgram: '/voucherprograms/{voucherProgramID}/publish',
    checkVoucherProgramPublished: '/voucherprograms/{voucherProgramID}/check-published',
    getVoucherProgram: '/voucherprograms',
    saveVoucherProgram: '/voucherprograms/save',
    getVoucherProgramVouchers: '/vouchers/voucherprogram/{voucherProgramID}',
    uploadRecipients: '/vouchers/recipients',
    previewVoucherEmail: '/vouchers/preview/voucherprogram/{voucherProgramID}',
    isReassignKeyfobFeatureEnabled: '/applicationsettings/isreassignkeyfobfeatureenabled',
    getFacilitiesForClubLocatorMap: 'facility/clubLocatorMap',
    getPlansForClubLocatorMap: 'v1/plans/clubLocatorMap',
    resetPassword: 'user/password/reset',
    contactUs: 'contactUs',
    nominateClub: 'nominateClub/email',
    listUserDescription: '/contactUs/userDescription/list',
    facilityDropdownList: 'facility/search',
    memberTransfer: 'v1/member/transfer',
    getAuthorizedAgentDesignationForm: 'v1/resources/authorizedagentdesignationform',
    deactivateMember: '/members/deactivate',
    getProgramPaymentTypeID: '/groupmemberships/programPaymentTypeID',
    getMembersWaitingToInviteCount: '/employeemanagement/partners/{partnerID}/countWaitingToInvite',
    getPartnerPaymentTypesByProgramPaymentType: '/paymenttypes/partners/programPaymentType/{id}',
    getMemberPaymentTypesByProgramPaymentType: '/paymenttypes/members/programPaymentType/{id}',
    getFile: 'v1/storage/{partitionKey}/{rowKey}',
    membersBillingInfo: '/account/billingInfo',
    getCalculatedDues: '/members/calculateddues',
    hasEnrolledOrInvitedMembers: '/groupmemberships/hasEnrolledOrInvitedMembers',
};
export default { BASE_URLS, API_PATHS };
