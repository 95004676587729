import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { replaceRouteParams } from '../../../utilities/routeHelper';

export function checkFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId) {
    return http.get(replaceRouteParams(API_PATHS.checkFacilityPaymentsDeposit, programPaymentTypeId, partnerId, periodId));
};

export function sendFacilityPaymentsDeposit(programPaymentTypeId, partnerId, periodId, pin) {
    return http.post(replaceRouteParams(API_PATHS.sendFacilityPaymentsDeposit, programPaymentTypeId, partnerId, periodId), { pin });
};

export function dowloadPaymentDeposit(programPaymentTypeId, partnerId, periodId) {
    const config = {
        responseType: 'blob'
    };
    return http.get(replaceRouteParams(API_PATHS.dowloadPaymentDeposit, programPaymentTypeId, partnerId, periodId), config);
}