import {
    SET_FACILITY_USAGES_GROUP,
    GET_USAGE_LOG_SUCCESS,
    SET_LOADING,
    UNSET_LOADING,
    SET_SELECTED_USAGE_PERIOD,
    SET_FACILITY_USAGES_GROUP_LOADING,
    UNSET_FACILITY_USAGES_GROUP_LOADING
} from './types';

const setLoading = () => ({
    type: SET_LOADING
});

const unsetLoading = () => ({
    type: UNSET_LOADING
});

const getUsageLogSuccess = usageLog => ({
    type: GET_USAGE_LOG_SUCCESS,
    payload: { usageLog }
});

const getUsageLog = membersIds => (dispatch, getState, { UsageLogService }) => {
    dispatch(setLoading());

    UsageLogService.getUsageLog(membersIds)
        .then((usageLog) => {
            dispatch(getUsageLogSuccess(usageLog));
            dispatch(unsetLoading());
        })
        .catch(() => {
            dispatch(unsetLoading());
        });
};

const setFacilityUsagesGroup = facilityUsagesGroup => ({
    type: SET_FACILITY_USAGES_GROUP,
    payload: { facilityUsagesGroup }
});

const setSelectedUsagePeriod = selectedPeriodData => ({
    type: SET_SELECTED_USAGE_PERIOD,
    payload: { selectedPeriodData }
});

const setFacilityUsagesGroupLoading = memberGuid => ({
    type: SET_FACILITY_USAGES_GROUP_LOADING,
    payload: { memberGuid }
});

const unsetFacilityUsagesGroupLoading = memberGuid => ({
    type: UNSET_FACILITY_USAGES_GROUP_LOADING,
    payload: { memberGuid }
});

const reloadFacilityUsagesGroup = (usagePeriod, memberGuid) => (dispatch, getState, { UsageLogService }) => {
    dispatch(setFacilityUsagesGroupLoading(memberGuid));

    dispatch(setSelectedUsagePeriod({
        usagePeriod,
        memberGuid
    }));

    UsageLogService.getFacilityUsagesGroup(memberGuid, usagePeriod)
        .then((facilityUsagesGroup) => {
            dispatch(setFacilityUsagesGroup(facilityUsagesGroup));
            dispatch(unsetFacilityUsagesGroupLoading(memberGuid));
        })
        .catch(() => {
            dispatch(unsetFacilityUsagesGroupLoading(memberGuid));
        });
};

export default { getUsageLog, reloadFacilityUsagesGroup };
