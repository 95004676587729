import http from '../../http/base';
import { API_PATHS } from '../../http/configs';

export function getFacilitiesByProgramType(programTypeId)
{
    return http.get(API_PATHS.listFacilities, { params: { programTypeId } });
}
export function getFacility(facilityId)
{
    return http.get(API_PATHS.getFacility, { params: { facilityId } });
}
export function getMemberFacility(memberGuid)
{
    return http.get(API_PATHS.getMemberFacility, { params: { memberGuid } });
}