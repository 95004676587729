import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { transferMembers } from './MemberTransferActions';
import Button from '../../../components/controls/Button';
import FacilitySearchBox from '../FacilitySearchBox/FacilitySearchBox';
import './_member-transfer.scss';
import selectors from './memberTransferSelectors';
import routes from '../../../routing/routes';
import membersTransferResources from './membersTransferResources';

export function MemberTransfer({ selectedMembers, transferMembers, history }) {
    const [selectedFacility, setSelectedFacility] = useState(null);
    const changeHandler = (value) => {
        setSelectedFacility(value);
    };

    const memberTransferHandler  = () => {
        transferMembers(selectedFacility, selectedMembers)
            .then(() => {
                history.push(routes.membersTransferResult.path);
            });
    };

    return (
        <>
            <div className="member-transfer__fields-wrapper">
                <div className="member-transfer__fields-wrapper__fields">
                    <FacilitySearchBox onChange={changeHandler}
                        placeholder={'Search by Facility ID, Facility name'}
                    />
                </div>
                <div className="member-transfer__fields-wrapper__action">
                    <Button className="btn_new btn_new--blue"
                        onClick={memberTransferHandler}
                        disabled={selectedFacility === null}
                    >
                        {membersTransferResources.transferButtonText}
                    </Button>
                </div>
            </div>
        </>
    );
};

MemberTransfer.propTypes = {
    selectedMembers: PropTypes.object.isRequired,
    transferMembers: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
};

const connectStateToProps = connect(selectors, { transferMembers });

export default connectStateToProps(MemberTransfer);


