import http from '../../../http/base';

export default ({ API_PATHS }) => ({
    getUsageLog: memberGuids => http.get(API_PATHS.usageLog, { params: { memberGuids } }),
    getFacilityUsagesGroup: (memberGuid, usagePeriod) => http.get(API_PATHS.facilityUsagesGroup, {
        params: {
            memberGuid,
            usagePeriodId: usagePeriod.get('id')
        }
    })
});