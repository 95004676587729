import * as types from './recipientsListTypes';
import * as loadingActions from '../../Loading/loadingActions';
import * as recipientsListService from './recipientsListService';
import addImagesFromAttachments from '../../../utilities/emailTemplate';

const setRecipientsFileContentValidationErrorsAction = (recipientsFileContentValidationErrors) => {
    return { type: types.SET_RECIPIENTS_FILE_CONTENT_VALIDATION_ERRORS, recipientsFileContentValidationErrors };
};

export function setRecipientsFileContentValidationErrors(recipientsFileContentValidationErrors) {
    return function (dispatch) {
        dispatch(setRecipientsFileContentValidationErrorsAction(recipientsFileContentValidationErrors));
    };
}

export function uploadRecipients(voucherProgramId, recipientsFile) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.UPLOAD_RECIPIENTS_LIST));
        return recipientsListService
            .uploadRecipients(voucherProgramId, recipientsFile)
            .then((response) => {
                if (response.success) {
                    dispatch(setRecipientsFileContentValidationErrorsAction([]));
                }
            })
            .catch((response) => {
                if (response.data) {
                    dispatch(setRecipientsFileContentValidationErrorsAction(response.data.data || []));
                }
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.UPLOAD_RECIPIENTS_LIST));
            });
    };
}

const setVouchersAction = (vouchers) => {
    return { type: types.SET_VOUCHERS, vouchers };
};

export function getVouchers(voucherProgramId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_VOUCHERS));
        return recipientsListService
            .getVouchers(voucherProgramId)
            .then((vouchers) => {
                dispatch(setVouchersAction(vouchers));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_VOUCHERS));
            });
    };
}

const setVouchersEmailPreviewAction = (voucherEmailPeview) => {
    return { type: types.SET_VOUCHER_EMAIL_PREVIEW, voucherEmailPeview };
};

export function previewVoucherEmail(voucherProgramId) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.PREVIEW_VOUCHER));
        return recipientsListService
            .previewVoucherEmail(voucherProgramId)
            .then((response) => {
                if (response !== null) {
                    const emailBody = addImagesFromAttachments(response.body, response.attachments);
                    dispatch(setVouchersEmailPreviewAction(emailBody));
                }
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.PREVIEW_VOUCHER));
            });
    };
}

export function setVouchersEmailPreview(vouchersEmailPreview) {
    return function (dispatch) {
        dispatch(setVouchersEmailPreviewAction(vouchersEmailPreview));
    };
}

export function publishVoucherProgram(voucherProgramID) {
    return function (dispatch) {
        dispatch(setIsPublishPopupVisibleAction(false));
        dispatch(loadingActions.setLoading(types.PUBLISH_VOUCHER_PROGRAM));
        return recipientsListService
            .publishVoucherProgram(voucherProgramID)
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.PUBLISH_VOUCHER_PROGRAM));
            });
    };
}

const setIsPublishPopupVisibleAction = (isPublishPopupVisible) => {
    return { type: types.SET_IS_PUBLISH_POPUP_VISIBLE, isPublishPopupVisible };
};

export function setIsPublishPopupVisible(isPublishPopupVisible) {
    return function (dispatch) {
        dispatch(setIsPublishPopupVisibleAction(isPublishPopupVisible));
    };
}

const setIsVoucherProgramPublished = (isVoucherProgramPublished) => {
    return { type: types.SET_IS_VOUCHER_PROGRAM_PUBLISHED, isVoucherProgramPublished };
};

export function checkVoucherProgramPublished(voucherProgramID) {
    return function (dispatch) {
        dispatch(loadingActions.setLoading(types.GET_IS_VOUCHER_PROGRAM_PUBLISHED));
        return recipientsListService
            .checkVoucherProgramPublished(voucherProgramID)
            .then((response) => {
                dispatch(setIsVoucherProgramPublished(response.data));
            })
            .finally(() => {
                dispatch(loadingActions.unsetLoading(types.GET_IS_VOUCHER_PROGRAM_PUBLISHED));
            });
    };
}

const setIsPreviewPopupVisibleAction = (isPreviewPopupVisible) => {
    return { type: types.SET_IS_PREVIEW_POPUP_VISIBLE, isPreviewPopupVisible };
};

export function setIsPreviewPopupVisible(isPreviewPopupVisible) {
    return function (dispatch) {
        dispatch(setIsPreviewPopupVisibleAction(isPreviewPopupVisible));
    };
};