import http from '../../../http/base';
import { API_PATHS } from '../../../http/configs';
import { MULTIPART_FORM_DATA } from '../../../constants/contentTypes';

export function getVoucherProgram(voucherProgramId) {
    return http.get(API_PATHS.getVoucherProgram, { params: { voucherProgramId } });
}

export function saveVoucherProgram(voucherProgram, partnerLogoFile) {
    const formData = new FormData();
    formData.append('voucherProgram', JSON.stringify(voucherProgram));
    formData.append('partnerLogoFile', partnerLogoFile);
    const config = {
        headers: {
            'content-type': MULTIPART_FORM_DATA
        }
    };
    return http.post(API_PATHS.saveVoucherProgram, formData, config);
}