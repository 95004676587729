import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import guid from '../../utilities/guid';

const InputRadio = ({ label, id, disabled, ...props }) => {
    const controlClass = 'c-control';
    const inputTypeClass = 'c-radio';
    const uniqueId = `id_${guid()}`;

    return (
        <div className={classNames([
            `${controlClass} ${inputTypeClass}`,
            { [`${controlClass}_disabled`]: disabled }
        ])}
        >
            <input
                id={uniqueId}
                disabled={disabled}
                className={`${controlClass}__field ${inputTypeClass}__field`}
                type="radio"
                {...props}
            />
            <label className={`${controlClass}__label ${inputTypeClass}__label`} htmlFor={uniqueId}>
                <span className={`${controlClass}__indicator ${inputTypeClass}__indicator`} />
                {label}
            </label>
        </div>
    );
};

InputRadio.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func
};

export default InputRadio;