import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';

export function MuiAutocomplete(props) {
    const {
        defaultValue,
        onInputChange,
        options,
        getOptionLabel,
        filterOptions,
        autoComplete,
        id,
        noOptionsText,
        inputLabel,
        variant,
        disabled,
        fullWidth,
        onChange,
        placeholder,
        value,
        loading
    } = props;
    return (
        <Autocomplete
            id={id}
            options={options || []}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            autoComplete={autoComplete}
            noOptionsText={noOptionsText}
            onInputChange={onInputChange}
            defaultValue={defaultValue}
            fullWidth={fullWidth}
            disabled={disabled}
            onChange={onChange}
            value={value}
            loading={loading}
            renderInput={params => (
                <TextField {...params} label={inputLabel}
                    placeholder={placeholder}
                    variant={variant}
                />
            )}
        />
    );
}

MuiAutocomplete.defaultProps = {
    variant: 'outlined',
    disabled: false,
    fullWidth: true,
    loading: false
};

MuiAutocomplete.propTypes = {
    defaultValue: PropTypes.object,
    onInputChange: PropTypes.func,
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    fullWidth: PropTypes.bool,
    filterOptions: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.object,
    placeholder: PropTypes.string
};