import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { connect } from 'react-redux';
import selectors from './selectors';
import actions from './actions';
import Spinner from '../../../components/Spinner/Spinner';
import FacilityUsagesGroup from './FacilityUsagesGroup/view';
import withMemberGroup from '../../../hoc/withMemberGroup';

class UsageLog extends Component {
    constructor(props) {
        super(props);

        this.handleReloadFacilityUsagesGroup = this.handleReloadFacilityUsagesGroup.bind(this);
    }

    handleReloadFacilityUsagesGroup(usagePeriod, memberGuid) {
        this.props.reloadFacilityUsagesGroup(usagePeriod, memberGuid);
    };

    render() {
        return (
            this.props.isLoading
                ? <div className="usage-log grid__row" id="usage_log"><Spinner/></div>
                : <div className="usage-log grid__row" id="usage_log">
                    <div className="col-xs-12">
                        {
                            this.props.facilityUsagesGroups.map((facilityUsagesGroup, index) => (
                                <FacilityUsagesGroup
                                    key={index}
                                    onRefresh={this.handleReloadFacilityUsagesGroup}
                                    facilityUsagesGroup={facilityUsagesGroup}
                                    availableUsagePeriods={this.props.availableUsagePeriods}
                                />
                            ))
                        }
                    </div>
                </div>
        );
    }
}

UsageLog.propTypes = {
    getUsageLog: PropTypes.func,
    reloadFacilityUsagesGroup: PropTypes.func,
    availableUsagePeriods: PropTypes.instanceOf(List),
    selectedMemberGroup: PropTypes.object,
    facilityUsagesGroups: PropTypes.instanceOf(List),
    isLoading: PropTypes.bool
};


const withMemberGroupObserved = withMemberGroup({
    actionName: 'getUsageLog'
})(UsageLog);

export default connect(selectors, actions)(withMemberGroupObserved);
