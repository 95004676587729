import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../../../components/Spinner/Spinner';
import Button from '../../../../components/controls/Button';
import { connect } from 'react-redux';
import selectors from './partnerManagersListSelectors';
import routes from '../../../../routing/routes';
import { generatePath } from 'react-router-dom';
import InfoBox from '../../../../components/InfoBox/InfoBox';
import InfoBoxRow from '../../../../components/InfoBox/InfoBoxRow';
import partnerManagerResources from '../../../Shared/PartnerManager/partnerManagerResources';
import Table from '../../../../components/InfoBox/Table';
import { getManagersList, deleteManager } from './partnerManagersListActions';
import ActionCell from '../../../../components/InfoBox/ActionCell';
import WrappedConfirmPopup from '../../../../components/Popups/ConfirmAlert/WrappedConfirmPopup';

function PartnerManagersList({
    isLoading,
    history,
    match,
    managers,
    getManagersList,
    deleteManager
}) {
    const partnerManagersAddPath = generatePath(
        routes.partnerManagersAdd.path,
        match.params
    );
    useEffect(() => {
        getManagersList(match.params.partnerId);
    }, []);
    const onDeleteButtonClick = value =>
        WrappedConfirmPopup({
            body: <div className="message">Are you sure you want to delete this user?</div>,
            onConfirm: () => deleteManager(value)
        });
    const columns = useMemo(
        () => [
            {
                Header: partnerManagerResources.labelFirstName,
                accessor: 'firstName'
            },
            {
                Header: partnerManagerResources.labelLastName,
                accessor: 'lastName'
            },
            {
                Header: partnerManagerResources.labelEmailAddress,
                accessor: 'email'
            },
            {
                Header: 'Actions',
                disableSortBy: true,
                accessor: 'id',
                Cell: ActionCell,
                getProps: () => ({
                    handler: onDeleteButtonClick,
                    label: 'Delete',
                    theme: 'red'
                })
            }
        ],
        [partnerManagerResources]
    );
    const defaultSorted = useMemo(() => [{ id: 'firstName' }]);
    return isLoading ?
        (
            <Spinner />
        )
        : (
            <InfoBox bordered>
                <InfoBoxRow>
                    <Button
                        className="btn_new btn_new--white"
                        onClick={() => {
                            history.push(partnerManagersAddPath);
                        }}
                    >
                        {partnerManagerResources.addManagerButtonText}
                    </Button>
                </InfoBoxRow>
                <Table
                    columns={columns}
                    data={managers.toJS()}
                    defaultSorted={defaultSorted}
                />
                <br />
            </InfoBox>
        );
}

PartnerManagersList.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    managers: PropTypes.object.isRequired,
    getManagersList: PropTypes.func.isRequired,
    deleteManager: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getManagersList,
    deleteManager
};

export default connect(selectors, mapDispatchToProps)(PartnerManagersList);
